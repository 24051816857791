import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IonButton, IonCard, IonIcon, IonLabel } from '@ionic/react'
import { pencil, trash } from 'ionicons/icons'
import { useTranslation } from 'react-i18next'
import { Field } from '../EditFieldModal'
import './EditModeSchemaField.scss'

interface EditModeSchemaFieldProps {
  field: Field
  onEditClick: (fieldName: string) => void
  onDeleteClick: (fieldName: string) => void
}

// 編輯模式下，客戶選單/內部選單欄位的元件
// 顯示該欄位的類型（如"下拉式選單"、"文字欄位"）與欄位名稱（如"樓層"、"戶別"）
// 以及編輯與刪除欄位按鈕
// 備註：這個元件只是單純顯示資訊，要編輯欄位詳細內容需要點擊編輯按鈕
const EditModeSchemaField = ({
  field,
  onEditClick,
  onDeleteClick,
}: EditModeSchemaFieldProps) => {
  const { t } = useTranslation()
  const {
    component: componentType,
    label,
    name,
    options,
    placeholder,
    helperText,
    initialValue,
  } = field
  return (
    <IonCard className="edit-mode-schema-field">
      <div className="toolbar">
        <span>{label}</span>
        <div>
          {/* 「編輯」按鈕 */}
          <IonButton
            fill="clear"
            slot="end"
            onClick={() => onEditClick?.(name)}
          >
            <IonIcon slot="start" icon={pencil}></IonIcon>
            <IonLabel>{t('Edit')}</IonLabel>
          </IonButton>

          {/* 「刪除」按鈕 */}
          <IonButton
            fill="clear"
            color="danger"
            slot="end"
            onClick={() => onDeleteClick?.(name)}
          >
            <IonIcon slot="start" icon={trash}></IonIcon>
            <IonLabel>{t('Delete')}</IonLabel>
          </IonButton>
        </div>
      </div>

      {/* 欄位類型（如"下拉式選單"、"文字欄位"） */}
      <div className="property">
        <p className="property-key">{t('Field type')}</p>
        <p className="property-value">
          {componentType === 'select' && (
            <FontAwesomeIcon icon="caret-square-down" className="icon" />
          )}
          {componentType === 'checkbox' && (
            <FontAwesomeIcon icon="check-square" className="icon" />
          )}
          {componentType === 'textarea' && (
            <FontAwesomeIcon icon="file-alt" className="icon" />
          )}
          {t(componentType)}
        </p>
      </div>

      {/* 選項 */}
      {options && (
        <div className="property">
          <p className="property-key">{t('Options')}</p>
          <p className="property-value">
            {options.map((option) => option.label).join(', ')}
          </p>
        </div>
      )}

      {/* 預設值 */}
      {initialValue && initialValue.length > 0 && (
        <div className="property">
          <p className="property-key">{t('Default Value')}</p>
          <p className="property-value">
            {typeof initialValue === 'string'
              ? initialValue
              : initialValue.join(', ')}
          </p>
        </div>
      )}

      {/* 範例說明 */}
      {placeholder && (
        <div className="property">
          <p className="property-key">{t('Field Placeholder')}</p>
          <p className="property-value">{placeholder}</p>
        </div>
      )}

      {/* 詳細說明 */}
      {helperText && (
        <div className="property">
          <p className="property-key">{t('Field HelperText')}</p>
          <p className="property-value">{helperText}</p>
        </div>
      )}
    </IonCard>
  )
}

export default EditModeSchemaField
