import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenuButton,
  IonPage,
  IonSpinner,
  IonTitle,
  IonToast,
  IonToolbar,
  isPlatform,
} from '@ionic/react'
import { caretUp, checkmark } from 'ionicons/icons'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { DELAY_SHORT, DELAY_TOAST } from '../../models/constants'
import { EPreviewFormType } from '../../models/form'
import {
  IFormBuilderSchemaField,
  ISchemaFieldNameObject,
} from '../../models/formBuilder'
import { ETicketStatus, ITicket } from '../../models/ticket'
import { createTicket } from '../../store/epics/ticket'
import PreviewForm from '../../components/preview_form/PreviewForm'
import './CreateTicket.scss'
import { useSearchParam } from '../../hooks/use_serch_params'
import {
  createPublicTicket,
  fetchPublicTemplate,
} from '../../store/public_ticket/actions'
import { useHistory } from 'react-router'
import { parseDate } from '../../helpers/util'
import { uploadComment, uploadPublicComment } from '../../store/epics/comment'
import { RawFileWithMemo } from '../../components/preview_form/CustomerPhotoFields'
import { hashCode } from '../../helpers/file'
import useToast from '../../hooks/useToast'
import { ETicketCommentUserType } from '../../models/comment'
import ReactGA from 'react-ga4'

type CreateTicketProps = {
  isPublicMode: boolean
}

export const CreateTicket = ({ isPublicMode }: CreateTicketProps) => {
  /* States */
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()
  const contentRef = useRef(null)
  const [isPageLoading, setIsPageLoading] = useState(false)
  const [isReadyToSave, setIsReadyToSave] = useState(false)
  const [showTicketFailedToast, setShowTicketFailedToast] = useState(false)
  const publicToken = useSearchParam('token') // 只會在 public mode 使用
  const isPublicTemplateLoaded = useRef(false) // 只會在 public mode 使用
  const authDuck = useSelector((state: any) => state.authDuck)
  const ticket = useSelector((state: any) => state.ticket)

  /**
   * 目前表單的資料內容，每當表單更新時會透過 handleChange 函式更新此變數
   */
  const [formData, setFormData] = useState<{
    values: ISchemaFieldNameObject<IFormBuilderSchemaField[]>
  }>({
    values: {},
  })
  const comment = useSelector((state: any) => state.comment)
  const { showToast, toastProps } = useToast()

  /**
   * 使用者從裝置上已選擇的檔案列表區
   * Notes: 在使用者點擊創建工單前，檔案只存在前端本地的變數中。在新增工單後才會將其一次全部上傳至留言區
   */
  const [files, setFiles] = useState<RawFileWithMemo[]>([])

  const scrollToTop = useCallback(() => {
    // @ts-ignore
    contentRef.current!.scrollToTop(DELAY_SHORT)
  }, [])

  /* Use effects */
  // 初始向後端請求專案資料，只會在 public mode 執行
  useEffect(() => {
    if (isPublicMode && isPublicTemplateLoaded && publicToken) {
      setIsPageLoading(true)
      dispatch(fetchPublicTemplate.request(publicToken))
    }
  }, [dispatch, isPublicMode, publicToken])

  // 從後端獲取專案資料成功，只會在 public mode 執行
  useEffect(() => {
    if (isPublicMode && !ticket.isDone) return
    setIsPageLoading(false)
  }, [ticket.isDone, isPublicMode])

  // public mode 工單讀取完成後，發送 page_view 事件
  useEffect(() => {
    if (process.env.NODE_ENV !== 'production') return
    if (!isPublicMode) return
    if (!ticket.isDone) return

    setTimeout(() => {
      ReactGA.event('page_view', {
        page_title: `${document.title}`,
        page_location: `${window.location.href}`,
      })
    }, 3000)
  }, [ticket.isDone, isPublicMode])

  // 創建 ticket 失敗
  useMemo(() => {
    if (!ticket.error) return
    setIsPageLoading(false)
    setShowTicketFailedToast(true)
  }, [ticket.error])

  // 如果沒有 template 資訊，重新導向至首頁
  useEffect(() => {
    if (!isPublicMode && !ticket.listKey) {
      history.replace('/home')
    }
  }, [ticket, isPublicMode, history])

  /**
   * 工單建立成功後的跳轉邏輯
   */
  const onTicketCreate = useCallback(() => {
    // 顯示 toast
    showToast(
      t('“{{target}}” has been created successfully.', {
        target: getTicketName(ticket),
      })
    )
    scrollToTop()

    // 跳轉到工單頁面
    if (isPublicMode) history.replace(`/public/ticket/u/${ticket.key}`)
    else history.replace(`/ticket/${ticket.key}`)
  }, [history, isPublicMode, scrollToTop, showToast, t, ticket])

  /**
   * 工單本體建立成功時觸發
   */
  useEffect(() => {
    if (!ticket.created) return
    if (files.length > 0) {
      // 使用者有選擇要上傳的檔案，須將其轉換成留言的格式後上傳
      const hashFunc = (file: File, index: number) =>
        hashCode(`${file.name}-${index}`)

      // 若有多個檔案，須將每個檔案的記事合成同一個留言內容
      // e.g. "1. memo1\n2. memo2\n"
      let memo = files.length === 1 ? files[0].memo : ''
      for (let i = 0; i < files.length; i++) {
        if (!files[i].memo) continue
        memo += `${i + 1}. ${files[i].memo}`
        if (i !== files.length - 1) memo += '\n'
      }
      const comment = {
        text: memo,
        files: files,
        filenames: files.map((file) => file.name),
        hashFunc,
      }

      // 上傳留言
      if (isPublicMode) {
        dispatch(
          uploadPublicComment.request({
            ...comment,
            key: ticket.key,
            commentUserType: ETicketCommentUserType.user,
          })
        )
      } else {
        dispatch(uploadComment.request({ ...comment, key: ticket.key }))
      }

      // 顯示正在上傳檔案的 toast
      showToast(t('Uploading files'))
      return
    } else {
      // 沒有要上傳的檔案，直接跳轉到工單頁面
      onTicketCreate()
    }
  }, [
    ticket.created,
    ticket.key,
    scrollToTop,
    isPublicMode,
    history,
    files,
    onTicketCreate,
    dispatch,
    ticket,
    showToast,
    t,
    publicToken,
  ])

  /**
   * 全部工單留言建立（檔案上傳）成功時觸發
   */
  useEffect(() => {
    if (comment.doneCountForCreateTicket === 0) return

    // 檔案上傳完成，跳轉到工單頁面
    onTicketCreate()
  }, [
    comment.doneCountForCreateTicket,
    scrollToTop,
    isPublicMode,
    history,
    files,
    ticket.key,
    onTicketCreate,
    showToast,
    t,
  ])

  /**
   * 每當表單變動時會透過此函式更新表單資訊
   */
  const handleChange = async (values: any, files?: RawFileWithMemo[]) => {
    if (values) {
      // 更新表單資料
      setFormData(values)
      setIsReadyToSave(values.dirty && values.valid)
    }
    if (files) {
      // 更新上傳的檔案列表
      setFiles(files)
    }
  }

  const handleSubmit = ({ values }: { values: Record<string, any> }) => {
    setIsPageLoading(true)

    let base_data = Object.fromEntries(
      Object.entries(values).filter(
        ([key, val]) =>
          !(
            key.startsWith('custom_fields_') ||
            key.startsWith('itn_custom_fields_')
          )
      )
    )
    if (base_data?.expected_end_date)
      base_data = {
        ...base_data,
        expected_end_date: parseDate(base_data.expected_end_date),
      }
    else
      base_data = {
        ...base_data,
        expected_end_date: null,
      }

    // 點擊 + 號按鈕新增的維修人員 2，將其資料放到 base_data 中
    if (base_data?.serv_ppl_2?.length) {
      const { serv_ppl_2, ...rest } = base_data

      base_data = {
        ...rest,
        serv_ppl_2_name: serv_ppl_2[0].serv_ppl_2_name,
        serv_ppl_2_phone: serv_ppl_2[0].serv_ppl_2_phone,
      }
    }

    const custom_fields_data = Object.fromEntries(
      Object.entries(values).filter(([key, val]) =>
        key.startsWith('custom_fields_')
      )
    )

    const itn_custom_fields_data = Object.fromEntries(
      Object.entries(values).filter(([key, val]) =>
        key.startsWith('itn_custom_fields_')
      )
    )
    if (ticket.listKey) {
      if (isPublicMode && publicToken) {
        dispatch(
          createPublicTicket.request({
            publicToken,
            key: ticket.listKey,
            ...base_data,
            custom_fields_data: JSON.stringify(custom_fields_data),
            itn_custom_fields_data: JSON.stringify(itn_custom_fields_data),
          })
        )
      } else {
        dispatch(
          createTicket.request({
            key: ticket.listKey,
            ...base_data,
            custom_fields_data: JSON.stringify(custom_fields_data),
            itn_custom_fields_data: JSON.stringify(itn_custom_fields_data),
          })
        )
      }
      setIsPageLoading(true)
    }
  }

  const done = () => {
    handleSubmit(formData)
  }

  const getTicketName = (item: ITicket) => {
    if (isPlatform('mobile') && !isPlatform('ipad')) {
      return item?.short_display_name || item?.name
    }
    return item?.display_name || item?.name
  }

  return (
    <IonPage className="page-ticket">
      <IonHeader>
        <IonToolbar color="primary">
          {authDuck?.isUserLoaded && (
            <IonButtons slot="start">
              <IonMenuButton />
            </IonButtons>
          )}

          {/* 返回按鈕 */}
          {!isPublicMode && (
            <IonButtons slot="start">
              <IonBackButton defaultHref="/home" />
            </IonButtons>
          )}

          {/* 工單名稱 */}
          <IonTitle>
            {isPlatform('desktop') && !isPublicMode ? (
              <>{ticket?.name}</>
            ) : (
              <>
                <IonButton className="logo immutable">
                  {t('Suiqui Support')}
                </IonButton>
                <span
                  className="subtitle"
                  hidden={isPlatform('mobile') && !isPlatform('ipad')}
                >
                  {ticket?.name}
                </span>
              </>
            )}
          </IonTitle>

          {/* 「立即存檔」按鈕 */}
          <IonButtons slot="end">
            <IonButton
              className="ion-padding-end"
              strong
              color="light"
              fill="clear"
              slot="icon-end"
              disabled={isPageLoading || !isReadyToSave}
              onClick={done}
              data-test="save-btn"
            >
              <IonIcon slot="start" icon={checkmark}></IonIcon>
              <IonLabel>{t('Save')}</IonLabel>
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent
        className="ion-padding bg"
        ref={contentRef}
        scrollEvents={true}
      >
        {/* 讀取中提示 */}
        {isPageLoading && (
          <div className="ion-text-center centered">
            <IonSpinner color="primary" name="crescent"></IonSpinner>
          </div>
        )}

        {!isPageLoading && (
          <>
            {/* 「新增工單」提示 */}
            <IonListHeader className="subject">
              <IonLabel>
                <h2>{t('New Ticket')}</h2>
              </IonLabel>
            </IonListHeader>

            {/* 表單 */}
            <IonList lines="inset" className="bg">
              <PreviewForm
                type={
                  isPublicMode
                    ? EPreviewFormType.publicTicket
                    : EPreviewFormType.ticket
                }
                isFillMode={ticket.ticket_status !== ETicketStatus.Done}
                item={ticket}
                // onSubmit 沒有用
                onSubmit={handleSubmit}
                // 使用 onChange callback 取得最新的表單資料
                onChange={handleChange}
                isShowCustomerPhotoFields
                // 新增工單時不顯示簽名欄位
                isShowSignatures={false}
              />
            </IonList>

            {/* 回到上面按鈕 */}
            <div className="ion-text-center">
              <IonButton
                color="warning"
                fill="clear"
                size="large"
                slot="icon-only"
                onClick={scrollToTop}
              >
                <IonIcon icon={caretUp} />
              </IonButton>
            </div>
          </>
        )}

        <IonToast {...toastProps} />

        <IonToast
          isOpen={showTicketFailedToast}
          onDidDismiss={() => setShowTicketFailedToast(false)}
          message={t(ticket?.error?.response)}
          color="danger"
          duration={DELAY_TOAST}
        />
      </IonContent>
    </IonPage>
  )
}

export default CreateTicket
