import {
  IonAlert,
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenuButton,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonSegment,
  IonSegmentButton,
  IonSpinner,
  IonTitle,
  IonToast,
  IonToolbar,
} from '@ionic/react'
import { archive, caretUp, create, eye, refresh, save } from 'ionicons/icons'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { RouteComponentProps } from 'react-router'
import { useHistory } from 'react-router-dom'
import { inFunctionList } from '../../helpers/base'
import { delay } from '../../helpers/util'
import { DELAY_SHORT } from '../../models/constants'
import { loadDefaultTemplateFullSchema } from '../../models/schema'
import { EActiveStatus, ETemplateFunction } from '../../models/template'
import { ReactRouterAction } from '../../store/epics/types'
import TemplateForm from './form_segment/TemplateForm'
import {
  archiveTemplate,
  clearError,
  clearStatus,
  clearToastMessage,
  fetchTemplate,
  unarchiveTemplate,
  updateTemplate,
  updateTemplateSchema,
} from '../../store/templateSlice'
import { RootState } from '../../store/ducks'
import useToast from '../../hooks/useToast'
import './TemplatePage.scss'
import AdvancedSegment from '../../components/preview_form/AdvancedSegment'
import { EPreviewFormType } from '../../models/form'
import useAlert from '../../hooks/useAlert'

type SegmentType = 'form' | 'advanced'
type TemplateProps = {} & RouteComponentProps & ReactRouterAction

// 表單狀態
interface TemplateFormData {
  values: { [key: string]: any }
  dirty: boolean
  valid: boolean
}

// 專案設定頁面
const Template = ({ match }: TemplateProps) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()
  const contentRef = useRef(null)
  const [isPreviewMode, setIsPreviewMode] = useState(false)
  const [segmentType, setSegmentType] = useState<SegmentType>('form')
  const { showToast, toastProps } = useToast()
  const { showAlert, alertProps } = useAlert()

  // 表單編輯過後的值
  // 備註：只有儲存基本資訊，客戶表單與內部表單會在欄位更動時馬上儲存
  const [formData, setFormData] = useState<TemplateFormData>({
    values: {},
    valid: false,
    dirty: false,
  })

  // 是否可以儲存表單
  const isReadyToSave = useMemo(
    () => formData.valid && formData.dirty,
    [formData]
  )

  const authDuck = useSelector((state: any) => state.authDuck)
  const template = useSelector((state: RootState) => state.newTemplate.template)
  const state = useSelector((state: RootState) => state.newTemplate)

  // 初始化讀取專案資訊
  useEffect(() => {
    if (!authDuck.isUserLoaded) return
    if (!match.params.key) return
    dispatch(
      fetchTemplate({
        key: match.params.key,
      })
    )
  }, [dispatch, match.params.key, authDuck.isUserLoaded])

  // 讀取專案資訊後，當沒有專案更新的權限時專案等於唯讀
  const isReadOnly = useMemo(() => {
    return !inFunctionList(
      ETemplateFunction.template_update,
      template?.function_list
    )
  }, [template?.function_list])

  // 唯讀時以預覽模式顯示專案
  useEffect(() => {
    setIsPreviewMode(isReadOnly)
  }, [isReadOnly])

  const scrollToTop = () => {
    // @ts-ignore
    contentRef.current!.scrollToTop(DELAY_SHORT)
  }

  const doRefresh = () => {
    if (match.params.key) {
      dispatch(
        fetchTemplate({
          key: match.params.key,
        })
      )
    }
  }

  // 每次專案更新時，若客戶選單或內部選單為 null，在前端將其設置為 default schema
  // （未來視情況移除）
  useEffect(() => {
    if (!template) return
    if (!template.custom_fields_schema) {
      dispatch(
        updateTemplateSchema({
          custom_fields_schema: JSON.stringify(
            loadDefaultTemplateFullSchema(template).custom_fields
          ),
        })
      )
    }
    if (!template.itn_custom_fields_schema) {
      dispatch(
        updateTemplateSchema({
          itn_custom_fields_schema: JSON.stringify(
            loadDefaultTemplateFullSchema(template).itn_custom_fields
          ),
        })
      )
    }
  }, [dispatch, template])

  // 當表單的值發生改變時，更新 formData
  const onFormDataChange = useCallback((formData: TemplateFormData) => {
    setFormData(formData)
  }, [])

  // 儲存編輯的表單更動
  // 備註：只有儲存基本資訊，客戶表單與內部表單會在欄位更動時馬上儲存
  const handleSubmit = useCallback(() => {
    if (!template) return

    let { values } = formData

    // 點擊 + 號按鈕新增的維修人員 2，將其資料放到 values 中
    if (values?.serv_ppl_2?.length) {
      const { serv_ppl_2, ...rest } = values

      values = {
        ...rest,
        serv_ppl_2_name: serv_ppl_2[0].serv_ppl_2_name,
        serv_ppl_2_phone: serv_ppl_2[0].serv_ppl_2_phone,
      }
    }

    dispatch(
      // Notice: 發送更新工單的請求時，如果不先將欄位設定為 null，當使用者將欄位清空後
      // 會導致該欄位的值不會被更新
      updateTemplate({
        key: template.key,
        serv_ppl_name: null,
        serv_ppl_phone: null,
        serv_ppl_2_name: null,
        serv_ppl_2_phone: null,
        ...values,
      })
    )
  }, [dispatch, formData, template])

  // 切換編輯模式與預覽模式
  const onTogglePreviewMode = useCallback(() => {
    setIsPreviewMode((isPreviewMode) => !isPreviewMode)
  }, [])

  // 當 toastMessage 有值時觸發，顯示相關的文字 toast
  // 備註：toastMessage 的值會在對應後端 API 完成時從 redux 設置，包括更新成功、複製完成等
  useEffect(() => {
    if (!template) return
    if (!state.toastMessage) return

    // 顯示 toast
    showToast(
      t(state.toastMessage, {
        target: template.name,
      })
    )

    // 清除 toastMessage
    dispatch(clearToastMessage())
  }, [state.toastMessage, showToast, dispatch, t, template])

  // 當 error 有值時觸發，顯示相關的錯誤訊息
  // 備註：error 的值會在對應後端 API 發生錯誤時從 redux 設置
  useEffect(() => {
    if (!state.error) return

    // 顯示錯誤訊息 toast
    showToast(t('Oops...')) // 目前所有 error 皆共用同一個錯誤訊息
    dispatch(clearError()) // 清除 error
  }, [state.error, showToast, dispatch, t])

  // 當 status 為 'archived' 或 'unarchived' 時觸發，重新導向至專案設定頁面
  // 備註：'archived' 或 'unarchived' 會在後端 API 完成封存/啟用專案時從 redux 設置
  useEffect(() => {
    if (state.status !== 'archived' && state.status !== 'unarchived') return
    dispatch(clearStatus()) // 清除 status

    // 重新導向至專案設定頁面
    const redirect = async () => {
      await delay(1000) // 等待相關 toast 顯示一段時間後再重新導向
      history.push('/project', {
        direction: 'root',
        state: {
          listType:
            state.status === 'archived'
              ? EActiveStatus.archive
              : EActiveStatus.active,
        },
      })
    }
    redirect()
  }, [state.status, history, dispatch])

  // 點擊封存按鈕時觸發，顯示警告 alert
  const onArchiveClick = useCallback(() => {
    if (!template) return
    // 顯示 alert，需輸入專案名稱才能完成封存
    showAlert({
      message: t('Click OK to archive this item.'),
      placeholder: t('Input project name to confirm.'),
      targetInput: template.name,
      onConfirm: () => {
        // 確認封存專案，向後端發送 request
        dispatch(
          archiveTemplate({
            key: template.key,
          })
        )
      },
    })
  }, [showAlert, t, template, dispatch])

  // 點擊啟用按鈕時觸發，顯示警告 alert
  const onUnarchiveClick = useCallback(() => {
    if (!template) return
    // 顯示 alert
    showAlert({
      message: t('Click OK to unarchive this item.'),
      onConfirm: () => {
        // 確認啟用專案，向後端發送 request
        dispatch(
          unarchiveTemplate({
            key: template.key,
          })
        )
      },
    })
  }, [showAlert, t, template, dispatch])

  return (
    <IonPage className="page-template">
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/home" />
          </IonButtons>
          <IonTitle>{t('View Project')}</IonTitle>
          {/* 立即存檔按鈕 */}
          {!isPreviewMode && (
            <IonButtons slot="end">
              <IonButton
                color="light"
                fill="clear"
                slot="icon-end"
                disabled={!isReadyToSave}
                onClick={handleSubmit}
                className="save-button"
              >
                <IonIcon icon={save}></IonIcon>
                <IonLabel>{t('Save')}</IonLabel>
              </IonButton>
            </IonButtons>
          )}

          {/* 切換預覽模式、編輯模式按鈕 */}
          <IonButtons slot="end">
            <IonButton
              className="ion-padding-end"
              disabled={state.isLoading}
              hidden={isReadOnly} // 唯讀不能切換至編輯模式
              color="light"
              fill="clear"
              slot="start"
              onClick={onTogglePreviewMode}
            >
              <IonIcon slot="start" icon={isPreviewMode ? create : eye} />
              <IonLabel>
                {isPreviewMode ? t('Edit Mode') : t('Preview Mode')}
              </IonLabel>
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent
        className="ion-padding bg"
        ref={contentRef}
        scrollEvents={true}
      >
        <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>

        {/* 讀取頁面提示 */}
        {state.isLoading && (
          <div className="ion-text-center centered">
            <IonSpinner color="primary" name="crescent"></IonSpinner>
          </div>
        )}

        {!state.isLoading && template && (
          <>
            {/* 啟用專案按鈕 */}
            {inFunctionList(
              ETemplateFunction.template_archive,
              template.function_list
            ) && (
              <>
                {template.active_status === EActiveStatus.archive && (
                  <IonToolbar color="light">
                    <IonButton
                      color="primary"
                      fill="solid"
                      slot="end"
                      onClick={onUnarchiveClick}
                    >
                      <IonIcon slot="start" icon={refresh} />
                      <IonLabel>{t('Restore')}</IonLabel>
                    </IonButton>
                  </IonToolbar>
                )}
              </>
            )}

            {/* 標題 （「編輯模式」/「預覽模式」） */}
            <IonListHeader>
              <IonLabel>
                <h2>{t(isPreviewMode ? 'Preview Mode' : 'Edit Mode')}</h2>
              </IonLabel>
            </IonListHeader>
            <IonList lines="inset" className="bg">
              <div>
                {/* 子頁面選單（「表單」/「進階設定」）*/}
                <IonSegment
                  value={segmentType}
                  color="secondary"
                  onIonChange={(e) =>
                    setSegmentType(e.detail.value as SegmentType)
                  }
                >
                  {/* 「表單」按鈕 */}
                  <IonSegmentButton value="form" data-test="form-segment-btn">
                    <IonLabel>{t('Form')}</IonLabel>
                  </IonSegmentButton>

                  {/* 「進階設定」按鈕 */}
                  <IonSegmentButton value="advanced" data-test="advanced-segment-btn">
                    <IonLabel>{t('Advanced')}</IonLabel>
                  </IonSegmentButton>
                </IonSegment>

                {/* 表單頁面 */}
                <div className="segment-box" hidden={segmentType !== 'form'}>
                  <TemplateForm
                    onChange={onFormDataChange}
                    isPreviewMode={isPreviewMode}
                  />
                </div>

                {/* 進階設定頁面 */}
                <div hidden={segmentType !== 'advanced'}>
                  <AdvancedSegment
                    item={template}
                    type={EPreviewFormType.template}
                  />
                </div>
              </div>
            </IonList>

            {/* 回到頂端按鈕 */}
            <div className="ion-text-center">
              <IonButton
                color="warning"
                fill="clear"
                size="large"
                slot="icon-only"
                onClick={scrollToTop}
              >
                <IonIcon icon={caretUp} />
              </IonButton>
            </div>

            {/* 封存專案按鈕 */}
            {!isPreviewMode &&
              template &&
              inFunctionList(
                ETemplateFunction.template_archive,
                template.function_list
              ) && (
                <>
                  {template.active_status === EActiveStatus.active && (
                    <IonToolbar color="light">
                      <IonButton
                        color="danger"
                        fill="solid"
                        onClick={onArchiveClick}
                        data-test="archive-template-btn"
                      >
                        <IonIcon slot="start" icon={archive} />
                        <IonLabel>{t('Archive')}</IonLabel>
                      </IonButton>
                    </IonToolbar>
                  )}
                </>
              )}
          </>
        )}

        {/* Toast */}
        <IonToast {...toastProps} />

        {/* Alert */}
        <IonAlert {...alertProps} />
      </IonContent>
    </IonPage>
  )
}

export default Template
