import { date, id } from './base'
import { EActiveStatus } from './template'
import { ITicketPermission } from './ticket'
import { User } from './user'

export const COMMENT_LIST_MIN_SIZE = 340
export interface ITicketComment extends ITicketPermission {
  key: id
  text?: string
  create_datetime: date
  modified_datetime: date
  files?: File[]
  commentUserType: ETicketCommentUserType
  active_status: EActiveStatus
  user: User
}

export enum ETicketCommentFunction {
  comment_delete = 'comment_delete',
}

export enum ETicketCommentUserType {
  user = 'user',
  serv = 'serv',
}

export interface ITicketCommentUpload {
  key: id
  text: string
  file?: Blob[]
  files?: Blob[]
  filename?: string
  filenames?: string[]
  hash?: number
  hashFunc?: Function
  name?: string
  commentUserType?: ETicketCommentUserType
}
