import {  id, key } from './../models/base'
import { ajax } from 'rxjs/ajax'
import { API, JWT } from '../helpers/base'

export const list = ({
  token,
  params,
}: {
  token: string
  params: { key: id }
}) =>
  ajax({
    url: API('permission/list', 'SUPPORT'),
    method: 'POST',
    body: { ...params },
    headers: JWT(token),
    responseType: 'json',
  })

export const update = ({
  token,
  params,
}: {
  token: string
  params: {
    key: id
    protected?: boolean
    add_readers?: key[]
    add_writers?: key[]
    remove_readers?: key[]
    remove_writers?: key[]
  }
}) =>
  ajax({
    url: API('permission/update', 'SUPPORT'),
    method: 'POST',
    body: { ...params },
    headers: JWT(token),
    responseType: 'json',
  })
