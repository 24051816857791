import {
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCol,
  IonGrid,
  IonRow,
} from '@ionic/react'
import { useTranslation } from 'react-i18next'
import { ITemplateDashboard } from '../models/template'
import './Dashboard.scss'

interface DashboardProps {
  // 在專案總覽中的 dashboard 資料不帶 key
  stats: Partial<ITemplateDashboard> | null
}

const Dashboard = ({ stats }: DashboardProps) => {
  const { t } = useTranslation()

  const showNumber = (num?: number) => {
    if (num === 0) return '0'
    if (!num) return '-'
    return num.toFixed(0)
  }

  return (
    <div className="component-dashboard">
      <IonGrid>
        <IonRow className="ion-justify-content-center">
          <IonCol size="18" size-md="3">
            <IonCard>
              <IonCardHeader>
                <span>{showNumber(stats?.total_tickets)}</span>
                <IonCardTitle>{t('Tickets Total')}</IonCardTitle>
              </IonCardHeader>
            </IonCard>
          </IonCol>
          <IonCol size="18" size-md="3">
            <IonCard>
              <IonCardHeader>
                <span>{showNumber(stats?.total_tickets_unfinished)}</span>
                <IonCardTitle>{t('Tickets Unfinished')}</IonCardTitle>
              </IonCardHeader>
            </IonCard>
          </IonCol>
          <IonCol size="18" size-md="3">
            <IonCard>
              <IonCardHeader>
                <span>{showNumber(stats?.total_tickets_done)}</span>
                <IonCardTitle>{t('Tickets Done')}</IonCardTitle>
              </IonCardHeader>
            </IonCard>
          </IonCol>
          <IonCol size="18" size-md="3">
            <IonCard>
              <IonCardHeader>
                <span>{showNumber(stats?.total_tickets_last_month)}</span>
                <IonCardTitle>{t('Tickets Last Month')}</IonCardTitle>
              </IonCardHeader>
            </IonCard>
          </IonCol>
          <IonCol size="18" size-md="3">
            <IonCard>
              <IonCardHeader>
                <span>{showNumber(stats?.total_tickets_new_month)}</span>
                <IonCardTitle>{t('Tickets New Month')}</IonCardTitle>
              </IonCardHeader>
            </IonCard>
          </IonCol>
          <IonCol size="18" size-md="3">
            <IonCard>
              <IonCardHeader>
                <span>{showNumber(stats?.total_tickets_overdue)}</span>
                <IonCardTitle>{t('Tickets Overdue')}</IonCardTitle>
              </IonCardHeader>
            </IonCard>
          </IonCol>
        </IonRow>
      </IonGrid>
    </div>
  )
}

export default Dashboard
