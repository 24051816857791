import FileSaver from 'file-saver'
import JSZip from 'jszip'
import { combineEpics, Epic } from 'redux-observable'
import { combineLatest, fromEvent, of } from 'rxjs'
import {
  catchError,
  filter,
  finalize,
  map,
  merge,
  switchMap,
  takeUntil,
} from 'rxjs/operators'
import {
  ActionType,
  createAsyncAction,
  createCustomAction,
  createReducer,
  getType,
  isActionOf,
} from 'typesafe-actions'
import { getDownloadUrl, getVersionFilename } from '../../helpers/file'
import XHRFactory from '../../helpers/XHRFactory'
import { SUIQUI_DOWNLOAD_CACHE } from '../../models/constants'
import { IDownloadZip } from '../../models/download'
import { IFile, ISuiquiVersionFile } from '../../models/suiquiFile'
import * as Providers from '../../providers'
import { RootState } from '../ducks'
import { RootAction } from './types'

const initialState: {
  name: string
  percentage: number
  isPending: boolean
  isDownloading: boolean
  isDone: boolean
  isFail: boolean
  isCancel: boolean
} = {
  name: '',
  percentage: 0,
  isPending: false,
  isDownloading: false,
  isDone: false,
  isFail: false,
  isCancel: false,
}

export const downloadZip = createAsyncAction(
  'DOWNLOAD_ZIP_REQUEST',
  'DOWNLOAD_ZIP_SUCCESS',
  'DOWNLOAD_ZIP_FAILURE',
  'DOWNLOAD_ZIP_CANCEL'
)<
  Partial<IDownloadZip> & Pick<IDownloadZip, 'name' | 'files' | 'hashs'>,
  Partial<IDownloadZip & { status: number }> &
    Pick<IDownloadZip, 'name' | 'blobs'>,
  Error,
  {}
>()

export const downloadZipProgress = createCustomAction(
  'DOWNLOAD_ZIP_PROGRESS',
  (payload) => {
    return {
      payload: {
        files: payload.files,
        percentage: payload.percentage,
      },
    }
  }
)

export const receiveFilesEpic: Epic<
  RootAction,
  RootAction,
  RootState,
  typeof Providers
> = (action$, state$, { downloadAPI }) => {
  return action$.pipe(
    filter(isActionOf(downloadZip.request)),
    switchMap((action) => {
      const requests$ = action.payload.files.map((file) => {
        const xhr = XHRFactory.getInstance()
        const getRequest$ = () => xhr
        const request$ = downloadAPI
          .exec({
            url: getDownloadUrl(file)!,
            createXHR: getRequest$,
          })
          .pipe(
            map((data) => {
              return {
                blob: data.response,
                ...action.payload,
              }
            }),
            catchError((message: Error) => of(downloadZip.failure(message))),
            finalize(() => {
              XHRFactory.release(xhr)
            }),
            takeUntil(action$.pipe(filter(isActionOf(downloadZip.cancel))))
          )

        const size = file?.blob_info?.size || 0
        return fromEvent(xhr, 'progress').pipe(
          map((e: any) => ({
            percentage: (e.loaded / (e.total | size)) * 100 || 0,
          })),
          map((data) => {
            return {
              percentage: data.percentage,
            }
          }),
          merge(request$)
        )
      })

      return combineLatest(requests$).pipe(
        map((rets) => {
          if (rets.every((ret) => ret.hasOwnProperty('blob'))) {
            return downloadZip.success({
              ...action.payload,
              blobs: rets.map((ret: any) => ret.blob),
              status: 200,
            })
          } else {
            return downloadZipProgress({
              ...action.payload,
              percentage:
                rets.reduce(
                  (total, ret: any) =>
                    ret.hasOwnProperty('percentage')
                      ? total + ret.percentage
                      : total + 100,
                  0
                ) / action.payload.files?.length || 0,
            })
          }
        })
      )
    })
  )
}

export type DownloadZipAction =
  | ActionType<typeof downloadZip>
  | ActionType<typeof downloadZipProgress>

export const downloadZipReducer = createReducer(initialState)
  .handleAction(
    getType(downloadZip.request),
    (state: any, { payload }: any) => ({
      isPending: false,
      isDownloading: true,
    })
  )
  .handleAction(
    getType(downloadZip.success),
    (state: any, { payload }: any) => {
      if (payload.blobs?.length) {
        const zip = new JSZip()
        payload.files.forEach((file: ISuiquiVersionFile, index: number) => {
          zip.file(getVersionFilename(file), payload.blobs[index], {
            base64: true,
          })
        })

        zip.generateAsync({ type: 'blob' }).then((blob) => {
          FileSaver.saveAs(blob, payload.name)
        })
      }

      if (payload.files?.length) {
        if ('caches' in window) {
          caches.open(SUIQUI_DOWNLOAD_CACHE).then((cache) => {
            payload.files.forEach((file: IFile, index: number) => {
              const url = getDownloadUrl(file)
              if (url) {
                cache.match(url).then((response) => {
                  if (response) {
                    //cached
                  } else {
                    cache.put(url, new Response(payload.blobs[index]))
                  }
                })
              }
            })
          })
        }
      }

      return {
        ...state,
        blobs: payload.blobs,
        name: payload.name,
        percentage: 100,
        isDone: true,
        isFail: false,
        isCancel: false,
        isPending: false,
        isDownloading: false,
      }
    }
  )
  .handleAction(
    getType(downloadZip.failure),
    (state: any, { payload }: any) => ({
      ...state,
      isFail: true,
      isPending: false,
      isDownloading: false,
    })
  )
  .handleAction(
    getType(downloadZip.cancel),
    (state: any, { payload }: any) => ({
      ...state,
      isFail: false,
      isPending: false,
      isCancel: false,
      isDone: false,
      isDownloading: false,
      percentage: 0,
    })
  )
  .handleAction(
    getType(downloadZipProgress),
    (state: any, { payload }: any) => {
      const percentage = payload.percentage
      return {
        ...state,
        isPending: false,
        isDone: false,
        isDownloading: true,
        percentage,
      }
    }
  )

const downloadZipEpic = combineEpics<any>(receiveFilesEpic)
export default downloadZipEpic
