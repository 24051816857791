import {
  IonBadge,
  IonButton,
  IonButtons,
  IonChip,
  IonContent,
  IonFab,
  IonFabButton,
  IonHeader,
  IonIcon,
  IonLabel,
  IonPage,
  IonSlide,
  IonSlides,
  IonSpinner,
  IonTitle,
  IonToolbar,
  isPlatform,
} from '@ionic/react'
import useComponentSize from '@rehooks/component-size'
import { getFileTypeIconProps } from '@uifabric/file-type-icons'
import FileSaver from 'file-saver'
import filesize from 'filesize'
import {
  arrowBack,
  arrowForward,
  close,
  cloudDownload,
  folder,
} from 'ionicons/icons'
import { Icon } from 'office-ui-fabric-react/lib/Icon'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
  dataURLtoBlob,
  getDownloadUrl,
  getFileExt,
  getIFileProgressKey,
  getImageUrl,
  isFilePdf,
  openDocument,
} from '../helpers/file'
import { ESuiquiFileType, IFile, ISuiquiFile } from '../models/suiquiFile'
import { downloadFile } from '../store/epics/downloadFile'
import './PhotoViewerModal.scss'

const PhotoViewerModal: React.FC<any> = ({
  isOpen,
  dismiss,
  items,
  index,
}: {
  isOpen: boolean
  dismiss: any
  items: ISuiquiFile[]
  index: number
}) => {
  const { t } = useTranslation()
  const contentRef = useRef(null)
  const pageRef = useRef(null)
  const slidesRef = useRef(null)
  const dispatch = useDispatch()
  const { width } = useComponentSize(contentRef)
  const [currentItem, setCurrentItem] = useState<ISuiquiFile>()
  const [currentIndex, setCurrentIndex] = useState(0)
  const [isPageLoading, setIsPageLoading] = useState(true)
  const [swiper, setSwiper] = useState<any>()

  const download = useSelector((state: any) => state.download)

  const cancel = () => {}

  const slideOpts = {
    initialSlide: index || 0,
    centeredSlides: true,
    paginationType: 'progress',
    speed: 200,
    grabCursor: !isPlatform('desktop'),
    cubeEffect: {
      shadow: true,
      slideShadows: true,
      shadowOffset: 20,
      shadowScale: 0.94,
    },
    zoom: {
      maxRatio: 3,
      minRatio: 1,
      enabled: !isPlatform('desktop'),
      toggle: true,
      containerClass: 'swiper-zoom-container',
      zoomedSlideClass: 'swiper-slide-zoomed',
    },
    keyboard: {
      enabled: true,
      onlyInViewport: true,
    },
  }

  useEffect(() => {
    if (!isOpen) return

    setTimeout(() => {
      // Workaround ...
      // @ts-ignore
      pageRef.current?.classList.remove('ion-page-invisible')
    }, 300)
    const file = items[index || 0]
    if (file) {
      setCurrentItem(file)

      setTimeout(() => {
        setIsPageLoading(false)

        const _ = async () => {
          // @ts-ignore
          setSwiper(await slidesRef.current.getSwiper())
        }
        _()
        /*
        if (index > 0) {
          // @ts-ignore
          slidesRef.current?.slideTo(index)
        }*/
      }, 1000)
    }
  }, [isOpen, items, index, setCurrentItem])

  useMemo(() => {
    if (swiper && swiper.zoom) {
      swiper.zoom.enable()
    }
  }, [swiper])

  const zoomIn = async (e: any) => {
    swiper.zoom.in(e)
  }

  const zoomOut = async (e: any) => {
    swiper.zoom.out(e)
  }
  const zoomToggle = async (e: any) => {
    swiper.zoom.toggle(e)
  }

  const prev = async () => {
    // @ts-ignore
    const index = (await slidesRef.current?.getActiveIndex()) - 1
    if (index < items.length) {
      // @ts-ignore
      await slidesRef.current?.slideTo(index)
    }
  }

  const next = async () => {
    // @ts-ignore
    const index = (await slidesRef.current?.getActiveIndex()) + 1
    if (index < items.length) {
      // @ts-ignore
      await slidesRef.current?.slideTo(index)
    }
  }

  const onSlideWillChange = async () => {
    // @ts-ignore
    const index = await slidesRef.current?.getActiveIndex()
    setCurrentIndex(index)
    setCurrentItem(items[index])

    if (swiper) {
      if (items[index]?.is_image) {
        swiper.zoom.enable()
      } else {
        swiper.zoom.disable()
      }
    }
  }

  const execDownload = (
    event: React.MouseEvent<any, MouseEvent>,
    file: IFile,
    filename?: string
  ) => {
    event.preventDefault()

    const url = getDownloadUrl(file)

    if (!url) return

    if (url.startsWith('data:')) {
      FileSaver.saveAs(dataURLtoBlob(url), filename)
    } else {
      /* Native Download
      let link = window.document.createElement('a')
      link.href = '#'
      link.download = filename
      link.hidden = true
      window.document.body.appendChild(link)

      link.click() // Save

      window.document.body.removeChild(link)
      */

      const hash = getIFileProgressKey(file)

      dispatch(
        downloadFile.request({
          key: file.key,
          name: filename || '',
          url,
          hash,
          file,
        })
      )
    }
  }

  const getFileExtCallback = useCallback((file: IFile) => {
    return getFileExt(file) || 'file'
  }, [])

  const pKey = getIFileProgressKey(currentItem as IFile)
  const fileExt = getFileExtCallback(currentItem as IFile)

  return (
    <IonPage className="page-photo-viewer-modal" ref={pageRef}>
      <IonHeader>
        <IonToolbar color="primary">
          <IonTitle>
            <IonButton class="logo immutable">{t('Suiqui Support')}</IonButton>
            <span hidden={isPlatform('mobile')} className="subtitle">
              {t('Photo Viewer')}
            </span>
          </IonTitle>
          <IonButtons slot="end">
            {execDownload && openDocument && currentItem && (
              <IonButton
                fill="solid"
                color="secondary"
                disabled={
                  !currentItem ||
                  (download.pFiles[pKey] &&
                    download.pFiles[pKey]?.isDownloading &&
                    !download.pFiles[pKey]?.isDone)
                }
                onClick={(e) =>
                  isPlatform('ios') ||
                  (isPlatform('mobile') && isFilePdf(currentItem))
                    ? openDocument(currentItem)
                    : execDownload(e, currentItem, currentItem.name)
                }
              >
                <IonIcon slot="start" icon={cloudDownload}></IonIcon>
                <IonLabel>
                  {t('Download')}
                  {currentItem && (
                    <span className="filesize">
                      (
                      {filesize(currentItem?.blob_info?.size || 0, {
                        base: 2,
                      })}
                      )
                    </span>
                  )}
                </IonLabel>
              </IonButton>
            )}
          </IonButtons>
          <IonButtons slot="end">
            <IonButton
              strong
              fill="clear"
              slot="icon-only"
              onClick={() => dismiss(cancel)}
            >
              <IonIcon size="large" icon={close}></IonIcon>
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent ref={contentRef} scrollEvents={false}>
        {isPageLoading ? (
          <div className="ion-text-center centered">
            <IonSpinner color="light" name="crescent"></IonSpinner>
          </div>
        ) : (
          <>
            <IonFab
              vertical="center"
              horizontal="start"
              slot="fixed"
              hidden={currentIndex === 0}
            >
              <IonFabButton className="prev" onClick={prev}>
                <IonIcon icon={arrowBack} />
              </IonFabButton>
            </IonFab>
            <IonFab
              vertical="center"
              horizontal="end"
              slot="fixed"
              hidden={currentIndex >= items?.length - 1}
            >
              <IonFabButton className="next" onClick={next}>
                <IonIcon icon={arrowForward} />
              </IonFabButton>
            </IonFab>
            <IonSlides
              ref={slidesRef}
              pager={false}
              scrollbar={true}
              options={slideOpts}
              onIonSlideWillChange={() => onSlideWillChange()}
            >
              {items?.map((item, index) => {
                return (
                  <IonSlide key={index}>
                    {index >= 0 && (
                      <IonBadge color="success" className="count">{`${
                        index + 1
                      }`}</IonBadge>
                    )}
                    {items[index]?.memo && (
                      <IonChip color="light" className="memo">
                        <div>{items[index].memo}</div>
                      </IonChip>
                    )}
                    {item?.is_image ? (
                      <div className="swiper-zoom-container">
                        <img
                          className="swiper-zoom-target"
                          onClick={(e) =>
                            !isPlatform('desktop') && zoomToggle(e)
                          }
                          alt=""
                          src={`${getImageUrl(item, width, `w${width}`)}`}
                        />
                      </div>
                    ) : (
                      items[index] && (
                        <div className="file-icon-container">
                          {items[index].type === ESuiquiFileType.directory ? (
                            <IonIcon color={'warning'} icon={folder} />
                          ) : (
                            <Icon
                              {...getFileTypeIconProps({
                                extension: fileExt,
                                size: 96,
                                imageFileType: 'svg',
                              })}
                            />
                          )}
                        </div>
                      )
                    )}

                    <div className="infomation">
                      <div>
                        <IonBadge color="dark" className="filename">
                          {currentItem?.name || currentItem?.name}
                        </IonBadge>
                      </div>
                      {currentItem && (
                        <div>
                          <IonChip color="warning" className="immutable">
                            <IonLabel>
                              {t('Upload by=')}
                              {currentItem?.user?.displayname ||
                                currentItem?.creator?.displayname}
                            </IonLabel>
                          </IonChip>
                        </div>
                      )}
                    </div>
                  </IonSlide>
                )
              })}
            </IonSlides>
          </>
        )}
      </IonContent>
    </IonPage>
  )
}

export default PhotoViewerModal
