import Field from '@data-driven-forms/react-form-renderer/common-types/field'

export interface IFormBuilderSchemaField extends Field {
  component: EFormBuilderSchemaComponentType
}

export enum EFormBuilderSchemaComponentType {
  textarea = 'textarea',
  checkbox = 'checkbox',
  select = 'select',
}

export interface ISchemaFieldNameObject<TValue> {
  [id: string]: TValue
}

export interface FormData {
  values: ISchemaFieldNameObject<IFormBuilderSchemaField[]>
}
