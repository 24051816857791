import { combineEpics, Epic } from 'redux-observable'
import { of } from 'rxjs'
import {
  catchError,
  filter,
  map,
  mergeMap,
  switchMap,
  takeUntil,
} from 'rxjs/operators'
import {
  ActionType,
  createAsyncAction,
  createCustomAction,
  createReducer,
  getType,
  isActionOf,
} from 'typesafe-actions'
import { IAuth } from '../../models/auth'
import { ITeam } from '../../models/team'
import { IAccountInfo } from '../../models/user'
import * as Providers from '../../providers'
import { RootState } from '../ducks'
import { RootAction } from './types'

export const fetchAuth = createAsyncAction(
  'FETCH_AUTH_REQUEST',
  'FETCH_AUTH_SUCCESS',
  'FETCH_AUTH_FAILURE',
  'FETCH_AUTH_CANCEL'
)<Partial<IAccountInfo>, IAuth, Error, undefined>()

const initialState = {
  token: null,
  error: null,
  isForceLogout: false,
  isResetPassword: null,
  status: null,
}

export const fetchAuthEpic: Epic<
  RootAction,
  RootAction,
  RootState,
  typeof Providers
> = (action$, state$, { authAPI }) =>
  action$.pipe(
    filter(isActionOf(fetchAuth.request)),
    switchMap((action) =>
      authAPI.login({ params: action.payload }).pipe(
        map((data) => fetchAuth.success(data.response)),
        catchError((message: Error) => of(fetchAuth.failure(message))),
        takeUntil(action$.pipe(filter(isActionOf(fetchAuth.cancel))))
      )
    )
  )

export const forceAuthLogout = createCustomAction(
  'FORCE_AUTH_LOGOUT',
  (payload) => {
    return {
      payload,
    }
  }
)

export const forceAuthClearErrors = createCustomAction(
  'FORCE_AUTH_CLEAR_ERRORS',
  (payload) => {
    return {
      payload,
    }
  }
)

export const forceAuthErrorAlert = createCustomAction(
  'FORCE_AUTH_ERROR_ALERT',
  (payload) => {
    return {
      payload,
    }
  }
)

export const prepareAuthTeamSwitching = createCustomAction(
  'PREPARE_AUTH_TEAM_SWICHING',
  (payload) => {
    return {
      payload,
    }
  }
)

export const switchAuth = createAsyncAction(
  'SWITCH_AUTH_REQUEST',
  'SWITCH_AUTH_SUCCESS',
  'SWITCH_AUTH_FAILURE',
  'SWITCH_AUTH_CANCEL'
)<Partial<ITeam & IAuth>, IAuth, Error, undefined>()

export const switchAuthEpic: Epic<
  RootAction,
  RootAction,
  RootState,
  typeof Providers
> = (action$, state$, { authAPI }) =>
  action$.pipe(
    filter(isActionOf(switchAuth.request)),
    mergeMap((action) =>
      authAPI
        .renew({
          params: { token: state$.value.authDuck.token, ...action.payload },
        })
        .pipe(
          map((data) => switchAuth.success(data.response)),
          catchError((message: Error) => of(switchAuth.failure(message))),
          takeUntil(action$.pipe(filter(isActionOf(switchAuth.cancel))))
        )
    )
  )

export const resetPassword = createAsyncAction(
  'RESET_PASSWORD_REQUEST',
  'RESET_PASSWORD_SUCCESS',
  'RESET_PASSWORD_FAILURE',
  'RESET_PASSWORD_CANCEL'
)<{ username: string }, IAuth, Error, undefined>()

export const resetPasswordEpic: Epic<
  RootAction,
  RootAction,
  RootState,
  typeof Providers
> = (action$, state$, { authAPI }) =>
  action$.pipe(
    filter(isActionOf(resetPassword.request)),
    mergeMap((action) =>
      authAPI
        .resetPassword({
          params: { ...action.payload },
        })
        .pipe(
          map((data) => resetPassword.success(data.response)),
          catchError((message: Error) => of(resetPassword.failure(message))),
          takeUntil(action$.pipe(filter(isActionOf(resetPassword.cancel))))
        )
    )
  )

export type AuthAction =
  | ActionType<typeof fetchAuth>
  | ActionType<typeof switchAuth>
  | ActionType<typeof forceAuthLogout>
  | ActionType<typeof forceAuthErrorAlert>
  | ActionType<typeof forceAuthClearErrors>
  | ActionType<typeof prepareAuthTeamSwitching>
  | ActionType<typeof resetPassword>

export const authReducer = createReducer(initialState)
  .handleAction(getType(fetchAuth.request), (state: any, { payload }: any) => ({
    ...initialState,
  }))
  .handleAction(getType(fetchAuth.success), (state: any, { payload }: any) => ({
    ...state,
    token: payload.token,
    error: null,
  }))
  .handleAction(getType(fetchAuth.failure), (state: any, { payload }: any) => ({
    ...state,
    error: payload,
  }))
  .handleAction(getType(fetchAuth.cancel), (state: any, { payload }: any) => ({
    ...state,
    token: null,
    error: null,
  }))
  .handleAction(
    getType(switchAuth.request),
    (state: any, { payload }: any) => ({
      ...state,
      nextTeam: undefined,
    })
  )
  .handleAction(
    getType(switchAuth.success),
    (state: any, { payload }: any) => ({
      ...state,
      ...payload,
    })
  )
  .handleAction(
    getType(resetPassword.request),
    (state: any, { payload }: any) => ({
      ...state,
      isResetPassword: null,
    })
  )
  .handleAction(
    getType(resetPassword.success),
    (state: any, { payload }: any) => ({
      ...state,
      isResetPassword: true,
    })
  )
  .handleAction(
    getType(resetPassword.failure),
    (state: any, { payload }: any) => ({
      ...state,
      isResetPassword: false,
    })
  )
  .handleAction(getType(switchAuth.cancel), (state: any, { payload }: any) => ({
    ...state,
    nextTeam: undefined,
  }))
  .handleAction(getType(forceAuthLogout), (state: any, { payload }: any) => ({
    ...state,
    isForceLogout: true,
  }))
  .handleAction(
    getType(forceAuthErrorAlert),
    (state: any, { payload }: any) => ({
      ...state,
      isForceAuthErrorAlert: true,
      ...payload,
    })
  )
  .handleAction(
    getType(forceAuthClearErrors),
    (state: any, { payload }: any) => ({
      ...state,
      isForceAuthErrorAlert: false,
      status: null,
    })
  )
  .handleAction(
    getType(prepareAuthTeamSwitching),
    (state: any, { payload }: any) => ({
      ...state,
      nextTeam: payload,
    })
  )

const authEpic = combineEpics<any>(
  fetchAuthEpic,
  switchAuthEpic,
  resetPasswordEpic
)
export default authEpic
