import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonLabel,
  IonList,
  IonModal,
  IonPage,
  IonTitle,
  IonToolbar,
} from '@ionic/react'
import { Checkbox, FormControlLabel, MenuItem, TextField } from '@mui/material'
import {
  add,
  checkmark,
  close,
  closeCircleOutline,
  reorderFour,
} from 'ionicons/icons'
import { useCallback } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
  generateFieldId,
  getSchemaFieldsFromTemplate,
} from '../../helpers/schema'
import useModal from '../../hooks/useModal'
import ReorderModal from '../../modals/ReorderModal'
import { SubSchemaType } from '../../models/schema'
import { RootState } from '../../store/ducks'
import { updateTemplate } from '../../store/templateSlice'
import './EditFieldModal.scss'

export interface Field {
  name: string
  component: string
  label: string
  options: { label: string; value: string }[]
  placeholder: string
  helperText: string
  isRequired: boolean
  initialValue?: string | string[]
}

interface EditFieldModalProps {
  // 正在編輯的選單，客戶選單或內部選單
  schemaType: SubSchemaType

  // 正在編輯的欄位，若值為 undefined，代表正在創建新的欄位
  editingField?: Field
  dismiss: () => void
}

const EditFieldModal = ({
  schemaType,
  editingField,
  dismiss,
}: EditFieldModalProps) => {
  const { t } = useTranslation()
  const { register, control, watch, handleSubmit, setValue } = useForm<Field>({
    defaultValues: editingField,
  })
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'options',
  })
  const dispatch = useDispatch()
  const template = useSelector((state: RootState) => state.newTemplate.template)
  const componentType = watch('component')
  const options = watch('options')
  const showOptions = componentType === 'select' || componentType === 'checkbox'
  const [isShowReorderModal, showReorderModal, dismissReorderModal] = useModal()

  // 若欄位類型為「核取方塊」，使用者設置的預設值列表
  // 備註：其他欄位類型的預設值型別為字串，而核取方塊的預設值型別為字串陣列，
  // 因此切換欄位類型為核取方塊的時候需特別做處理，不然會出錯
  let checkboxInitialValue = watch('initialValue')
  if (
    componentType === 'checkbox' &&
    typeof checkboxInitialValue === 'string'
  ) {
    checkboxInitialValue = []
  }

  // 儲存編輯的欄位或新增欄位
  const onSave = useCallback(() => {
    handleSubmit((input) => {
      // 確認 template 是否已初始化
      if (!template) return
      const { custom_fields_schema, itn_custom_fields_schema } = template
      if (!custom_fields_schema || !itn_custom_fields_schema) return

      // 對使用者輸入的內容進行額外處理
      const { component: componentType, options, initialValue } = input
      if (componentType === 'textarea') {
        // 文字欄位不需要儲存選項
        input.options = []
      } else if (componentType === 'select' || componentType === 'checkbox') {
        // 下拉選單與核取方塊需要至少一個選項
        if (options.length <= 0) {
          return
        }

        // 將輸入的選項格式調整為資料庫儲存的選項格式
        input.options = options.map(({ value }) => ({ value, label: value }))

        // 設置的預設值要等於其中一個選項的值
        const optionLabels = input.options.map(({ label }) => label)
        if (
          typeof initialValue === 'string' &&
          !optionLabels.includes(initialValue)
        ) {
          input.initialValue = undefined
        }
      }

      // 儲存在資料庫中的客戶選單與內部選單為 json 字串，需先轉換為物件
      const fields: Field[] = getSchemaFieldsFromTemplate(schemaType, template)

      if (editingField) {
        // 更新原本客戶選單或內部選單的欄位
        const targetIdx = fields.findIndex(
          (field) => editingField.name === field.name
        )
        if (targetIdx >= 0) fields[targetIdx] = input
      } else {
        // 新增新的欄位
        fields.push({
          ...input,
          name: generateFieldId(schemaType),
        })
      }

      // 向後端發送更新 template 的 request
      dispatch(
        updateTemplate({
          key: template.key,
          [`${schemaType}_schema`]: JSON.stringify({
            fields,
          }),
        })
      )
      dismiss()
    })()
  }, [handleSubmit, template, dispatch, editingField, schemaType, dismiss])

  const onReorderOptions = (options: { label: string; value: string }[]) => {
    setValue('options', options)
    dismissReorderModal()
  }

  return (
    <IonPage className="edit-field-modal">
      <IonHeader>
        <IonToolbar color="primary">
          {/* 標題 */}
          <IonTitle>
            <IonButton class="logo immutable">{t('Suiqui Support')}</IonButton>
            <span className="subtitle">
              {editingField ? t('Edit Field') : t('Add field')}
            </span>
          </IonTitle>

          <IonButtons slot="end">
            {/* 關閉按鈕 */}
            <IonButton strong fill="clear" slot="icon-only" onClick={dismiss}>
              <IonIcon size="large" icon={close}></IonIcon>
            </IonButton>

            {/* 儲存後關閉按鈕 */}
            <IonButton strong fill="clear" slot="icon-only" onClick={onSave}>
              <IonIcon size="large" icon={checkmark}></IonIcon>
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding bg" scrollEvents={true}>
        <IonList lines="inset">
          {/* 基本資訊 */}
          <section>
            <h5>{t('Basic Info')}</h5>
            <div className="field-name-input">
              {/* 欄位名稱 */}
              <TextField
                fullWidth
                required
                label={t('Field Name')}
                className="input-field"
                {...register('label', { required: true })}
              />

              {/* 必填 */}
              <FormControlLabel
                control={<Checkbox {...register('isRequired')} />}
                label={t('Field Required').toString()}
                className="input-field"
              />
            </div>

            {/* 欄位類型 */}
            <TextField
              select
              fullWidth
              required
              label={t('Field type')}
              defaultValue={editingField?.component ?? 'select'}
              className="input-field"
              {...register('component', { required: true })}
            >
              {['textarea', 'select', 'checkbox'].map((option) => (
                <MenuItem key={option} value={option}>
                  {t(option)}
                </MenuItem>
              ))}
            </TextField>

            {/* 範例說明 */}
            <TextField
              fullWidth
              label={t('Field Placeholder')}
              className="input-field"
              {...register('placeholder')}
            />

            {/* 詳細說明 */}
            <TextField
              fullWidth
              label={t('Field HelperText')}
              className="input-field"
              {...register('helperText')}
            />

            {/* 預設值 */}
            {componentType === 'textarea' && (
              <TextField
                fullWidth
                label={t('Default Value')}
                className="input-field"
                {...register('initialValue')}
              />
            )}
            {componentType === 'select' && (
              <TextField
                select
                fullWidth
                label={t('Default Value')}
                className="input-field"
                defaultValue={editingField?.initialValue}
                {...register('initialValue')}
              >
                <MenuItem value={undefined}>{t('None')}</MenuItem>
                {options?.map(({ value: option }) => (
                  <MenuItem key={option} value={option}>
                    {t(option)}
                  </MenuItem>
                ))}
              </TextField>
            )}
            {componentType === 'checkbox' && (
              <TextField
                select
                fullWidth
                label={t('Default Value')}
                className="input-field"
                defaultValue={editingField?.initialValue ?? []}
                {...register('initialValue')}
                SelectProps={{ multiple: true }}
                value={checkboxInitialValue}
              >
                {options?.map(({ value: option }) => (
                  <MenuItem key={option} value={option}>
                    {t(option)}
                  </MenuItem>
                ))}
              </TextField>
            )}
          </section>

          {/* 選項 */}
          {showOptions && (
            <section>
              <div className="options-header">
                <h5>
                  {t('Options')}
                  {fields.length === 0 && (
                    <span className="no-option-hint">
                      {t('* Required at least one option')}
                    </span>
                  )}
                </h5>

                {/* 重新排序選項按鈕 */}
                <IonButton fill="clear" slot="end" onClick={showReorderModal}>
                  <IonIcon slot="start" icon={reorderFour} />
                  <IonLabel>{t('Reorder')}</IonLabel>
                </IonButton>
              </div>

              {fields.map((field, index) => (
                <div key={field.id} className="option-input-field">
                  {/* 選項名稱 */}
                  <TextField
                    fullWidth
                    label={t('Option Label')}
                    {...register(`options.${index}.value` as const, {
                      required: true,
                    })}
                  />
                  <IonButton
                    fill="clear"
                    color="danger"
                    onClick={() => remove(index)}
                    className="delete-option-btn"
                  >
                    <IonIcon icon={closeCircleOutline}></IonIcon>
                  </IonButton>
                </div>
              ))}

              {/* 新增選項按鈕 */}
              <div className="add-option-btn">
                <IonButton onClick={() => append({ label: '', value: '' })}>
                  <IonIcon slot="start" icon={add}></IonIcon>
                  <IonLabel>{t('Add Option')}</IonLabel>
                </IonButton>
              </div>
            </section>
          )}
        </IonList>
      </IonContent>

      {/* 重新排序選項 Modal */}
      <IonModal isOpen={isShowReorderModal} backdropDismiss={false}>
        {isShowReorderModal && (
          <ReorderModal
            isOpen={isShowReorderModal}
            initialItems={options}
            getName={(option) => option.label}
            onDone={onReorderOptions}
            dismiss={dismissReorderModal}
          />
        )}
      </IonModal>
    </IonPage>
  )
}

export default EditFieldModal
