import { useRef, useEffect } from 'react'

/**
 * 解決 ionic 的空白頁面 bug，移除 IonPage cssClass 中的 ion-page-invisible，以正確顯示頁面
 * @returns pageRef — IonPage 的 reference
 */
export function useRemoveIonPageInvisible() {
  const pageRef = useRef<HTMLElement | null>(null)

  useEffect(() => {
    if (!pageRef) return

    // workaround to fix blank page...
    setTimeout(() => {
      pageRef.current?.classList.remove('ion-page-invisible')
    }, 300)
  }, [pageRef])

  return pageRef
}
