import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonPage,
  IonSpinner,
  IonTitle,
  IonToolbar,
  isPlatform,
  useIonLoading,
} from '@ionic/react'
import { DatePicker } from '@mui/lab'
import { Checkbox, FormControlLabel, TextField } from '@mui/material'
import { checkmark, close } from 'ionicons/icons'
import moment from 'moment'
import { useCallback, useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { defaultDatePickerProps } from '../../models/schema'
import { RootState } from '../../store/ducks'
import { clearStatus, exportBatchCsv } from '../../store/templateSlice'
import './ExportTemplatesModal.scss'

interface FormData {
  // 是否匯出各個專案，每個專案分別對應到一個 boolean
  isExportTemplate: boolean[]
  startDate?: Date
  endDate?: Date
}

interface ExportTemplatesModalProps {
  dismiss: () => void
}

const ExportTemplatesModal = ({ dismiss }: ExportTemplatesModalProps) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const templates = useSelector(
    (state: RootState) => state.newTemplate.templates
  )
  const { isLoading, status } = useSelector(
    (state: RootState) => state.newTemplate
  )
  const { control, handleSubmit, watch, setValue } = useForm<FormData>({
    defaultValues: {
      isExportTemplate: templates.map(() => true),
      startDate: moment(new Date()).subtract(1, 'month').toDate() ?? new Date(),
      endDate: new Date(),
    },
  })
  const isSelectedAll = watch('isExportTemplate').every((e) => e === true) // 是否已全選所有專案
  const [showIonLoading, dismissIonLoading] = useIonLoading() // 等待匯出的視覺效果
  // 是否有尚未填寫的內容
  const isValid =
    !watch('isExportTemplate').every((e) => e === false) &&
    watch('startDate') &&
    watch('endDate')

  const onExport = useCallback(() => {
    handleSubmit(({ isExportTemplate, startDate, endDate }) => {
      if (!startDate || !endDate) return
      const template_keys = []
      for (let i = 0; i < isExportTemplate.length; i++) {
        const isExport = isExportTemplate[i]
        if (isExport) template_keys.push(templates[i].key)
      }
      dispatch(
        exportBatchCsv({
          template_keys: template_keys,
          start_date: startDate,
          end_date: endDate,
        })
      )
      showIonLoading()
    })()
  }, [dispatch, handleSubmit, templates, showIonLoading])

  const onSelectAll = useCallback(() => {
    if (isSelectedAll) {
      // 取消全選
      setValue(
        'isExportTemplate',
        templates.map(() => false)
      )
    } else {
      // 全選
      setValue(
        'isExportTemplate',
        templates.map(() => true)
      )
    }
  }, [isSelectedAll, templates, setValue])

  // 當完成匯出時觸發，關閉 ionLoading 與此 modal
  useEffect(() => {
    if (status === 'batch exported') {
      dismissIonLoading()
      dispatch(clearStatus())
      dismiss()
    }
  }, [dispatch, status, dismiss, dismissIonLoading])

  return (
    <IonPage className="page-export-templates-modal">
      <IonHeader>
        <IonToolbar color="primary">
          <IonTitle>
            <IonButton className="logo immutable">
              {t('Suiqui Support')}
            </IonButton>
            <span className="subtitle" hidden={isPlatform('mobile')}>
              {t('Export projects')}
            </span>
          </IonTitle>
          <IonButtons slot="end">
            {/* 取消按鈕 */}
            <IonButton strong fill="clear" slot="icon-only" onClick={dismiss}>
              <IonIcon size="large" icon={close}></IonIcon>
            </IonButton>

            {/* 確認按鈕 */}
            <IonButton
              strong
              fill="clear"
              slot="icon-only"
              disabled={!isValid}
              onClick={onExport}
            >
              <IonIcon size="large" icon={checkmark}></IonIcon>
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding" scrollEvents>
        {isLoading ? (
          <div className="ion-text-center centered">
            <IonSpinner color="primary" name="crescent"></IonSpinner>
          </div>
        ) : (
          <div>
            {/* 「日期區間」 */}
            <IonItem lines="none">
              <h3>{t('Date range')}</h3>
            </IonItem>

            <div className="date-picker-container">
              {/* 選擇起始日期欄位*/}
              <Controller
                render={({ field }) => {
                  return (
                    <DatePicker
                      label={t('Start date')}
                      value={field.value}
                      onChange={(date) => field.onChange(date)}
                      renderInput={(params) => <TextField {...params} />}
                      {...defaultDatePickerProps}
                    />
                  )
                }}
                name="startDate"
                control={control}
              />
              <span> ~ </span>

              {/* 選擇結束日期欄位*/}
              <Controller
                render={({ field }) => {
                  return (
                    <DatePicker
                      label={t('End date')}
                      value={field.value}
                      onChange={(date) => field.onChange(date)}
                      renderInput={(params) => <TextField {...params} />}
                      {...defaultDatePickerProps}
                    />
                  )
                }}
                name="endDate"
                control={control}
              />
            </div>

            <IonItem lines="none">
              {/* 「要匯出的專案」 */}
              <h3>{t('Exporting projects')}</h3>

              {/* 「全選」/「取消全選」按鈕 */}
              <IonButtons slot="end">
                <IonButton
                  color="secondary"
                  fill="outline"
                  slot="end"
                  onClick={onSelectAll}
                >
                  <IonLabel>
                    {isSelectedAll ? t('Unselect all') : t('Select all')}
                  </IonLabel>
                </IonButton>
              </IonButtons>
            </IonItem>

            {/* 專案列表 */}
            {templates.map((template, index) => {
              return (
                <IonItem button key={template.key}>
                  <FormControlLabel
                    label={template.name}
                    className="checkbox-label"
                    control={
                      <Controller
                        name={`isExportTemplate.${index}` as const}
                        control={control}
                        render={({ field: { value, ...field } }) => (
                          <Checkbox {...field} checked={!!value} />
                        )}
                      />
                    }
                  />
                </IonItem>
              )
            })}
          </div>
        )}
      </IonContent>
    </IonPage>
  )
}

export default ExportTemplatesModal
