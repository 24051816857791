import { IonButton, IonButtons, IonIcon, IonModal } from '@ionic/react'
import { person } from 'ionicons/icons'
import { useTranslation } from 'react-i18next'
import { useModalWithData } from '../../hooks/useModal'
import { id } from '../../models/base'
import { Signature } from '../../models/template'
import './SignatureFields.scss'
import SignatureModal from './SignatureModal'

interface SignatureFieldsProps {
  signatures: Signature[]
}

/**
 * 簽名欄位
 *  Notes: 此元件並非使用 react-form-renderer 套件方式進行 render，而是直接在 FormTemplate 裡的欄位元件下方加入此自訂元件
 */
const SignatureFields = ({ signatures }: SignatureFieldsProps) => {
  const { t } = useTranslation()
  const [
    isShowSignatureModal, // 是否顯示簽名 modal
    signatureModalData, // 要傳入簽名 modal 的資料（signature key）
    showSignatureModal,
    dismissSignatureModal,
  ] = useModalWithData<id>()

  return (
    <section className="signature-fields">
      <h5 className="title">{t('Signature Fields')}</h5>
      <IonButtons className="signatures">
        {signatures.map((signature, index) => (
          <IonButton
            key={index}
            color="primary"
            fill={signature.signed ? 'solid' : 'outline'}
            onClick={() => showSignatureModal(signature.key)}
          >
            <IonIcon slot="start" icon={person} />
            {signature.name}
          </IonButton>
        ))}
      </IonButtons>

      {/* 新增簽核欄位 Modal */}
      <IonModal isOpen={isShowSignatureModal} backdropDismiss={false}>
        {isShowSignatureModal && signatureModalData && (
          <SignatureModal
            signatureKey={signatureModalData}
            dismiss={dismissSignatureModal}
          />
        )}
      </IonModal>
    </section>
  )
}

export default SignatureFields
