import { combineEpics, Epic } from 'redux-observable'
import { of } from 'rxjs'
import { catchError, filter, map, switchMap, takeUntil } from 'rxjs/operators'
import {
  ActionType,
  createAsyncAction,
  createReducer,
  getType,
  isActionOf,
} from 'typesafe-actions'
import { ITeamMeta, ITeamMetaDetail } from '../../models/teamMeta'
import * as Providers from '../../providers'
import { RootState } from '../ducks'
import { RootAction } from './types'
import { fetchPublicTeamMeta } from '../public_ticket/actions'

export const fetchTeamMeta = createAsyncAction(
  'FETCH_TEAM_META_REQUEST',
  'FETCH_TEAM_META_SUCCESS',
  'FETCH_TEAM_META_FAILURE',
  'FETCH_TEAM_META_CANCEL'
)<undefined, ITeamMeta, Error, undefined>()

const initialState: ITeamMeta & {
  isDone: boolean | undefined,
  updated: boolean | undefined,
} = {
  meta: {
    ticket_required_fields: undefined,
    key: undefined,
    type: undefined,
  },
  isDone: undefined,
  updated: undefined,
}

export const fetchTeamMetaEpic: Epic<
  RootAction,
  RootAction,
  RootState,
  typeof Providers
> = (action$, state$, { teamAPI }) =>
  action$.pipe(
    filter(isActionOf(fetchTeamMeta.request)),
    switchMap((action) =>
      teamAPI.getTeamMeta({ token: state$.value.authDuck.token }).pipe(
        map((data) => fetchTeamMeta.success(data.response)),
        catchError((message: Error) => of(fetchTeamMeta.failure(message))),
        takeUntil(action$.pipe(filter(isActionOf(fetchTeamMeta.cancel))))
      )
    )
  )

export const updateTeamMeta = createAsyncAction(
  'UPDATE_TEAM_META_REQUEST',
  'UPDATE_TEAM_META_SUCCESS',
  'UPDATE_TEAM_META_FAILURE',
  'UPDATE_TEAM_META_CANCEL'
)<Partial<ITeamMetaDetail>, Partial<ITeamMeta>, Error, undefined>()

export const updateTeamMetaEpic: Epic<
  RootAction,
  RootAction,
  RootState,
  typeof Providers
> = (action$, state$, { teamAPI }) =>
  action$.pipe(
    filter(isActionOf(updateTeamMeta.request)),
    switchMap((action) =>
      teamAPI
        .updateTeamMeta({ token: state$.value.authDuck.token, params: action.payload })
        .pipe(
          map((data) => updateTeamMeta.success(data.response)),
          catchError((message: Error) => of(updateTeamMeta.failure(message))),
          takeUntil(action$.pipe(filter(isActionOf(updateTeamMeta.cancel))))
        )
    )
  )

export const teamMetaReducer = createReducer(initialState)
  .handleAction(getType(fetchTeamMeta.request), (state: any, { payload }: any) => ({
    ...initialState,
  }))
  .handleAction(getType(fetchTeamMeta.success), (state: any, { payload }: any) => ({
    ...initialState,
    ...payload,
    error: null,
    isDone: true,
  }))
  .handleAction(getType(fetchTeamMeta.failure), (state: any, { payload }: any) => ({
    ...state,
    error: payload,
    isDone: true,
  }))
  .handleAction(getType(fetchTeamMeta.cancel), (state: any, { payload }: any) => ({
    ...initialState,
  }))
  .handleAction(getType(fetchPublicTeamMeta.request), (state: any, { payload }: any) => ({
    ...initialState,
  }))
  .handleAction(getType(fetchPublicTeamMeta.success), (state: any, { payload }: any) => ({
    ...initialState,
    ...payload,
    error: null,
    isDone: true,
  }))
  .handleAction(getType(fetchPublicTeamMeta.failure), (state: any, { payload }: any) => ({
    ...state,
    error: payload,
    isDone: true,
  }))
  .handleAction(getType(fetchPublicTeamMeta.cancel), (state: any, { payload }: any) => ({
    ...initialState,
  }))
  .handleAction(
    getType(updateTeamMeta.request),
    (state: any, { payload }: any) => ({
      ...state,
      updated: undefined,
  }))
  .handleAction(
    getType(updateTeamMeta.success),
    (state: any, { payload }: any) => ({
      ...state,
      ...payload,
      error: null,
      updated: true,
    })
  )
  .handleAction(
    getType(updateTeamMeta.failure),
    (state: any, { payload }: any) => ({
      ...state,
      error: payload,
      updated: false,
    })
  )
  .handleAction(getType(updateTeamMeta.cancel), (state: any, { payload }: any) => ({
    ...state,
    error: null,
    updated: undefined,
  }))

export type TeamMetaAction =
  | ActionType<typeof fetchTeamMeta>
  | ActionType<typeof updateTeamMeta>

const teamMetaEpic = combineEpics<any>(fetchTeamMetaEpic, updateTeamMetaEpic)
export default teamMetaEpic
