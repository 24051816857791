import { IonText, isPlatform } from '@ionic/react'
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import WebFont from 'webfontloader'
import App from './App'
import i18n from './i18n'
import './index.css'
import reportWebVitals from './reportWebVitals'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import store from './store'
import swConfig from './swConfig'
import ReactGA from 'react-ga4'

// 當產品在 production 環境時，才啟用 GA
if (process.env.NODE_ENV === 'production') {
  ReactGA.initialize(`${process.env.REACT_APP_GA_TRACKING_ID}`)
}

if (/Line/.test(navigator.userAgent)) {
  const urlParams = new URLSearchParams(window.location.search)

  // 當連結中沒有 openExternalBrowser 參數時，自動加上
  if (!urlParams.get('openExternalBrowser')) {
    urlParams.set('openExternalBrowser', '1')

    // Notice: href 有包含 query string，所以要用 origin + pathname 來取代
    // 避免重複加上 query string
    const redirectUrl = `${window.location.origin}${
      window.location.pathname
    }?${urlParams.toString()}`

    // 重新導向至開啟外部瀏覽器的網址
    window.location.replace(redirectUrl)
  }

  ReactDOM.render(
    <div className="ion-padding ion-text-center ion-justify-content-center ion-align-self-center">
      <IonText color="primary">{i18n.t('Please close this page.')}</IonText>
    </div>,
    document.getElementById('root')
  )
} else {
  if (isPlatform('desktop')) {
    WebFont.load({
      google: {
        families: ['Noto Sans TC:400,700:chinese-traditional&display=swap'],
      },
      active: function () {
        sessionStorage.fonts = true
      },
    })
  }

  ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        <App />
      </Provider>
    </React.StrictMode>,
    document.getElementById('root')
  )
}

if ('serviceWorker' in navigator) {
  serviceWorkerRegistration.register(swConfig as any)
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
