import {
  IonButton,
  IonIcon,
  IonItem,
  IonLabel,
  IonThumbnail,
  IonToolbar,
} from '@ionic/react'
import { pencil, trash } from 'ionicons/icons'
import { useTranslation } from 'react-i18next'
import { getImageUrl, getFallbackImage } from '../../helpers/file'
import { ISuiquiFile } from '../../models/suiquiFile'
import './PhotoDetailsCard.scss'

interface PhotoDetailsCardProps {
  image: { url: string; name: string; memo?: string }
  photoSize: number
  onClick?: () => void
  onEditMemo: () => void
  onDelete?: () => void
}

const PhotoDetailsCard = ({
  image,
  photoSize,
  onClick,
  onEditMemo,
  onDelete,
}: PhotoDetailsCardProps) => {
  const { t } = useTranslation()
  const sizeRatio = 4 / 3
  return (
    <IonItem
      lines="full"
      className="photo-details-card"
      onClick={onClick}
      button
    >
      {/* 圖片 */}
      <IonThumbnail
        className="photo"
        style={{
          width: photoSize * sizeRatio,
          height: photoSize,
        }}
      >
        <img
          alt=""
          width={photoSize * sizeRatio}
          height={photoSize}
          src={image.url}
          onError={getFallbackImage}
        ></img>
      </IonThumbnail>
      <div className="photo-info">
        <div className="photo-toolbar">
          <p className="photo-name">{image.name}</p>
          <div>
            {/* 編輯記事按鈕 */}
            <IonButton
              color="medium"
              fill="clear"
              slot="icon-only"
              onClick={(event) => {
                event.stopPropagation()
                onEditMemo()
              }}
            >
              <IonIcon icon={pencil} />
            </IonButton>

            {/* 刪除按鈕 */}
            {onDelete && (
              <IonButton
                color="medium"
                fill="clear"
                slot="icon-only"
                onClick={(event) => {
                  event.stopPropagation()
                  onDelete()
                }}
              >
                <IonIcon icon={trash} />
              </IonButton>
            )}
          </div>
        </div>

        {/* 記事 */}
        <p className="photo-memo">{!image.memo ? t('No Memo') : image.memo}</p>
      </div>
    </IonItem>
  )
}

export default PhotoDetailsCard
