import { IonIcon, IonItem, IonMenuToggle } from '@ionic/react'
import classNames from 'classnames'
import React, { useCallback } from 'react'
import { useHistory, useLocation } from 'react-router'
import { AppPage } from '../../declarations'
import './MenuItem.scss'

interface MenuItemProps {
  appPage: AppPage
  index: number
}

function MenuItem({ appPage, index }: MenuItemProps) {
  const location = useLocation()
  const history = useHistory()

  const handleRouterLink = useCallback(
    (event: any, path: string) => {
      event.preventDefault()
      event.stopPropagation()
      history.push(path, { direction: 'root' })
    },
    [history]
  )

  const isSelected = location.pathname.includes(appPage.url)

  return (
    <IonMenuToggle key={index} auto-hide="false">
      <IonItem
        onClick={(e) => handleRouterLink(e, appPage.url)}
        href="#"
        lines="none"
        className={classNames({
          'menu-item': true,
          'selected-menu-item': isSelected,
        })}
      >
        <IonIcon
          slot="start"
          icon={appPage.icon}
          color={isSelected ? 'primary' : ''}
        />
        {appPage.title}
      </IonItem>
    </IonMenuToggle>
  )
}

export default MenuItem
