import {
  IonButton,
  IonContent,
  IonFooter,
  IonGrid,
  IonHeader,
  IonLabel,
  IonLoading,
  IonModal,
  IonPage,
  IonTitle,
  IonToast,
  IonToolbar,
  useIonViewWillEnter,
} from '@ionic/react'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import useLocalForge from '../../hooks/use-local-forge'
import useModal from '../../hooks/useModal'
import useToast from '../../hooks/useToast'
import { DELAY } from '../../models/constants'
import { Creators as AuthActions } from '../../store/ducks/auth'
import { fetchAuth } from '../../store/epics/auth'
import { fetchUser } from '../../store/epics/user'
import ForgetPasswordModal from './ForgetPasswordModal'
import LoginForm from './LoginForm'
import './Login.scss'

const Login = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [token, setToken] = useLocalForge('apiToken')
  const [_, setCurrentUser] = useLocalForge('suiquiUserData')
  const history = useHistory()
  const auth = useSelector((state: any) => state.auth)
  const user = useSelector((state: any) => state.user)
  const authDuck = useSelector((state: any) => state.authDuck)
  const [
    showForgetPasswordModal,
    openForgetPasswordModal,
    dismissForgetPasswordModal,
  ] = useModal()

  const [isPageLoading, setIsPageLoading] = useState(true)
  const [isRedirecting, setIsRedirecting] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { showToast, toastProps } = useToast()

  const [currentLocation, setCurrentLocation] = useState({
    state: {
      from: {
        pathname: undefined,
        state: {
          direction: 'root',
        },
      },
    },
  })

  const handleSubmit = (values: Record<string, any>) => {
    setIsPageLoading(true)
    setIsSubmitting(true)
    if (authDuck.location) {
      setCurrentLocation(authDuck.location as any)
      dispatch(AuthActions.setLocation(null))
    }
    dispatch(fetchAuth.request(values))
  }

  useIonViewWillEnter(() => {
    setIsRedirecting(false)
    setIsPageLoading(false)
  }, [setIsPageLoading, setIsRedirecting])

  useMemo(() => {
    if (!auth.token) return
    if (authDuck.isAuthenticated) return
    // save login
    if (token !== auth.token) {
      setToken(auth.token)
    }

    dispatch(AuthActions.login(auth.token))
  }, [dispatch, setToken, authDuck.isAuthenticated, auth.token, token])

  useMemo(() => {
    if (!auth.token) return
    if (user.currentUser) return
    // fetch user when auth
    if (!authDuck.isUserLoaded) {
      dispatch(
        fetchUser.request({
          token: auth.token,
        })
      )
    }
  }, [dispatch, auth.token, user.currentUser, authDuck.isUserLoaded])

  useMemo(() => {
    if (authDuck.isUserLoaded) return
    if (user.currentUser) {
      setToken(auth.token)
      setCurrentUser(user.currentUser)
      dispatch(AuthActions.checkIn(user.currentUser))
    }
  }, [
    dispatch,
    setToken,
    setCurrentUser,
    authDuck.isUserLoaded,
    user.currentUser,
    auth.token,
  ])

  useMemo(() => {
    if (isRedirecting) return
    if (auth.token && authDuck.isUserLoaded) {
      setIsRedirecting(true)
      dispatch(AuthActions.openMenu(true))
      const { from } = currentLocation.state || {
        from: { pathname: '/home', state: { direction: 'root' } },
      }

      setTimeout(() => {
        if (from && from.pathname && from.pathname !== '/login') {
          history.replace(from.pathname || '/home', { direction: 'root' })
        } else {
          history.replace('/home', { direction: 'root' })
        }
      }, DELAY)
    }
  }, [
    dispatch,
    history,
    auth.token,
    authDuck.isUserLoaded,
    isRedirecting,
    currentLocation.state,
  ])

  useEffect(() => {
    if (auth.token) return
    if (auth.error) {
      setIsPageLoading(false)
      setIsSubmitting(false)
      setIsRedirecting(false)
      showToast(
        t(
          'Unable to sign in. Please check your account information and try again.'
        )
      )
    }
  }, [t, auth.token, auth.error, setIsPageLoading, showToast])

  // 傳送密碼重設信成功
  useEffect(() => {
    if (!auth.isResetPassword) return
    showToast(t('Password reset email sent successfully'))
  }, [t, auth.isResetPassword, showToast])

  // 傳送密碼重設信失敗
  useEffect(() => {
    if (auth.isResetPassword === false) {
      showToast(t('Failed to send password reset email'))
    }
  }, [t, auth.isResetPassword, showToast])

  return (
    <IonPage className="page-login">
      <IonHeader>
        <IonToolbar color="primary">
          <IonTitle>
            <IonButton class="logo immutable">{t('Suiqui Support')}</IonButton>
            <span className="subtitle">{t('SUIQUI_SUPPORT')}</span>
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding bg">
        <IonLoading isOpen={isSubmitting} />
        {!isPageLoading && (
          <IonGrid className="grid">
            <LoginForm handleSubmit={handleSubmit} />
            <IonButton
              onClick={openForgetPasswordModal}
              color="primary"
              fill="clear"
              className="ion-margin-top"
            >
              <IonLabel>{t('Forget password?')}</IonLabel>
            </IonButton>
          </IonGrid>
        )}
      </IonContent>

      <IonFooter className="ion-text-center">
        <IonButton
          fill="clear"
          size="small"
          strong={true}
          className="immutable"
        >
          <IonLabel color="medium">
            Suiqui Support {`${process.env.REACT_APP_VERSION}`}
          </IonLabel>
        </IonButton>
      </IonFooter>

      <IonToast {...toastProps} />

      {showForgetPasswordModal && (
        <IonModal isOpen={showForgetPasswordModal} backdropDismiss={false}>
          {showForgetPasswordModal && (
            <ForgetPasswordModal
              isOpen={showForgetPasswordModal}
              dismiss={dismissForgetPasswordModal}
            />
          )}
        </IonModal>
      )}
    </IonPage>
  )
}

export default Login
