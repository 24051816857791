import {
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonNote,
  IonPage,
  IonPopover,
  IonText,
  IonTitle,
  IonToggle,
  IonToolbar,
  isPlatform,
} from '@ionic/react'
import classNames from 'classnames'
import { close, createOutline, informationCircle } from 'ionicons/icons'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import useModal from '../../hooks/useModal'
import { useRemoveIonPageInvisible } from '../../hooks/useRemoveIonPageInvisible'
import { id } from '../../models/base'
import { EPreviewFormType } from '../../models/form'
import { ITicketNotificationBell } from '../../models/ticket'
import { updateTicketReminder } from '../../store/epics/ticket'
import NoReminder from './NoReminder'
import ReminderEditModal from './ReminderEditModal'
import './ReminderPreviewModal.scss'

interface ReminderPreviewModalProps {
  dismiss: () => void
  itemKey: id
  notificationBells: ITicketNotificationBell[]
}

// 預覽 節點提醒 的 modal 畫面，在 Ticket 中點擊「小鬧鐘」按鈕後顯示
const ReminderPreviewModal = ({
  dismiss,
  itemKey,
  notificationBells,
}: ReminderPreviewModalProps) => {
  const pageRef = useRemoveIonPageInvisible()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [
    isShowReminderEditModal,
    openReminderEditModal,
    dismissReminderEditModal,
  ] = useModal()

  // 設定是否顯示「說明」的 Popover 以及 Popover 的位置
  const [helpPopoverState, setHelpPopoverState] = useState<{
    isShowPopover: boolean
    event: Event | undefined // 用作 Popover 的位置
  }>({
    isShowPopover: false,
    event: undefined,
  })

  const handleHelpBtnClick = (e: React.MouseEvent) => {
    e.persist()
    setHelpPopoverState({
      isShowPopover: true,
      event: e.nativeEvent,
    })
  }

  // 當 Toggle 狀態改變時，更新提醒的開啓/關閉狀態
  const handelToggleChange = (value: boolean, modifiedIdx: number) => {
    const newNotificationBells = notificationBells.map((bell, idx) =>
      idx === modifiedIdx ? { ...bell, is_active: value } : bell
    )

    dispatch(
      updateTicketReminder.request({
        key: itemKey,
        notification_bells: newNotificationBells as ITicketNotificationBell[],
      })
    )
  }

  return (
    <IonPage ref={pageRef} class="page-reminder-preview-modal">
      <IonHeader>
        <IonToolbar color="primary">
          {/* Suiqui logo & 標題 */}
          <IonTitle>
            <IonButton className="logo immutable">
              {t('Suiqui Support')}
            </IonButton>
            <span className="subtitle" hidden={isPlatform('mobile')}>
              {t('Reminder')}
            </span>
          </IonTitle>

          <IonButtons slot="end">
            {/* 開啓「說明」Popover 的按鈕  */}
            <IonButton
              strong
              fill="clear"
              slot="icon-only"
              onClick={handleHelpBtnClick}
            >
              <IonIcon size="large" icon={informationCircle} />
            </IonButton>
            {/* close button */}
            <IonButton strong fill="clear" slot="icon-only" onClick={dismiss}>
              <IonIcon size="large" icon={close} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent className="ion-padding-top">
        {notificationBells.length ? (
          <IonList className="ion-margin-horizontal">
            {notificationBells.map((bell, idx) => (
              <IonItem
                key={idx}
                lines={idx === notificationBells.length - 1 ? 'none' : 'inset'}
                className={classNames({
                  'ion-margin-horizontal': !isPlatform('mobile'),
                  'ion-padding-horizontal': !isPlatform('mobile'),
                })}
              >
                {/* 提醒名稱 */}
                <IonLabel position="fixed">{bell.name}</IonLabel>
                {/* Ticket 顯示是否開啓提醒的 Toggle 與 提醒日期 */}
                <IonNote slot="end" className="notify-date">
                  <IonToggle
                    checked={bell.is_active}
                    onIonChange={(e) =>
                      handelToggleChange(e.detail.checked, idx)
                    }
                  />
                  <IonText>{bell.notify_date}</IonText>
                </IonNote>
              </IonItem>
            ))}
          </IonList>
        ) : (
          <NoReminder />
        )}
      </IonContent>

      <IonFooter>
        {/* 編輯 按鈕 */}
        <IonButton className="add-reminder" onClick={openReminderEditModal}>
          <IonIcon slot="start" icon={createOutline} />
          {t('Edit')}
        </IonButton>
      </IonFooter>

      {isShowReminderEditModal && (
        <IonModal isOpen={isShowReminderEditModal}>
          <ReminderEditModal
            dismiss={dismissReminderEditModal}
            itemKey={itemKey}
            notificationBells={notificationBells}
            type={EPreviewFormType.ticket}
          />
        </IonModal>
      )}

      {/* 說明 Popover */}
      <IonPopover
        cssClass="help-popover"
        isOpen={helpPopoverState.isShowPopover}
        event={helpPopoverState.event}
        onDidDismiss={() =>
          setHelpPopoverState({ isShowPopover: false, event: undefined })
        }
      >
        <IonItem>
          <IonLabel className="ion-text-wrap">
            <p>{t('Reminder help message')}</p>
          </IonLabel>
        </IonItem>
      </IonPopover>
    </IonPage>
  )
}

export default ReminderPreviewModal
