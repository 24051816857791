import { RefresherEventDetail } from '@ionic/core'
import {
  IonBadge,
  IonButton,
  IonButtons,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenuButton,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonSpinner,
  IonTitle,
  IonToast,
  IonToolbar,
  isPlatform,
  useIonViewDidEnter,
  useIonViewWillEnter,
  useIonViewWillLeave,
} from '@ionic/react'
import {
  caretUp,
  closeCircleOutline,
  logInOutline,
} from 'ionicons/icons'
import moment from 'moment'
import { useEffect, useRef, useState } from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { RouteComponentProps, useHistory } from 'react-router'
import { inFunctionList } from '../../helpers/base'
import { BREAKPOINT_MD, DELAY_SHORT, DELAY_TOAST } from '../../models/constants'
import { EPreviewFormType } from '../../models/form'
import {
  ETicketFunction,
  ETicketStatus,
  ETicketUserType,
  ITicket,
} from '../../models/ticket'
import { Creators as AuthActions } from '../../store/ducks/auth'
import {
  fetchComment,
  fetchCommentList,
  fetchPublicComment,
  fetchPublicCommentList,
} from '../../store/epics/comment'
import { fetchPublicTicket, fetchTicket } from '../../store/epics/ticket'
import { ReactRouterAction } from '../../store/epics/types'
import PreviewForm from '../../components/preview_form/PreviewForm'
import './PublicTicket.scss'
import PopupMenu from '../../components/PopupMenu'
import useToast from '../../hooks/useToast'
import ReactGA from 'react-ga4'
import classNames from 'classnames'
import ToggleCommentFAB from '../../components/ToggleCommentFAB'

interface PublicTicketProps
  extends RouteComponentProps<{ key: string }>,
    ReactRouterAction {
  userType: ETicketUserType
  pageWidth: number
  pageHeight: number
  actions: { toggleCommentList: () => void }
  actionProps: { isShowCommentList: boolean }
}

const PublicTicket = ({
  match,
  userType,
  pageWidth,
  actions,
  actionProps,
}: PublicTicketProps) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()
  const contentRef = useRef(null)

  const [isOpen, setIsOpen] = useState(false)
  const [isPageLoading, setIsPageLoading] = useState(false)
  const [isProcessing, setIsProcessing] = useState(false)
  const [showEmpty, setShowEmpty] = useState(false)
  const [isLoaded, setIsLoaded] = useState(false)
  const [showTicketFailedToast, setShowTicketFailedToast] = useState(false)
  const [currentTicketStatus, setCurrentTicketStatus] =
    useState<ETicketStatus>()
  const [currentTicketUserType, setCurrentTicketUserType] =
    useState<ETicketUserType>()

  const ticket = useSelector((state: any) => state.ticket)
  const authDuck = useSelector((state: any) => state.authDuck)

  const isPostponed =
    ticket.ticket_status === ETicketStatus.InProgress &&
    ticket.expected_end_date &&
    !ticket.actual_end_date &&
    moment().isAfter(ticket.expected_end_date, 'day')

  const { showToast, toastProps } = useToast()

  useEffect(() => {
    if (!isOpen) return
    setCurrentTicketUserType(userType)

    dispatch(AuthActions.openMenu(false))

    if (match.params.key) {
      setIsPageLoading(true)
      dispatch(fetchPublicTicket.cancel())
      dispatch(
        fetchPublicTicket.request({
          key: match.params.key,
        })
      )
    }
  }, [dispatch, match.params.key, setIsPageLoading, userType, isOpen])

  useEffect(() => {
    if (!isOpen) return
    if (!match.params.key) return
    if (!ticket.isDone) return

    if (userType) {
      dispatch(fetchPublicCommentList.cancel())
      dispatch(
        fetchPublicCommentList.request({
          key: match.params.key,
        })
      )
    } else {
      dispatch(fetchCommentList.cancel())
      dispatch(
        fetchCommentList.request({
          key: match.params.key,
        })
      )
    }
  }, [dispatch, isOpen, ticket.isDone, match.params.key, userType])

  useIonViewWillEnter(() => {
    if (isOpen) return
    setIsOpen(true)
  }, [setIsOpen, isOpen])

  // 進入公開工單頁面時，發送 page_view 事件
  useIonViewDidEnter(() => {
    if (process.env.NODE_ENV !== 'production') return
    setTimeout(() => {
      ReactGA.event('page_view', {
        page_title: `${document.title}`,
        page_location: `${window.location.href}`,
      })
    }, 3000)
  }, [])

  useIonViewWillLeave(() => {
    if (!isOpen) return
    setIsOpen(false)
    if (userType) {
      dispatch(fetchPublicTicket.cancel())
      dispatch(fetchPublicComment.cancel())
    } else {
      dispatch(fetchTicket.cancel())
      dispatch(fetchComment.cancel())
    }
    setShowTicketFailedToast(false)

    if (authDuck.isUserLoaded) {
      dispatch(AuthActions.openMenu(true))
    }
  }, [setIsOpen, dispatch, userType, isOpen, authDuck.isUserLoaded])

  const scrollToTop = () => {
    // @ts-ignore
    contentRef.current!.scrollToTop(DELAY_SHORT)
  }

  const loadData = (event?: any) => {
    setIsProcessing(true)
    dispatch(
      fetchPublicTicket.request({
        key: match.params.key,
        event,
      })
    )
  }

  const doRefresh = (event: CustomEvent<RefresherEventDetail>) => {
    setIsProcessing(true)
    dispatch(fetchPublicTicket.cancel())

    if (match.params.key) {
      dispatch(
        fetchPublicTicket.request({
          key: match.params.key,
          event,
        })
      )
    }
  }

  useEffect(() => {
    if (ticket.key !== undefined && ticket.isDone) {
      setShowEmpty(true)
      setIsPageLoading(false)
      setIsProcessing(false)
      setIsLoaded(true)

      setCurrentTicketStatus(ticket.ticket_status)
    }
  }, [
    setShowEmpty,
    setIsPageLoading,
    setIsProcessing,
    setCurrentTicketStatus,
    ticket.key,
    ticket.isDone,
    ticket.ticket_status,
  ])

  useEffect(() => {
    if (!ticket.error) return

    setIsPageLoading(false)
    setIsProcessing(false)
    setIsLoaded(true)

    if (ticket.error.response?.ticket_status === ETicketStatus.Done) {
      // 如果是瀏覽已完成的工單，顯示「此工單已完成。」
      showToast(t('This ticket is closed.'), () => {
        if (!authDuck.isUserLoaded) return

        // 如果使用者已登入，在 toast 結束後導回首頁
        history.replace('/')
      })
      return
    }

    // 否則顯示「不正確或無效的資料存取」
    setShowTicketFailedToast(true)
  }, [authDuck.isUserLoaded, history, showToast, t, ticket.error])

  const getTicketName = (item: ITicket) => {
    if (isPlatform('mobile') && !isPlatform('ipad')) {
      return item?.short_display_name || item?.name
    }
    return item?.display_name || item?.name
  }

  const getTicketId = (ticket: ITicket) => {
    const arr = `${ticket.id}`.match(/.{1,4}/g)
    if (arr?.length) return arr.join(' ')
    return ''
  }

  const handleLogInClick = () => {
    if (authDuck?.isUserLoaded) {
      history.replace(`/ticket/${match.params.key}`, {
        direction: 'root',
      })
    } else {
      dispatch(
        AuthActions.setLocation({
          state: {
            from: {
              pathname: `/ticket/${match.params.key}`,
            },
          },
        })
      )
      history.replace('/login', {
        direction: 'root',
      })
    }
  }

  return (
    <IonPage className="page-public-ticket">
      <PopupMenu></PopupMenu>
      <IonHeader>
        <IonToolbar color="primary">
          {/* 左側選單 */}
          {authDuck?.isUserLoaded && (
            <IonButtons slot="start">
              <IonMenuButton />
            </IonButtons>
          )}

          {/* SUIQUI Logo + 工單名稱 */}
          <IonTitle>
            <IonButton className="logo immutable">
              {t('Suiqui Support')}
            </IonButton>
            <span
              className="subtitle"
              hidden={isPlatform('mobile') && !isPlatform('ipad')}
            >
              {ticket?.name}
            </span>
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent
        className="ion-padding bg"
        ref={contentRef}
        scrollEvents={true}
      >
        {/* 向下拉動觸發重新整理 */}
        <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>

        {/* 讀取中提示 */}
        {isPageLoading && (
          <div className="ion-text-center centered">
            <IonSpinner color="primary" name="crescent"></IonSpinner>
          </div>
        )}

        {!isPageLoading && (
          <>
            <IonList lines="inset" className="bg">
              {/* 讀取中提示 */}
              {isProcessing && (
                <IonListHeader>
                  <IonSpinner color="primary" name="dots"></IonSpinner>
                </IonListHeader>
              )}

              {/* 「尚無工單」提示 */}
              {!isProcessing &&
                ticket?.isDone &&
                !ticket?.key &&
                !ticket?.error &&
                showEmpty && (
                  <IonCard>
                    <IonCardHeader color="light">
                      <IonCardTitle className="ion-text-center">
                        <IonLabel color="medium">{t('No Ticket')}</IonLabel>
                      </IonCardTitle>
                    </IonCardHeader>
                  </IonCard>
                )}

              {!isProcessing && (
                <>
                  {isLoaded && (
                    <>
                      {ticket?.key && (
                        <>
                          <IonListHeader className="subject" color="light">
                            <IonLabel>
                              <h2>
                                {isPlatform('desktop') || isPlatform('ipad') ? (
                                  getTicketName(ticket)
                                ) : (
                                  <>
                                    {ticket?.name &&
                                      `${getTicketName(ticket)} ${' ｜ '} ${
                                        ticket?.name
                                      }`}
                                  </>
                                )}
                              </h2>
                            </IonLabel>
                          </IonListHeader>

                          <IonToolbar color="light">
                            {/* Ticket 狀態 */}
                            {inFunctionList(
                                'ticket_read',
                                ticket?.function_list
                              ) && currentTicketStatus && (
                              <IonBadge
                                slot="start"
                                data-test="ticket-status"
                                className={classNames({
                                  'ticket-status': true,
                                  'ticket-status-in-progress':
                                    currentTicketStatus === ETicketStatus.InProgress,
                                  'ticket-status-observing':
                                    currentTicketStatus === ETicketStatus.Observing,
                                  'ticket-status-postponed': isPostponed,
                                  'ticket-status-waiting':
                                    ticket.ticket_status === ETicketStatus.Waiting,
                                  'ticket-status-preparing':
                                    ticket.ticket_status === ETicketStatus.Preparing,
                                })}
                              >
                                {isPostponed
                                  ? t(`Ticket Postponed`)
                                  : t(`Ticket ${ticket.ticket_status}`)}
                              </IonBadge>
                            )}
                            <IonButtons slot="end">
                              <CopyToClipboard
                                text={ticket.id}
                                onCopy={() => {}}
                              >
                                <IonButton
                                  color="medium"
                                  className="ticket id"
                                  hidden
                                  onClick={(e) => {
                                    e.preventDefault()
                                    e.stopPropagation()
                                    showToast('The text is copied to clipboard.')
                                  }}
                                >
                                  {getTicketId(ticket)}
                                </IonButton>
                              </CopyToClipboard>
                            </IonButtons>

                            <IonButtons slot="end">
                              {inFunctionList(
                                'ticket_read',
                                ticket?.function_list
                              ) &&
                                currentTicketUserType && (
                                  <IonButton
                                    color="light"
                                    fill="solid"
                                    className="ticket user-type immutable"
                                    hidden={pageWidth < BREAKPOINT_MD}
                                  >
                                    {t(`Ticket ${currentTicketUserType}`)}
                                  </IonButton>
                                )}

                              {inFunctionList(
                                'ticket_read',
                                ticket?.function_list
                              ) &&
                                currentTicketUserType ===
                                  ETicketUserType.service_ppl && (
                                  <IonButton
                                    color={'success'}
                                    fill="solid"
                                    className="ticket log-in"
                                    onClick={(e) => handleLogInClick()}
                                  >
                                    <IonIcon
                                      icon={logInOutline}
                                      slot="start"
                                    ></IonIcon>
                                    {t(
                                      `Ticket ${currentTicketUserType} Log-In`
                                    )}
                                  </IonButton>
                                )}
                            </IonButtons>
                          </IonToolbar>
                        </>
                      )}
                      {!ticket?.error && (
                        <PreviewForm
                          type={EPreviewFormType.publicTicket}
                          isFillMode={false}
                          item={ticket}
                          onSubmit={() => {}}
                        />
                      )}
                    </>
                  )}
                </>
              )}
            </IonList>

            <div className="ion-text-center" hidden={isProcessing}>
              <IonButton
                color="warning"
                fill="clear"
                size="large"
                slot="icon-only"
                onClick={scrollToTop}
              >
                <IonIcon icon={caretUp} />
              </IonButton>
            </div>
          </>
        )}

        {/* 「不正確或無效的資料存取」提示 toast
            Notice: 因為設定了自訂的 toast 顏色，所以不使用下面的 <IonToast {...toastProps} /> */}
        <IonToast
          isOpen={showTicketFailedToast}
          onDidDismiss={() => setShowTicketFailedToast(false)}
          message={t('incorrect key or no access.')}
          color="danger"
          duration={DELAY_TOAST}
        />

        <IonToast {...toastProps} />
      </IonContent>
      {/* 於小畫面打開留言區時顯示 */}
      {currentTicketUserType && (
        <IonFooter
          hidden={pageWidth >= BREAKPOINT_MD || !actionProps.isShowCommentList}
        >
          <IonToolbar
            color={
              currentTicketUserType
                ? currentTicketUserType === ETicketUserType.service_ppl
                  ? 'danger'
                  : 'secondary'
                : 'light'
            }
          >
            <IonButtons slot="start">
              {inFunctionList(
                ETicketFunction.ticket_read,
                ticket?.function_list
              ) && currentTicketUserType ? (
                <>
                  <IonButton
                    fill="clear"
                    className="ticket user-type immutable"
                  >
                    {t(`Ticket ${currentTicketUserType}`)}
                  </IonButton>
                </>
              ) : (
                <IonButton
                  color="primary"
                  fill="solid"
                  className="ticket user immutable"
                >
                  {authDuck.currentUser?.displayname}
                </IonButton>
              )}
            </IonButtons>

            {/* 關閉留言區的按鈕 */}
            <IonButton
              color={
                currentTicketUserType
                  ? currentTicketUserType === ETicketUserType.service_ppl
                    ? 'danger'
                    : 'secondary'
                  : 'light'
              }
              className="action toggle"
              hidden={!actionProps?.isShowCommentList}
              fill={actionProps?.isShowCommentList ? 'clear' : 'solid'}
              expand={actionProps?.isShowCommentList ? undefined : 'full'}
              slot={actionProps?.isShowCommentList ? 'end' : ''}
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
                actions.toggleCommentList()
              }}
            >
              <IonIcon
                slot="icon-only"
                color="light"
                icon={closeCircleOutline}
              ></IonIcon>
            </IonButton>
          </IonToolbar>
        </IonFooter>
      )}

      {/* 顯示留言區的按鈕，於小畫面關閉留言區時顯示 */}
      {currentTicketUserType && (
        <ToggleCommentFAB
          hidden={pageWidth >= BREAKPOINT_MD || actionProps.isShowCommentList}
          vertical="bottom"
          horizontal="end"
          slot="fixed"
          color={
            currentTicketUserType
              ? currentTicketUserType === ETicketUserType.service_ppl
                ? 'danger'
                : 'secondary'
              : 'light'
          }
          isShowCommentList={false} // Notice: 公開工單開啟留言區時不會顯示此 FAB，所以僅需要顯示 isShowCommentList 為 false 時的 icon
          toggleCommentList={actions.toggleCommentList}
        />
      )}
    </IonPage>
  )
}

export default PublicTicket
