import {
  IonBadge,
  IonButton,
  IonButtons,
  IonIcon,
  IonItem,
  IonItemDivider,
  IonLabel,
  IonList,
  IonListHeader,
  IonModal,
  IonToast,
  isPlatform,
} from '@ionic/react'
import useComponentSize from '@rehooks/component-size'
import FileSaver from 'file-saver'
import { imagesOutline, list, trash } from 'ionicons/icons'
import { useEffect, useRef, useState } from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { inFunctionList } from '../../helpers/base'
import {
  dataURLtoBlob,
  getDownloadUrl,
  getIFileProgressKey,
  getImageUrl,
  isVersionMedia,
  openDocument,
} from '../../helpers/file'
import { useModalWithData } from '../../hooks/useModal'
import useToast from '../../hooks/useToast'
import FileRemoveModal from '../../modals/FileRemoveModal'
import FileViewerModal from '../../modals/FileViewerModal'
import PanoramaViewerModal from '../../modals/PanoramaViewerModal'
import PhotoViewerModal from '../../modals/PhotoViewerModal'
import UploadModal from '../../modals/UploadModal'
import { IFile, ISuiquiFile } from '../../models/suiquiFile'
import { ITemplate } from '../../models/template'
import { ETicketFileLabel, ITicket } from '../../models/ticket'
import { downloadFile } from '../../store/epics/downloadFile'
import { uploadFiles } from '../../store/epics/ticket'
import FileCard from './FileCard'
import MemoModal from './MemoModal'
import PhotoCard from './PhotoCard'
import PhotoDetailsCard from './PhotoDetailsCard'
import './PreviewForm.scss'

interface FilesSegmentProps {
  item: ITicket | ITemplate
}

const labels = [
  ETicketFileLabel.fix_before,
  ETicketFileLabel.fix_in_progress,
  ETicketFileLabel.fix_after,
  ETicketFileLabel.fix_attachment,
]

function FilesSegment({ item }: FilesSegmentProps) {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const ticket = useSelector((state: any) => state.ticket)
  const canUpdate = inFunctionList('ticket_update', item.function_list)
  const photoRef = useRef(null)
  // 是否展開照片縮排，顯示記事
  const [isExpanded, setIsExpanded] = useState<{
    [key in keyof typeof ETicketFileLabel]: boolean
  }>({
    fix_after: true,
    fix_before: true,
    fix_in_progress: true,
    fix_attachment: true,
  })
  const { showToast, toastProps } = useToast()
  const { width: photoWidth } = useComponentSize(photoRef)
  const [
    showFileRemoveModal, // 是否顯示刪除檔案 modal
    fileRemoveModalData, // 要傳入刪除檔案 modal 的資料
    openFileRemoveModal,
    dismissFileRemoveModal,
  ] = useModalWithData<{
    name: string
    files: ISuiquiFile[]
  }>()
  const [
    showPhotoViewerModal, // 是否顯示相片瀏覽 modal
    photoViewerModalData, // 要傳入相片瀏覽 modal 的資料
    openPhotoViewerModal,
    dismissPhotoViewerModal,
  ] = useModalWithData<{
    initialIndex: number // 第一張要顯示的相片的 index
    photos: ISuiquiFile[] // 全部要顯示的相片
  }>()
  const [
    showPanoramaViewerModal, // 是否顯示全景相片瀏覽 modal
    panoramaViewerModalPhoto, // 要傳入全景相片瀏覽 modal 的相片
    openPanoramaViewerModal,
    dismissPanoramaViewerModal,
  ] = useModalWithData<ISuiquiFile>()
  const [
    showFileViewerModal, // 是否顯示檔案瀏覽 modal
    fileViewerModalFile, // 要傳入檔案瀏覽 modal 的檔案
    openFileViewerModal,
    dismissFileViewerModal,
  ] = useModalWithData<ISuiquiFile>()
  const [
    showEditMemoModal, // 是否顯示編輯記事 modal
    editMemoModalFile, // 要傳入編輯記事 modal 的檔案
    openEditMemoModal,
    dismissEditMemoModal,
  ] = useModalWithData<ISuiquiFile>()
  const [
    showUploadModal, // 是否顯示上傳 modal
    uploadModalLabel, // 要傳入上傳 modal 的 label
    openUploadModal,
    dismissUploadModal,
  ] = useModalWithData<ETicketFileLabel>()

  // 回傳該工單指定標籤下的所有照片與檔案
  const getTicketFilesByLabel = (label: ETicketFileLabel) => {
    return item.hasOwnProperty(label)
      ? (item as ITicket)[label]
      : { photos: [], files: [] }
  }

  // 下載檔案
  const download = (file: IFile) => {
    const url = getDownloadUrl(file)
    if (!url) return
    if (url.startsWith('data:')) {
      FileSaver.saveAs(dataURLtoBlob(url), file.name)
    } else {
      const hash = getIFileProgressKey(file)
      dispatch(
        downloadFile.request({
          key: file.key,
          name: file.name || '',
          url,
          hash,
          file,
        })
      )
    }
  }

  // 若檔案為圖片或 pdf，則打開檔案瀏覽 modal
  // 若為其他類型的檔案，則下載該檔案
  const openOrDownloadFile = (file: ISuiquiFile) => {
    if (isVersionMedia(file)) {
      openFileViewerModal(file)
    } else {
      isPlatform('mobile')
        ? openDocument(file, false, t('IOS_INAPP_FILE_OPEN_ERROR_MESSAGE'))
        : download(file)
    }
  }

  // 照片的大小
  const getPhotoSize = () => {
    return +((photoWidth - 50) / 4).toFixed(0)
  }

  // 上傳檔案
  const handleStartUpload = ({
    files,
    filenames,
    label,
    hashFunc,
  }: {
    files: File[]
    filenames: string[]
    label: ETicketFileLabel
    hashFunc: Function
  }) => {
    if (item && files?.length) {
      dispatch(
        uploadFiles.request({
          label,
          key: item.key,
          files,
          filenames,
          hashFunc,
        })
      )
    }
  }

  // 更新記事成功時顯示 toast
  useEffect(() => {
    if (!ticket.fileUpdated) return
    showToast(t('Update memo successfully.'))
  }, [ticket.fileUpdated, showToast, t])

  return (
    <>
      {labels.map((label) => {
        const {
          photos, // 此標籤的相片檔案
          files, // 此標籤的非相片檔案
        } = getTicketFilesByLabel(label)
        const allFiles = [...photos, ...files] // 此標籤的所有檔案，包括相片與非相片
        const isLabelEmpty = allFiles.length === 0
        return (
          <IonList color="light" className={label}>
            {/* 標題列 */}
            <IonListHeader className="files">
              <IonLabel>
                <h3>{t(label)}</h3>
              </IonLabel>

              {/* 上傳按鈕 */}
              {canUpdate && (
                <IonButtons>
                  <IonButton
                    fill="clear"
                    color="primary"
                    onClick={() => openUploadModal(label)}
                    data-test="upload-btn"
                  >
                    <IonIcon slot="start" icon={imagesOutline}></IonIcon>
                    <IonLabel>{t('Upload')}</IonLabel>
                  </IonButton>
                </IonButtons>
              )}
            </IonListHeader>

            {/* 功能按鈕列 */}
            <IonItemDivider className="action">
              <IonButtons slot="end">
                {/* 縮排檔案按鈕 */}
                <IonButton
                  fill="clear"
                  color={isExpanded[label] ? 'medium' : 'primary'}
                  disabled={isLabelEmpty}
                  slot="icon-only"
                  onClick={() => {
                    setIsExpanded({
                      ...isExpanded,
                      [label]: !isExpanded[label],
                    })
                  }}
                >
                  <IonIcon icon={list}></IonIcon>
                </IonButton>

                {/* 刪除檔案按鈕 */}
                <IonButton
                  fill="clear"
                  color="medium"
                  hidden={!canUpdate}
                  disabled={isLabelEmpty}
                  slot="icon-only"
                  onClick={() => {
                    // 顯示刪除檔案 modal
                    openFileRemoveModal({
                      name: t(label),
                      files: allFiles,
                    })
                  }}
                >
                  <IonIcon icon={trash}></IonIcon>
                </IonButton>
              </IonButtons>
              <IonButtons>
                {/* 相片數量 */}
                <IonButton fill="clear" className="immutable count">
                  <IonBadge color="medium">
                    {t('Fix Photos')} {photos.length}
                  </IonBadge>
                </IonButton>

                {/* 非相片檔案數量 */}
                <IonButton fill="clear" className="immutable count">
                  <IonBadge color="medium">
                    {t('Fix Documents')} {files.length}
                  </IonBadge>
                </IonButton>
              </IonButtons>
            </IonItemDivider>

            {/* 檔案列表 */}
            <IonList className="fix-items" ref={photoRef}>
              {/* 「沒有資料」提示 */}
              {isLabelEmpty && (
                <IonItem lines="full">
                  <IonLabel className="ion-text-center">
                    {t('No Items')}
                  </IonLabel>
                </IonItem>
              )}

              {/* 相片列表 */}
              {photos.map((photo, index) => {
                const photoSize = getPhotoSize()
                if (isExpanded[label]) {
                  const image = {
                    url: getImageUrl(photo, photoSize, 'c') ?? '',
                    name: photo.name,
                    memo: photo.memo,
                  }
                  return (
                    <PhotoDetailsCard
                      key={photo.key}
                      image={image}
                      photoSize={photoSize}
                      onEditMemo={() => openEditMemoModal(photo)}
                      onClick={() => {
                        if (photo?.is_panorama) {
                          openPanoramaViewerModal(photo)
                        } else {
                          openPhotoViewerModal({ initialIndex: index, photos })
                        }
                      }}
                    />
                  )
                }
                return (
                  <PhotoCard
                    key={photo.key}
                    image={photo}
                    photoSize={photoSize}
                    onClick={() => {
                      if (photo?.is_panorama) {
                        openPanoramaViewerModal(photo)
                      } else {
                        openPhotoViewerModal({ initialIndex: index, photos })
                      }
                    }}
                  />
                )
              })}

              {/* 非相片檔案列表 */}
              {files.map((file) => {
                return (
                  <FileCard
                    key={file.key}
                    file={file}
                    onClick={() => openOrDownloadFile(file)}
                  />
                )
              })}
            </IonList>
          </IonList>
        )
      })}

      {/* 上傳檔案 modal */}
      <IonModal isOpen={showUploadModal} backdropDismiss={false}>
        {showUploadModal && (
          <UploadModal
            isOpen={showUploadModal}
            isPending={true}
            isSaveMode={false}
            dismiss={dismissUploadModal}
            label={uploadModalLabel}
            state={ticket}
            onStartUpload={handleStartUpload}
            isBundleFiles={true}
          />
        )}
      </IonModal>

      {/* 刪除檔案 modal */}
      <IonModal isOpen={showFileRemoveModal} backdropDismiss={false}>
        {showFileRemoveModal && (
          <FileRemoveModal
            isOpen={showFileRemoveModal}
            items={fileRemoveModalData?.files}
            name={fileRemoveModalData?.name}
            dismiss={dismissFileRemoveModal}
          />
        )}
      </IonModal>

      {/* 相片瀏覽 modal */}
      <IonModal
        isOpen={showPhotoViewerModal}
        showBackdrop={false}
        backdropDismiss={false}
        cssClass="modal-fullscreen"
      >
        {showPhotoViewerModal && (
          <PhotoViewerModal
            isOpen={showPhotoViewerModal}
            items={photoViewerModalData?.photos}
            index={photoViewerModalData?.initialIndex}
            dismiss={dismissPhotoViewerModal}
          />
        )}
      </IonModal>

      {/* 全景相片瀏覽 modal */}
      <IonModal
        isOpen={showPanoramaViewerModal}
        showBackdrop={false}
        backdropDismiss={false}
        cssClass="modal-fullscreen"
      >
        {showPanoramaViewerModal && (
          <PanoramaViewerModal
            isOpen={showPanoramaViewerModal}
            item={panoramaViewerModalPhoto}
            dismiss={dismissPanoramaViewerModal}
          />
        )}
      </IonModal>

      {/* 檔案瀏覽 modal */}
      <IonModal
        isOpen={showFileViewerModal}
        showBackdrop={false}
        backdropDismiss={false}
        cssClass="modal-fullscreen"
      >
        {showFileViewerModal && (
          <FileViewerModal
            isOpen={showFileViewerModal}
            item={fileViewerModalFile}
            dismiss={dismissFileViewerModal}
          />
        )}
      </IonModal>

      {/* 編輯記事 modal */}
      <IonModal isOpen={showEditMemoModal} backdropDismiss={false}>
        {showEditMemoModal && editMemoModalFile && (
          <MemoModal
            isOpen={showEditMemoModal}
            file={editMemoModalFile}
            dismiss={dismissEditMemoModal}
          />
        )}
      </IonModal>

      <IonToast {...toastProps} />
    </>
  )
}

export default FilesSegment
