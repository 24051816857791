import {
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonItemDivider,
  IonLabel,
  IonPage,
  IonSpinner,
  IonTitle,
  IonToast,
  IonToolbar,
} from '@ionic/react'
import { close, download, shareOutline } from 'ionicons/icons'
import { QRCodeSVG } from 'qrcode.react'
import { useCallback, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { exportComponentAsImage } from '../helpers/file'
import { useRemoveIonPageInvisible } from '../hooks/useRemoveIonPageInvisible'
import useToast from '../hooks/useToast'
import { ITeam } from '../models/team'
import './QuickAddAccountModal.scss'

interface QuickAddAccountModalProps {
  dismiss: () => void
  team?: Partial<ITeam> & Pick<ITeam, 'name' | 'key'> // 僅需要 name 和 key，其他 optional
}

// 快速新增帳號 modal 畫面
const QuickAddAccountModal = ({ dismiss, team }: QuickAddAccountModalProps) => {
  const { t } = useTranslation()
  const pageRef = useRemoveIonPageInvisible()

  const qrCodeContainerRef = useRef<HTMLDivElement>(null)

  const { showToast, toastProps } = useToast()

  const teamRegistrationUrl = useMemo(
    () => `${process.env.REACT_APP_TEAM_FE_URL}/signup?team_key=${team?.key}`,
    [team?.key]
  )

  // 複製連結到剪貼簿，並顯示成功通知
  const copyRegistrationUrlToClipboard = useCallback(async () => {
    await navigator.clipboard.writeText(teamRegistrationUrl)
    showToast(t('The link is copied to clipboard.'))
  }, [teamRegistrationUrl, showToast, t])

  return (
    <IonPage className="page-quick-add-account-modal" ref={pageRef}>
      <IonHeader>
        <IonToolbar color="primary">
          <IonTitle>
            <IonButton class="logo immutable">{t('Suiqui Library')}</IonButton>
            <span className="subtitle">{t('Quick Add Account')}</span>
          </IonTitle>

          <IonButtons slot="end">
            {/* 關閉 modal 按鈕 */}
            <IonButton strong fill="clear" slot="icon-only" onClick={dismiss}>
              <IonIcon size="large" icon={close}></IonIcon>
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        {!team ? (
          <div className="ion-text-center centered">
            <IonSpinner color="primary" name="crescent" />
          </div>
        ) : (
          <div className="ion-text-center centered">
            <div ref={qrCodeContainerRef} className="qr-code-container">
              {/* 團隊名稱 */}
              <IonLabel className="qr-code-team-name">
                {team.name}
                {/* 掃描註冊 提示 */}
                <p className="qr-code-action">{t('Scan to register')}</p>
              </IonLabel>

              {/* QR code */}
              <QRCodeSVG value={teamRegistrationUrl} size={256} />
            </div>

            <IonItemDivider className="ion-margin-top" />

            <div className="footer-buttons">
              {/* 複製網址 按鈕 */}
              <IonButton fill="clear" onClick={copyRegistrationUrlToClipboard}>
                <IonIcon slot="start" icon={shareOutline} />
                {t('Copy url')}
              </IonButton>

              {/* 下載圖檔 按鈕 */}
              <IonButton
                fill="clear"
                onClick={() => exportComponentAsImage(qrCodeContainerRef)}
              >
                <IonIcon slot="start" icon={download} />
                {t('Download image')}
              </IonButton>
            </div>
          </div>
        )}
      </IonContent>

      <IonToast {...toastProps} />
    </IonPage>
  )
}

export default QuickAddAccountModal
