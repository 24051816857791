import * as idbKeyval from 'idb-keyval'
import { createStore } from 'idb-keyval'
import { id } from './models/base'
import { CacheResponse } from './models/cache'

const store = createStore('sw-cache', 'post')

export const getCacheData = async (id: IDBValidKey) => {
  let data: CacheResponse | undefined
  try {
    data = await idbKeyval.get(id, store)
    if (!data) return null

    return data
  } catch (err) {
    return null
  }
}

export const setCacheData = async (id: IDBValidKey, data: any) => {
  await idbKeyval.set(id, data, store)
}

export const deleteCacheData = async (id: IDBValidKey) => {
  await idbKeyval.del(id, store)
}

export const findCacheKeysByUrl = async (url: string) => {
  const ret = []
  const keys = await idbKeyval.keys(store)
  for (const key of keys) {
    const data = (await idbKeyval.get(key, store)) as CacheResponse
    if (data?.request?.url.endsWith(url)) {
      ret.push(key)
    }
  }
  return ret
}

export const clearSwCacheStore = async () => {
  await idbKeyval.clear(store)
}

export const getCacheDataByUrl = async (
  url: string,
  key: id,
  reload: boolean
) => {
  let ids: IDBValidKey[]
  try {
    ids = await findCacheKeysByUrl(url)
    if (!ids?.length) return null

    let data: CacheResponse
    try {
      const rets = []
      for (const id of ids) {
        data = (await idbKeyval.get(id, store)) as CacheResponse
        if (data.request.body['key'] === key) {
          rets.push(data)
        } else if (reload && data.request.body['reload']) {
          rets.push(data)
        } else {
          //pass
        }
      }
      if (rets.length) {
        rets.sort((a: any, b: any) => {
          const t1 = a['timestamp']
          const t2 = b['timestamp']
          return t2 - t1
        })
        return rets[0]
      }
    } catch (err) {
      return null
    }

    return null
  } catch (err) {
    return null
  }
}

export const preload = async (
  key: id,
  url: string,
  dispatch: Function,
  apiFns: { success: Function }[],
  reload = true
) => {
  try {
    const data = await getCacheDataByUrl(url, key, reload)
    if (data && apiFns?.length) {
      dispatch(
        apiFns[0].success({
          ...data.response.body,
          reload: true,
        })
      )
    }
    return Promise.resolve(data)
  } catch (err) {
    return Promise.reject(err)
  }
}
