/* eslint-disable react-hooks/exhaustive-deps */
import { IonAlert, IonRouterOutlet } from '@ionic/react'
import moment from 'moment'
import 'moment/locale/zh-tw'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, Route, useHistory, useLocation } from 'react-router'
import { useInterval } from 'react-use'
import useLocalForge from './hooks/use-local-forge'
import i18n from './i18n'
import {
  BASE_SERVICE_CHECK_DELAY,
  BASE_SERVICE_INTERVAL,
} from './models/baseService'
import { ETicketUserType } from './models/ticket'
import CreateTicket from './pages/create_ticket/CreateTicket'
import Home from './pages/home/HomePage'
import ListTemplate from './pages/ListTemplate'
import Login from './pages/login/Login'
import Logout from './pages/Logout'
import PublicTicketWithSplitPane from './pages/public_ticket/PublicTicketWithSplitPane'
import Reload from './pages/Reload'
import Settings from './pages/Settings'
import Template from './pages/template/TemplatePage'
import TicketWithSplitPane from './pages/ticket/TicketWithSplitPane'
import TicketList from './pages/ticket_list/TicketList'
import { Creators as AuthActions } from './store/ducks/auth'
import { forceAuthClearErrors, forceAuthLogout } from './store/epics/auth'
import { fetchBaseService, fetchUser } from './store/epics/user'
import ReactGA from 'react-ga4'

type AppRoutesProps = {}
const AppRoutes = (props: AppRoutesProps) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const [token, , isTokenLoading, errToken] = useLocalForge('apiToken')
  const [currentUser, setCurrentUser, isCurrentUserLoading, errCurrentUser] =
    useLocalForge('suiquiUserData')
  const auth = useSelector((state: any) => state.auth)
  const user = useSelector((state: any) => state.user)
  const authDuck = useSelector((state: any) => state.authDuck)
  const [isAuthed, setIsAuthed] = useState(true)
  const [, setIsAdminAuthed] = useState(true)
  const [lang] = useLocalForge('userLang', 'zh')
  const [showErrorAlert, setShowErrorAlert] = useState(false)
  const [errorStatus, setErrorStatus] = useState()
  const [lastAction, setLastAction] = useState(0)

  useMemo(() => {
    if (location.pathname === '/') return
    if (location.pathname === '/login') return
    if (location.pathname === '/logout') return
    if (authDuck.location) return
    dispatch(
      AuthActions.setLocation({
        state: {
          from: location,
        },
      })
    )
  }, [dispatch, location, authDuck.location])

  const checkWithInterval = useCallback(
    (forceTimeout?: boolean) => {
      if (!authDuck.isAuthenticated) return
      const now = Date.now()
      const timeleft = lastAction + BASE_SERVICE_CHECK_DELAY
      const diff = timeleft - now
      const isTimeout = diff < 0

      if (
        document.visibilityState === 'visible' &&
        (isTimeout || forceTimeout)
      ) {
        console.info('isTimeout')
        setLastAction(() => Date.now())
        dispatch(fetchBaseService.request())
      }
    },
    [dispatch, authDuck.isAuthenticated, setLastAction, lastAction]
  )

  useInterval(checkWithInterval, BASE_SERVICE_INTERVAL)

  useMemo(() => {
    const { actions, created_datetime } = user
    if (moment(created_datetime).diff(moment()) < 0) {
      if (actions?.length) {
        if (actions.indexOf('logout') > -1) {
          dispatch(forceAuthLogout(undefined))
        }
      }
    }
  }, [dispatch, user])

  useMemo(() => {
    if (auth.isForceLogout) {
      setIsAuthed(false)
      setIsAdminAuthed(false)
      history.replace('/logout', {
        from: {
          pathname: window.location.pathname,
        },
        direction: 'root',
      })
    } else {
      setIsAuthed(true)
    }
  }, [setIsAuthed, setIsAdminAuthed, auth.isForceLogout, history])

  useMemo(() => {
    if (auth.isForceAuthErrorAlert) {
      setShowErrorAlert(true)
      setErrorStatus(auth.status)
      dispatch(forceAuthClearErrors({}))
    }
  }, [dispatch, auth.status, setShowErrorAlert, auth.isForceAuthErrorAlert])

  useMemo(() => {
    if (isTokenLoading) return
    if (isCurrentUserLoading) return
    if (authDuck.isAuthenticated) return
    if (auth.token) return
    if (window.location.pathname === '/login') return
    if (window.location.pathname === '/logout') return
    if (window.location.pathname === '/home?reload') return

    // Restore token
    if (token) {
      // 若使用者資訊不存在 user store 中，重新獲取使用者資訊
      if (!user.currentUser) {
        dispatch(fetchUser.request({ token }))
      }
      dispatch(AuthActions.login(token))
      if (currentUser && !authDuck.isUserLoaded) {
        dispatch(AuthActions.checkIn(currentUser))
        if (!window.location.pathname.startsWith('/public')) {
          dispatch(AuthActions.openMenu(true))
        }
      }
    } else {
      if (window.location.pathname.startsWith('/public')) {
        return
      }
      history.push('/logout', { direction: 'root' })
    }
  }, [
    dispatch,
    history,
    token,
    isTokenLoading,
    auth.token,
    currentUser,
    isCurrentUserLoading,
    authDuck.isUserLoaded,
    authDuck.isAuthenticated,
    user.currentUser,
  ])

  // 將 user store 的使用者資訊複製到 auth store 中
  useEffect(() => {
    if (authDuck.isUserLoaded) return
    if (!user.currentUser) return
    setCurrentUser(user.currentUser)
    dispatch(AuthActions.checkIn(user.currentUser))
  }, [dispatch, setCurrentUser, user.currentUser])

  // 換頁時，發送 GA page_view 事件
  // 與 SUIQUI Library 產品一致，會在 page_title 中加上當前團隊名稱 e.g. SUIQUI Test | 專案總覽
  // Notice: 不包含首次載入，因為首次載入時 GA 會自動發送 page_view 事件，且無法取消
  useEffect(() => {
    // 當產品在 production 環境時，才啟用 GA
    if (process.env.NODE_ENV !== 'production') return
    if (!currentUser?.team) return

    const unregisterCb = history.listen((location: any) => {
      setTimeout(() => {
        ReactGA.event('page_view', {
          page_title: `${currentUser.team?.name} | ${document.title}`,
          page_location: location.href,
        })
      }, 3000)
    })

    return unregisterCb
  }, [currentUser?.team, history])

  useMemo(() => {
    // Translation
    const currentLang = lang === 'zh' ? 'zh-TW' : lang
    if (currentLang === i18n.language) return

    i18n.changeLanguage(currentLang || '')
    moment.locale(currentLang || '')
  }, [lang])

  return (
    <>
      <IonRouterOutlet animated={false} {...props}>
        <Route exact path="/login" component={Login} />
        <Route exact path="/logout" component={Logout} />
        <Route exact path="/reload" component={Reload} />

        <Route
          exact
          path={['/home']}
          render={(routeProps) => {
            return isAuthed ? (
              <Home {...routeProps} />
            ) : (
              <Redirect
                to={{
                  pathname: '/logout',
                  state: { from: routeProps.location },
                }}
              />
            )
          }}
        />

        <Route
          exact
          path={['/project']}
          render={(routeProps) => {
            return isAuthed ? (
              <ListTemplate {...routeProps} />
            ) : (
              <Redirect
                to={{
                  pathname: '/logout',
                  state: { from: routeProps.location },
                }}
              />
            )
          }}
        />

        <Route
          exact
          path={['/tracking', '/tracking/:key']}
          render={(routeProps) => {
            return isAuthed ? (
              <TicketList {...routeProps} />
            ) : (
              <Redirect
                to={{
                  pathname: '/logout',
                  state: { from: routeProps.location },
                }}
              />
            )
          }}
        />

        <Route
          exact
          path={['/template/:key']}
          render={(routeProps) => {
            return isAuthed ? (
              <Template {...routeProps} />
            ) : (
              <Redirect
                to={{
                  pathname: '/logout',
                  state: { from: routeProps.location },
                }}
              />
            )
          }}
        />

        <Route
          exact
          path={['/ticket/:key']}
          render={(routeProps) => {
            return isAuthed ? (
              <TicketWithSplitPane {...routeProps} />
            ) : (
              <Redirect
                to={{
                  pathname: '/logout',
                  state: { from: routeProps.location },
                }}
              />
            )
          }}
        />

        <Route
          exact
          path={['/ticket/new']}
          render={(routeProps) => {
            return isAuthed ? (
              <CreateTicket isPublicMode={false} />
            ) : (
              <Redirect
                to={{
                  pathname: '/logout',
                  state: { from: routeProps.location },
                }}
              />
            )
          }}
        />

        <Redirect exact from="/ticket" to="/home" />

        <Route
          exact
          path={['/public/ticket/new']}
          render={() => {
            return <CreateTicket isPublicMode={true} />
          }}
        />

        <Route
          exact
          path={['/public/ticket/u', '/public/ticket/u/:key', '/p/t/u/:key']}
          render={(routeProps) => {
            return (
              <PublicTicketWithSplitPane
                userType={ETicketUserType.user}
                {...routeProps}
              />
            )
          }}
        />

        <Route
          exact
          path={['/public/ticket/s', '/public/ticket/s/:key', '/p/t/s/:key']}
          render={(routeProps) => {
            return (
              <PublicTicketWithSplitPane
                userType={ETicketUserType.service_ppl}
                {...routeProps}
              />
            )
          }}
        />

        <Route
          exact
          path={['/settings']}
          render={(routeProps) => {
            return isAuthed ? (
              <Settings {...routeProps} />
            ) : (
              <Redirect
                to={{
                  pathname: '/logout',
                  state: { from: routeProps.location },
                }}
              />
            )
          }}
        />

        <Redirect exact from="/" to="/home" />
      </IonRouterOutlet>
      <IonAlert
        keyboardClose={true}
        isOpen={showErrorAlert}
        onDidDismiss={() => setShowErrorAlert(false)}
        header={t('Oops...')}
        message={t(
          'Something has gone wrong. Please contact a system administrator.（{{status}}',
          {
            status: errorStatus,
          }
        )}
        buttons={[
          {
            text: t('OK'),
            cssClass: 'ok',
            handler: (params) => {},
          },
        ]}
      />
    </>
  )
}

export default AppRoutes
