import { yupResolver } from '@hookform/resolvers/yup'
import {
  IonAlert,
  IonButton,
  IonButtons,
  IonContent,
  IonDatetime,
  IonFooter,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonItemGroup,
  IonLabel,
  IonList,
  IonListHeader,
  IonPage,
  IonPopover,
  IonTitle,
  IonToolbar,
  isPlatform,
} from '@ionic/react'
import classNames from 'classnames'
import {
  addCircle,
  calendarClear,
  checkmarkDone,
  close,
  closeCircle,
  ellipsisHorizontal,
  notifications,
  trash,
} from 'ionicons/icons'
import moment from 'moment'
import { useRef, useState } from 'react'
import {
  Controller,
  SubmitHandler,
  useFieldArray,
  useForm,
} from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import * as yup from 'yup'
import LoadingSpinner from '../../components/LoadingSpinner'
import { useRemoveIonPageInvisible } from '../../hooks/useRemoveIonPageInvisible'
import { id } from '../../models/base'
import { EPreviewFormType } from '../../models/form'
import { INotificationBellBase } from '../../models/notificationBell'
import { ITemplateNotificationBell } from '../../models/template'
import { ITicketNotificationBell } from '../../models/ticket'
import { updateTicketReminder } from '../../store/epics/ticket'
import { updateTemplateReminder } from '../../store/templateSlice'
import './ReminderEditModal.scss'
import NoReminder from './NoReminder'

// 表單驗證規則
const validationSchema = yup
  .object()
  .shape({
    notification_bells: yup
      .array()
      .of(
        yup.object().shape(
          {
            name: yup.string().required(),
            // 有 notify_date 就不需要 day_offset
            notify_date: yup.string().when('day_offset', {
              is: undefined,
              then: yup.string().required(),
            }),
            // 有 day_offset 就不需要 notify_date
            day_offset: yup.number().when('notify_date', {
              is: undefined,
              then: yup.number().required(),
            }),
          },
          [['notify_date', 'day_offset']]
        )
      )
      .required(),
  })
  .required()

interface FormValues {
  notification_bells: {
    name: string
    notify_date?: string
    day_offset?: number
  }[]
}

interface ReminderEditModalProps {
  dismiss: () => void
  itemKey: id
  notificationBells: INotificationBellBase[]
  type: EPreviewFormType
}

// 設定提醒節點的 modal 元件
const ReminderEditModal = ({
  dismiss,
  itemKey,
  notificationBells,
  type,
}: ReminderEditModalProps) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const pageRef = useRemoveIonPageInvisible()
  const scrollRef = useRef<HTMLIonContentElement | null>(null)
  const newAppendNameInputRef = useRef<HTMLIonInputElement | null>(null)

  const {
    control,
    handleSubmit,
    formState: { isValid, isDirty },
  } = useForm<FormValues>({
    defaultValues: { notification_bells: notificationBells },
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
  })

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'notification_bells',
  })

  const [isRemoveMode, setIsRemoveMode] = useState(false)
  const [isShowDismissConfirm, setIsShowDismissConfirm] = useState(false)
  const [isShowMoreActionPopover, setIsShowMoreActionPopover] = useState(false)

  // 設定 popover 的出現位置
  const [clickEvent, setClickEvent] = useState<Event | null>(null)
  // 設定要移除的節點的 index
  const [removeConfirmIdx, setRemoveConfirmIdx] = useState<number | null>(null)

  const onSubmit: SubmitHandler<FormValues> = (data) => {
    // 根據 type 來 dispatch 不同的 action 更新提醒節點
    if (type === EPreviewFormType.template) {
      dispatch(
        updateTemplateReminder({
          key: itemKey,
          notification_bells:
            data.notification_bells as ITemplateNotificationBell[],
        })
      )
    } else {
      dispatch(
        updateTicketReminder.request({
          key: itemKey,
          notification_bells:
            data.notification_bells as ITicketNotificationBell[],
        })
      )
    }

    dismiss() // 關閉 modal
  }

  // 關閉 modal，如果有修改過就顯示確認視窗
  const handleCloseModalBtnClick = () => {
    if (isDirty) {
      setIsShowDismissConfirm(true)
    } else {
      dismiss()
    }
  }

  // 新增提醒節點
  const handleAddReminderBtnClick = () => {
    if (type === EPreviewFormType.template) {
      append({
        name: '',
        day_offset: 0, // 預設為 0
      })
    } else {
      append({
        name: '',
        notify_date: moment().format('YYYY-MM-DD'), // 預設為今天
      })
    }

    // scroll to the bottom of the content
    setTimeout(() => {
      scrollToBottom()
      newAppendNameInputRef.current?.setFocus()
    }, 300)
  }

  const scrollToBottom = () => {
    scrollRef.current?.scrollToBottom(300)
  }

  // 顯示更多動作的 popover
  const handleShowMoreActionBtnClick = (e: Event) => {
    setClickEvent(e)
    setIsShowMoreActionPopover(true)
  }

  // 關閉更多動作的 popover
  const handleShowMoreActionPopoverDismiss = () => {
    setIsShowMoreActionPopover(false)
    setClickEvent(null)
  }

  // 開啓移除節點模式
  const handleRemoveModeBtnClick = () => {
    setIsRemoveMode(true)
    setIsShowMoreActionPopover(false)
  }

  // 移除提醒節點
  // Notice: 只會移除畫面上的節點，不會真的刪除資料，需要送出表單才會刪除資料
  const handleRemoveNotificationBellBtnClick = (idx: number) => {
    setRemoveConfirmIdx(idx)
  }

  return (
    <IonPage ref={pageRef} className="page-reminder-edit-modal">
      <IonHeader>
        <IonToolbar color="primary">
          {/* Suiqui logo & 標題 */}
          <IonTitle>
            <IonButton className="logo immutable">
              {t('Suiqui Support')}
            </IonButton>
            <span className="subtitle" hidden={isPlatform('mobile')}>
              {t('Reminder Setting')}
            </span>
          </IonTitle>

          <IonButtons slot="end">
            {/* close button */}
            <IonButton
              strong
              fill="clear"
              slot="icon-only"
              onClick={handleCloseModalBtnClick}
            >
              <IonIcon size="large" icon={close} />
            </IonButton>

            {/* save (done) button */}
            <IonButton
              strong
              fill="clear"
              slot="icon-only"
              disabled={!isDirty || !isValid}
              onClick={() => handleSubmit(onSubmit)()}
            >
              <IonIcon size="large" icon={checkmarkDone} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent ref={scrollRef} className="ion-padding-top">
        <IonListHeader>
          <IonLabel>{/* Placeholder */}</IonLabel>
          {/* 打開更多操作 (...) 的按鈕 */}
          <IonButton
            color="medium"
            disabled={isRemoveMode}
            onClick={(e) => handleShowMoreActionBtnClick(e as unknown as Event)}
          >
            <IonIcon slot="icon-only" icon={ellipsisHorizontal} />
          </IonButton>
        </IonListHeader>
        {fields.length ? (
          <IonList className="ion-margin-horizontal">
            <form onSubmit={handleSubmit(onSubmit)}>
              {fields.map((field, idx) => (
                // 每個 IonItemGroup 代表一個提醒節點
                <IonItemGroup
                  key={field.id}
                  className={classNames(
                    'notification-bell',
                    'ion-margin-bottom',
                    'ion-padding-bottom',
                    {
                      'ion-margin-horizontal': !isPlatform('mobile'),
                      'ion-padding-horizontal': !isPlatform('mobile'),
                      'ion-margin-end': isPlatform('mobile'),
                    }
                  )}
                >
                  {/* 名稱欄位
                    Notice: 這裏需要額外設定 onIonChange 與 onIonBlur，
                    否則 react-hook-form 無法偵測修改 */}
                  <IonItem className="ion-margin-end ion-padding-horizontal">
                    <IonLabel position="fixed">{t('Name')}</IonLabel>
                    <IonIcon
                      color="primary"
                      slot="start"
                      size="small"
                      icon={notifications}
                    />
                    <Controller
                      render={({ field }) => (
                        <IonInput
                          {...field}
                          placeholder={t('Input Name')}
                          ref={(ref) => {
                            // 設定最後一個新增的節點的 ref
                            if (idx === fields.length - 1) {
                              newAppendNameInputRef.current = ref
                            }
                            return field.ref
                          }}
                          onIonChange={field.onChange}
                          onIonBlur={field.onBlur}
                        />
                      )}
                      name={`notification_bells.${idx}.name` as const}
                      control={control}
                    />
                  </IonItem>

                  {/* 天數欄位，只有 Template 才會顯示 */}
                  {type === EPreviewFormType.template && (
                    <IonItem className="ion-margin-end ion-padding-horizontal">
                      <IonLabel position="fixed">{t('Offset')}</IonLabel>
                      <IonIcon
                        color="primary"
                        slot="start"
                        size="small"
                        icon={calendarClear}
                      />
                      <Controller
                        render={({ field }) => (
                          <IonInput
                            {...field}
                            type="number"
                            inputMode="numeric"
                            placeholder={t('Input Offset')}
                            onIonChange={field.onChange}
                            onIonBlur={field.onBlur}
                          />
                        )}
                        name={`notification_bells.${idx}.day_offset` as const}
                        control={control}
                      />
                    </IonItem>
                  )}

                  {/* 日期欄位，只有 Ticket 才會顯示 */}
                  {type === EPreviewFormType.ticket && (
                    <IonItem className="ion-margin-end ion-padding-horizontal">
                      <IonLabel position="fixed">{t('Date')}</IonLabel>
                      <IonIcon
                        color="primary"
                        slot="start"
                        size="small"
                        icon={calendarClear}
                      />
                      <Controller
                        render={({ field }) => (
                          <IonDatetime
                            {...field}
                            displayFormat="YYYY-MM-DD"
                            cancelText={t('Cancel')}
                            doneText={t('Done')}
                            placeholder={t('Input Date')}
                            onIonChange={(e) =>
                              field.onChange(
                                moment(e.detail.value).format('YYYY-MM-DD')
                              )
                            }
                            onIonBlur={field.onBlur}
                          />
                        )}
                        name={`notification_bells.${idx}.notify_date` as const}
                        control={control}
                      />
                    </IonItem>
                  )}

                  {/* 刪除按鈕，當 isRemoveMode 為 true 時才會顯示 */}
                  {isRemoveMode && (
                    <IonButton
                      fill="clear"
                      className="remove-bell-btn"
                      onClick={() => handleRemoveNotificationBellBtnClick(idx)}
                    >
                      <IonIcon
                        color="danger"
                        slot="icon-only"
                        icon={closeCircle}
                      />
                    </IonButton>
                  )}
                </IonItemGroup>
              ))}
            </form>
          </IonList>
        ) : (
          <NoReminder />
        )}
      </IonContent>

      <IonFooter>
        {/* 新增節點 按鈕 */}
        <IonButton className="add-reminder" onClick={handleAddReminderBtnClick}>
          <IonIcon slot="start" icon={addCircle} />
          {t('Add Reminder')}
        </IonButton>
      </IonFooter>

      {/* 更多動作 popover */}
      <IonPopover
        event={clickEvent!}
        isOpen={isShowMoreActionPopover}
        onDidDismiss={handleShowMoreActionPopoverDismiss}
      >
        <IonItem button lines="none" onClick={handleRemoveModeBtnClick}>
          <IonLabel>{t('Delete')}</IonLabel>
          <IonIcon color="medium" slot="start" size="small" icon={trash} />
        </IonItem>
      </IonPopover>

      {/* 確認是否放棄修改的 alert */}
      <IonAlert
        isOpen={isShowDismissConfirm}
        onDidDismiss={() => setIsShowDismissConfirm(false)}
        header={t('Notice')}
        message={t('Data not saved, are you sure to quit?')}
        buttons={[
          {
            text: t('Cancel'),
            role: 'cancel',
            cssClass: 'secondary',
          },
          {
            text: t('OK'),
            handler: dismiss,
          },
        ]}
      />

      {/* 確認是否刪除節點的 alert
          在設定 removeConfirmIdx 後才顯示，關閉後取消設定 removeConfirmIdx */}
      <IonAlert
        isOpen={removeConfirmIdx !== null}
        onDidDismiss={() => setRemoveConfirmIdx(null)}
        header={t('Notice')}
        message={t('Confirm to delete this reminder?')}
        buttons={[
          {
            text: t('Cancel'),
            role: 'cancel',
            cssClass: 'secondary',
          },
          {
            text: t('OK'),
            handler: () => remove(removeConfirmIdx!),
          },
        ]}
      />
    </IonPage>
  )
}

export default ReminderEditModal
