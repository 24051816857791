import { useCallback, useMemo, useState } from 'react'

/**
 * 用法：
 *
 * ```typescript
 * const { showToast, toastProps } = useToast()
 * ...
 * <IonToast {...toastProps} />
 * ```
 * 在要顯示 toast 的時候直接呼叫 showToast 函式即可
 * ```typescript
 * showToast('message')
 * ```
 */
export default function useToast() {
  // 預設值（關閉）
  const dismissedToastProps = useMemo(
    () => ({
      isOpen: false,
      onDidDismiss: () => {},
      message: '',
      duration: 3000,
    }),
    []
  )

  // toastProps 為傳入 IonToast 的所有參數之集合
  // 在要使用 toast 的元件： <IonToast {...toastProps} />
  const [toastProps, setToastProps] = useState(dismissedToastProps)

  // 此函式會將 toastProps 設回關閉狀態
  const dismissToast = useCallback(() => {
    setToastProps((toastProps) => ({
      ...toastProps,
      isOpen: false,
      onDidDismiss: () => setToastProps(dismissedToastProps),
    }))
  }, [dismissedToastProps])

  // 此函式會設置 toastProps 設為開啟狀態，打開 toast
  // 可以額外傳入客製的 onDidDismiss，在關閉 toast 時除了呼叫 dismissToast 外也會一併呼叫
  const showToast = useCallback(
    (message: string, customDismissToast?: () => void) => {
      setToastProps((toastProps) => ({
        ...toastProps,
        isOpen: true,
        message,
        onDidDismiss: () => {
          customDismissToast?.()
          dismissToast()
        },
      }))
    },
    [dismissToast]
  )

  return { showToast, toastProps }
}
