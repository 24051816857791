import { PUBLIC_JSON } from './../helpers/base';
import { ajax } from 'rxjs/ajax'
import { API, JWT } from '../helpers/base'
import { id } from '../models/base'

export const getSignature = ({
  token,
  params,
}: {
  token: string
  params: {
    key: id // signature key
  }
}) =>
  ajax({
    url: API('signature', 'SUPPORT'),
    method: 'POST',
    body: {
      ...params,
    },
    headers: JWT(token),
    responseType: 'json',
  })

// 使用者在公開清單中未登入時，可以使用此 API 取得完整的簽核資訊
export const getPublicSignature = ({
  params
}: {
  params: {
    key: id
  }
}) => ajax({
  url: API('public/signature', 'SUPPORT'),
  method: 'POST',
  body: {
    ...params,
  },
  headers: PUBLIC_JSON(),
  responseType: 'json',
})

export const createSignature = ({
  token,
  params,
}: {
  token: string
  params: {
    key: id // template key
    name: string
    can_print: boolean
  }
}) =>
  ajax({
    url: API('signature/create', 'SUPPORT'),
    method: 'POST',
    body: {
      ...params,
    },
    headers: JWT(token),
    responseType: 'json',
  })

export const updateSignature = ({
  token,
  params,
}: {
  token: string
  params: {
    key: id // signature key
    name: string
    can_print: boolean
  }
}) =>
  ajax({
    url: API('signature/update', 'SUPPORT'),
    method: 'POST',
    body: {
      ...params,
    },
    headers: JWT(token),
    responseType: 'json',
  })

export const deleteSignature = ({
  token,
  params,
}: {
  token: string
  params: {
    key: id // signature key
  }
}) =>
  ajax({
    url: API('signature/delete', 'SUPPORT'),
    method: 'POST',
    body: {
      ...params,
    },
    headers: JWT(token),
    responseType: 'json',
  })

export const reorderSignatures = ({
  token,
  params,
}: {
  token: string
  params: {
    key: id // template key
    signature_keys: id[]
  }
}) =>
  ajax({
    url: API('signature/reorder', 'SUPPORT'),
    method: 'POST',
    body: {
      ...params,
    },
    headers: JWT(token),
    responseType: 'json',
  })

// 在簽核欄位上簽名
export const signSignature = ({
  token,
  params,
}: {
  token: string
  params: {
    key: id // signature key
    value: string // 序列化後的簽名
  }
}) =>
  ajax({
    url: API('signature/sign', 'SUPPORT'),
    method: 'POST',
    body: {
      ...params,
    },
    headers: JWT(token),
    responseType: 'json',
  })

// 使用者在公開清單中未登入時，可以使用此 API 於簽核欄位上簽名
export const signPublicSignature = ({
  params,
}: {
  params: {
    key: id // signature key
    value: string // 序列化後的簽名
  }
}) =>
  ajax({
    url: API('public/signature/sign', 'SUPPORT'),
    method: 'POST',
    body: {
      ...params,
    },
    headers: PUBLIC_JSON(),
    responseType: 'json',
  })

// 取消簽名
export const unsignSignature = ({
  token,
  params,
}: {
  token: string
  params: {
    key: id // signature key
  }
}) =>
  ajax({
    url: API('signature/unsign', 'SUPPORT'),
    method: 'POST',
    body: {
      ...params,
    },
    headers: JWT(token),
    responseType: 'json',
  })

// 使用者在公開清單中未登入時，可以使用此 API 取消簽名
export const unsignPublicSignature = ({
  params,
}: {
  params: {
    key: id // signature key
  }
}) =>
  ajax({
    url: API('public/signature/unsign', 'SUPPORT'),
    method: 'POST',
    body: {
      ...params,
    },
    headers: PUBLIC_JSON(),
    responseType: 'json',
  })
