import { ajax } from 'rxjs/ajax'
import { API, JWT } from '../helpers/base'
import { id } from '../models/base'

export const move = ({
  token,
  params,
}: {
  token: string
  params: { key: id; dest_key: id }
}) =>
  ajax({
    url: API('common/move', 'LIBRARY'),
    method: 'POST',
    body: {
      ...params,
    },
    headers: JWT(token),
    responseType: 'json',
  })
