import localforage from 'localforage'
import React, { useCallback } from 'react'

localforage.config({
  driver: localforage.INDEXEDDB,
  name: 'suiqui',
  storeName: 'suiqui-support',
})

export default function useLocalForge(key: string, defaultValue?: any) {
  const [isLoading, setIsLoading] = React.useState(true)
  const [value, setValue] = React.useState(defaultValue)
  const [err, setErr] = React.useState(null)

  React.useEffect(() => {
    ;(async function () {
      try {
        const value = await localforage.getItem(key)
        setValue(value as any)

        setErr(null)
        setIsLoading(false)
      } catch (err) {
        if (err) {
          setErr(err)
        }
        setIsLoading(false)
        return defaultValue
      }
    })()
  }, [defaultValue, key])

  const set = useCallback(
    (value: any) => {
      ;(async function () {
        try {
          await localforage.setItem(key, value)
          setValue(value)
        } catch (err) {
          return defaultValue
        }
      })()
    },
    [key, defaultValue]
  )

  const remove = useCallback(() => {
    ;(async function () {
      try {
        await localforage.removeItem(key)
        setValue(null)
      } catch (e) {}
    })()
  }, [key])

  return [value, set, isLoading, err, remove] as const
}
