import { EActiveStatus, ITemplateNotificationBell } from './../models/template'
import { ajax } from 'rxjs/ajax'
import { API, JWT } from '../helpers/base'
import { id, key } from '../models/base'
import { ITemplate } from '../models/template'

export const list = ({
  token,
  params,
}: {
  token: string
  params: {
    cursor?: key
    active_status?: EActiveStatus[] // 'active' or 'archive'
    include_stats?: boolean // 後端回傳的 template 包含統計數據
  }
}) => {
  return ajax({
    url: API('template/list', 'SUPPORT'),
    method: 'POST',
    body: {
      ...params,
    },
    headers: JWT(token),
    responseType: 'json',
  })
}

export const get = ({
  token,
  params,
}: {
  token: string
  params: { key: id }
}) =>
  ajax({
    url: API('template', 'SUPPORT'),
    method: 'POST',
    body: {
      ...params,
    },
    headers: JWT(token),
    responseType: 'json',
  })

export const create = ({
  token,
  params,
}: {
  token: string
  params: Partial<ITemplate>
}) =>
  ajax({
    url: API('template/create', 'SUPPORT'),
    method: 'POST',
    body: {
      ...params,
    },
    headers: JWT(token),
    responseType: 'json',
  })

export const update = ({
  token,
  params,
}: {
  token: string
  params: { key: id } & Partial<ITemplate>
}) =>
  ajax({
    url: API('template/update', 'SUPPORT'),
    method: 'POST',
    body: {
      ...params,
    },
    headers: JWT(token),
    responseType: 'json',
  })

export const print = ({
  token,
  params,
}: {
  token: string
  params: {
    key: id
    start_date: string
    end_date: string
    include_fields: {
      system: string[]
      custom: string[]
      internal_custom: string[]
      photos: boolean
    }
  }
}) =>
  ajax({
    url: API('template/print', 'SUPPORT'),
    method: 'POST',
    body: {
      ...params,
    },
    headers: JWT(token),
    responseType: 'html',
  })

export const archive = ({
  token,
  params,
}: {
  token: string
  params: { key: id }
}) =>
  ajax({
    url: API('template/archive', 'SUPPORT'),
    method: 'POST',
    body: {
      ...params,
    },
    headers: JWT(token),
    responseType: 'json',
  })

export const unarchive = ({
  token,
  params,
}: {
  token: string
  params: { key: id }
}) =>
  ajax({
    url: API('template/unarchive', 'SUPPORT'),
    method: 'POST',
    body: {
      ...params,
    },
    headers: JWT(token),
    responseType: 'json',
  })

export const getDashboard = ({
  token,
  params,
}: {
  token: string
  params: { key: id }
}) =>
  ajax({
    url: API('template/dashboard', 'SUPPORT'),
    method: 'POST',
    body: {
      ...params,
    },
    headers: JWT(token),
    responseType: 'json',
  })

// 讀取專案總覽統計元件的資料（所有專案統計資料的加總）
export const getDashboardAll = ({
  token,
  params,
}: {
  token: string
  params: { active_statuses?: EActiveStatus[] }
}) =>
  ajax({
    url: API('template/dashboard/all', 'SUPPORT'),
    method: 'POST',
    body: {
      ...params,
    },
    headers: JWT(token),
    responseType: 'json',
  })

// 複製專案設定到新專案
export const copyTemplate = ({
  token,
  params,
}: {
  token: string
  params: { key: id }
}) =>
  ajax({
    url: API('template/copy', 'SUPPORT'),
    method: 'POST',
    body: {
      ...params,
    },
    headers: JWT(token),
    responseType: 'json',
  })

// 重新生成 line 驗證碼
export const resetLineVerificationCode = ({
  token,
  params,
}: {
  token: string
  params: { key: id }
}) =>
  ajax({
    url: API('template/reset_lineapp_code', 'SUPPORT'),
    method: 'POST',
    body: {
      ...params,
    },
    headers: JWT(token),
    responseType: 'json',
  })

interface TemplateServiceApiRequest {
  token: string
  params: { key: id }
}

export const activateServiceApi = ({
  token,
  params,
}: TemplateServiceApiRequest) =>
  ajax({
    url: API('admin/api/activate', 'SUPPORT'),
    method: 'POST',
    body: {
      ...params,
    },
    headers: JWT(token),
    responseType: 'json',
  })

export const deactivateServiceApi = ({
  token,
  params,
}: TemplateServiceApiRequest) =>
  ajax({
    url: API('admin/api/deactivate', 'SUPPORT'),
    method: 'POST',
    body: {
      ...params,
    },
    headers: JWT(token),
    responseType: 'json',
  })

export const renewServiceApi = ({ token, params }: TemplateServiceApiRequest) =>
  ajax({
    url: API('admin/api/renew', 'SUPPORT'),
    method: 'POST',
    body: {
      ...params,
    },
    headers: JWT(token),
    responseType: 'json',
  })

export const updateTemplateReminder = ({
  token,
  params,
}: {
  token: string
  params: {
    key: id
    notification_bells: ITemplateNotificationBell[]
  }
}) => ajax({
  url: API('template/update/bells', 'SUPPORT'),
  method: 'POST',
  body: {
    ...params,
  },
  headers: JWT(token),
  responseType: 'json',
})
