import { RefresherEventDetail } from '@ionic/core'
import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonMenuButton,
  IonModal,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonSegment,
  IonSegmentButton,
  IonSpinner,
  IonTitle,
  IonToast,
  IonToolbar,
  useIonViewDidEnter,
} from '@ionic/react'
import { add, caretUp } from 'ionicons/icons'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { RouteComponentProps, useHistory } from 'react-router'
import TemplatePermissionChip from '../components/TemplatePermissionChip'
import { inFunctionList } from '../helpers/base'
import TemplateCreateModal from '../modals/TemplateCreateModal'
import { DELAY_SHORT, DELAY_TOAST } from '../models/constants'
import { EActiveStatus, ETemplateFunction, ITemplate } from '../models/template'
import { createTemplate, fetchTemplateList } from '../store/epics/template'
import { TemplateState } from '../store/templateSlice'
import './ListTemplate.scss'

export type ListTemplateProps = {} & RouteComponentProps<{}>
const ListTemplate = ({ location, ...props }: ListTemplateProps) => {
  const { t } = useTranslation()
  const authDuck = useSelector((state: any) => state.authDuck)
  const dispatch = useDispatch()
  const history = useHistory()
  const contentRef = useRef<HTMLIonContentElement | null>(null)
  const [isPageLoading, setIsPageLoading] = useState(false)
  const [isProcessing, setIsProcessing] = useState(false)
  const [showEmpty, setShowEmpty] = useState(false)
  const [showCreateTemplateModal, setShowCreateTemplateModal] = useState(false)
  const [showCreateTemplateToast, setShowCreateTemplateToast] = useState(false)

  const [activeStatus, setActiveStatus] = useState<EActiveStatus[]>([
    EActiveStatus.active,
  ])

  const [listType, setListType] = useState<EActiveStatus>(EActiveStatus.active)

  const template: TemplateState = useSelector((state: any) => state.template)

  useIonViewDidEnter(() => {
    if (!authDuck.isUserLoaded) return
    if (showCreateTemplateModal) return
    if (!location.state) return

    const { listType } = location.state as any
    if (listType) {
      setListType(listType)
      setActiveStatus([listType])
    }
    setIsProcessing(true)
    dispatch(fetchTemplateList.cancel())
    dispatch(
      fetchTemplateList.request({
        active_status: activeStatus,
      })
    )
  }, [authDuck.isUserLoaded, showCreateTemplateModal, listType, location.state])

  const scrollToTop = () => {
    contentRef.current?.scrollToTop(DELAY_SHORT)
  }

  const doRefresh = useCallback(
    (event?: CustomEvent<RefresherEventDetail>) => {
      setIsProcessing(true)

      dispatch(fetchTemplateList.cancel())

      dispatch(
        fetchTemplateList.request({
          event,
          active_status: activeStatus,
        })
      )
    },
    [dispatch, activeStatus]
  )

  useEffect(() => {
    if (template.templates !== undefined && template.isDone) {
      setIsPageLoading(false)
      setIsProcessing(false)
    }
  }, [setIsPageLoading, setIsProcessing, template.templates, template.isDone])

  useEffect(() => {
    if (!template?.templates) return
    if (template.isDone && template?.templates?.length >= 0) {
      setIsProcessing(false)
      setShowEmpty(true)
      setIsPageLoading(false)
    }
  }, [
    template.isDone,
    template.templates,
    setIsPageLoading,
    setIsProcessing,
    setShowEmpty,
  ])

  useEffect(() => {
    if (!template.created) return
    setShowCreateTemplateToast(true)
    setTimeout(() => {
      history.push(`/template/${template.key}`, { direction: 'forward' })
    }, DELAY_SHORT)
  }, [template.created, template.key, history, setShowCreateTemplateToast])

  const dismissCreateTemplateModal = (values: any) => {
    setShowCreateTemplateModal(false)
    createTemplateAction({ ...values })
  }

  const createTemplateAction = ({ name }: { name: string }) => {
    if (!name) return
    dispatch(
      createTemplate.request({
        name,
      })
    )
  }

  const handleSegmentChanged = (name?: string) => {
    if (!name) return
    setListType(EActiveStatus[name as keyof typeof EActiveStatus])
  }

  useEffect(() => {
    if (!authDuck.isUserLoaded) return
    if (!listType) return
    if (activeStatus?.length && activeStatus[0] !== listType) {
      setActiveStatus([EActiveStatus[listType as keyof typeof EActiveStatus]])
    }
  }, [listType, activeStatus, authDuck.isUserLoaded])

  useEffect(() => {
    if (!authDuck.isUserLoaded) return
    if (activeStatus?.length && activeStatus[0] === listType) {
      doRefresh()
    }
  }, [listType, activeStatus, authDuck.isUserLoaded, doRefresh])

  return (
    <IonPage className="page-list-template">
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>{t('Project Manage')}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent
        className="ion-padding bg"
        ref={contentRef}
        scrollEvents={true}
      >
        <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>
        {isPageLoading ? (
          <div className="ion-text-center centered">
            <IonSpinner color="primary" name="crescent"></IonSpinner>
          </div>
        ) : (
          <>
            <IonSegment
              value={listType}
              onIonChange={(e) => {
                handleSegmentChanged(e.detail.value)
              }}
            >
              <IonSegmentButton value="active">
                <IonLabel>{t('Current Projects')}</IonLabel>
              </IonSegmentButton>
              <IonSegmentButton value="archive">
                <IonLabel>{t('Archive Projects')}</IonLabel>
              </IonSegmentButton>
            </IonSegment>

            <IonToolbar>
              <IonTitle>
                {listType === 'archive'
                  ? t('Archive Projects')
                  : t('Current Projects')}
              </IonTitle>
              <IonButtons slot="end">
                {listType === 'active' &&
                  inFunctionList(
                    ETemplateFunction.template_create,
                    template.function_list
                  ) && (
                    <IonButton
                      disabled={template.error}
                      color="primary"
                      fill="solid"
                      onClick={() => setShowCreateTemplateModal(true)}
                      data-test="add-template-btn"
                    >
                      <IonIcon slot="start" icon={add} />
                      <IonLabel>{t('New Project')}</IonLabel>
                    </IonButton>
                  )}
              </IonButtons>
            </IonToolbar>
            <IonList lines="inset">
              {isProcessing && (
                <IonItem lines="none">
                  <IonSpinner color="primary" name="dots"></IonSpinner>
                </IonItem>
              )}
              {template?.isDone &&
                !template?.error &&
                !template.templates?.length &&
                showEmpty && (
                  <IonCard>
                    <IonCardHeader>
                      <IonCardTitle className="ion-text-center">
                        <IonLabel color="medium">{t('No Projects')}</IonLabel>
                      </IonCardTitle>
                    </IonCardHeader>
                  </IonCard>
                )}
              {template.templates?.map((item: ITemplate, index: number) => (
                <div key={index} className="template-list">
                  <IonItem
                    button
                    detail
                    disabled={
                      !inFunctionList(
                        ETemplateFunction.permission_read,
                        item.function_list
                      )
                    }
                    routerLink={`/template/${item.key}`}
                    routerDirection="forward"
                  >
                    <IonLabel>{item.name}</IonLabel>

                    {/* 顯示用戶對該專案的權限 (檢視者/編輯者)，
                        在使用者是管理員 or 專案設定成「所有團隊成員」權限時不顯示 */}
                    {!authDuck.currentUser?.is_admin &&
                      item.permission?.protected && (
                        <TemplatePermissionChip
                          functionList={item.function_list}
                        />
                      )}
                  </IonItem>
                </div>
              ))}
            </IonList>
            <div
              className="ion-text-center"
              hidden={isProcessing || !!template.cursor}
            >
              <IonButton
                color="warning"
                fill="clear"
                size="large"
                slot="icon-only"
                onClick={scrollToTop}
              >
                <IonIcon icon={caretUp} />
              </IonButton>
            </div>
          </>
        )}
        <IonToast
          isOpen={showCreateTemplateToast}
          onDidDismiss={() => {
            setShowCreateTemplateToast(false)
            dispatch(createTemplate.cancel())
          }}
          message={t('“{{target}}” has been created successfully.', {
            target: template.name,
          })}
          duration={DELAY_TOAST}
        />
        <IonModal isOpen={showCreateTemplateModal} backdropDismiss={false}>
          {showCreateTemplateModal && (
            <TemplateCreateModal
              isOpen={showCreateTemplateModal}
              dismiss={dismissCreateTemplateModal}
            />
          )}
        </IonModal>
      </IonContent>
    </IonPage>
  )
}

export default ListTemplate
