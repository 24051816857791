import FormTemplateCommonProps from '@data-driven-forms/common/form-template'
import Checkbox from '@data-driven-forms/mui-component-mapper/checkbox'
import Select from '@data-driven-forms/mui-component-mapper/select'
import SubForm from '@data-driven-forms/mui-component-mapper/sub-form'
import TextField from '@data-driven-forms/mui-component-mapper/text-field'
import TextArea from '@data-driven-forms/mui-component-mapper/textarea'
import FieldArray from '@data-driven-forms/mui-component-mapper/field-array'
import { FormSpy, Schema } from '@data-driven-forms/react-form-renderer'
import componentTypes from '@data-driven-forms/react-form-renderer/component-types'
import FormRenderer from '@data-driven-forms/react-form-renderer/form-renderer'
import useFormApi from '@data-driven-forms/react-form-renderer/use-form-api'
import Validators from '@data-driven-forms/react-form-renderer/validators'
import { IonItemDivider, IonList } from '@ionic/react'
import classnames from 'classnames'
import React, { useEffect, useState } from 'react'
import { useBeforeunload } from 'react-beforeunload'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { loadDefaultTemplateFullSchema } from '../../../models/schema'
import { ITemplate } from '../../../models/template'
import { RootState } from '../../../store/ducks'
import { Field } from '../EditFieldModal'
import EditModeSchemaSection from './EditModeSchemaSection'
import EditModeSignatureSection from './EditModeSignatureSection'
import './TemplateForm.scss'

// 從 template 上取出欄位資訊
const loadSchema = (name: string, item: ITemplate) => {
  const schemaName = `${name}_schema`
  if (
    (item as any)[schemaName] &&
    Object.keys((item as any)[schemaName]).length
  ) {
    return JSON.parse((item as any)[schemaName])
  } else {
    return (loadDefaultTemplateFullSchema(item) as any)[name]
  }
}

// 生成要顯示的表單 schema 給 FormRenderer
const loadUISchema = (item: ITemplate, isEditMode: boolean): Schema => {
  // 基本欄位（如"專案名稱"、"預設維修人員"）
  const baseFields = loadDefaultTemplateFullSchema(item)['base'].fields

  // 客戶選單/內部選單欄位
  const customFields: Field[] = loadSchema('custom_fields', item).fields
  const itnCustomFields: Field[] = loadSchema('itn_custom_fields', item).fields

  if (isEditMode) {
    // 在編輯模式中只顯示基本欄位
    return {
      fields: baseFields,
    }
  } else {
    // 在預覽模式中除了顯示基本欄位，也顯示客戶選單、內部選單在實際表單中的模樣
    return {
      fields: [
        ...baseFields,
        {
          name: 'divider-001',
          component: 'ITEM_DIVIDER',
        },
        {
          component: 'sub-form',
          title: '客戶選單',
          name: 'custom_fields_schema',
          fields: customFields,
        },
        {
          component: 'sub-form',
          title: '內部選單',
          name: 'itn_custom_fields_schema',
          fields: itnCustomFields,
        },
      ],
    }
  }
}

const templateFormComponentMapper = {
  [componentTypes.TEXT_FIELD]: TextField,
  [componentTypes.SELECT]: Select,
  [componentTypes.CHECKBOX]: Checkbox,
  [componentTypes.TEXTAREA]: TextArea,
  [componentTypes.SUB_FORM]: SubForm,
  [componentTypes.FIELD_ARRAY]: FieldArray,
  ITEM_DIVIDER: IonItemDivider,
}

interface TemplateFormProps {
  onChange: Function
  isPreviewMode: boolean
}

/*
  專案設定頁面表單
  顯示一個表單需要將以下資訊傳進 <FormRenderer/>：
  - schema：
    紀錄每個欄位的資訊，包括欄位名稱、初始值、以及顯示其欄位的 UI 元件名稱（如"下拉式選單"、"文字煩為"等）
  - componentMapper：
    一個 map，將每個欄位的 UI 元件名稱對應到各自的 React 元件，以客製化欄位的 UI 元件（如"下拉式選單" => <Select/>）
  - FormTemplate：
    一個元件，負責顯示每個欄位的 UI 元件
*/
const TemplateForm = ({ onChange, isPreviewMode }: TemplateFormProps) => {
  const { t } = useTranslation()
  const [schema, setSchema] = useState<Schema>({ fields: [] })
  const [customFields, setCustomFields] = useState<Field[]>([])
  const [itnCustomFields, setItnCustomFields] = useState<Field[]>([])
  const template = useSelector((state: RootState) => state.newTemplate.template)

  //@ts-ignore
  Validators.messages = {
    //@ts-ignore
    ...Validators.messages,
    required: t('Required'),
  }

  useEffect(() => {
    if (template) {
      setSchema(loadUISchema(template, !isPreviewMode))
      setCustomFields(loadSchema('custom_fields', template).fields)
      setItnCustomFields(loadSchema('itn_custom_fields', template).fields)
    }
  }, [template, isPreviewMode])

  return (
    <div className="page-template-form">
      {template && (
        <div>
          {/* 表單資訊 */}
          {/* 編輯模式下只顯示基本欄位，預覽模式下顯示基本欄位、客戶選單與內部選單 */}
          <FormRenderer
            schema={schema}
            componentMapper={templateFormComponentMapper}
            FormTemplate={(props: FormTemplateCommonProps) => (
              <FormTemplate
                {...props}
                onChange={onChange}
                isPreviewMode={isPreviewMode}
              />
            )}
            className="form-container"
            onSubmit={() => {}}
          />

          {/* 在編輯模式下，使用客製化元件顯示客戶選單與內部選單 */}
          {!isPreviewMode && (
            <>
              {/* 客戶選單 */}
              <EditModeSchemaSection
                schemaType="custom_fields"
                fields={customFields}
              />

              {/* 內部選單 */}
              <EditModeSchemaSection
                schemaType="itn_custom_fields"
                fields={itnCustomFields}
              />

              {/* 簽名欄位 */}
              <EditModeSignatureSection
                signatures={template.signatures}
              />
            </>
          )}
        </div>
      )}
    </div>
  )
}

interface FormTemplateProp {
  formFields: React.ElementType<any>[]
  onChange: any
  isPreviewMode: boolean
}

const FormTemplate = ({
  formFields,
  onChange,
  isPreviewMode,
}: FormTemplateProp) => {
  const { handleSubmit, getState } = useFormApi()
  const { dirty } = getState()

  useBeforeunload((event) => {
    if (dirty) {
      event.preventDefault()
    }
  })

  return (
    <div
      style={{ position: 'relative' }}
      className={classnames({
        'form-disabled': isPreviewMode,
      })}
    >
      <form onSubmit={handleSubmit}>
        <IonList className="form-container">
          {formFields}
          <FormSpy
            subscription={{
              values: true,
              valid: true,
              dirty: true,
            }}
            onChange={onChange}
          />
        </IonList>
      </form>
    </div>
  )
}

export default React.memo(TemplateForm)
