import { isPlatform, RefresherEventDetail } from '@ionic/core'
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonItem,
  IonItemDivider,
  IonLabel,
  IonList,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonSearchbar,
  IonSpinner,
  IonThumbnail,
  IonTitle,
  IonToolbar,
  useIonViewDidEnter,
  useIonViewDidLeave
} from '@ionic/react'
import { caretUp, close, cube } from 'ionicons/icons'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { useDebounce } from 'react-use'
import { getFallbackImage, getImageUrl } from '../helpers/file'
import useLocalForge from '../hooks/use-local-forge'
import { DELAY, DELAY_SHORT } from '../models/constants'
import { ITeam } from '../models/team'
import { switchAuth } from '../store/epics/auth'
import { fetchTeamList } from '../store/epics/user'
import { clearSwCacheStore } from '../swCache'
import './TeamSwitchModal.scss'

const TeamSwitchModal: React.FC<any> = ({
  isOpen,
  dismiss,
}: {
  isOpen: boolean
  dismiss: Function
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [, setToken] = useLocalForge('apiToken')
  const [, setCurrentUser] = useLocalForge('suiquiUserData')
  const history = useHistory()
  const contentRef = useRef(null)
  const searchbarRef = useRef(null)
  const pageRef = useRef(null)
  const user = useSelector((state: any) => state.user)
  const auth = useSelector((state: any) => state.auth)
  const authDuck = useSelector((state: any) => state.authDuck)
  const [isPageLoading, setIsPageLoading] = useState(true)
  const [isProcessing, setIsProcessing] = useState(true)
  const [currentTeam, setCurrentTeam] = useState<ITeam | null>()
  const [itemList, setItemList] = useState([]) // 團隊列表的搜尋結果清單
  const [keyword, setKeyword] = useState('') // 搜尋用的關鍵詞
  const [debouncedKeyword, setDebouncedKeyword] = useState('.')
  const [, cancelDebounced] = useDebounce(
    () => {
      //@ts-ignore
      setDebouncedKeyword(keyword)
    },
    1000,
    [keyword]
  )
  const [isOnComposition, setIsOnComposition] = useState(false)

  const handleCompositionStart = (event: any) => {
    setIsOnComposition(true)
  }

  const handleCompositionEnd = (event: any) => {
    setIsOnComposition(false)
    setKeyword(event.target.value || '')
  }

  useIonViewDidEnter(() => {
    if (isPlatform('desktop')) {
      //@ts-ignore
      searchbarRef.current?.addEventListener(
        'compositionstart',
        handleCompositionStart
      )

      //@ts-ignore
      searchbarRef.current?.addEventListener(
        'compositionend',
        handleCompositionEnd
      )
    }
  }, [isPlatform])

  useIonViewDidLeave(() => {})

  useEffect(() => {
    if (!isOpen || !authDuck.currentUser?.key) return

    setTimeout(() => {
      // Workaround ...
      // @ts-ignore
      pageRef.current?.classList.remove('ion-page-invisible')
    }, DELAY_SHORT)

    setIsPageLoading(true)
    dispatch(
      fetchTeamList.request({
        key: authDuck.currentUser?.key,
      })
    )
  }, [dispatch, setIsPageLoading, isOpen, authDuck.currentUser])

  /**
   * 用關鍵字搜尋團隊名稱
   */
  useEffect(() => {
    if (!isOpen) return
    if (!debouncedKeyword) return
    if (!user?.teams?.length) return

    setIsProcessing(true)

    const filteredList = user?.teams?.filter(
      // Notice: 忽略大小寫的搜尋值
      (team: ITeam) => team.name.toLowerCase().indexOf(keyword.toLowerCase()) > -1
    )

    setItemList(filteredList)

    setIsProcessing(false)
  }, [isOpen, debouncedKeyword, keyword, user.teams])

  const cancel = () => {}

  const clearCaches = async () => {
    if ('caches' in window.self) {
      await caches.delete('api-user')
    }
    await clearSwCacheStore()
  }

  const doRefresh = (event: CustomEvent<RefresherEventDetail>) => {
    setKeyword('')

    dispatch(fetchTeamList.cancel())

    dispatch(
      fetchTeamList.request({
        key: authDuck.currentUser!.key,
        event,
      })
    )
  }

  const scrollToTop = () => {
    // @ts-ignore
    contentRef.current!.scrollToTop(DELAY_SHORT)
  }

  const loadData = (event: CustomEvent<void>) => {
    dispatch(
      fetchTeamList.request({
        key: authDuck.currentUser.key,
        event,
      })
    )
  }

  /*
  const reloadData = () => {
    setIsPageLoading(true)
    dispatch(fetchTeamList.cancel())
    dispatch(
      fetchTeamList.request({
        key: authDuck.currentUser.key,
      })
    )
  }*/

  const switchTo = (team: ITeam) => {
    setCurrentTeam(team)
    dispatch(
      switchAuth.request({
        team_id: team.team_id,
      })
    )
    clearCaches()
  }

  useEffect(() => {
    if (!user.teams) return
    if (user.teams.length > 0) {
      setItemList(user.teams)
      setIsPageLoading(false)
      setIsProcessing(false)
    }
  }, [setIsPageLoading, setIsProcessing, user.teams])

  useMemo(() => {
    if (!auth.token || !currentTeam) return

    history.replace('/home?pending', {
      direction: 'root',
    })

    dismiss(cancel)

    setCurrentTeam(null)
    setCurrentUser(null)

    setTimeout(() => {
      history.replace('/reload', {
        direction: 'root',
      })
    }, DELAY)
  }, [
    auth.token,
    currentTeam,
    dismiss,
    setCurrentTeam,
    setCurrentUser,
    history,
  ])

  return (
    <IonPage className="page-team-switch-modal" ref={pageRef}>
      <IonHeader>
        <IonToolbar color="primary">
          <IonTitle>
            <IonButton class="logo immutable">{t('Suiqui Support')}</IonButton>
            <span>{t('Switch Team')}</span>
          </IonTitle>
          <IonButtons slot="end">
            <IonButton
              strong
              fill="clear"
              slot="icon-only"
              onClick={() => dismiss(cancel)}
            >
              <IonIcon size="large" icon={close}></IonIcon>
            </IonButton>
          </IonButtons>
        </IonToolbar>
        <IonItemDivider className="header">
          <IonSearchbar
            ref={searchbarRef}
            type="search"
            mode="ios"
            value={keyword === '.' ? '' : keyword}
            showCancelButton="focus"
            cancelButtonText={t('Cancel')}
            placeholder={t('Search...')}
            inputmode={'text'}
            onIonCancel={() => {
              setKeyword('')
              setItemList(user.teams)
            }}
            onIonInput={(event: any) => {
              isPlatform('desktop') && setKeyword(event.target.value || '')
            }}
          ></IonSearchbar>
          <IonButton
            hidden={isPlatform('desktop')}
            className="ion-padding-end"
            disabled={auth.nextTeam}
            color="danger"
            size="default"
            onClick={(event) => {
              // @ts-ignore
              setKeyword(searchbarRef.current.value || '')
            }}
          >
            <IonLabel>{t('Search')}</IonLabel>
          </IonButton>
        </IonItemDivider>
      </IonHeader>
      <IonContent ref={contentRef} scrollEvents={true}>
        <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>

        {isPageLoading ? (
          <div className="ion-text-center centered">
            <IonSpinner color="primary" name="crescent"></IonSpinner>
          </div>
        ) : (
          <div>
            <IonList>
              {itemList.map((team: ITeam, index: number) => {
                return (
                  <IonItem
                    button
                    key={index}
                    className={team.key === user.currentTeam?.key ? 'active' : ''}
                    disabled={team.key === user.currentTeam.key}
                    onClick={(e) => switchTo(team)}
                  >
                    <IonLabel>{team.name}</IonLabel>
                    <IonThumbnail slot="start">
                      <img
                        alt=""
                        src={`${getImageUrl(team, 56, 'c')}`}
                        onError={getFallbackImage}
                      ></img>
                    </IonThumbnail>
                  </IonItem>
                )
              })}
              <div className="ion-text-center" hidden={isProcessing}>
                <IonButton
                  color="danger"
                  fill="clear"
                  size="large"
                  onClick={scrollToTop}
                >
                  <IonIcon slot="icon-only" icon={caretUp} />
                </IonButton>
              </div>
            </IonList>
          </div>
        )}
        <IonInfiniteScroll
          threshold="50%"
          onIonInfinite={loadData}
          disabled={!user.teams?.cursor}
        >
          <IonInfiniteScrollContent></IonInfiniteScrollContent>
        </IonInfiniteScroll>
      </IonContent>
    </IonPage>
  )
}

export default TeamSwitchModal
