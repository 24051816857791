import { applyMiddleware, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import { createEpicMiddleware } from 'redux-observable'
import * as dependencies from '../providers'
import reducers from './ducks'
import epics from './epics'
import thunkMiddleware from 'redux-thunk'

const epicMiddleware = createEpicMiddleware({
  dependencies,
})

const composeEnhancers = composeWithDevTools({
  trace: true,
  traceLimit: 50,
})

const store = createStore(
  reducers,
  composeEnhancers(
    applyMiddleware(epicMiddleware, (store) => (next) => (action) => {
      try {
        next(action)
      } catch (e) {
        setTimeout(() => {
          throw e
        })
      }
    }),
    applyMiddleware(thunkMiddleware)
  )
)

epicMiddleware.run(epics)

export default store
