import moment from 'moment'
import { id } from '../models/base'

export interface keyItem {
  key: id
}

export function clone(obj: any): any {
  return JSON.parse(JSON.stringify(obj))
}

export function equal<T>(objA: T, objB: T): boolean {
  return JSON.stringify(objA) === JSON.stringify(objB)
}

export function pick<T, K extends keyof T>(obj: T, paths: K[]): Pick<T, K> {
  return {
    ...paths.reduce((mem, key) => ({ ...mem, [key]: obj[key] }), {}),
  } as Pick<T, K>
}

export function makeKeys<T>(items: keyItem[], flat?: boolean): T[] {
  if (!items || !items.length) return []
  return Array.prototype.concat.apply(
    [],
    flat
      ? items.map((item) => item.key)
      : items.map((item) => ({ key: item.key }))
  )
}

function hasOwnProperty<X extends {}, Y extends PropertyKey>(
  obj: X,
  prop: Y
): obj is X & Record<Y, unknown> {
  return obj.hasOwnProperty(prop)
}

export function makeDiff<T>(item: T, orig: T): T {
  const getKeys = <T extends {}>(o: T): Array<keyof T> =>
    Object.keys(o) as Array<keyof T>

  let ret = clone(item)
  getKeys(item).forEach((key) => {
    if (orig && hasOwnProperty(orig, key) && equal(item[key], orig[key])) {
      delete ret[key]
    }
  })
  return ret
}

export function makeDiffKeys(
  items: keyItem[],
  origs: keyItem[],
  plain: boolean = false,
  keyOnly: boolean = true
): [keyItem[], keyItem[]] {
  const comparer = (arr: keyItem[]) => (cur: keyItem) =>
    arr.filter((a: keyItem) => a.key === cur.key).length === 0

  const added = items.filter(comparer(origs))
  const removed = origs.filter(comparer(items))
  return keyOnly
    ? [makeKeys(added, plain), makeKeys(removed, plain)]
    : [added, removed]
}

export function basename(path: string) {
  if (!path) return path
  return path.replace(/\\/g, '/').replace(/.*\//, '')
}

export function dirname(path: string) {
  if (!path) return path
  const ret = path.replace(/\\/g, '/').replace(/\/[^/]*$/, '')
  if (ret === '/') return undefined
  if (ret === basename(path)) return undefined

  return ret
}

export function removeDuplicates(array: any[]) {
  return array.filter((a, b) => array.indexOf(a) === b)
}

export function makeUnique(items: any[], prop: string) {
  if (!items || !items.length || !items[0].hasOwnProperty(prop)) return items
  return items.filter(
    (item, pos, arr) =>
      arr.findIndex((_item) => _item[prop] === item[prop]) === pos
  )
}

export const delay = (amount: number) => {
  return new Promise((resolve) => {
    setTimeout(resolve, amount)
  })
}

export function detectUserAgent() {
  const ua = navigator.userAgent
  const url = window.location.href
  if (ua.indexOf('Line') > -1 && ua.indexOf('Android') > -1) {
    const target = `intent:${url}#Intent;action=android.intent.action.VIEW;category=android.intent.category.BROWSABLE;component=com.android.browser/com.android.browser.BrowserActivity;end`
    window.location.href = target

    setTimeout(function () {
      window.location.href = target
    }, 500)
  }
}

export function isLineInApp() {
  const winNav = window['navigator'],
    isLineInApp = winNav.userAgent.match('Line')

  if (isLineInApp) {
    return true
  }

  return false
}

export function isIOSChrome() {
  const winNav = window['navigator'],
    isIOSChrome = winNav.userAgent.match('CriOS')

  if (isIOSChrome) {
    return true
  }

  return false
}

export function isSafari() {
  return !!navigator.userAgent.match(/Version\/[\d.]+.*Safari/)
}

export function isIOS() {
  const iOS =
    (/iPad|iPhone|iPod/.test(navigator.platform) ||
      (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) &&
    !window['MSStream']

  return iOS
}

export function isInStandaloneMode() {
  const iOS = isIOS()
  return (
    //@ts-ignore
    iOS && 'standalone' in window.navigator && window.navigator['standalone']
  )
}

export function isIphoneX() {
  const iOS =
    /iPad|iPhone|iPod/.test(navigator.userAgent) && !window['MSStream']
  const ratio = window.devicePixelRatio || 1
  const screen = {
    width: window.screen.width * ratio,
    height: window.screen.height * ratio,
  }
  return iOS && screen.width === 1125 && screen.height === 2436
}

export function parseDate(date: any) {
  if (!date) return undefined
  return moment(date).format('YYYY-MM-DD')
}
