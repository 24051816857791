import {
  IonItem,
  IonThumbnail,
  IonLabel,
  IonProgressBar,
  IonItemDivider,
  IonButtons,
  IonButton,
  IonIcon,
} from '@ionic/react'
import { getFileTypeIconProps } from '@uifabric/file-type-icons'
import filesize from 'filesize'
import { cloudDownload } from 'ionicons/icons'
import { Icon } from 'office-ui-fabric-react'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import {
  getFileExt,
  getIFileProgressKey,
  getProgressBarColor,
  getProgressNumber,
} from '../../helpers/file'
import { ISuiquiFile } from '../../models/suiquiFile'

interface FileCardProps {
  file: ISuiquiFile
  onClick: () => void
}

const FileCard = ({ file, onClick }: FileCardProps) => {
  const download = useSelector((state: any) => state.download)
  const pKey = getIFileProgressKey(file)
  const fileExt = useMemo(() => {
    return getFileExt(file) || 'file'
  }, [file])

  const getProgressNumberCallback = useCallback((pFile) => {
    return getProgressNumber(pFile)
  }, [])

  return (
    <>
      <IonItem className="fix-item" button={true} onClick={onClick}>
        <IonThumbnail slot="start">
          <Icon
            {...getFileTypeIconProps({
              extension: fileExt,
              size: 48,
              imageFileType: 'svg',
            })}
          />
        </IonThumbnail>
        <IonLabel className="ion-text-wrap">{file.name}</IonLabel>
        <div>
          <IonLabel color="medium">
            {filesize(file?.blob_info?.size || 0, {
              base: 2,
            })}
          </IonLabel>
        </div>
      </IonItem>

      <IonProgressBar
        hidden={file.is_image}
        type={download.pFiles[pKey]?.isPending ? 'determinate' : 'determinate'}
        color={
          !download.pFiles[pKey]
            ? 'light'
            : getProgressBarColor(download.pFiles[pKey])
        }
        value={getProgressNumberCallback(download.pFiles[pKey])}
      ></IonProgressBar>
    </>
  )
}

export default FileCard
