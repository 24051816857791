import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonSpinner,
  IonTitle,
  IonToolbar,
} from '@ionic/react'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { useTitle } from 'react-use'
import useLocalForge from '../hooks/use-local-forge'
import { useSearchParam } from '../hooks/use_serch_params'
import { Creators as AuthActions } from '../store/ducks/auth'
import { fetchUser } from '../store/epics/user'

// 切換團隊時導向的頁面
// 將 redux 的 auth store 中的資訊複製至 authDuck store，並儲存新的 api token 到 local storage
const Reload = () => {
  const { t } = useTranslation()
  useTitle(t('Suiqui Support'))

  const history = useHistory()

  const dispatch = useDispatch()
  const [, setToken, isTokenLoading] = useLocalForge('apiToken')
  const [, setCurrentUser] = useLocalForge('suiquiUserData')
  const [isRedirecting, setIsRedirecting] = useState(false)

  const auth = useSelector((state: any) => state.auth)
  const user = useSelector((state: any) => state.user)
  const authDuck = useSelector((state: any) => state.authDuck)

  // 切換團隊完成後要重新導向的頁面，透過 url params 傳遞
  // 備註：預設會導向至 /home
  const redirectUrl = useSearchParam('redirectUrl')

  useEffect(() => {
    if (isTokenLoading) return
    if (!auth.token) return
    if (authDuck.token === auth.token) return

    setToken(auth.token)
    dispatch(AuthActions.login(auth.token))
  }, [dispatch, setToken, isTokenLoading, auth.token, authDuck.token])

  useEffect(() => {
    if (authDuck.isUserLoaded) return
    if (authDuck.token !== auth.token) return
    dispatch(fetchUser.request({}))
  }, [
    dispatch,
    authDuck.isUserLoaded,
    auth.token,
    authDuck.token,
    user.currentUser,
  ])

  useEffect(() => {
    if (authDuck.isUserLoaded) return
    if (authDuck.token !== auth.token) return
    if (!user.currentUser) return
    if (user.currentUser?.key === authDuck.currentUser?.key) return

    setCurrentUser(user.currentUser)
    dispatch(AuthActions.checkIn(user.currentUser))
  }, [
    dispatch,
    setCurrentUser,
    authDuck.isUserLoaded,
    auth.token,
    authDuck.token,
    authDuck.currentUser,
    user.currentUser,
  ])

  // 切換團隊完成後，重新導向頁面，可以透過 redirecUrl 參數改變要導向的頁面
  // 備註：預設會導向至 /home
  useEffect(() => {
    if (authDuck.token !== auth.token) return
    if (!authDuck.isUserLoaded) return
    if (isRedirecting) return
    setIsRedirecting(true)
    if (redirectUrl) {
      history.replace(redirectUrl)
    } else {
      history.replace('/home')
    }
  }, [
    authDuck.token,
    auth.token,
    authDuck.isUserLoaded,
    redirectUrl,
    history,
    isRedirecting,
  ])

  return (
    <IonPage className="page-reload">
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>{t('Suiqui Support')}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding bg">
        <div className="ion-text-center centered">
          <IonSpinner color="primary" name="crescent"></IonSpinner>
        </div>
      </IonContent>
    </IonPage>
  )
}

export default Reload
