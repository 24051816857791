import {
  isPlatform,
  useIonViewWillEnter,
  useIonViewWillLeave,
} from '@ionic/react'
import { useSelector } from 'react-redux'
import { RouteComponentProps } from 'react-router'
import SplitPane from 'react-split-pane'
import { useWindowSize } from 'react-use'
import TeamSwitchPage from '../../components/TeamSwitchPage'
import useLocalForge from '../../hooks/use-local-forge'
import { COMMENT_LIST_MIN_SIZE } from '../../models/comment'
import { BREAKPOINT_MD, DELAY_SHORT, DELAY_TOAST } from '../../models/constants'
import { ETicketStatus, ETicketUserType } from '../../models/ticket'
import { ReactRouterAction } from '../../store/epics/types'
import ListTicketComment from '../ListTicketComment'
import Ticket from './Ticket'
import './Ticket.scss'

export type TicketWithSplitPaneProps = {
  userType: ETicketUserType
} & RouteComponentProps &
  ReactRouterAction

const TicketWithSplitPane = (props: TicketWithSplitPaneProps) => {
  const [token, setToken] = useLocalForge('apiToken')
  const [currentUser, setCurrentUser] = useLocalForge('suiquiUserData')
  const { width, height } = useWindowSize()
  const isSplitComment =
    (isPlatform('desktop') || isPlatform('ipad')) && width >= BREAKPOINT_MD
  const ticket = useSelector((state: any) => state.ticket)
  const auth = useSelector((state: any) => state.auth)

  // 隱藏右上角九宮格按鈕
  useIonViewWillEnter(() => {
    const popupmenu = document.querySelector('#global-popup-menu')
    popupmenu?.classList.add('hidden')
  })

  // 離開頁面時取消隱藏右上角九宮格按鈕
  useIonViewWillLeave(() => {
    const popupmenu = document.querySelector('#global-popup-menu')
    popupmenu?.classList.remove('hidden')
  })

  // 若目前使用者無權限查閱，顯示切換團隊頁面
  if (auth?.nextTeam) {
    return <TeamSwitchPage />
  }

  return (
    <div>
      {/* 
        大螢幕，左右分割顯示工單與留言
        Ticket 與 ListTicketComment 為 SplitPane 下同階層的元件
      */}
      {isSplitComment && (
        <SplitPane
          {...props}
          primary="secondary"
          minSize={COMMENT_LIST_MIN_SIZE}
          defaultSize={COMMENT_LIST_MIN_SIZE}
        >
          <Ticket
            {...props}
            pageWidth={width}
            pageHeight={height}
            isSplitComment={isSplitComment}
          ></Ticket>
          <ListTicketComment
            {...props}
            pageWidth={width}
            pageHeight={height}
            isAllowNewComment={
              ticket?.ticket_status === ETicketStatus.InProgress
            }
          ></ListTicketComment>
        </SplitPane>
      )}

      {/* 
        小螢幕：按按鈕切換顯示留言
        ListTicketComment 為 Ticket 元件下的元件 
      */}
      {!isSplitComment && <Ticket {...props}></Ticket>}
    </div>
  )
}

export default TicketWithSplitPane
