import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonList,
  IonPage,
  IonTitle,
  IonToolbar,
} from '@ionic/react'
import { Checkbox, FormControlLabel, TextField } from '@mui/material'
import { checkmark, close } from 'ionicons/icons'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Signature } from '../../models/template'
import { RootState } from '../../store/ducks'
import {
  createSignatureField,
  updateSignatureField,
} from '../../store/templateSlice'
import './EditSignatureModal.scss'

export interface FormData {
  name: string
  can_print: boolean
}

interface EditSignatureModalProps {
  // 正在編輯的簽核欄位，若值為 undefined，代表正在創建新的欄位
  editingSignature?: Signature
  dismiss: () => void
}

const EditSignatureModal = ({
  editingSignature,
  dismiss,
}: EditSignatureModalProps) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const template = useSelector((state: RootState) => state.newTemplate.template)
  const { register, handleSubmit } = useForm<FormData>({
    defaultValues: editingSignature,
  })

  const onSave = () => {
    handleSubmit(({ name, can_print }) => {
      if (!template) return
      if (editingSignature) {
        // 更新簽核欄位
        dispatch(
          updateSignatureField({ key: editingSignature.key, name, can_print })
        )
      } else {
        // 新增簽核欄位
        dispatch(createSignatureField({ key: template.key, name, can_print }))
      }
      dismiss()
    })()
  }

  return (
    <IonPage className="edit-signature-modal">
      <IonHeader>
        <IonToolbar color="primary">
          {/* 標題 */}
          <IonTitle>
            <IonButton class="logo immutable">{t('Suiqui Support')}</IonButton>
            <span className="subtitle">
              {editingSignature ? t('Edit Field') : t('Add field')}
            </span>
          </IonTitle>

          <IonButtons slot="end">
            {/* 關閉按鈕 */}
            <IonButton strong fill="clear" slot="icon-only" onClick={dismiss}>
              <IonIcon size="large" icon={close}></IonIcon>
            </IonButton>

            {/* 儲存後關閉按鈕 */}
            <IonButton strong fill="clear" slot="icon-only" onClick={onSave}>
              <IonIcon size="large" icon={checkmark}></IonIcon>
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding bg" scrollEvents={true}>
        <IonList lines="inset">
          <h5>{t('Signature Name')}</h5>
          <div className="field-name-input">
            {/* 簽名職稱 */}
            <TextField
              fullWidth
              required
              label={t('Signature Name')}
              className="input-field"
              {...register('name', { required: true })}
            />

            {/* 列印 */}
            <FormControlLabel
              control={
                <Checkbox
                  {...register('can_print')}
                  defaultChecked={editingSignature?.can_print}
                />
              }
              label={t('Print').toString()}
              className="input-field"
            />
          </div>
        </IonList>
      </IonContent>
    </IonPage>
  )
}

export default EditSignatureModal
