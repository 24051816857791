import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import moment from 'moment'
import { downloadCSV, printHtmlPage } from '../helpers/file'
import { id, key } from '../models/base'
import {
  ITemplate,
  ITemplateNotificationBell,
  ServiceApi,
  Signature,
} from '../models/template'
import * as Providers from '../providers'
import { EActiveStatus, ITemplateDashboard } from './../models/template'
import { ThunkConfig } from './ducks'

// 舊的 template state，待全部相關部分更新完成後移除
export interface TemplateState extends ITemplate {
  // templates 為團隊所有專案的集合，也就是在專案總覽或專案設定頁面時，顯示的一整排的 template 的資訊
  templates: ITemplate[]
  isDone: boolean
  isReadyToSave: boolean
  archived: boolean | null
  unarchived: boolean | null
  updated: boolean | null
  error: boolean
  isRenewAPISuccess: boolean | null
  isResetLineVerificationCodeSuccess: boolean | null
  copied: boolean | null
  copyError: boolean | null
  created: boolean | null
  cursor: key | null
  dashboard: ITemplateDashboard | null // 專案總覽/單個專案頁面中統計元件的資料
  toastMessage: string | null
}

// 新的 template state，待全部相關部分更新完成後改名為 TemplateState
export interface NewTemplateState {
  template: ITemplate | null
  isDone: boolean // 初次讀取專案完成後設置為 true（未來視情況清除此變數）

  // templates 為團隊所有專案的集合，也就是在專案總覽或專案設定頁面時，顯示的一整排的 template 的資訊
  templates: ITemplate[]

  // dashboard 為專案總覽/單個專案頁面中統計元件的資料
  // Notice: 目前只有專案總覽頁面使用 NewTemplateState.dashboard
  // 單個專案中的統計元件尚採用 src/store/epics/template.ts 中定義的 TemplateState.dashboard
  dashboard: Partial<ITemplateDashboard> | null

  // 以下變數會在後端 API 完成時設成對應的值，以觸發 UI 元件內的相關邏輯
  // 例如：顯示 toast、顯示錯誤訊息、重新導向頁面等
  // 當 UI 完成相關邏輯時，需將其重新清除為 null，以避免再次設置一樣的值時不會觸發更新
  status: TemplateStateStatus | null
  error: string | null // 後端 API 失敗時設置
  toastMessage: string | null
  isLoading: boolean
}

// 後端 API 完成的動作狀態
type TemplateStateStatus =
  | 'archived'
  | 'unarchived'
  | 'copied'
  | 'loading'
  | 'printed'
  | 'batch exported'
  | 'to notified updated'

// 初始 state
const initialState: NewTemplateState = {
  template: null,
  templates: [],
  error: null,
  status: null,
  toastMessage: null,
  isDone: false,
  isLoading: false,
  dashboard: null,
}

const templatesSlice = createSlice({
  name: 'newTemplate', // 待全部相關部分更新完成後改名為 template
  initialState,
  reducers: {
    // 將 status 重新清除為 null
    clearStatus(state) {
      state.status = null
    },

    // 將 error 重新清除為 null
    clearError(state) {
      state.error = null
    },

    // 將 toastMessage 重新清除為 null
    clearToastMessage(state) {
      state.toastMessage = null
    },

    // 不經由後端 API，只在前端更新 state.template 中的 schema 變數（未來視情況清除）
    updateTemplateSchema(
      state,
      {
        payload: { custom_fields_schema, itn_custom_fields_schema },
      }: PayloadAction<
        Partial<
          Pick<ITemplate, 'custom_fields_schema' | 'itn_custom_fields_schema'>
        >
      >
    ) {
      if (!state.template) return
      if (custom_fields_schema) {
        state.template.custom_fields_schema = custom_fields_schema
      }
      if (itn_custom_fields_schema) {
        state.template.itn_custom_fields_schema = itn_custom_fields_schema
      }
    },
  },

  // 處理從 thunk 觸發的 action 的 reducers
  extraReducers: (builder) => {
    // 讀取單一專案中
    builder.addCase(fetchTemplate.pending, (state) => {
      state.isLoading = true
    })

    // 讀取單一專案成功
    builder.addCase(fetchTemplate.fulfilled, (state, { payload: template }) => {
      state.template = template
      state.isDone = true
      state.isLoading = false
    })

    // 讀取列表專案中
    builder.addCase(fetchTemplateList.pending, (state) => {
      state.isLoading = true
    })

    // 讀取專案列表成功
    builder.addCase(
      fetchTemplateList.fulfilled,
      (state, { payload: { templates } }) => {
        state.templates = templates
        state.isLoading = false
      }
    )

    // 讀取專案總覽統計元件的資料中
    builder.addCase(fetchTemplateDashboardAll.pending, (state) => {
      state.dashboard = null
    })

    // 讀取專案總覽統計元件的資料成功
    builder.addCase(
      fetchTemplateDashboardAll.fulfilled,
      (state, { payload }) => {
        state.dashboard = payload
      }
    )

    // 更新專案中
    builder.addCase(updateTemplate.pending, (state) => {
      state.isLoading = true
    })

    // 更新專案成功
    builder.addCase(
      updateTemplate.fulfilled,
      (state, { payload: template }) => {
        state.template = template
        state.toastMessage = '“{{target}}” has been updated successfully.'
        state.isLoading = false
      }
    )

    // 封存專案成功
    builder.addCase(
      archiveTemplate.fulfilled,
      (state, { payload: template }) => {
        state.template = template
        state.status = 'archived'
        state.toastMessage = '“{{target}}” has been archived successfully.'
      }
    )

    // 解除封存專案成功
    builder.addCase(
      unarchiveTemplate.fulfilled,
      (state, { payload: template }) => {
        state.template = template
        state.status = 'unarchived'
        state.toastMessage = '“{{target}}” has been unarchived successfully.'
      }
    )

    // 重置專案 line 驗證碼成功
    builder.addCase(
      resetLineVerificationCode.fulfilled,
      (state, { payload: { ticket_template: template } }) => {
        state.template = template
        state.toastMessage = 'Reset line verfication code successfully'
      }
    )

    // 匯出多個專案工單 csv 成功
    builder.addCase(
      exportBatchCsv.fulfilled,
      (state, { payload: { filename, csvContent } }) => {
        downloadCSV(filename, csvContent)
        state.status = 'batch exported'
      }
    )

    // 列印專案工單成功
    builder.addCase(
      printTemplate.fulfilled,
      (state, { payload: { filename, data } }) => {
        printHtmlPage(data, filename)
        state.status = 'printed'
      }
    )

    // 複製專案成功
    builder.addCase(copyTemplate.fulfilled, (state, { payload: template }) => {
      state.template = template
      state.status = 'copied'
      state.toastMessage = 'Copy settings to new project successfully'
    })

    // 新增簽核欄位成功
    builder.addCase(
      createSignatureField.fulfilled,
      (state, { payload: { signature: newSignature } }) => {
        if (!state.template) return
        state.template.signatures.push(newSignature)
      }
    )

    // 更新簽核欄位成功
    builder.addCase(
      updateSignatureField.fulfilled,
      (state, { payload: { signature: updatedSignature } }) => {
        if (!state.template) return
        const updatedSignatureIdx = state.template.signatures.findIndex(
          (signature) => signature.key === updatedSignature.key
        )
        state.template.signatures[updatedSignatureIdx] = updatedSignature
      }
    )

    // 刪除簽核欄位成功
    builder.addCase(
      deleteSignatureField.fulfilled,
      (state, { payload: { deletingSignatureKey } }) => {
        if (!state.template) return
        state.template.signatures = state.template.signatures.filter(
          (e) => e.key !== deletingSignatureKey
        )
      }
    )

    // 重新排序簽核欄位成功
    builder.addCase(
      reorderSignatureFields.fulfilled,
      (state, { payload: { signatures } }) => {
        if (!state.template) return
        state.template.signatures = signatures
      }
    )

    // 重新生成 QR Code 開單網址成功
    builder.addCase(
      renewServiceApi.fulfilled,
      (state, { payload: { api } }) => {
        if (!state.template) return
        state.template = { ...state.template, api }
        state.toastMessage = 'Renew QR Code successfully'
      }
    )

    // 啟用 QR Code 開單網址成功
    builder.addCase(
      activateServiceApi.fulfilled,
      (state, { payload: { api } }) => {
        if (!state.template) return
        state.template = { ...state.template, api }
      }
    )

    // 凍結 QR Code 開單網址成功
    builder.addCase(
      deactivateServiceApi.fulfilled,
      (state, { payload: { api } }) => {
        if (!state.template) return
        state.template = { ...state.template, api }
      }
    )

    // 更新節點提醒成功
    builder.addCase(
      updateTemplateReminder.fulfilled,
      (state, { payload: { template } }) => {
        if (!state.template) return
        state.template = {
          ...state.template,
          notification_bells: template.notification_bells,
        }
        state.toastMessage = 'Update reminder successfully'
      }
    )

    // TODO: 尚未實作後端 API 失敗 reducers
  },
})

// 讀取單一專案
export const fetchTemplate = createAsyncThunk<
  ITemplate,
  { key: id },
  ThunkConfig
>('templates/fetchTemplate', async (params, { getState }) => {
  const token = getState().authDuck.token
  const { response } = await Providers.templateAPI
    .get({ token, params })
    .toPromise()
  return response as ITemplate
})

// 讀取專案列表
export const fetchTemplateList = createAsyncThunk<
  { templates: ITemplate[] },
  { include_stats?: boolean },
  ThunkConfig
>('templates/fetchTemplateList', async (params, { getState }) => {
  const token = getState().authDuck.token
  const { response } = await Providers.templateAPI
    .list({ token, params })
    .toPromise()
  return response as { templates: ITemplate[] }
})

// 讀取專案總覽統計元件的資料（所有專案統計資料的加總）
export const fetchTemplateDashboardAll = createAsyncThunk<
  Omit<ITemplateDashboard, 'key'>,
  { active_statuses?: EActiveStatus[] },
  ThunkConfig
>('templates/dashboard/all', async (params, { getState }) => {
  const token = getState().authDuck.token
  const { response: dashboard } = await Providers.templateAPI
    .getDashboardAll({ token, params })
    .toPromise()
  return dashboard as Omit<ITemplateDashboard, 'key'>
})

// 更新專案
export const updateTemplate = createAsyncThunk<
  ITemplate,
  { key: id } & Partial<ITemplate>,
  ThunkConfig
>('templates/updateTemplate', async (params, { getState }) => {
  const token = getState().authDuck.token
  const { response } = await Providers.templateAPI
    .update({ token, params })
    .toPromise()
  return response as ITemplate
})

// 封存專案
export const archiveTemplate = createAsyncThunk<
  ITemplate,
  { key: id },
  ThunkConfig
>('templates/archiveTemplate', async (params, { getState }) => {
  const token = getState().authDuck.token
  const { response } = await Providers.templateAPI
    .archive({ token, params })
    .toPromise()
  return response as ITemplate
})

// 解除封存專案
export const unarchiveTemplate = createAsyncThunk<
  ITemplate,
  { key: id },
  ThunkConfig
>('templates/unarchiveTemplate', async (params, { getState }) => {
  const token = getState().authDuck.token
  const { response } = await Providers.templateAPI
    .unarchive({ token, params })
    .toPromise()
  return response as ITemplate
})

// 複製專案
export const copyTemplate = createAsyncThunk<
  ITemplate,
  { key: id },
  ThunkConfig
>('templates/copyTemplate', async (params, { getState }) => {
  const token = getState().authDuck.token
  const { response } = await Providers.templateAPI
    .copyTemplate({ token, params })
    .toPromise()
  return response as ITemplate
})

// 匯出多個專案工單 csv
export const exportBatchCsv = createAsyncThunk<
  { filename: string; csvContent: string },
  { template_keys: id[]; start_date: Date; end_date: Date },
  ThunkConfig
>('templates/exportBatchCsv', async (params, { getState }) => {
  const token = getState().authDuck.token
  const teamName = getState().authDuck.currentUser?.team?.name
  const { template_keys, start_date, end_date } = params
  const { response } = await Providers.ticketAPI
    .exportBatchCsv({
      token,
      params: {
        template_keys: template_keys,
        start_date: moment(start_date).format('YYYY-MM-DD'),
        end_date: moment(end_date).format('YYYY-MM-DD'),
      },
    })
    .toPromise()
  return {
    filename: `${teamName}專案進度總表_${moment(params.start_date).format(
      'YYMMDD'
    )}_${moment(params.end_date).format('YYMMDD')}`, // e.g. <團隊名稱>專案進度總表_220712_220813.csv
    csvContent: response as string,
  }
})

// 列印專案的工單
export const printTemplate = createAsyncThunk<
  { data: string; filename: string },
  {
    key: id
    start_date: Date
    end_date: Date
    include_fields: {
      system: string[]
      custom: string[]
      internal_custom: string[]
      photos: boolean
    }
  },
  ThunkConfig
>('templates/printTemplate', async (params, { getState }) => {
  const token = getState().authDuck.token
  const templateName = getState().newTemplate.template?.name
  const { key, start_date, end_date, include_fields } = params
  const { response } = await Providers.templateAPI
    .print({
      token,
      params: {
        key,
        start_date: moment(start_date).format('YYYY-MM-DD'),
        end_date: moment(end_date).format('YYYY-MM-DD'),
        include_fields,
      },
    })
    .toPromise()
  return {
    filename: `${templateName}專案進度_${moment(params.start_date).format(
      'YYMMDD'
    )}_${moment(params.end_date).format('YYMMDD')}`, // e.g. <專案名稱>專案進度_220712_220813
    data: response as string,
  }
})

// 重置專案 line 驗證碼
export const resetLineVerificationCode = createAsyncThunk<
  { ticket_template: ITemplate },
  { key: id },
  ThunkConfig
>('templates/resetLineVerificationCode', async (params, { getState }) => {
  const token = getState().authDuck.token
  const { response } = await Providers.templateAPI
    .resetLineVerificationCode({ token, params })
    .toPromise()
  return response
})

// 新增簽核欄位
export const createSignatureField = createAsyncThunk<
  { signature: Signature },
  {
    key: id // template id
    name: string
    can_print: boolean
  },
  ThunkConfig
>('templates/createSignatureField', async (params, { getState }) => {
  const token = getState().authDuck.token
  const { response } = await Providers.signatureAPI
    .createSignature({ token, params })
    .toPromise()
  return response
})

// 更新簽核欄位
export const updateSignatureField = createAsyncThunk<
  { signature: Signature },
  {
    key: id // signature id
    name: string
    can_print: boolean
  },
  ThunkConfig
>('templates/updateSignatureField', async (params, { getState }) => {
  const token = getState().authDuck.token
  const { response } = await Providers.signatureAPI
    .updateSignature({ token, params })
    .toPromise()
  return response
})

// 刪除簽核欄位
export const deleteSignatureField = createAsyncThunk<
  { deletingSignatureKey: id },
  {
    key: id // signature id
  },
  ThunkConfig
>('templates/deleteSignatureField', async (params, { getState }) => {
  const token = getState().authDuck.token
  await Providers.signatureAPI.deleteSignature({ token, params }).toPromise()
  return { deletingSignatureKey: params.key }
})

// 更新簽核欄位
export const reorderSignatureFields = createAsyncThunk<
  { signatures: Signature[] },
  {
    key: id // template id
    signature_keys: id[]
  },
  ThunkConfig
>('templates/reorderSignatureFields', async (params, { getState }) => {
  const token = getState().authDuck.token
  const { response } = await Providers.signatureAPI
    .reorderSignatures({ token, params })
    .toPromise()
  return response
})

// 重新生成 QR Code 開單網址
export const renewServiceApi = createAsyncThunk<
  { api: ServiceApi },
  { key: id },
  ThunkConfig
>('templates/renewServiceApi', async (params, { getState }) => {
  const token = getState().authDuck.token
  const { response } = await Providers.templateAPI
    .renewServiceApi({ token, params })
    .toPromise()
  return response
})

// 啟用 QR Code 開單網址
export const activateServiceApi = createAsyncThunk<
  { api: ServiceApi },
  { key: id },
  ThunkConfig
>('templates/activateServiceApi', async (params, { getState }) => {
  const token = getState().authDuck.token
  const { response } = await Providers.templateAPI
    .activateServiceApi({ token, params })
    .toPromise()
  return response
})

// 凍結 QR Code 開單網址
export const deactivateServiceApi = createAsyncThunk<
  { api: ServiceApi },
  { key: id },
  ThunkConfig
>('templates/deactivateServiceApi', async (params, { getState }) => {
  const token = getState().authDuck.token
  const { response } = await Providers.templateAPI
    .deactivateServiceApi({ token, params })
    .toPromise()
  return response
})

// 更新節點提醒
export const updateTemplateReminder = createAsyncThunk<
  { template: ITemplate },
  { key: id; notification_bells: ITemplateNotificationBell[] },
  ThunkConfig
>('templates/updateTemplateReminder', async (params, { getState }) => {
  const token = getState().authDuck.token
  const { response } = await Providers.templateAPI
    .updateTemplateReminder({
      token,
      params,
    })
    .toPromise()
  return response
})

export const {
  updateTemplateSchema,
  clearStatus,
  clearError,
  clearToastMessage,
} = templatesSlice.actions
export const templateReducer = templatesSlice.reducer
