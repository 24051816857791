import { ajax } from 'rxjs/ajax'
import { API } from '../helpers/base'
import { IAuth } from '../models/auth'
import { ITeam } from '../models/team'
import { IAccountInfo } from '../models/user'

export const login = ({ params }: { params: Partial<IAccountInfo> }) =>
  ajax({
    url: API('api-token-auth', 'AUTH'),
    method: 'POST',
    responseType: 'json',
    headers: {
      'Content-Type': 'application/json',
    },
    body: {
      ...params,
    },
  })

export const resetPassword = ({ params }: { params: { username: string } }) =>
  ajax({
    url: API('user/publicresetpassword', 'AUTH'),
    method: 'POST',
    responseType: 'json',
    headers: {
      'Content-Type': 'application/json',
    },
    body: {
      ...params,
    },
  })

export const renew = ({ params }: { params: Partial<ITeam & IAuth> }) =>
  ajax({
    url: API('api-token-switchteam', 'AUTH'),
    method: 'POST',
    body: {
      ...params,
    },
    headers: {
      'Content-Type': 'application/json',
    },
    responseType: 'json',
  })
