import { ajax } from 'rxjs/ajax'
import { API, JWT } from '../helpers/base'
import { key } from '../models/base'

export const get = ({ token }: { token: string }) =>
  ajax({
    url: API('user', 'AUTH'),
    method: 'GET',
    headers: JWT(token),
    responseType: 'json',
  })

export const teamList = ({ token }: { token: string }) =>
  ajax({
    url: API('user/switchteam', 'AUTH'),
    method: 'GET',
    headers: JWT(token),
    responseType: 'json',
  })

export const baseService = ({ token }: { token: string }) =>
  ajax({
    url: API('user/base/service/support', 'AUTH'),
    method: 'POST',
    headers: JWT(token),
    responseType: 'json',
  })

export const autoComplete = ({
  token,
  params,
}: {
  token: string
  params: {
    keyword?: string
    cursor?: key
    page_size?: number
  }
}) =>
  ajax({
    url: API('autocomplete', 'AUTH'),
    method: 'POST',
    body: {
      ...params,
    },
    headers: JWT(token),
    responseType: 'json',
  })

// 取得使用者以前的簽名
export const getQuickSignature = ({ token }: { token: string }) =>
  ajax({
    url: API('user/sign', 'AUTH'),
    method: 'GET',
    headers: JWT(token),
    responseType: 'json',
  })
