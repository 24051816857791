import { Field } from '@data-driven-forms/react-form-renderer'
import { print } from 'ionicons/icons'
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonPage,
  IonTitle,
  IonToolbar,
  isPlatform,
} from '@ionic/react'
import { DatePicker } from '@mui/lab'
import { Checkbox, FormControlLabel, TextField } from '@mui/material'
import { close } from 'ionicons/icons'
import moment from 'moment'
import React, { useCallback, useEffect, useRef } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { DELAY_SHORT } from '../../models/constants'
import {
  defaultDatePickerProps,
  loadDefaultTemplateFullSchema,
} from '../../models/schema'
import { RootState } from '../../store/ducks'
import './PrintTicketsModal.scss'
import { printTemplate } from '../../store/templateSlice'

interface FormData {
  startDate?: Date
  endDate?: Date
  includeFields: {
    system: boolean[]
    custom: boolean[]
    itnCustom: boolean[]
    photos: boolean
  }
}

interface PrintTicketsModalProps {
  isOpen: boolean
  dismiss: () => void

  // 發送列印 request 時觸發的 callback
  onPrint: () => void
}

const systemFields = [
  'create_date',
  'creator',
  'ticket_status',
  'expected_end_date',
  'observation_start_date',
  'actual_end_date',
  'memo',
]

const PrintTicketsModal = ({
  isOpen,
  onPrint,
  dismiss,
}: PrintTicketsModalProps) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const pageRef = useRef(null)
  const template = useSelector((state: RootState) => state.newTemplate.template)
  const customFields: Field[] = template?.custom_fields_schema
    ? JSON.parse(template.custom_fields_schema).fields
    : loadDefaultTemplateFullSchema(template).custom_fields.fields
  const itnCustomFields: Field[] = template?.itn_custom_fields_schema
    ? JSON.parse(template.itn_custom_fields_schema).fields
    : loadDefaultTemplateFullSchema(template).itn_custom_fields.fields

  const { control, handleSubmit, watch } = useForm<FormData>({
    // 在畫面上所有選項預設都是已勾選的狀態
    defaultValues: {
      startDate: moment(new Date()).subtract(1, 'month').toDate() ?? new Date(),
      endDate: new Date(),
      includeFields: {
        system: systemFields.map(() => true),
        custom: customFields.map(() => true),
        itnCustom: itnCustomFields.map(() => true),
        photos: true,
      },
    },
  })
  const isValid = watch('startDate') !== null && watch('endDate') !== null

  useEffect(() => {
    if (!isOpen) return
    setTimeout(() => {
      // Workaround ...
      // @ts-ignore
      pageRef.current?.classList.remove('ion-page-invisible')
    }, DELAY_SHORT)
  }, [isOpen])

  const printTickets = useCallback(() => {
    handleSubmit((input) => {
      const {
        startDate,
        endDate,
        includeFields: { system, custom, itnCustom, photos },
      } = input
      if (!startDate || !endDate) return

      // 準備發送後端 API 的 payload
      const includeSystemFieldNames: string[] = []
      const includeCustomFieldNames: string[] = []
      const includeItnCustomFieldNames: string[] = []
      for (let i = 0; i < system.length; i++) {
        if (system[i]) {
          includeSystemFieldNames.push(systemFields[i])
        }
      }
      for (let i = 0; i < custom.length; i++) {
        if (custom[i]) {
          includeCustomFieldNames.push(customFields[i].name)
        }
      }
      for (let i = 0; i < itnCustom.length; i++) {
        if (itnCustom[i]) {
          includeItnCustomFieldNames.push(itnCustomFields[i].name)
        }
      }
      const payload = {
        key: template?.key,
        start_date: startDate,
        end_date: endDate,
        include_fields: {
          system: includeSystemFieldNames,
          custom: includeCustomFieldNames,
          internal_custom: includeItnCustomFieldNames,
          photos,
        },
      }
      dispatch(printTemplate(payload))
      onPrint()
      dismiss()
    })()
  }, [
    handleSubmit,
    customFields,
    itnCustomFields,
    template?.key,
    dispatch,
    onPrint,
    dismiss,
  ])

  return (
    <IonPage className="page-print-tickets-modal" ref={pageRef}>
      <IonHeader>
        <IonToolbar color="primary">
          <IonTitle>
            <IonButton className="logo immutable">
              {t('Suiqui Support')}
            </IonButton>
            <span className="subtitle" hidden={isPlatform('mobile')}>
              {t('Print Tickets')}
            </span>
          </IonTitle>
          <IonButtons slot="end">
            <IonButton strong fill="clear" slot="icon-only" onClick={dismiss}>
              <IonIcon size="large" icon={close}></IonIcon>
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding" scrollEvents>
        {/* 「日期區間」 */}
        <IonItem lines="none">
          <h3>{t('Date range')}</h3>
        </IonItem>

        <section className="date-picker-container">
          {/* 選擇起始日期欄位*/}
          <Controller
            render={({ field }) => {
              return (
                <DatePicker
                  label={t('Start date')}
                  value={field.value}
                  onChange={(date) => field.onChange(date)}
                  renderInput={(params) => <TextField {...params} />}
                  {...defaultDatePickerProps}
                />
              )
            }}
            name="startDate"
            control={control}
          />
          <span> ~ </span>

          {/* 選擇結束日期欄位*/}
          <Controller
            render={({ field }) => {
              return (
                <DatePicker
                  label={t('End date')}
                  value={field.value}
                  onChange={(date) => field.onChange(date)}
                  renderInput={(params) => <TextField {...params} />}
                  {...defaultDatePickerProps}
                />
              )
            }}
            name="endDate"
            control={control}
          />
        </section>

        {/* 系統選單 */}
        <section>
          <h3>{t('System Fields')}</h3>
          <div className="checkboxes">
            {systemFields.map((label, index) => (
              <FormControlLabel
                label={`${t(label)}`}
                key={label}
                className="checkbox-label"
                control={
                  <Controller
                    name={`includeFields.system.${index}` as const}
                    control={control}
                    render={({ field: { value, ...field } }) => (
                      <Checkbox {...field} checked={!!value} />
                    )}
                  />
                }
              />
            ))}
            <FormControlLabel
              label={`${t('Photo')}`}
              className="checkbox-label"
              control={
                <Controller
                  name={`includeFields.photos` as const}
                  control={control}
                  render={({ field: { value, ...field } }) => (
                    <Checkbox {...field} checked={!!value} />
                  )}
                />
              }
            />
          </div>
        </section>

        {/* 客戶選單 */}
        <section>
          <h3>{t('Custom Fields')}</h3>
          <div className="checkboxes">
            {customFields.map(({ label, name }, index) => (
              <FormControlLabel
                label={label}
                key={name}
                className="checkbox-label"
                control={
                  <Controller
                    name={`includeFields.custom.${index}` as const}
                    control={control}
                    render={({ field: { value, ...field } }) => (
                      <Checkbox {...field} checked={!!value} />
                    )}
                  />
                }
              />
            ))}
          </div>
        </section>

        {/* 內部選單 */}
        <section>
          <h3>{t('Internal Custom Fields')}</h3>
          <div className="checkboxes">
            {itnCustomFields.map(({ label, name }, index) => (
              <FormControlLabel
                label={label}
                key={name}
                className="checkbox-label"
                control={
                  <Controller
                    name={`includeFields.itnCustom.${index}` as const}
                    control={control}
                    render={({ field: { value, ...field } }) => (
                      <Checkbox {...field} checked={!!value} />
                    )}
                  />
                }
              />
            ))}
          </div>
        </section>

        {/* 列印按鈕 */}
        <div className="print-btn-container">
          <IonButton color="primary" disabled={!isValid} onClick={printTickets}>
            <IonIcon slot="start" icon={print} />
            <IonLabel>{t('Print')}</IonLabel>
          </IonButton>
        </div>
      </IonContent>
    </IonPage>
  )
}

export default PrintTicketsModal
