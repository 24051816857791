import FormTemplateCommonProps from '@data-driven-forms/common/form-template'
import {
  FormSpy,
  Meta,
  UseFieldApiConfig,
} from '@data-driven-forms/react-form-renderer'
import componentTypes from '@data-driven-forms/react-form-renderer/component-types'
import FormRenderer from '@data-driven-forms/react-form-renderer/form-renderer'
import useFieldApi from '@data-driven-forms/react-form-renderer/use-field-api'
import useFormApi from '@data-driven-forms/react-form-renderer/use-form-api'
import validatorTypes from '@data-driven-forms/react-form-renderer/validator-types'
import Validators from '@data-driven-forms/react-form-renderer/validators'
import { IonButton, IonInput, IonItem, IonLabel, IonList } from '@ionic/react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { EMAIL_OR_MOBILE_PHONE_NUMBER } from '../../models/regex'

type TextFieldProps = {} & UseFieldApiConfig
const TextField = (props: TextFieldProps) => {
  const {
    customProp,
    label,
    input,
    isRequired,
    isDisabled,
    placeholder,
    meta,
    FieldArrayProvider,
    dataType,
    validateOnMount,
    ...rest
  } = useFieldApi(props)
  const invalid = validationError(meta, validateOnMount)
  return (
    <div>
      <IonItem>
        <IonLabel position="floating" style={requiredStyle}>
          {isRequired && <span style={errorStyle}>*&nbsp;</span>}
          {label}
        </IonLabel>
        <IonInput
          name={props.name}
          type={props.type as any}
          placeholder={placeholder}
          value={input.value}
          required={isRequired}
          disabled={isDisabled}
          color={invalid || !meta.valid ? 'danger' : 'primary'}
          onIonInput={(e) => input.onChange(e)}
          {...rest}
        ></IonInput>
        {meta.touched && meta.error && (
          <small style={paragraphStyle}>{meta.error}</small>
        )}
      </IonItem>
    </div>
  )
}

const customComponentMapper = {
  [componentTypes.TEXT_FIELD]: TextField,
}

const validationError = (meta: Meta<any>, validateOnMount?: boolean) => {
  if (validateOnMount) {
    return meta.error || meta.submitError
  }

  return meta.touched && (meta.error || meta.submitError)
}

const paragraphStyle = {
  marginTop: 0,
  marginBottom: 4,
}

const requiredStyle = {
  marginLeft: 2,
}

const errorStyle = {
  color: 'orangered',
}

type LoginFormTemplateProp = {} & FormTemplateCommonProps
const FormTemplate = ({
  schema,
  formFields,
  submitLabel,
}: LoginFormTemplateProp) => {
  const { handleSubmit, getState } = useFormApi()
  const { submitting, valid, pristine } = getState()

  return (
    <>
      <form onSubmit={handleSubmit}>
        <IonList className="form-container">
          <div className="logo-welcome" />
          {schema.title}
          {formFields}
          <FormSpy>
            {() => (
              <IonButton
                type="submit"
                expand="full"
                className="login-btn"
                disabled={submitting || !valid}
              >
                {submitLabel}
              </IonButton>
            )}
          </FormSpy>
        </IonList>
      </form>
    </>
  )
}

const propsAreEqual = (prevProps: any, nextProps: any) => {
  const prevValue = JSON.stringify(prevProps.form?.getState())
  const nextValue = JSON.stringify(nextProps.form?.getState())
  return prevValue === nextValue
}

const LoginForm = ({ handleSubmit }: { handleSubmit: Function }) => {
  const { t } = useTranslation()

  //@ts-ignore
  Validators.messages = {
    //@ts-ignore
    ...Validators.messages,
    required: t('Required'),
  }

  const LoginFormTemplate = (props: LoginFormTemplateProp) => (
    <FormTemplate {...props} submitLabel={t('Log In')} />
  )

  const schema = {
    fields: [
      {
        component: componentTypes.TEXT_FIELD,
        name: 'username',
        label: t('Email or phone number'),
        isRequired: true,
        validate: [
          {
            type: validatorTypes.REQUIRED,
          },
          {
            type: validatorTypes.PATTERN,
            pattern: EMAIL_OR_MOBILE_PHONE_NUMBER,
            message: '',
          },
        ],
      },
      {
        component: componentTypes.TEXT_FIELD,
        name: 'password',
        label: t('Password'),
        type: 'password',
        isRequired: true,
        validate: [
          {
            type: validatorTypes.REQUIRED,
          },
        ],
      },
    ],
  }

  return (
    <FormRenderer
      schema={schema}
      componentMapper={customComponentMapper}
      FormTemplate={LoginFormTemplate}
      onSubmit={(values, formApi) => {
        handleSubmit(values)
        formApi.reset()
      }}
      className="form-container"
    />
  )
}

export default React.memo(LoginForm, propsAreEqual)
