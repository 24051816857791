import { RefresherEventDetail } from '@ionic/core'
import {
  date,
  id,
  key,
  phone_number,
  schema,
  schema_form_data,
} from './base'
import { IUser } from './user'
import { INotificationBellBase } from './notificationBell'

type ServiceApiStatus = 'inactive' | 'active'

// Service api = 「快速開單網址」 + 「維修清單網址」
export interface ServiceApi {
  active_status: ServiceApiStatus
  'ticket-create-url': string
  'ticket-list-url': string
  token: string
}

export interface ITemplateBase {
  key: id
  name: string
  serv_ppl_name: string | null
  serv_ppl_phone: phone_number | null
  serv_ppl_2_name: string | null
  serv_ppl_2_phone: phone_number | null
  active_status: EActiveStatus
  create_datetime: date | null
  modified_datetime: date | null

  // 客戶選單
  custom_fields_schema: schema | null
  custom_fields_data: schema_form_data | null

  // 內部選單
  itn_custom_fields_schema: schema | null
  itn_custom_fields_data: schema_form_data | null
  send_sms_to_user: boolean
  api: ServiceApi
  is_line_active: boolean
  line_app_verification_code: string | null
  signatures: Signature[]
}
export interface ITemplate extends ITemplateBase, ITemplatePermission {
  // templateAPI.list params include_stats 時回傳的統計資料
  // Notice: 不包含 template key
  stats?: Omit<ITemplateDashboard, 'key'>
  notification_bells: ITemplateNotificationBell[]
}

export interface ITemplateDashboard {
  key: id
  total_tickets: number
  total_tickets_unfinished: number // 未完成工單數 = 待確認 + 備料中 + 進行中 + 觀察中
  total_tickets_done: number
  total_tickets_last_month: number // 上月新增，目前前端沒在用，但後端會傳
  total_tickets_new_month: number
  total_tickets_overdue: number // 逾期工單數
}

export enum ETemplateFunction {
  template_create = 'template_create',
  ticket_create = 'ticket_create',
  template_update = 'template_update',
  template_archive = 'template_archive',
  permission_read = 'permission_read',
}

export interface ITemplatePermission {
  function_list: ETemplateFunction[]
  permission: {
    key: id

    // protected === true 時代表「自訂權限」; false 代表「所有團隊成員」
    protected: boolean

    writers: IUser[]
    readers: IUser[]
  } | null
}

export enum EActiveStatus {
  active = 'active',
  archive = 'archive',
}
export interface IList {
  key: id
  cursor?: key
  page_size?: number
  event?: CustomEvent<void> | CustomEvent<RefresherEventDetail>
  reload?: boolean
  preloadMode?: boolean
}

export interface IEvent {
  key: id
  event?: CustomEvent<void> | CustomEvent<RefresherEventDetail>
}

export interface Signature {
  key: id
  name: string
  can_print: boolean
  signed: boolean
  value?: string // 序列化後的簽名
}

export interface ITemplateNotificationBell extends INotificationBellBase {
  name: string // 提醒的名稱
  day_offset: number // 開單日的幾天後提醒  (0: 當天, 1: 隔天, 2: 隔兩天, ...)
  notify_date?: never
  is_active?: never
}
