import {
  IonAlert,
  IonBadge,
  IonButton,
  IonButtons,
  IonCheckbox,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonItem,
  IonItemDivider,
  IonLabel,
  IonList,
  IonPage,
  IonSpinner,
  IonThumbnail,
  IonTitle,
  IonToast,
  IonToolbar,
} from '@ionic/react'
import { getFileTypeIconProps } from '@uifabric/file-type-icons'
import { caretUp, close } from 'ionicons/icons'
import { Icon } from 'office-ui-fabric-react/lib/Icon'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { inFunctionList } from '../helpers/base'
import { getFallbackImage, getFileExt, getImageUrl } from '../helpers/file'
import { clone, makeKeys } from '../helpers/util'
import { DELAY_TOAST } from '../models/constants'
import {
  ESuiquiFileFunction,
  ESuiquiFileType,
  IFile,
  ISuiquiFile,
} from '../models/suiquiFile'
import { removeFiles } from '../store/epics/ticket'
import './FileRemoveModal.scss'

const FileRemoveModal: React.FC<any> = ({
  isOpen,
  dismiss,
  items = [],
  name = '',
}: {
  isOpen: boolean
  dismiss: any
  items: (ISuiquiFile & { path: string; index: number; checked?: boolean })[]
  name: string
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const contentRef = useRef(null)
  const pageRef = useRef(null)
  const [isPageLoading, setIsPageLoading] = useState(true)
  const [currentFiles, setCurrentFiles] = useState<
    (ISuiquiFile & { checked: boolean })[]
  >([])
  const [isProcessing, setIsProcessing] = useState(false)
  const [checkedCount, setCheckedCount] = useState(0)
  const [showFileRemoveToast, setShowFileRemoveToast] = useState(false)
  const [showFileRemoveAlert, setShowFileRemoveAlert] = useState(false)

  const ticket = useSelector((state: any) => state.ticket)

  useMemo(() => {
    if (!isOpen) return

    setTimeout(() => {
      // Workaround ...
      // @ts-ignore
      pageRef.current?.classList.remove('ion-page-invisible')
    }, 300)
  }, [isOpen])

  useMemo(() => {
    if (isOpen) {
      setCurrentFiles(
        clone(items.filter((item) => item.type !== ESuiquiFileType.directory))
      )
      setTimeout(() => {
        setIsPageLoading(false)
      }, 1000)
    }
  }, [isOpen, setIsPageLoading, items])

  const scrollToTop = () => {
    // @ts-ignore
    contentRef.current!.scrollToTop()
  }

  const countChecked = useCallback(() => {
    return currentFiles.filter((item) => !!item?.checked).length || 0
  }, [currentFiles])

  const handleCheckItem = (item: ISuiquiFile & { checked?: boolean }) => {
    item.checked = !item.checked
    setCheckedCount(countChecked())
  }

  const removeFilesAction = (params: any) => {
    setIsProcessing(true)

    const files = currentFiles.filter((item) => item.checked)

    dispatch(
      removeFiles.request({
        files: makeKeys(files),
      })
    )
  }

  const getFileExtCallback = useCallback((file: IFile) => {
    return getFileExt(file) || 'file'
  }, [])

  const cancel = () => {
    setCurrentFiles([])
    dispatch(removeFiles.cancel())
    dismiss()
  }

  useEffect(() => {
    if (!ticket.filesRemoved) return
    setShowFileRemoveToast(true)
    setCheckedCount(0)
    setCurrentFiles(currentFiles.filter((item) => !item.checked))
  }, [
    ticket.filesRemoved,
    setShowFileRemoveToast,
    setCurrentFiles,
    setCheckedCount,
    currentFiles,
  ])

  return (
    <IonPage className="page-file-remove-modal" ref={pageRef}>
      <IonHeader>
        <IonToolbar color="primary">
          <IonTitle>
            <IonButton class="logo immutable">{t('Suiqui Support')}</IonButton>
            <span className="subtitle">{t('Remove Files')}</span>
          </IonTitle>
          <IonButtons slot="end">
            <IonButton
              strong
              fill="clear"
              slot="icon-only"
              disabled={isProcessing}
              onClick={() => cancel()}
            >
              <IonIcon size="large" icon={close}></IonIcon>
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent ref={contentRef} scrollEvents={true}>
        {isPageLoading ? (
          <div className="ion-text-center centered">
            <IonSpinner color="primary" name="crescent"></IonSpinner>
          </div>
        ) : (
          <IonList>
            <IonItemDivider color="light">
              <IonBadge color="warning" slot="start">
                {currentFiles?.length}
              </IonBadge>
              <IonLabel color="primary">{name}</IonLabel>
            </IonItemDivider>
            {currentFiles.map(
              (item: ISuiquiFile & { checked?: boolean }, index: number) => {
                return (
                  <IonItem key={index} onClick={() => handleCheckItem(item)}>
                    <IonCheckbox
                      checked={item?.checked}
                      disabled={
                        isProcessing ||
                        !inFunctionList(
                          ESuiquiFileFunction.file_delete,
                          item.function_list
                        )
                      }
                      slot="start"
                    ></IonCheckbox>
                    {item?.is_image ? (
                      <IonThumbnail slot="start">
                        <img
                          alt=""
                          src={`${getImageUrl(item, 48, 'c')}`}
                          onError={getFallbackImage}
                        ></img>
                      </IonThumbnail>
                    ) : (
                      <IonThumbnail slot="start">
                        <Icon
                          {...getFileTypeIconProps({
                            extension: getFileExtCallback(item),
                            size: 48,
                            imageFileType: 'svg',
                          })}
                        />
                      </IonThumbnail>
                    )}
                    <IonLabel className="ion-text-wrap">{item.name}</IonLabel>
                  </IonItem>
                )
              }
            )}
            <div className="ion-text-center">
              <IonButton
                color="danger"
                fill="clear"
                size="large"
                disabled={isProcessing}
                onClick={scrollToTop}
              >
                <IonIcon slot="icon-only" icon={caretUp} />
              </IonButton>
            </div>
          </IonList>
        )}
        <IonAlert
          keyboardClose={true}
          isOpen={showFileRemoveAlert}
          onDidDismiss={() => setShowFileRemoveAlert(false)}
          header={t('Notice')}
          message={t('Click OK to remove those items.')}
          buttons={[
            {
              text: t('Cancel'),
              role: 'cancel',
              cssClass: 'cancel',
              handler: () => {},
            },
            {
              text: t('OK'),
              cssClass: 'ok',
              handler: (params) => {
                removeFilesAction({ ...params })
              },
            },
          ]}
        />
        <IonToast
          isOpen={showFileRemoveToast}
          onWillPresent={() => {
            dispatch(removeFiles.cancel())
          }}
          onDidDismiss={() => {
            setShowFileRemoveToast(false)
            setIsProcessing(false)
            dismiss()
          }}
          message={t('“{{target}}” has been removed successfully.', {
            target: `${t('Files')}`,
          })}
          duration={DELAY_TOAST}
        />
      </IonContent>
      <IonFooter>
        <IonToolbar color="primary">
          <IonButtons slot="end">
            <IonSpinner
              hidden={!isProcessing}
              color="light"
              name="dots"
              className="ion-padding-end"
            ></IonSpinner>
            <IonButton
              color="danger"
              disabled={!checkedCount || isProcessing}
              onClick={() => {
                setShowFileRemoveAlert(true)
              }}
            >
              {t('Remove')} ({checkedCount})
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonFooter>
    </IonPage>
  )
}

export default FileRemoveModal
