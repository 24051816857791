import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonPage,
  IonSpinner,
  IonTitle,
  IonToolbar,
  isPlatform,
} from '@ionic/react'
import { caretUp, checkmark, close } from 'ionicons/icons'
import React, { useCallback, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { parseDate } from '../../helpers/util'
import { DELAY_SHORT } from '../../models/constants'
import { FormData } from '../../models/formBuilder'
import { ITicket } from '../../models/ticket'
import FilterForm from './FilterForm'
import './TicketFilterModal.scss'

interface TicketFilterModalProps {
  item: ITicket
  isOpen: boolean
  dismiss: Function
  initialValues: any
}

const TicketFilterModal = ({
  item,
  isOpen,
  dismiss,
  initialValues,
}: TicketFilterModalProps) => {
  const { t } = useTranslation()
  const contentRef = useRef(null)
  const pageRef = useRef(null)
  const [isPageLoading, setIsPageLoading] = useState(true)
  const [formData, setFormData] = useState<FormData | undefined>()

  // 將輸入的篩選選項轉換成關鍵字
  const done = useCallback(() => {
    const { values } = formData as any
    const custom_fields_data = Object.values(
      Object.fromEntries(
        Object.entries(values as any).filter(([key, val]) =>
          key.startsWith('custom_fields_')
        )
      )
    )
    const itn_custom_fields_data = Object.values(
      Object.fromEntries(
        Object.entries(values as any).filter(([key, val]) =>
          key.startsWith('itn_custom_fields_')
        )
      )
    )
    const start_date = parseDate(values.start_date)
    const end_date = parseDate(values.end_date)
    const kw_custom_fields =
      custom_fields_data.join(' ').replaceAll(',', ' ').trim() || undefined
    const kw_itn_custom_fields =
      itn_custom_fields_data.join(' ').replaceAll(',', ' ').trim() || undefined

    dismiss({
      formData,
      start_date,
      end_date,
      kw_custom_fields,
      kw_itn_custom_fields,
    })
  }, [dismiss, formData])

  // 意義不明
  useMemo(() => {
    if (!isOpen) return
    setTimeout(() => {
      // Workaround ...
      // @ts-ignore
      pageRef.current?.classList.remove('ion-page-invisible')
    }, DELAY_SHORT)
    setIsPageLoading(false)
  }, [isOpen])

  const scrollToTop = useCallback(() => {
    // @ts-ignore
    contentRef.current!.scrollToTop(DELAY_SHORT)
  }, [contentRef])

  // 監聽使用者輸入的篩選選項
  const handleFormChange = useCallback((values: any) => {
    setFormData(values)
  }, [])

  return (
    <IonPage className="page-ticket-filter-modal" ref={pageRef}>
      <IonHeader>
        <IonToolbar color="primary">
          <IonTitle>
            <IonButton className="logo immutable">
              {t('Suiqui Support')}
            </IonButton>
            <span className="subtitle" hidden={isPlatform('mobile')}>
              {t('Ticket Filter')}
            </span>
          </IonTitle>
          <IonButtons slot="end">
            <IonButton
              strong
              fill="clear"
              slot="icon-only"
              onClick={() => dismiss()}
            >
              <IonIcon size="large" icon={close}></IonIcon>
            </IonButton>
            <IonButton
              strong
              fill="clear"
              slot="icon-only"
              onClick={done}
            >
              <IonIcon size="large" icon={checkmark}></IonIcon>
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent
        ref={contentRef}
        scrollEvents={true}
        className="ion-padding bg"
      >
        {isPageLoading ? (
          <div className="ion-text-center centered">
            <IonSpinner color="primary" name="crescent"></IonSpinner>
          </div>
        ) : (
          <div>
            <FilterForm
              item={item}
              initialValues={initialValues}
              onChange={handleFormChange}
            />

            {/* 回到上面按鈕 */}
            <div className="ion-text-center">
              <IonButton
                color="danger"
                fill="clear"
                size="large"
                onClick={scrollToTop}
              >
                <IonIcon slot="icon-only" icon={caretUp} />
              </IonButton>
            </div>
          </div>
        )}
      </IonContent>
    </IonPage>
  )
}

export default TicketFilterModal
