import { isPlatform } from '@ionic/core'
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonModal,
  IonPage,
  IonRadio,
  IonRadioGroup,
  IonTitle,
  IonToast,
  IonToolbar,
  useIonLoading,
} from '@ionic/react'
import { add, close, informationCircleOutline } from 'ionicons/icons'
import { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import LoadingSpinner from '../../components/LoadingSpinner'
import Tooltip from '../../components/Tooltip'
import useModal from '../../hooks/useModal'
import useToast from '../../hooks/useToast'
import { useRemoveIonPageInvisible } from '../../hooks/useRemoveIonPageInvisible'
import { key } from '../../models/base'
import { ITemplate } from '../../models/template'
import { IUser } from '../../models/user'
import { RootState } from '../../store/ducks'
import {
  fetchTemplatePermissionList,
  updateTemplatePermission,
  clearToastMessage,
} from '../../store/epics/template'
import { TemplateState } from '../../store/templateSlice'
import MemberListModal from '../MemberListModal'
import { UserItem } from '../../components/UserItem'
import './PermissionModal.scss'

interface PermissionModalProps {
  template: ITemplate
  dismiss: () => void
}

type UpdatePermissionParams = Partial<{
  protected: boolean
  add_writers: key[]
  add_readers: key[]
  remove_writers: key[]
  remove_readers: key[]
  toastMsg: string
}>

/**
 * 更改專案權限的 modal
 */
export default function PermissionModal({
  template,
  dismiss,
}: PermissionModalProps) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const pageRef = useRemoveIonPageInvisible()

  /* State */
  const { permission, isDone, toastMessage, error } = useSelector(
    // workaround until RootState is definitely typed
    (state: RootState) => state.template as TemplateState
  )
  // 更新權限時顯示的 loading indicator
  const [presentLoading, dismissLoading] = useIonLoading()
  // 更新權限後的 toast notification
  const { showToast, toastProps } = useToast()

  /* Modals */
  // 新增編輯者 Modal
  const [isShowAddWritersModal, openAddWritersModal, dismissAddWritersModal] =
    useModal()

  // 新增檢視者 Modal
  const [isShowAddReadersModal, openAddReadersModal, dismissAddReadersModal] =
    useModal()

  /* Effects */
  // Init, 取得專案的用戶權限列表
  useEffect(() => {
    dispatch(fetchTemplatePermissionList.request({ key: template.key }))
  }, [template.key, dispatch])

  // 更新權限成功/失敗時 dismiss loading indicator
  useEffect(() => {
    // 在專案的用戶權限列表尚未讀取成功，或更新權限尚未完成時不動作
    if (!permission || !isDone) return
    dismissLoading()
  }, [permission, isDone, dismissLoading])

  // 顯示更新權限成功 toast
  useEffect(() => {
    if (!toastMessage) return

    // toast dismiss 時移除在 store 中儲存的 toastMessage
    showToast(toastMessage, () => dispatch(clearToastMessage()))
  }, [showToast, toastMessage, dispatch])

  // 顯示錯誤訊息 toast
  useEffect(() => {
    if (!error) return

    // toast dismiss 時移除在 store 中儲存的 toastMessage
    showToast(t('Failed to update permission'), () =>
      dispatch(clearToastMessage())
    )
  }, [showToast, error, dispatch, t])

  /* Functions */
  // 更新權限
  const updatePermission = useCallback(
    (params: UpdatePermissionParams) => {
      dispatch(
        updateTemplatePermission.request({
          key: template.key,
          ...params,
        })
      )
    },
    [template.key, dispatch]
  )

  // 設定所有團隊成員 or 自訂權限
  const handleRadioButtonClick = (value: boolean) => {
    // 當前專案權限等於點擊的權限時 early return
    if (permission?.protected === value) return

    presentLoading()
    updatePermission({ protected: value })
  }

  // 新增編輯者
  const handleAddWritersModalDismiss = (value: { users: IUser[] }) => {
    dismissAddWritersModal()

    // 當 modal dismiss 時沒有傳回 users 時 early return
    if (!value?.users) return

    presentLoading()
    updatePermission({
      add_writers: value.users.map((user) => user.key),
      toastMsg: t('Update write permission successfully'),
    })
  }

  // 新增檢視者
  const handleAddReadersModalDismiss = (value: { users: IUser[] }) => {
    dismissAddReadersModal()

    // 當 modal dismiss 時沒有傳回 users 時 early return
    if (!value?.users) return

    presentLoading()
    updatePermission({
      add_readers: value.users.map((user) => user.key),
      toastMsg: t('Update read permission successfully'),
    })
  }

  // 移除編輯者
  const handleRemoveWriterBtnOnClick = ({ key, displayname }: IUser) => {
    presentLoading()
    updatePermission({
      remove_writers: [key],
      toastMsg: t("Remove '{{name}}' successfully", { name: displayname }),
    })
  }

  // 移除檢視者
  const handleRemoveReaderBtnOnClick = ({ key, displayname }: IUser) => {
    presentLoading()
    updatePermission({
      remove_readers: [key],
      toastMsg: t("Remove '{{name}}' successfully", { name: displayname }),
    })
  }

  return (
    <IonPage ref={pageRef} className="page-permission-modal">
      <IonHeader>
        <IonToolbar color="primary">
          {/* Suiqui logo & 標題 */}
          <IonTitle>
            <IonButton className="logo immutable">
              {t('Suiqui Support')}
            </IonButton>
            <span className="subtitle" hidden={isPlatform('mobile')}>
              {t('Project Permission')}
            </span>
          </IonTitle>

          {/* close button */}
          <IonButtons slot="end">
            <IonButton strong fill="clear" slot="icon-only" onClick={dismiss}>
              <IonIcon size="large" icon={close}></IonIcon>
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent className="ion-padding-top">
        {!permission ? (
          // 讀取中
          <LoadingSpinner />
        ) : (
          <>
            {/* 專案名稱 */}
            <IonTitle>{template.name}</IonTitle>

            <IonList lines="none" className="ion-padding bg">
              {/* protected === true 時代表「自訂權限」; false 代表「所有團隊成員」
                  Notice: 因使用 IonRadioGroup 的 onIonChange event 在每次 page onLoad 時都會被觸發，改用 IonRadio 的 onClick event 避免發出多餘的 API request
              */}
              <IonRadioGroup value={permission.protected}>
                {/* 所有團隊成員 radio button */}
                <IonItem>
                  <IonLabel>{t('All team members')}</IonLabel>
                  <IonRadio
                    slot="start"
                    value={false}
                    onClick={() => handleRadioButtonClick(false)}
                  />
                  <Tooltip
                    className="all-member-tooltip"
                    position="bottom"
                    content={t('All team members are editors')}
                  >
                    <IonIcon icon={informationCircleOutline} color="medium" />
                  </Tooltip>
                </IonItem>

                {/* 自訂權限 radio button */}
                <IonItem>
                  <IonLabel>{t('Custom permissions')}</IonLabel>
                  <IonRadio
                    slot="start"
                    value={true}
                    onClick={() => handleRadioButtonClick(true)}
                  />
                </IonItem>
              </IonRadioGroup>
            </IonList>

            {/* 用戶權限列表，僅在「自訂權限」顯示 */}
            <IonList hidden={!permission.protected} className="ion-padding bg">
              <IonListHeader color="primary">
                {/* 編輯者權限 */}
                <IonLabel>{t('Permission write')}</IonLabel>
                <IonButtons>
                  <IonButton strong color="light" onClick={openAddWritersModal}>
                    <IonIcon slot="icon-only" icon={add} />
                  </IonButton>
                </IonButtons>
              </IonListHeader>
              {permission.writers.map((writer, idx) => (
                <UserItem
                  key={writer.key}
                  isRemovable
                  isLast={idx === permission.writers.length - 1}
                  user={writer}
                  onRemoveBtnClick={handleRemoveWriterBtnOnClick}
                />
              ))}
              {/* 沒有成員（編輯者） */}
              {!permission.writers.length && (
                <IonItem lines="none">
                  <IonLabel className="ion-text-center">
                    {t('No Items')}
                  </IonLabel>
                </IonItem>
              )}

              {/* 檢視者權限 */}
              <IonListHeader color="primary" className="ion-margin-top">
                <IonLabel>{t('Permission read')}</IonLabel>
                <IonButtons>
                  <IonButton strong color="light" onClick={openAddReadersModal}>
                    <IonIcon slot="icon-only" icon={add} />
                  </IonButton>
                </IonButtons>
              </IonListHeader>
              {permission.readers.map((reader, idx) => (
                <UserItem
                  key={reader.key}
                  isRemovable
                  isLast={idx === permission.readers.length - 1}
                  user={reader}
                  onRemoveBtnClick={handleRemoveReaderBtnOnClick}
                />
              ))}
              {/* 沒有成員（檢視者） */}
              {!permission.readers.length && (
                <IonItem lines="none">
                  <IonLabel className="ion-text-center">
                    {t('No Items')}
                  </IonLabel>
                </IonItem>
              )}
            </IonList>
          </>
        )}
      </IonContent>

      {/* 成員選單 modal for 新增編輯者 */}
      <IonModal isOpen={isShowAddWritersModal}>
        {isShowAddWritersModal && (
          <MemberListModal
            isMultiMode
            isActiveOnly
            filterOut={permission?.writers} // 不顯示擁有編輯者權限的用戶
            dismiss={handleAddWritersModalDismiss}
          />
        )}
      </IonModal>

      {/* 成員選單 modal for 新增檢視者 */}
      <IonModal isOpen={isShowAddReadersModal}>
        {isShowAddReadersModal && (
          <MemberListModal
            isMultiMode
            isActiveOnly
            filterOut={permission?.readers} // 不顯示擁有檢視者權限的用戶
            dismiss={handleAddReadersModalDismiss}
          />
        )}
      </IonModal>

      {/* 更新權限後的 toast notification */}
      <IonToast {...toastProps} />
    </IonPage>
  )
}
