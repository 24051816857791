import {
  IonAlert,
  IonAvatar,
  IonBadge,
  IonButton,
  IonButtons,
  IonChip,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonNote,
  IonPage,
  IonProgressBar,
  IonReorder,
  IonText,
  IonTitle,
  IonToast,
  IonToolbar,
  isPlatform,
  useIonViewWillEnter,
  useIonViewWillLeave,
} from '@ionic/react'
import useComponentSize from '@rehooks/component-size'
import classNames from 'classnames'
import {
  caretUp,
  checkmarkCircle,
  checkmarkDone,
  close,
  closeCircle,
  gitCommit,
  refreshCircle,
  cloudUpload,
} from 'ionicons/icons'
import React, { useCallback, useMemo, useRef, useState } from 'react'
import { useBeforeunload } from 'react-beforeunload'
import { useDropzone } from 'react-dropzone'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import {
  getFallbackImage,
  getFileExt,
  getFilenameExt,
  getImageUrl,
  getProgressBarColor,
  getProgressKey,
  getProgressNumber,
  hashCode,
} from '../helpers/file'
import { DELAY, MAX_UPLOAD_FILES_DISPLAY } from '../models/constants'
import { IFile, ISuiquiFile } from '../models/suiquiFile'
import { ETicketFileLabel } from '../models/ticket'
import { RootState } from '../store/ducks'
import { uploadCommentFilesCancelAll } from '../store/epics/comment'
import { uploadFiles, uploadFilesCancelAll } from '../store/epics/ticket'
import './UploadModal.scss'

const UploadModal: React.FC<any> = ({
  isOpen,
  isPending,
  isSaveMode,
  isBundleFiles,
  dismiss,
  sFile,
  files = [],
  label,
  state,
  onStartUpload,
}: {
  isOpen: boolean
  isPending: boolean
  isSaveMode: boolean
  isBundleFiles?: boolean
  dismiss: any
  sFile: ISuiquiFile
  files: (File & { path: string })[]
  label?: ETicketFileLabel
  state: RootState[keyof RootState]
  onStartUpload?: Function
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const contentRef = useRef(null)
  const fileInputRef = useRef<HTMLInputElement>(null)
  const pageRef = useRef(null)
  const [showCloseAlert, setShowCloseAlert] = useState(false)
  const [isUploading, setIsUploading] = useState(false)
  const [pendingFiles, setPendingFiles] = useState([])
  const [checked, setChecked] = useState(false)
  const { width, height } = useComponentSize(pageRef)
  const [, updateState] = useState<any>()
  const [isDone, setIsDone] = useState(false)
  const [showUnexpectedExtAlert, setShowUnexpectedExtAlert] = useState(false)
  const [showUploadFailedToast, setShowUploadFailedToast] = useState(false)
  const [showAll, setShowAll] = useState(false)
  const [bundleFilesMode, setBundleFilesMode] = useState(false)

  useIonViewWillEnter(() => {
    if (!files?.length) {
      dispatch(uploadFilesCancelAll({}))
      dispatch(uploadCommentFilesCancelAll({}))
    }
  }, [dispatch])

  useIonViewWillLeave(() => {
    setShowUploadFailedToast(false)
  }, [setShowUploadFailedToast])

  const forceUpdate = useCallback(() => updateState({}), [])

  const onBeforeunload = (event: any) => {
    event.preventDefault()
  }

  useBeforeunload((event) => {
    if (isOpen) {
      return onBeforeunload(event)
    }
  })

  const cancelAll = useCallback(() => {
    setTimeout(() => {
      dispatch(uploadFilesCancelAll({}))
    }, 500)

    dismiss({
      isUploading,
      isDone,
      label,
      [`${label}`]: state[`${label}`],
    })

    setIsDone(false)
  }, [dispatch, dismiss, isUploading, isDone, label, state, setIsDone])

  useMemo(() => {
    if (!isOpen) return
    if (isUploading) return

    if (files?.length) {
      setPendingFiles(files as [])
    }
  }, [isOpen, setPendingFiles, files, isUploading])

  useMemo(() => {
    if (!isOpen) return
    if (isUploading) return

    if (!state) {
      cancelAll()
    }

    if (isBundleFiles) {
      setBundleFilesMode(true)
    }

    setTimeout(() => {
      // Workaround ...
      // @ts-ignore
      pageRef.current?.classList.remove('ion-page-invisible')
    }, 300)
  }, [isOpen, isUploading, state, cancelAll, isBundleFiles, setBundleFilesMode])

  useMemo(() => {
    if (!state.error) return
    if (isDone) return
    setIsDone(true)
    setShowUploadFailedToast(true)
  }, [isDone, setIsDone, state.error])

  const cancelUpload = (pIndex: number) => {
    dispatch(
      uploadFiles.cancel({
        hash: pIndex,
      })
    )
  }

  const openCloseAlert = (shown: boolean, callback?: Function) => {
    if (shown) {
      setShowCloseAlert(true)
    } else {
      callback && callback()
    }
  }

  const getTotalProgressNumberCallback = useCallback((pFiles) => {
    if (!pFiles) return 0
    let total = 0
    const keys = Object.keys(pFiles)
    keys.forEach((key) => {
      const n = getProgressNumber(pFiles[key])
      total = total + n
    })
    return keys.length ? total / keys.length : 0
  }, [])

  const getMoreFilesCountCallback = useCallback((files) => {
    return files?.length - MAX_UPLOAD_FILES_DISPLAY
  }, [])

  const getFileCountTextCallback = useCallback(
    (files) => {
      return `${state.doneCount}/${files?.length}`
    },
    [state.doneCount]
  )

  const getProgressNumberCallback = useCallback((pFile) => {
    return getProgressNumber(pFile)
  }, [])

  const scrollToTop = () => {
    // @ts-ignore
    contentRef.current!.scrollToTop()
  }

  useMemo(() => {
    if (!isUploading) return
    if (pendingFiles?.length && state.doneCount >= pendingFiles?.length) {
      setIsDone(true)
    }
  }, [state.doneCount, isUploading, pendingFiles, setIsDone])

  const onDrop = useCallback(
    (acceptedFiles) => {
      if (isUploading) return
      setIsDone(false)
      setPendingFiles([...pendingFiles, ...acceptedFiles] as [])
    },
    [isUploading, setPendingFiles, pendingFiles, setIsDone]
  )

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop,
    noClick: true,
    disabled: isUploading || !isPlatform('desktop'),
  })

  const baseStyle = {
    border: '5px solid transparent',
  }

  const activeStyle = {
    border: '5px solid transparent',
  }

  const acceptStyle = {
    border: '5px dashed var(--ion-color-warning)',
  }

  const rejectStyle = {
    border: '5px dashed var(--ion-color-danger)',
  }

  const getHeight = () => {
    if (isPending && pendingFiles?.length) {
      return height - 168
    }

    if (isPending && !pendingFiles?.length) {
      return height - 112
    }

    return height - 56
  }

  const style = {
    ...baseStyle,
    ...(isDragActive ? activeStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {}),
    minHeight: getHeight(),
  }

  const handleStartUpload = () => {
    if (isUploading) return
    if (onStartUpload && pendingFiles?.length) {
      const hashFunc = (file: IFile, index: number) =>
        hashCode(`${file.name}-${index}`)
      const files = pendingFiles
      const filenames = (pendingFiles || []).map((file: File) => {
        return getFileName(file)
      })

      setIsUploading(true)

      setTimeout(() => {
        onStartUpload({ files, filenames, label, hashFunc })
      }, DELAY)
    }
  }

  const remove = (index: number) => {
    if (pendingFiles?.length) {
      pendingFiles.splice(index, 1)
      forceUpdate()
    }
  }
  const getFileFromDevice = (
    event: React.MouseEvent<
      HTMLIonButtonElement | HTMLIonItemElement | HTMLIonTextElement,
      MouseEvent
    >
  ) => {
    event.persist()

    // @ts-ignore
    fileInputRef.current.click()
  }

  const processWebUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault()
    let currentFiles = Array.from(event.target.files || [])
    event.target.value = ''
    if (!currentFiles.length) return

    setPendingFiles([...pendingFiles, ...currentFiles] as [])
  }

  const getFileName = (file: File & { rename?: string }) => {
    if (file.rename) return file.rename
    if (file.name === 'image.jpg') {
      file.rename = `${Date.now()}.jpg`
      return file.rename
    }

    return file.name
  }

  const done = () => {
    dismiss({
      files: pendingFiles,
      filenames: (pendingFiles || []).map((file: File) => {
        return getFileName(file)
      }),
    })
  }

  return (
    <IonPage className="page-upload-modal" ref={pageRef}>
      <IonHeader>
        <IonToolbar color="primary">
          <IonTitle>
            <IonButton
              hidden={!isPending && isPlatform('mobile')}
              class="logo immutable"
            >
              {t('Suiqui Support')}
            </IonButton>
            <span
              hidden={isPending}
              className={classNames({ subtitle: !isPlatform('mobile') })}
            >
              ({getFileCountTextCallback(pendingFiles)})
            </span>
          </IonTitle>
          <IonButtons slot="end">
            <input
              type="file"
              ref={fileInputRef}
              multiple
              style={{
                position: 'absolute',
                visibility: 'hidden',
                height: '0px',
              }}
              name="files[]"
              data-test="upload-files-input"
              onChange={processWebUpload}
            />
            <IonButton
              hidden={!isPending || isUploading}
              disabled={isUploading}
              color="light"
              fill="clear"
              onClick={(event) => getFileFromDevice(event)}
            >
              <IonIcon slot="start" icon={cloudUpload} />
              <IonText>{t('Upload Photos or Files')}</IonText>
            </IonButton>
            <IonButton className="immutable separator">
              <span>|</span>
            </IonButton>
            <IonButton
              strong
              fill="clear"
              slot="icon-only"
              data-test="close-btn"
              onClick={() =>
                openCloseAlert(!isDone && !!pendingFiles?.length, () =>
                  cancelAll()
                )
              }
            >
              {isDone ? (
                <IonIcon
                  size="large"
                  icon={state?.error ? close : checkmarkDone}
                ></IonIcon>
              ) : (
                <IonIcon size="large" icon={close}></IonIcon>
              )}
            </IonButton>
          </IonButtons>
        </IonToolbar>

        {isUploading &&
          (pendingFiles?.length >= MAX_UPLOAD_FILES_DISPLAY ||
            bundleFilesMode) && (
            <IonProgressBar
              type={state.doneCount > 0 ? 'determinate' : 'determinate'}
              color={
                state?.error
                  ? 'danger'
                  : state.doneCount < pendingFiles.length
                  ? 'warning'
                  : 'success'
              }
              value={getTotalProgressNumberCallback(state.pFiles)}
            ></IonProgressBar>
          )}
      </IonHeader>

      <IonFooter hidden={!isSaveMode}>
        <IonToolbar color="primary">
          <IonButton
            expand="full"
            color="primary"
            className="upload-save"
            disabled={!pendingFiles?.length || isUploading}
            onClick={() => done()}
          >
            {t('Done')}
          </IonButton>
        </IonToolbar>
      </IonFooter>

      <IonFooter
        hidden={
          !isPending || isUploading || !pendingFiles?.length || isSaveMode
        }
      >
        <IonToolbar>
          <IonButton
            expand="full"
            color="primary"
            className="upload-submit"
            disabled={!pendingFiles?.length || isUploading || state?.error}
            onClick={handleStartUpload}
            data-test="start-upload-btn"
          >
            {t('Start Upload')}
          </IonButton>
        </IonToolbar>
      </IonFooter>

      <IonContent ref={contentRef} scrollEvents={true} className="bg">
        <IonList {...getRootProps({ className: 'dropzone', style })}>
          <input {...getInputProps()} />

          <IonText
            className="upload-placeholder"
            hidden={isUploading || !!pendingFiles?.length}
            onClick={(event) => getFileFromDevice(event)}
          >
            <IonIcon icon={cloudUpload} size="large" style={{ opacity: 0.4 }}></IonIcon>
            <IonText>
              <span>
                {isPlatform('desktop')
                  ? t('Drop to Upload')
                  : t('Click to Upload')}
              </span>
            </IonText>
          </IonText>

          {true &&
            pendingFiles?.map((file: File, index: number) => {
              if (!showAll && index >= MAX_UPLOAD_FILES_DISPLAY)
                return <div key={index}></div>
              const pKey = getProgressKey(file, index)
              return (
                <div key={index}>
                  <IonItem lines="none" className="upload-item compact">
                    <IonReorder slot="start" hidden={!isPending} />
                    <IonAvatar slot="start" hidden={false}>
                      <img
                        alt=""
                        src={
                          state.pFiles[pKey]?.isDone
                            ? getImageUrl(state.pFiles[pKey].file, 18, 'c')
                            : ''
                        }
                        onError={getFallbackImage}
                      ></img>
                    </IonAvatar>
                    <IonLabel
                      color={state.pFiles[pKey]?.isDone ? 'dark' : 'medium'}
                    >
                      {getFileName(file)}
                    </IonLabel>
                    <IonBadge slot="end" className="ext" color="dark">
                      {getFileExt(file)}
                    </IonBadge>
                    <IonNote slot="end">
                      {isPending && checked && (
                        <IonChip color="danger" className="immutable">
                          <IonIcon icon={gitCommit} />
                          <IonLabel>{`V${index + 1}`}</IonLabel>
                        </IonChip>
                      )}
                    </IonNote>
                    {state.pFiles[pKey]?.isDone ? (
                      <></>
                    ) : (
                      <IonButtons
                        hidden={
                          !isPending ||
                          getProgressNumberCallback(state.pFiles[pKey]) === 1
                        }
                        slot="end"
                      >
                        {state.pFiles[pKey]?.isCancel ? (
                          <IonButton
                            fill="clear"
                            slot="icon-only"
                            color="warning"
                            onClick={() => {}}
                          >
                            <IonIcon icon={refreshCircle}></IonIcon>
                          </IonButton>
                        ) : (
                          <IonButton
                            fill="clear"
                            slot="icon-only"
                            color="danger"
                            hidden={
                              (isBundleFiles && isUploading) ||
                              (!state.pFiles[pKey]?.isAllowProcessing &&
                                !isPending)
                            }
                            onClick={() =>
                              isPending && !isUploading
                                ? remove(index)
                                : cancelUpload(pKey)
                            }
                          >
                            <IonIcon icon={closeCircle}></IonIcon>
                          </IonButton>
                        )}
                      </IonButtons>
                    )}
                    <div className="icon-status">
                      <IonIcon
                        icon={checkmarkCircle}
                        color={state.pFiles[pKey]?.isDone ? 'success' : 'light'}
                      ></IonIcon>
                    </div>
                  </IonItem>
                  {!bundleFilesMode &&
                    state.doneCount < pendingFiles.length && (
                      <IonProgressBar
                        type={
                          state.pFiles[pKey]?.isPending
                            ? 'determinate'
                            : 'determinate'
                        }
                        color={getProgressBarColor(state.pFiles[pKey])}
                        value={getProgressNumberCallback(state.pFiles[pKey])}
                      ></IonProgressBar>
                    )}
                </div>
              )
            })}
          {!showAll && pendingFiles?.length > MAX_UPLOAD_FILES_DISPLAY && (
            <IonButton
              className="ion-padding"
              color="primary"
              expand="full"
              onClick={() => setShowAll(true)}
            >
              <IonLabel>
                {t('{{count}} files have been hidden', {
                  count: getMoreFilesCountCallback(pendingFiles),
                })}
              </IonLabel>
            </IonButton>
          )}

          <div className="ion-text-center" hidden={!pendingFiles?.length}>
            <IonButton
              color="warning"
              fill="clear"
              size="large"
              slot="icon-only"
              onClick={scrollToTop}
            >
              <IonIcon icon={caretUp} />
            </IonButton>
          </div>
        </IonList>

        <IonToast
          isOpen={showUploadFailedToast}
          onDidDismiss={() => setShowUploadFailedToast(false)}
          message={t('The upload task is failed, close and retry again.')}
          color="danger"
        />

        <IonAlert
          keyboardClose={false}
          isOpen={showCloseAlert}
          onDidDismiss={() => setShowCloseAlert(false)}
          header={t('You have unsaved data, are you sure you want to leave?')}
          buttons={[
            {
              text: t('Cancel'),
              role: 'cancel',
              cssClass: 'cancel',
              handler: () => {},
            },
            {
              text: t('OK'),
              cssClass: 'ok',
              handler: (params) => {
                cancelAll()
              },
            },
          ]}
        />

        <IonAlert
          keyboardClose={true}
          isOpen={showUnexpectedExtAlert}
          onDidDismiss={() => setShowUnexpectedExtAlert(false)}
          header={t('Notice')}
          message={t(`The extension is not ”.{{ext}}”.`, {
            ext: getFilenameExt(`${sFile?.name}`),
          })}
          buttons={[
            {
              text: t('OK'),
              cssClass: 'ok',
              handler: (params) => {},
            },
          ]}
        />
      </IonContent>
    </IonPage>
  )
}

export default UploadModal
