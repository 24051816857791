import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faCaretSquareDown,
  faCheckSquare,
  faFileAlt,
} from '@fortawesome/free-solid-svg-icons'
import {
  IonApp,
  IonPage,
  IonSplitPane,
  isPlatform,
  setupConfig,
} from '@ionic/react'
import { IonReactRouter } from '@ionic/react-router'
/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css'
import '@ionic/react/css/display.css'
import '@ionic/react/css/flex-utils.css'
import '@ionic/react/css/float-elements.css'
/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css'
/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css'
import '@ionic/react/css/structure.css'
import '@ionic/react/css/text-alignment.css'
import '@ionic/react/css/text-transformation.css'
import '@ionic/react/css/typography.css'
import { initializeFileTypeIcons } from '@uifabric/file-type-icons'
import 'moment/locale/zh-tw'
import React, { useMemo, useState } from 'react'
import { I18nextProvider } from 'react-i18next'
import { useSelector } from 'react-redux'
import './App.scss'
import AppRoutes from './AppRoutes'
import Menu from './components/menu/Menu'
import { detectUserAgent, isInStandaloneMode } from './helpers/util'
import i18n from './i18n'
import { DELAY_SHORT } from './models/constants'
import zhLocale from 'date-fns/locale/zh-TW'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import './theme/variables.css'
import PopupMenu from './components/PopupMenu'

library.add(faCaretSquareDown, faCheckSquare, faFileAlt)

if (isPlatform('ios')) {
  document.body.style.height = window.innerHeight + 'px'
  window.addEventListener('orientationchange', () => {
    if (window.orientation === 0 || window.orientation === 180) {
      setTimeout(() => {
        window.scrollTo(0, isInStandaloneMode() ? 0 : 1)
      }, DELAY_SHORT)
    }
  })
}

detectUserAgent()
initializeFileTypeIcons()
setupConfig({
  mode: 'md',
  swipeBackEnabled: false,
})

const App = () => {
  const authDuck = useSelector((state: any) => state.authDuck)
  const [showMenu, setShowMenu] = useState(false)

  useMemo(() => {
    setShowMenu(authDuck.opened)
  }, [setShowMenu, authDuck.opened])

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={zhLocale}>
      <I18nextProvider i18n={i18n}>
        <IonApp>
          <IonReactRouter>
            <div id="global-popup-menu">
              <PopupMenu></PopupMenu>
            </div>
            <IonSplitPane contentId="main" disabled={!showMenu}>
              <Menu />
              <IonPage id="main">
                <AppRoutes></AppRoutes>
              </IonPage>
            </IonSplitPane>
          </IonReactRouter>
        </IonApp>
      </I18nextProvider>
    </LocalizationProvider>
  )
}

export default App
