import {
  IonLabel,
  IonButtons,
  IonButton,
  IonIcon,
  IonModal,
} from '@ionic/react'
import useComponentSize from '@rehooks/component-size'
import { randomBytes } from 'crypto'
import { imagesOutline } from 'ionicons/icons'
import { ChangeEvent, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { isRawFileImage } from '../../helpers/file'
import { useModalWithData } from '../../hooks/useModal'
import './CustomerPhotoFields.scss'
import EditRawFileMemoModal from './EditRawFileMemoModal'
import PhotoDetailsCard from './PhotoDetailsCard'

/**
 * Js 原生的檔案型別加上記事欄位後的型別（與一般後端回傳的 SuiquiFile 型別不同）
 */
export type RawFileWithMemo = File & {
  /**
   * 在選擇檔案時前端隨機生成的字串，確保檔名重複時也能分辨出不同的檔案
   */
  id: string

  /**
   * 檔案的記事，在儲存工單後會轉變成留言的文字
   */
  memo: string
}

/**
 * 計算預覽的相片在不同螢幕下的佔的寬度
 */
const getPhotoSize = (sectionWidth: number) => {
  return +((sectionWidth - 50) / 4).toFixed(0)
}

interface CustomerPhotoFieldsProps {
  /**
   * 當檔案列表有改動時呼叫的 callback
   */
  onCustomerPhotosChange: (files: RawFileWithMemo[]) => void
}

/**
 * 客戶上傳照片的欄位
 * Notes: 
 * - 此欄位只有在開單時提供客戶上傳照片使用，儲存後會將上傳的內容轉至留言區
 * - 此元件並非使用 react-form-renderer 套件方式進行 render，而是直接在 FormTemplate 裡的欄位元件下方加入此自訂元件
 */
const CustomerPhotoFields = ({
  onCustomerPhotosChange,
}: CustomerPhotoFieldsProps) => {
  const { t } = useTranslation()
  const fileInputRef = useRef<HTMLInputElement>(null)
  const sectionRef = useRef(null)
  const { width: sectionWidth } = useComponentSize(sectionRef)

  const [
    showEditMemoModal, // 是否顯示編輯記事 modal
    editMemoModalFile, // 要傳入編輯記事 modal 的檔案
    openEditMemoModal,
    dismissEditMemoModal,
  ] = useModalWithData<RawFileWithMemo>()

  /**
   * 使用者從裝置上已選擇的檔案列表區
   * Notes: 在使用者點擊創建工單前，檔案只存在前端本地的變數中。在新增工單後才會將其一次全部上傳至留言區
   */
  const [files, setFiles] = useState<RawFileWithMemo[]>([])

  /**
   * 使用者每從裝置上選擇一個檔案時觸發，將新選擇的檔案加入目前已選擇的檔案列表中
   */
  const onFileInputChanged = (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) return
    const newFiles = Array.from(event.target.files) as RawFileWithMemo[]
    for (const file of newFiles) {
      file.id = randomBytes(16).toString('hex') // 隨機生成的字串
      file.memo = '' // 預設記事為空字串
    }
    setFiles([...files, ...newFiles])
  }

  /**
   * 打開瀏覽器原生的上傳檔案介面
   */
  const getFileFromDevice = (event: React.MouseEvent) => {
    event.persist()
    fileInputRef.current?.click()
  }

  /**
   * 使用者編輯完檔案的記事後觸發的 callback，更改本地變數 files 中對應的檔案的記事
   */
  const onEditFileMemo = (newMemo: string) => {
    setFiles((files) => {
      const editingFile = files.find(
        (file) => file.id === editMemoModalFile?.id
      )
      if (editingFile) {
        editingFile.memo = newMemo
      }
      return [...files]
    })
  }

  /**
   * 使用者編輯完檔案的記事後觸發的 callback，更改本地變數 files 中對應的檔案的記事
   */
  const onDeleteFile = (id: string) => {
    setFiles((files) => {
      return [...files.filter((file) => file.id !== id)]
    })
  }

  /**
   * 每當檔案列表有改動時，將其傳給上層的 component
   */
  useEffect(() => {
    onCustomerPhotosChange(files)
  }, [onCustomerPhotosChange, files])

  return (
    <section className="customer-photo-fields" ref={sectionRef}>
      {/* 隱藏的檔案 input，用於打開瀏覽器原生的上傳檔案介面 */}
      <input
        type="file"
        ref={fileInputRef}
        multiple
        style={{
          position: 'absolute',
          visibility: 'hidden',
          height: '0px',
        }}
        name="files[]"
        data-test="upload-files-input"
        onChange={onFileInputChanged}
      />

      <div className="title">
        {/* 「照片/檔案」 */}
        <h5>
          {t('Photo')}/{t('Files')}
        </h5>

        {/* 上傳按鈕 */}
        <IonButtons>
          <IonButton
            fill="clear"
            color="secondary"
            data-test="upload-btn"
            onClick={getFileFromDevice}
          >
            <IonIcon slot="start" icon={imagesOutline}></IonIcon>
            <IonLabel>{t('Upload')}</IonLabel>
          </IonButton>
        </IonButtons>
      </div>

      {/* 已選擇的檔案列表 */}
      {files.length === 0 && <div>{t('No Items')}</div>}
      {files.map((file, index) => {
        const isImage = isRawFileImage(file)
        // 顯示預覽圖片
        const image = {
          url: isImage
            ? URL.createObjectURL(file)
            : 'https://spoprod-a.akamaihd.net/files/fabric-cdn-prod_20210115.001/assets/item-types/48/pdf.svg',
          memo: file.memo,
          name: file.name,
        }
        return (
          <PhotoDetailsCard
            key={index}
            image={image}
            photoSize={getPhotoSize(sectionWidth)}
            onEditMemo={() => openEditMemoModal(file)}
            onDelete={() => onDeleteFile(file.id)}
          />
        )
      })}

      {/* 編輯記事 modal */}
      <IonModal isOpen={showEditMemoModal} backdropDismiss={false}>
        {showEditMemoModal && editMemoModalFile && (
          <EditRawFileMemoModal
            file={editMemoModalFile}
            onEdit={onEditFileMemo}
            dismiss={dismissEditMemoModal}
          />
        )}
      </IonModal>
    </section>
  )
}

export default CustomerPhotoFields
