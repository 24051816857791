import {
  IonAlert,
  IonButton,
  IonButtons,
  IonCard,
  IonIcon,
  IonLabel,
  IonModal,
} from '@ionic/react'
import {
  add,
  caretDownCircle,
  caretUpCircle,
  pencil,
  reorderFour,
  trash,
} from 'ionicons/icons'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import useAlert from '../../../hooks/useAlert'
import useModal from '../../../hooks/useModal'
import ReorderModal from '../../../modals/ReorderModal'
import { Signature } from '../../../models/template'
import { RootState } from '../../../store/ducks'
import {
  deleteSignatureField,
  reorderSignatureFields,
} from '../../../store/templateSlice'
import EditSignatureModal from '../EditSignatureModal'
import './EditModeSchemaField.scss'

interface EditModeSignatureSectionProps {
  signatures: Signature[]
}

// 在編輯模式下，使用客製化元件顯示簽名欄位
const EditModeSignatureSection = ({
  signatures,
}: EditModeSignatureSectionProps) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const template = useSelector((state: RootState) => state.newTemplate.template)
  const [isHide, setIsHide] = useState(false)
  const [editingSignature, setEditingSignature] = useState<Signature>()
  const [
    isShowAddSignatureModal,
    showAddSignatureModal,
    dismissAddSignatureModal,
  ] = useModal()
  const [isShowReorderModal, showReorderModal, dismissReorderModal] = useModal()
  const { showAlert, alertProps } = useAlert()

  const onDeleteClick = useCallback(
    (signature: Signature) => {
      if (!template) return

      // 顯示 alert，需確認才能完成刪除
      showAlert({
        message: t('Click OK to remove this item.'),
        onConfirm: () => {
          dispatch(deleteSignatureField({ key: signature.key }))
        },
      })
    },
    [showAlert, t, template, dispatch]
  )

  // 交換兩個欄位的順序
  const onReorder = useCallback(
    (signatures: Signature[]) => {
      if (!template) return
      // 向後端發送更新 template 的 request
      dispatch(
        reorderSignatureFields({
          key: template.key,
          signature_keys: signatures.map((signature) => signature.key),
        })
      )
      dismissReorderModal()
    },
    [dispatch, template, dismissReorderModal]
  )

  return (
    <section className="schema">
      <div className="schema-title">
        {/* 簽核欄位 */}
        <h5>{t('Signature Fields')}</h5>

        <IonButtons>
          {/* 重新排序按鈕 */}
          {!isHide && (
            <IonButton fill="clear" slot="end" onClick={showReorderModal}>
              <IonIcon slot="start" icon={reorderFour} />
              <IonLabel>{t('Reorder')}</IonLabel>
            </IonButton>
          )}

          {/* 隱藏選單按鈕 */}
          <IonButton
            fill="clear"
            slot="end"
            onClick={() => setIsHide((isHide) => !isHide)}
          >
            <IonIcon
              slot="start"
              icon={isHide ? caretUpCircle : caretDownCircle}
            />
            <IonLabel>{isHide ? t('Expand') : t('Hide')}</IonLabel>
          </IonButton>
        </IonButtons>
      </div>

      {!isHide && (
        <>
          {/* 欄位列表 */}
          {signatures.map((signature) => (
            <IonCard className="edit-mode-schema-field">
              <div className="toolbar">
                <span className="signature-name">{signature.name}</span>
                <div>
                  {/* 「編輯」按鈕 */}
                  <IonButton
                    fill="clear"
                    slot="end"
                    onClick={() => setEditingSignature(signature)}
                  >
                    <IonIcon slot="start" icon={pencil}></IonIcon>
                    <IonLabel>{t('Edit')}</IonLabel>
                  </IonButton>

                  {/* 「刪除」按鈕 */}
                  <IonButton
                    fill="clear"
                    color="danger"
                    slot="end"
                    onClick={() => onDeleteClick(signature)}
                  >
                    <IonIcon slot="start" icon={trash}></IonIcon>
                    <IonLabel>{t('Delete')}</IonLabel>
                  </IonButton>
                </div>
              </div>
            </IonCard>
          ))}

          {/* 新增欄位按鈕 */}
          <div className="add-schema-field-btn">
            <IonButton fill="clear" onClick={showAddSignatureModal}>
              <IonIcon slot="start" icon={add}></IonIcon>
              <IonLabel>{t('Add field')}</IonLabel>
            </IonButton>
          </div>
        </>
      )}

      {/* 編輯簽核欄位 Modal */}
      <IonModal isOpen={editingSignature !== undefined} backdropDismiss={false}>
        {editingSignature !== undefined && (
          <EditSignatureModal
            editingSignature={editingSignature}
            dismiss={() => setEditingSignature(undefined)}
          />
        )}
      </IonModal>

      {/* 新增簽核欄位 Modal */}
      <IonModal isOpen={isShowAddSignatureModal} backdropDismiss={false}>
        {isShowAddSignatureModal && (
          <EditSignatureModal dismiss={dismissAddSignatureModal} />
        )}
      </IonModal>

      {/* 重新排序 Modal */}
      <IonModal isOpen={isShowReorderModal} backdropDismiss={false}>
        {isShowReorderModal && (
          <ReorderModal
            isOpen={isShowReorderModal}
            initialItems={signatures}
            getName={(signature) => signature.name}
            onDone={onReorder}
            dismiss={dismissReorderModal}
          />
        )}
      </IonModal>

      {/* Alert */}
      <IonAlert {...alertProps} />
    </section>
  )
}

export default EditModeSignatureSection
