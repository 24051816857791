import { key } from './base'
import { IUser } from './user'

export enum EAutocomplete {
  user = 'user',
  group = 'group',
}

export interface IAutoComplete {
  keyword: string
  is_active: boolean
  types: EAutocomplete[]
  user: IUser[]
  cursor?: {
    user: key
  }
  reload?: boolean
  all?: boolean
  page_size?: number
}
