import { RefresherEventDetail } from '@ionic/core'
import { combineEpics, Epic } from 'redux-observable'
import { of } from 'rxjs'
import {
  catchError,
  filter,
  finalize,
  map,
  switchMap,
  takeUntil,
} from 'rxjs/operators'
import {
  ActionType,
  createAsyncAction,
  createReducer,
  getType,
  isActionOf,
} from 'typesafe-actions'
import { IAuth } from '../../models/auth'
import { id } from '../../models/base'
import { IBaseService } from '../../models/baseService'
import { ITeam } from '../../models/team'
import { IUser } from '../../models/user'
import * as Providers from '../../providers'
import { RootState } from '../ducks'
import { RootAction } from './types'

export const fetchUser = createAsyncAction(
  'FETCH_USER_REQUEST',
  'FETCH_USER_SUCCESS',
  'FETCH_USER_FAILURE',
  'FETCH_USER_CANCEL'
)<Pick<IAuth, 'token'>, IUser, Error, undefined>()

const initialState = {
  currentUser: null,
  currentTeam: null,
  teams: [],
  error: null,
  actions: [],
  created_datetime: undefined,
}

export const fetchUserEpic: Epic<
  RootAction,
  RootAction,
  RootState,
  typeof Providers
> = (action$, state$, { userAPI }) =>
  action$.pipe(
    filter(isActionOf(fetchUser.request)),
    switchMap((action) =>
      userAPI
        .get({ token: action.payload?.token || state$.value.authDuck.token })
        .pipe(
          map((data) => fetchUser.success(data.response)),
          catchError((message: Error) => of(fetchUser.failure(message))),
          takeUntil(action$.pipe(filter(isActionOf(fetchUser.cancel))))
        )
    )
  )

export const fetchTeamList = createAsyncAction(
  'FETCH_TEAM_LIST_REQUEST',
  'FETCH_TEAM_LIST_SUCCESS',
  'FETCH_TEAM_LIST_FAILURE',
  'FETCH_TEAM_LIST_CANCEL'
)<
  { key: id; event?: CustomEvent<RefresherEventDetail | void> },
  ITeam[],
  Error,
  undefined
>()

export const fetchTeamListEpic: Epic<
  RootAction,
  RootAction,
  RootState,
  typeof Providers
> = (action$, state$, { userAPI }) =>
  action$.pipe(
    filter(isActionOf(fetchTeamList.request)),
    switchMap((action) =>
      userAPI
        .teamList({ token: state$.value.authDuck.token })
        .pipe(
          finalize(() => {
            const { event } = action.payload
            if (event) {
              if (event.target) {
                // @ts-ignore
                event.target.complete()
              } else if (event.detail) {
                // @ts-ignore
                event.detail.complete()
              }
            }
          })
        )
        .pipe(
          map((data) => fetchTeamList.success(data.response)),
          catchError((message: Error) => of(fetchTeamList.failure(message))),
          takeUntil(action$.pipe(filter(isActionOf(fetchTeamList.cancel))))
        )
    )
  )

export const fetchBaseService = createAsyncAction(
  'FETCH_BASE_SERVICE_REQUEST',
  'FETCH_BASE_SERVICE_SUCCESS',
  'FETCH_BASE_SERVICE_FAILURE',
  'FETCH_BASE_SERVICE_CANCEL'
)<undefined, IBaseService[], Error, undefined>()

export const fetchBaseServiceEpic: Epic<
  RootAction,
  RootAction,
  RootState,
  typeof Providers
> = (action$, state$, { userAPI }) =>
  action$.pipe(
    filter(isActionOf(fetchBaseService.request)),
    switchMap((action) =>
      userAPI.baseService({ token: state$.value.authDuck.token }).pipe(
        map((data) => fetchBaseService.success(data.response)),
        catchError((message: Error) => of(fetchBaseService.failure(message))),
        takeUntil(action$.pipe(filter(isActionOf(fetchBaseService.cancel))))
      )
    )
  )

export const userReducer = createReducer(initialState)
  .handleAction(getType(fetchUser.success), (state: any, { payload }: any) => ({
    currentUser: payload,
    error: null,
  }))
  .handleAction(getType(fetchUser.failure), (state: any, { payload }: any) => ({
    error: payload,
  }))
  .handleAction(getType(fetchUser.cancel), (state: any, { payload }: any) => ({
    currentUser: null,
    error: null,
  }))
  .handleAction(
    getType(fetchTeamList.success),
    (state: any, { payload }: any) => ({
      ...state,
      currentTeam: payload.current_team,
      teams: payload.teams,
    })
  )
  .handleAction(
    getType(fetchBaseService.success),
    (state: any, { payload }: any) => ({
      ...state,
      created_datetime: payload.created_datetime,
      actions: payload.actions,
    })
  )

export type UserAction =
  | ActionType<typeof fetchUser>
  | ActionType<typeof fetchTeamList>
  | ActionType<typeof fetchBaseService>

const userEpic = combineEpics<any>(
  fetchUserEpic,
  fetchTeamListEpic,
  fetchBaseServiceEpic
)
export default userEpic
