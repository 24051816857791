import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

interface ShowAlertProps {
  header?: string
  message: string

  // 點擊 OK 按鈕後會執行傳入的 onConfirm 函式
  onConfirm?: () => void

  // 若不為 undefined 則顯示輸入欄，使用者需要輸入一樣的字串才能完成動作
  targetInput?: string

  // 輸入欄的提示
  placeholder?: string
}

/**
 * 用法：
 *
 * ```typescript
 * const { showAlert, alertProps } = useAlert()
 * ...
 * <IonAlert {...alertProps} />
 * ```
 * 
 * 在要顯示 alert 的時候直接呼叫 showAlert 函式即可
 * ```typescript
 * showAlert({
 *   header: t('Notice),
 *   message: t('Click OK to archive this item.'),
 *   onConfirm: () => {
 *      doSomething();
 *   }
 * })
 * ```
 * 
 * 若顯示的 alert 需要讓使用者輸入特定字串後才能完成，可額外傳入以下參數：
 * ```typescript
 * showAlert({
 *   ...
 *   targetValue: template.name,
 *   placeholder: t('Input project name to confirm.'),
 *   ...
 * })
 * ```
 */
export default function useAlert() {
  // 預設值（關閉）
  const dismissedAlertProps = useMemo(
    () => ({
      isOpen: false,
      onDidDismiss: () => {},
    }),
    []
  )

  // toastProps 為傳入 IonToast 的所有參數之集合
  // 在要使用 toast 的元件： <IonToast {...toastProps} />
  const [alertProps, setAlertProps] = useState(dismissedAlertProps)

  const { t } = useTranslation()

  // 此函式會將 toastProps 設回關閉狀態
  const dismissAlert = useCallback(() => {
    setAlertProps((toastProps) => ({
      ...toastProps,
      isOpen: false,
      onDidDismiss: () => setAlertProps(dismissedAlertProps),
    }))
  }, [dismissedAlertProps])

  // 此函式會設置 alertProps 設為開啟狀態，打開 alert
  // 點擊 OK 按鈕後會執行傳入的 onConfirm 函式
  // 若 targetInput 不為 undefined 則顯示輸入欄，使用者需要輸入一樣的字串才能完成動作
  const showAlert = useCallback(
    ({
      header,
      message,
      onConfirm,
      targetInput,
      placeholder,
    }: ShowAlertProps) => {
      setAlertProps(() => ({
        keyboardClose: true,
        isOpen: true,
        header: header ?? t('Notice'),
        message,
        onDidDismiss: () => {
          dismissAlert()
        },

        // 使用者輸入欄
        inputs: targetInput
          ? [
              {
                name: 'input',
                type: 'text',
                placeholder: placeholder,
              },
            ]
          : undefined,
        
        // 按鈕
        buttons: [
          // 取消按鈕
          {
            text: t('Cancel'),
            role: 'cancel',
            cssClass: 'cancel',
            handler: () => {},
          },

          // 確認按鈕
          {
            text: t('OK'),
            cssClass: 'ok',
            handler: (params: { input: string }) => {
              if (targetInput && params.input !== targetInput) {
                return
              }
              onConfirm?.()
            },
          },
        ],
      }))
    },
    [dismissAlert, t]
  )

  return { showAlert, alertProps }
}
