import { isPlatform } from '@ionic/core'
import { IonIcon } from '@ionic/react'
import classNames from 'classnames'
import { apps } from 'ionicons/icons'
import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import './PopupMenu.scss'

const PopupMenu: React.FC<any> = (props) => {
  const { t } = useTranslation()
  const [isOpenMenu, setIsOpenMenu] = useState(false)

  const togglePopupMenu = useCallback(() => {
    setIsOpenMenu(!isOpenMenu)
    return isOpenMenu
  }, [isOpenMenu])

  const goToSuiqui = useCallback(() => {
    window.open(
      process.env.REACT_APP_SUIQUI_FE_URL,
      isPlatform('desktop') ? '_blank' : undefined,
      'noopener'
    )

    togglePopupMenu()
  }, [togglePopupMenu])

  const goToChecklist = useCallback(() => {
    window.open(
      process.env.REACT_APP_CL_FE_URL,
      isPlatform('desktop') ? '_blank' : undefined,
      'noopener'
    )

    togglePopupMenu()
  }, [togglePopupMenu])

  const goToDailyreport = useCallback(() => {
    window.open(
      process.env.REACT_APP_DR_FE_URL,
      isPlatform('desktop') ? '_blank' : undefined,
      'noopener'
    )

    togglePopupMenu()
  }, [togglePopupMenu])

  const goToLibrary = useCallback(() => {
    window.open(
      process.env.REACT_APP_LIB_FE_URL,
      isPlatform('desktop') ? '_blank' : undefined,
      'noopener'
    )

    togglePopupMenu()
  }, [togglePopupMenu])

  const goToSupport = useCallback(() => {
    window.open(
      `${window.location.protocol}//${window.location.host}`,
      isPlatform('desktop') ? '_blank' : undefined,
      'noopener'
    )

    togglePopupMenu()
  }, [togglePopupMenu])

  const goToDashboard = useCallback(() => {
    window.open(
      process.env.REACT_APP_DASH_FE_URL,
      isPlatform('desktop') ? '_blank' : undefined,
      'noopener'
    )

    togglePopupMenu()
  }, [togglePopupMenu])

  return (
    <div className="component-popup-menu">
      <div className="popup-menu">
        <div
          className={classNames({ 'popup-menu-overlay': true, in: isOpenMenu })}
          onClick={togglePopupMenu}
        ></div>
        <div
          className={classNames({ 'popup-menu-toggle': true, out: isOpenMenu })}
          onClick={togglePopupMenu}
        >
          <IonIcon color="light" icon={apps}></IonIcon>
        </div>
        <div
          className={classNames({ 'popup-menu-panel': true, in: isOpenMenu })}
        >
          <div className="popup-menu-title">{t('POPUP_MENU_TITLE')}</div>
          <div className="popup-menu-items">
            <div className="popup-menu-item" onClick={goToSuiqui}>
              <div className="logo">
                <div className="suiqui"></div>
              </div>
              <span>{t('SUIQUI_APP')}</span>
            </div>
            <div className="popup-menu-item" onClick={goToChecklist}>
              <div className="logo">
                <div className="checklist"></div>
              </div>
              <span>{t('SUIQUI_CHECKLIST')}</span>
            </div>
            <div className="popup-menu-item" onClick={goToDailyreport}>
              <div className="logo">
                <div className="dailyreport"></div>
              </div>
              <span>{t('SUIQUI_DAILYREPORT')}</span>
            </div>
          </div>
          <div className="popup-menu-items">
            <div className="popup-menu-item" onClick={goToLibrary}>
              <div className="logo">
                <div className="library"></div>
              </div>
              <span>{t('SUIQUI_LIBRARY')}</span>
            </div>
            <div className="popup-menu-item" onClick={goToSupport}>
              <div className="logo">
                <div className="support"></div>
              </div>
              <span>{t('SUIQUI_SUPPORT')}</span>
            </div>
            <div className="popup-menu-item" onClick={goToDashboard}>
              <div className="logo">
                <div className="dashboard"></div>
              </div>
              <span>{t('SUIQUI_DASHBOARD')}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default React.memo(PopupMenu)
