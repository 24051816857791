import {
  IonBadge,
  IonItem,
  IonLabel,
  IonNote,
  IonText,
  isPlatform,
} from '@ionic/react'
import classNames from 'classnames'
import moment from 'moment'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { ETicketStatus, ITicket } from '../../models/ticket'

interface TicketListCardProps {
  item: ITicket
  index: number
}

function TicketListCard({ item, index }: TicketListCardProps) {
  const { t } = useTranslation()
  // 已逾期（超過預定完成日）
  const isPostponed =
    item.ticket_status === ETicketStatus.InProgress &&
    item.expected_end_date &&
    !item.actual_end_date &&
    moment().isAfter(item.expected_end_date, 'day')

  const getTicketName = useCallback((item: ITicket) => {
    if (isPlatform('mobile') && !isPlatform('ipad')) {
      return item?.short_display_name || item?.name
    }
    return item?.display_name || item?.name
  }, [])

  return (
    <div key={index} className="ticket-list">
      <IonItem
        button
        detail
        routerLink={`/ticket/${item.key}`}
        routerDirection="forward"
      >
        <IonNote className="serial">{item?.serial_number ?? ''}</IonNote>
        {item?.ticket_status && (
          <IonBadge
            className={classNames({
              'ticket-status': true,
              'ticket-status-done': item.ticket_status === ETicketStatus.Done,
              'ticket-status-in-progress':
                item.ticket_status === ETicketStatus.InProgress,
              'ticket-status-postponed': isPostponed,
              'ticket-status-observing':
                item.ticket_status === ETicketStatus.Observing,
              'ticket-status-waiting':
                item.ticket_status === ETicketStatus.Waiting,
              'ticket-status-preparing':
                item.ticket_status === ETicketStatus.Preparing,
            })}
          >
            {isPostponed
              ? t(`Ticket Postponed`)
              : t(`Ticket ${item.ticket_status}`)}
          </IonBadge>
        )}
        <IonLabel>
          <h2>{getTicketName(item)}</h2>
          <h3
            className="ion-text-wrap date-info"
            hidden={!(isPlatform('desktop') || isPlatform('ipad'))}
          >
            <IonText color="medium" hidden={!item.create_datetime}>
              {(isPlatform('desktop') || isPlatform('ipad')) && (
                <span>{t('Created Date')} </span>
              )}
              {`${moment(item.create_datetime).format('YYYY-MM-DD')}`}
            </IonText>
            {` `}
            <IonText color="secondary" hidden={!item.expected_end_date}>
              {(isPlatform('desktop') || isPlatform('ipad')) && (
                <span>{t('Expected End Date')} </span>
              )}
              {`${moment(item.expected_end_date).format('YYYY-MM-DD')}`}
            </IonText>
            {` `}
            <IonText color="success" hidden={!item.actual_end_date}>
              {(isPlatform('desktop') || isPlatform('ipad')) && (
                <span>{t('Actual End Date')} </span>
              )}
              {`${moment(item.actual_end_date).format('YYYY-MM-DD')}`}
            </IonText>
            <IonText
              className="observation-start-date"
              hidden={!item.observation_start_date}
            >
              {(isPlatform('desktop') || isPlatform('ipad')) && (
                <span>{t('Observation Start Date')} </span>
              )}
              {`${moment(item.observation_start_date).format('YYYY-MM-DD')}`}
            </IonText>
          </h3>
        </IonLabel>
      </IonItem>
    </div>
  )
}

export default TicketListCard
