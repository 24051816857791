import {
  IonBadge,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonIcon,
  IonItem,
  IonItemDivider,
  IonLabel,
  IonList,
  IonNote,
  IonProgressBar,
  IonText,
  IonThumbnail,
  IonToolbar,
  isPlatform,
} from '@ionic/react'
import useComponentSize from '@rehooks/component-size'
import { getFileTypeIconProps } from '@uifabric/file-type-icons'
import filesize from 'filesize'
import { cloudDownload, trash } from 'ionicons/icons'
import moment from 'moment'
import { Icon } from 'office-ui-fabric-react/lib/Icon'
import React, { useCallback, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { inFunctionList } from '../helpers/base'
import {
  getFallbackImage,
  getFileExt,
  getIFileProgressKey,
  getImageUrl,
  getProgressBarColor,
  getProgressNumber,
  isExtImage,
  isFilePdf,
  openDocument,
} from '../helpers/file'
import { humanize } from '../helpers/text'
import {
  ETicketCommentFunction,
  ETicketCommentUserType,
  ITicketComment,
} from '../models/comment'
import { IFile } from '../models/suiquiFile'
import './TicketComment.scss'

type TicketCommentProp = {
  item: ITicketComment
  index?: number
  userType?: ETicketCommentUserType
  removeFn?: Function
  disabled?: boolean
  execDownload?: Function
  execOpen?: Function
  openPhotoViewerModal?: Function
}
const TicketComment: React.FC<TicketCommentProp> = (
  props: TicketCommentProp
) => {
  const {
    item,
    index,
    userType,
    removeFn,
    disabled,
    execDownload,
    execOpen,
    openPhotoViewerModal,
  } = props
  const { t } = useTranslation()

  const photoRef = useRef(null)
  const { width: photoWidth } = useComponentSize(photoRef)

  const download = useSelector((state: any) => state.download)

  const getFileExtCallback = useCallback((file: IFile) => {
    return getFileExt(file) || 'file'
  }, [])

  const getProgressNumberCallback = useCallback((pFile) => {
    return getProgressNumber(pFile)
  }, [])

  return (
    <div className="component-ticket-comment">
      <IonCard>
        <IonCardHeader>
          <IonCardSubtitle>
            <IonLabel color="primary" className="immutable user">
              <h2>{item?.user?.displayname}</h2>
            </IonLabel>
          </IonCardSubtitle>
          <IonCardTitle></IonCardTitle>
        </IonCardHeader>
        {item?.text && (
          <IonItem lines="none" className="ion-text-wrap">
            <IonText
              className="ion-padding"
              style={{ 'white-space': 'pre-line' }}
            >
              {item?.text}
            </IonText>
          </IonItem>
        )}

        {!!item?.files?.length && (
          <>
            <IonList className="file-list">
              {item?.files &&
                item.files.map((file: IFile, index: number) => {
                  const pKey = getIFileProgressKey(file)
                  const fileExt = getFileExtCallback(file)

                  return (
                    <div key={index}>
                      <IonItem
                        lines="none"
                        button={true}
                        onClick={(event) => {
                          if (file?.is_image || isExtImage(file)) {
                            if (index !== undefined) {
                              openPhotoViewerModal &&
                                openPhotoViewerModal(event, index, item.files)
                            } else {
                              openPhotoViewerModal &&
                                openPhotoViewerModal(event, 0, [file])
                            }
                          } else {
                            if (execOpen) {
                              execOpen(event, file, file.name)
                            }
                          }
                        }}
                      >
                        {file?.is_image ? (
                          <IonThumbnail
                            slot="end"
                            className="photo"
                            ref={photoRef}
                          >
                            <img
                              alt=""
                              width={photoWidth}
                              height={photoWidth}
                              src={`${getImageUrl(file, photoWidth, 'c')}`}
                              onError={getFallbackImage}
                            ></img>
                          </IonThumbnail>
                        ) : (
                          <IonThumbnail slot="start">
                            <Icon
                              {...getFileTypeIconProps({
                                extension: fileExt,
                                size: 48,
                                imageFileType: 'svg',
                              })}
                            />
                          </IonThumbnail>
                        )}
                        {!file?.is_image && (
                          <IonNote slot="end">
                            <IonBadge
                              color={file.is_image ? 'success' : 'secondary'}
                            >
                              {file.is_image ? t('Image') : t('File')}
                            </IonBadge>
                          </IonNote>
                        )}
                        {!file?.is_image && (
                          <IonLabel className="ion-text-wrap">
                            {file.name}
                          </IonLabel>
                        )}
                      </IonItem>

                      <IonProgressBar
                        type={
                          download.pFiles[pKey]?.isPending
                            ? 'determinate'
                            : 'determinate'
                        }
                        color={
                          !download.pFiles[pKey]
                            ? 'light'
                            : getProgressBarColor(download.pFiles[pKey])
                        }
                        value={getProgressNumberCallback(download.pFiles[pKey])}
                      ></IonProgressBar>

                      <IonItemDivider className="download">
                        <IonButtons slot="end">
                          <IonButton
                            fill="clear"
                            color="primary"
                            className="download"
                            disabled={
                              download.pFiles[pKey] &&
                              download.pFiles[pKey]?.isDownloading &&
                              !download.pFiles[pKey]?.isDone
                            }
                            onClick={(e) =>
                              isPlatform('ios') ||
                              (isPlatform('mobile') && isFilePdf(file))
                                ? openDocument(
                                    file,
                                    false,
                                    t('IOS_INAPP_FILE_OPEN_ERROR_MESSAGE')
                                  )
                                : execDownload &&
                                  execDownload(e, file, file.name)
                            }
                          >
                            <IonIcon
                              slot="start"
                              icon={cloudDownload}
                            ></IonIcon>
                            <IonLabel>
                              {t('Download')}
                              {true && (
                                <span className="filesize">
                                  (
                                  {filesize(file?.blob_info?.size || 0, {
                                    base: 2,
                                  })}
                                  )
                                </span>
                              )}
                            </IonLabel>
                          </IonButton>
                        </IonButtons>
                      </IonItemDivider>
                    </div>
                  )
                })}
            </IonList>
          </>
        )}

        <IonCardContent className="ion-text-wrap">
          <IonToolbar>
            <IonButton slot="end" fill="clear" color="medium" size="small">
              <h5
                title={moment(
                  item?.modified_datetime || item?.create_datetime
                ).format()}
              >
                {humanize(item?.modified_datetime || item?.create_datetime)}
              </h5>
            </IonButton>
            {removeFn &&
              !userType &&
              inFunctionList(
                ETicketCommentFunction.comment_delete,
                item?.function_list
              ) && (
                <IonButton
                  slot="start"
                  fill="clear"
                  color="danger"
                  size="small"
                  disabled={disabled}
                  onClick={(e) => {
                    removeFn(item)
                  }}
                >
                  <IonIcon slot="icon-only" icon={trash} />
                </IonButton>
              )}
          </IonToolbar>
        </IonCardContent>
      </IonCard>
    </div>
  )
}

export default TicketComment
