import { ajax } from 'rxjs/ajax'
import { JWT_UPLOAD } from '../helpers/base'
import { url } from '../models/base'

/**  getUploadUrl sample **/
/*
export const getUploadUrl = ({ token }: { token: string }) => {
  return ajax({
    url: API(`upload/uploadurl`, 'SUPPORT'),
    method: 'POST',
    headers: JWT(token),
    responseType: 'json',
  })
    .pipe(map((res) => res.response))
    .pipe(map((res: { uploadurl: string }) => res.uploadurl))
}*/

const makeFormData = (params: any, file: Blob, filename?: string) => {
  const formData = new FormData()
  formData.append(
    'json_data',
    JSON.stringify({
      ...params,
    })
  )

  if (!file) return formData

  if (filename) {
    formData.append('file', file, filename)
  } else {
    formData.append('file', file)
  }
  return formData
}

const makeFormFilesData = (
  params: any,
  files: Blob[],
  filenames?: string[]
) => {
  const formData = new FormData()
  formData.append(
    'json_data',
    JSON.stringify({
      ...params,
    })
  )

  if (!files) return formData

  files.forEach((file, index) => {
    if (filenames && filenames[index]) {
      formData.append('file[]', file, filenames[index])
    } else {
      formData.append('file[]', file)
    }
  })

  return formData
}

export const exec = ({
  token,
  uploadUrl,
  files,
  file,
  filename,
  filenames,
  params,
  createXHR,
  progressSubscriber,
}: {
  token: string
  uploadUrl: url
  files?: Blob[]
  file?: Blob
  filename?: string
  filenames?: string[]
  params?: any
  createXHR?: any
  progressSubscriber?: any
}) => {
  let payload
  if (files) {
    payload = makeFormFilesData(params, files, filenames)
  } else if (file) {
    payload = makeFormData(params, file, filename)
  } else {
    throw new Error('no file or files')
  }

  return ajax({
    url: uploadUrl,
    method: 'POST',
    headers: JWT_UPLOAD(token),
    body: payload,
    responseType: 'json',
    createXHR,
    progressSubscriber,
  })
}
