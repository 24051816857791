import {
  IonAlert,
  IonBackButton,
  IonBadge,
  IonButton,
  IonButtons,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenuButton,
  IonModal,
  IonPage,
  IonPopover,
  IonSpinner,
  IonText,
  IonTitle,
  IonToast,
  IonToolbar,
  isPlatform,
  useIonViewWillEnter,
  useIonViewWillLeave,
} from '@ionic/react'
import classNames from 'classnames'
import {
  alarmOutline,
  caretUp,
  informationCircleOutline,
  printOutline,
  save,
  shareSocialOutline,
  swapHorizontalOutline,
  trash,
} from 'ionicons/icons'
import moment from 'moment'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { RouteComponentProps, useHistory, useLocation } from 'react-router'
import PopupMenu from '../../components/PopupMenu'
import PreviewForm from '../../components/preview_form/PreviewForm'
import { inFunctionList } from '../../helpers/base'
import useModal from '../../hooks/useModal'
import { id } from '../../models/base'
import { DELAY_SHORT, DELAY_TOAST } from '../../models/constants'
import { EPreviewFormType } from '../../models/form'
import {
  IFormBuilderSchemaField,
  ISchemaFieldNameObject,
} from '../../models/formBuilder'
import {
  ETicketFunction,
  ETicketStatus,
  ETicketUserType,
  ITicket,
} from '../../models/ticket'
import { fetchCommentList } from '../../store/epics/comment'
import {
  createTicket,
  fetchTicket,
  removeTicket,
  updateTicket,
  updateTicketStatus,
} from '../../store/epics/ticket'
import { ReactRouterAction } from '../../store/epics/types'
import ListTicketComment from '../ListTicketComment'
import PrintTicketModal from './PrintTicketModal'
import './Ticket.scss'
import ReminderPreviewModal from '../../modals/reminder/ReminderPreviewModal'
import ToggleCommentFAB from '../../components/ToggleCommentFAB'

export type TicketProps = {
  userType: ETicketUserType
  pageWidth?: number
  pageHeight?: number
  isBrandNew?: boolean
  isSplitComment?: boolean /* true: 大螢幕，左右分割顯示工單與留言 / false: 小螢幕，按按鈕切換顯示留言 */
} & RouteComponentProps &
  ReactRouterAction

export const Ticket = (props: TicketProps) => {
  const { match, isSplitComment } = props
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const contentRef = useRef(null)
  const [isOpen, setIsOpen] = useState(false)
  const [isPageLoading, setIsPageLoading] = useState(false)
  const [isProcessing, setIsProcessing] = useState(false)
  const [showEmpty, setShowEmpty] = useState(false)
  const [isLoaded, setIsLoaded] = useState(false)
  const [isNewTicket, setIsNewTicket] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isReadyToSave, setIsReadyToSave] = useState(false)
  const [showTicketCreateToast, setShowTicketCreateToast] = useState(false)
  const [showTicketUpdateToast, setShowTicketUpdateToast] = useState(false)
  const [showTicketFailedToast, setShowTicketFailedToast] = useState(false)
  const [currentTicketStatus, setCurrentTicketStatus] =
    useState<ETicketStatus>()
  const [showChangeStatusAlert, setShowChangeStatusAlert] = useState(false)
  const [currentItem, setCurrentItem] = useState<any>()
  const [showTicketRemoveAlert, setShowTicketRemoveAlert] = useState(false)
  const [showTicketRemoveToast, setShowTicketRemoveToast] = useState(false)
  const [, setIsAllowCommentList] = useState(false)
  const [clickEvent, setClickEvent] = useState<any>() // 設定 popover 的出現位置
  const [showTicketInfoPopover, setShowTicketInfoPopover] = useState(false)
  const [showSharePublicUrlPopover, setShowSharePublicUrlPopover] =
    useState(false)
  const [showSrvPplCopyUrlToast, setShowSrvPplCopyUrlToast] = useState(false)
  const [showUserCopyUrlToast, setShowUserCopyUrlToast] = useState(false)

  const authDuck = useSelector((state: any) => state.authDuck)
  const ticket = useSelector((state: any) => state.ticket)

  const isPostponed =
    ticket.ticket_status === ETicketStatus.InProgress &&
    ticket.expected_end_date &&
    !ticket.actual_end_date &&
    moment().isAfter(ticket.expected_end_date, 'day')

  const [
    isShowPrintTicketModal,
    openPrintTicketModal,
    dismissPrintTicketModal,
  ] = useModal()

  const [
    isShowReminderPreviewModal,
    openReminderPreviewModal,
    dismissReminderPreviewModal,
  ] = useModal()

  // 切換顯示留言與工單（只在小螢幕使用）
  const [isShowCommentList, setIsShowCommentList] = useState(false)
  const toggleCommentList = useCallback((forceOpen: boolean = false) => {
    if (forceOpen) setIsShowCommentList(true)
    else setIsShowCommentList((isShow) => !isShow)
  }, [])

  // 透過 url 強制切換顯示留言（只在小螢幕使用）
  useIonViewWillEnter(() => {
    const searchParams = new URLSearchParams(location.search)
    if (searchParams.has('openCommentList')) {
      toggleCommentList(true)
    }
  }, [location.search])

  /**
   * 目前表單的資料內容，每當表單更新時會透過 handleChange 函式更新此變數
   */
  const [formData, setFormData] = useState<{
    values: ISchemaFieldNameObject<IFormBuilderSchemaField[]>
  }>({
    values: {},
  })

  useEffect(() => {
    if (!isOpen) return
    if (!authDuck.isUserLoaded) return

    if (match.params.key) {
      setIsPageLoading(true)
      dispatch(fetchTicket.cancel())
      dispatch(
        fetchTicket.request({
          key: match.params.key,
        })
      )
    } else {
      if (ticket.listKey) {
        dispatch(fetchTicket.cancel())
        setIsNewTicket(true)
        setIsPageLoading(true)
        setTimeout(() => {
          setIsPageLoading(false)
          setIsLoaded(true)
        }, DELAY_SHORT)
      } else {
        history.replace('/')
      }
    }
  }, [
    dispatch,
    match.params.key,
    setIsPageLoading,
    setIsLoaded,
    authDuck.isUserLoaded,
    ticket.listKey,
    history,
    isOpen,
  ])

  useEffect(() => {
    if (!isOpen) return
    if (!match.params.key) return

    dispatch(fetchCommentList.cancel())
    dispatch(
      fetchCommentList.request({
        key: match.params.key,
      })
    )
  }, [dispatch, isOpen, match.params.key])

  useIonViewWillEnter(() => {
    if (isOpen) return
    setIsOpen(true)
  }, [setIsOpen])

  useIonViewWillLeave(() => {
    if (!isOpen) return
    setIsOpen(false)
    setIsReadyToSave(false)
    dispatch(fetchTicket.cancel())
    setShowTicketFailedToast(false)
    setIsAllowCommentList(false)
  }, [setIsOpen, dispatch, isOpen])

  const scrollToTop = useCallback(() => {
    // @ts-ignore
    contentRef.current!.scrollToTop(DELAY_SHORT)
  }, [])

  useEffect(() => {
    if (ticket.key !== undefined && ticket.isDone) {
      setShowEmpty(true)
      setIsPageLoading(false)
      setIsProcessing(false)
      setIsLoaded(true)

      setCurrentTicketStatus(ticket.ticket_status)

      setIsSubmitting(false)
    }
  }, [
    setShowEmpty,
    setIsPageLoading,
    setIsProcessing,
    setCurrentTicketStatus,
    setIsSubmitting,
    ticket.key,
    ticket.isDone,
    ticket.ticket_status,
  ])

  useEffect(() => {
    if (!ticket.error) return
    setIsPageLoading(false)
    setIsSubmitting(true)
    setIsProcessing(false)
    setIsLoaded(true)
    setShowTicketFailedToast(true)
  }, [ticket.error])

  useEffect(() => {
    if (!ticket.updated) return
    setIsNewTicket(false)
    setShowTicketUpdateToast(true)
    scrollToTop()
  }, [ticket.updated, setShowTicketUpdateToast, scrollToTop])

  useEffect(() => {
    if (!ticket.statusUpdated) return
    setIsLoaded(true)
    setIsPageLoading(false)
  }, [ticket.statusUpdated, setIsLoaded, setIsPageLoading])

  useEffect(() => {
    if (!ticket.removed) return
    setIsNewTicket(false)
    setIsLoaded(false)
    setIsPageLoading(true)
    setShowTicketRemoveToast(true)
  }, [
    ticket.removed,
    setIsNewTicket,
    setIsLoaded,
    setIsPageLoading,
    setShowTicketRemoveToast,
  ])

  /**
   * 每當表單變動時會透過此函式更新表單資訊
   */
  const handleChange = async (values: any) => {
    setFormData(values)
    setIsReadyToSave(values.dirty && values.valid)
  }

  const handleSubmit = ({ values }: { values: Record<string, any> }) => {
    setIsPageLoading(true)
    setIsSubmitting(true)

    let base_data = Object.fromEntries(
      Object.entries(values).filter(
        ([key, val]) =>
          !(
            key.startsWith('custom_fields_') ||
            key.startsWith('itn_custom_fields_')
          )
      )
    )
    if (base_data?.expected_end_date)
      base_data = {
        ...base_data,
        expected_end_date: moment(base_data.expected_end_date).format(
          'YYYY-MM-DD'
        ),
      }
    else
      base_data = {
        ...base_data,
        expected_end_date: null,
      }

    // 點擊 + 號按鈕新增的維修人員 2，將其資料放到 base_data 中
    if (base_data?.serv_ppl_2?.length) {
      const { serv_ppl_2, ...rest } = base_data

      base_data = {
        ...rest,
        serv_ppl_2_name: serv_ppl_2[0].serv_ppl_2_name,
        serv_ppl_2_phone: serv_ppl_2[0].serv_ppl_2_phone,
      }
    }

    const custom_fields_data = Object.fromEntries(
      Object.entries(values).filter(([key, val]) =>
        key.startsWith('custom_fields_')
      )
    )

    const itn_custom_fields_data = Object.fromEntries(
      Object.entries(values).filter(([key, val]) =>
        key.startsWith('itn_custom_fields_')
      )
    )

    if (ticket.ticket_status === ETicketStatus.Done) {
      dispatch(
        updateTicket.request({
          key: ticket.key,
          ticket_status: values.ticket_status,
        })
      )
    } else {
      if (isNewTicket) {
        if (ticket.listKey) {
          dispatch(
            createTicket.request({
              key: ticket.listKey,
              ...base_data,
              custom_fields_data: JSON.stringify(custom_fields_data),
              itn_custom_fields_data: JSON.stringify(itn_custom_fields_data),
            })
          )
        }
      } else {
        dispatch(
          // Notice: 發送更新工單的請求時，如果不先將欄位設定為 null，當使用者將欄位清空後
          // 會導致該欄位的值不會被更新
          updateTicket.request({
            key: ticket.key,
            serv_ppl_name: null,
            serv_ppl_phone: null,
            serv_ppl_2_name: null,
            serv_ppl_2_phone: null,
            customer_name: null,
            customer_phone: null,
            memo: null,
            ...base_data,
            custom_fields_data: JSON.stringify(custom_fields_data),
            itn_custom_fields_data: JSON.stringify(itn_custom_fields_data),
          })
        )
      }
    }
  }

  const done = () => {
    handleSubmit(formData)
  }

  const getTicketName = (item: ITicket) => {
    if (isPlatform('mobile') && !isPlatform('ipad')) {
      return item?.short_display_name || item?.name
    }
    return item?.display_name || item?.name
  }

  const copyTicketPublicUrlToClipboard = (userType: 'u' | 's') => {
    const publicUrl = `${window.location.origin}/public/ticket/${userType}/${ticket.key}`

    navigator.clipboard
      .writeText(publicUrl) // write public url to clipboard
      .then(() => {
        // dismiss popover and reset click event
        setShowSharePublicUrlPopover(false)
        setClickEvent(undefined)

        // show toast notification
        userType === 'u'
          ? setShowUserCopyUrlToast(true)
          : setShowSrvPplCopyUrlToast(true)
      })
  }

  const changeStatusAction = (newStatus: ETicketStatus) => {
    if (newStatus && currentTicketStatus !== newStatus) {
      setIsLoaded(false)
      setIsPageLoading(true)

      dispatch(
        updateTicketStatus.request({
          key: ticket.key,
          ticket_status: newStatus,
        })
      )
    }
  }

  const removeTicketAction = ({ key, name }: { key: id; name: string }) => {
    if (!key) return
    dispatch(
      removeTicket.request({
        key,
        name,
      })
    )
  }

  return (
    <IonPage className="page-ticket">
      <PopupMenu></PopupMenu>
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>

          {/* 返回按鈕 */}
          <IonButtons slot="start">
            <IonBackButton
              defaultHref={
                ticket?.listKey ? `/tracking/${ticket.listKey}` : `/home`
              }
            />
          </IonButtons>

          {/* Ticket 名稱 */}
          <IonTitle>
            {isPlatform('desktop') ? (
              <>{ticket?.name}</>
            ) : (
              <>
                <IonButton className="logo immutable">
                  {t('Suiqui Support')}
                </IonButton>
                <span
                  className="subtitle"
                  hidden={isPlatform('mobile') && !isPlatform('ipad')}
                >
                  {ticket?.name}
                </span>
              </>
            )}
          </IonTitle>

          {/* 「立即存檔」按鈕 */}
          {(isNewTicket ||
            inFunctionList(
              ETicketFunction.ticket_update,
              ticket?.function_list
            )) && (
            <IonButtons slot="end">
              <IonButton
                className="ion-padding-end"
                strong
                color="light"
                fill="clear"
                slot="icon-end"
                disabled={
                  isPageLoading ||
                  isProcessing ||
                  !isLoaded ||
                  !isReadyToSave ||
                  isSubmitting
                }
                data-test="save-btn"
                onClick={done}
              >
                <IonIcon slot="start" icon={save}></IonIcon>
                <IonLabel>{t('Save')}</IonLabel>
              </IonButton>
            </IonButtons>
          )}
        </IonToolbar>
      </IonHeader>

      <IonContent
        className="ion-padding bg"
        ref={contentRef}
        scrollEvents={true}
      >
        {/* 工單內容 */}
        <div hidden={isShowCommentList}>
          {/* 讀取中進度條 */}
          {isPageLoading && (
            <div className="ion-text-center centered">
              <IonSpinner color="primary" name="crescent"></IonSpinner>
            </div>
          )}

          {!isPageLoading && (
            <>
              {isNewTicket ? (
                <IonListHeader className="subject">
                  {/* 「新增工單」提示 */}
                  <IonLabel>
                    <h2>{t('New Ticket')}</h2>
                  </IonLabel>
                </IonListHeader>
              ) : (
                <IonListHeader className="subject" color="light">
                  <IonLabel>
                    {/* Ticket 名稱 */}
                    <h2>
                      {isPlatform('desktop') || isPlatform('ipad') ? (
                        getTicketName(ticket)
                      ) : (
                        <>
                          {ticket?.name &&
                            `${getTicketName(ticket)} ${' ｜ '} ${
                              ticket?.name
                            }`}
                        </>
                      )}
                    </h2>

                    <h3 className="ion-text-wrap status-date-info">
                      {/* Ticket 狀態（「進行中」,「已完成」或「觀察中」）*/}
                      {ticket.ticket_status && (
                        <IonBadge
                          slot="start"
                          data-test="ticket-status"
                          className={classNames({
                            'ticket-status': true,
                            'ticket-status-done':
                              ticket.ticket_status === ETicketStatus.Done,
                            'ticket-status-in-progress':
                              ticket.ticket_status === ETicketStatus.InProgress,
                            'ticket-status-observing':
                              ticket.ticket_status === ETicketStatus.Observing,
                            'ticket-status-postponed': isPostponed,
                            'ticket-status-waiting':
                              ticket.ticket_status === ETicketStatus.Waiting,
                            'ticket-status-preparing':
                              ticket.ticket_status === ETicketStatus.Preparing,
                          })}
                        >
                          {isPostponed
                            ? t(`Ticket Postponed`)
                            : t(`Ticket ${ticket.ticket_status}`)}
                        </IonBadge>
                      )}
                      {/* 開單日 */}
                      <IonText
                        color="primary"
                        hidden={!ticket.create_datetime}
                        data-test="created-date"
                      >
                        <span>{t('Created Date')} </span>
                        {ticket.create_datetime &&
                          `${moment(ticket.create_datetime).format(
                            'YYYY-MM-DD'
                          )}`}
                      </IonText>

                      {/* 預計完成日 */}
                      <IonText
                        color="secondary"
                        hidden={!ticket.expected_end_date}
                        data-test="expected-end-date"
                      >
                        <span>{t('Expected End Date')} </span>
                        {ticket.expected_end_date &&
                          `${moment(ticket.expected_end_date).format(
                            'YYYY-MM-DD'
                          )}`}
                      </IonText>

                      {/* 開始觀察日 */}
                      <IonText
                        className="observation-start-date"
                        hidden={!ticket.observation_start_date}
                        data-test="observation-start-date"
                      >
                        <span>{t('Observation Start Date')} </span>
                        {ticket.observation_start_date &&
                          `${moment(ticket.observation_start_date).format(
                            'YYYY-MM-DD'
                          )}`}
                      </IonText>

                      {/* 實際完成日 */}
                      <IonText
                        color="success"
                        hidden={!ticket.actual_end_date}
                        data-test="actual-end-date"
                      >
                        <span>{t('Actual End Date')} </span>
                        {ticket.actual_end_date &&
                          `${moment(ticket.actual_end_date).format(
                            'YYYY-MM-DD'
                          )}`}
                      </IonText>
                    </h3>
                  </IonLabel>
                </IonListHeader>
              )}

              <IonList lines="inset" className="bg">
                {isProcessing && (
                  <IonListHeader>
                    <IonSpinner color="primary" name="dots"></IonSpinner>
                  </IonListHeader>
                )}
                {!isProcessing &&
                  ticket?.isDone &&
                  !ticket?.key &&
                  !ticket?.error &&
                  showEmpty &&
                  !isNewTicket && (
                    <IonCard>
                      <IonCardHeader color="light">
                        <IonCardTitle className="ion-text-center">
                          <IonLabel color="medium">{t('No Ticket')}</IonLabel>
                        </IonCardTitle>
                      </IonCardHeader>
                    </IonCard>
                  )}
                {!isProcessing && isLoaded && (
                  <>
                    {!isNewTicket && (
                      <>
                        <IonToolbar color="light" className="ticket-toolbar">
                          {/* 顯示工單詳細資訊按鈕 */}
                          <IonButtons>
                            <IonButton
                              color="primary"
                              fill="clear"
                              slot="icon-only"
                              onClick={(e) => {
                                setClickEvent(e)
                                setShowTicketInfoPopover(true)
                              }}
                            >
                              <IonIcon
                                slot="icon-only"
                                icon={informationCircleOutline}
                              />
                            </IonButton>

                            {/* 顯示工單分享連結選單按鈕 */}
                            <IonButton
                              color="primary"
                              fill="clear"
                              slot="icon-only"
                              onClick={(e) => {
                                setClickEvent(e)
                                setShowSharePublicUrlPopover(true)
                              }}
                            >
                              <IonIcon
                                slot="icon-only"
                                icon={shareSocialOutline}
                              />
                            </IonButton>

                            {/* 設定 節點提醒 功能按鈕 */}
                            <IonButton
                              color="primary"
                              fill="clear"
                              slot="icon-only"
                              onClick={openReminderPreviewModal}
                            >
                              <IonIcon slot="icon-only" icon={alarmOutline} />
                            </IonButton>

                            {/* 工單列印按鈕 */}
                            <IonButton
                              color="primary"
                              fill="clear"
                              slot="icon-only"
                              onClick={openPrintTicketModal}
                            >
                              <IonIcon slot="icon-only" icon={printOutline} />
                            </IonButton>
                          </IonButtons>

                          {/* 工單詳細資訊 Popover */}
                          <IonPopover
                            id="ticket-id-popover"
                            event={clickEvent}
                            isOpen={showTicketInfoPopover}
                            onDidDismiss={() => {
                              setClickEvent(undefined)
                              setShowTicketInfoPopover(false)
                            }}
                          >
                            {/* 工單編號 */}
                            <IonItem className="ticket-id">
                              <IonLabel>
                                {t('Ticket id')}：{ticket.id}
                              </IonLabel>
                            </IonItem>

                            {/* 開單者 */}
                            <IonItem className="ticket-id">
                              <IonLabel>
                                {t('Creator')}：
                                {ticket.user?.user_type === 'service'
                                  ? t('Create by QR Code')
                                  : ticket.user?.displayname}
                              </IonLabel>
                            </IonItem>
                          </IonPopover>

                          {/* 工單分享連結選單 Popover */}
                          <IonPopover
                            event={clickEvent}
                            isOpen={showSharePublicUrlPopover}
                            onDidDismiss={() => {
                              setClickEvent(undefined)
                              setShowSharePublicUrlPopover(false)
                            }}
                          >
                            <IonItem
                              button
                              onClick={() => {
                                copyTicketPublicUrlToClipboard('u')
                              }}
                            >
                              <IonLabel>{t('Public url user')}</IonLabel>
                            </IonItem>
                            <IonItem
                              button
                              lines="none"
                              onClick={() => {
                                copyTicketPublicUrlToClipboard('s')
                              }}
                            >
                              <IonLabel>{t('Public url service_ppl')}</IonLabel>
                            </IonItem>
                          </IonPopover>

                          {/* 「切換狀態」按鈕 */}
                          <IonButtons slot="end">
                            {inFunctionList(
                              'ticket_status_change',
                              ticket?.function_list
                            ) &&
                              currentTicketStatus && (
                                <IonButton
                                  color="primary"
                                  fill="clear"
                                  data-test="change-status-btn"
                                  onClick={() => {
                                    setShowChangeStatusAlert(true)
                                  }}
                                >
                                  <IonIcon
                                    slot="start"
                                    icon={swapHorizontalOutline}
                                  />
                                  {t('Change status')}
                                </IonButton>
                              )}
                          </IonButtons>
                        </IonToolbar>
                      </>
                    )}

                    {/* 表單 */}
                    <PreviewForm
                      type={EPreviewFormType.ticket}
                      isFillMode={ticket.ticket_status !== ETicketStatus.Done}
                      item={ticket}
                      // onSubmit 沒有用
                      onSubmit={handleSubmit}
                      // 使用 onChange callback 取得最新的表單資料
                      onChange={handleChange}
                    />
                  </>
                )}
              </IonList>

              {/* 回到上面按鈕 */}
              <div className="ion-text-center" hidden={isProcessing}>
                <IonButton
                  color="warning"
                  fill="clear"
                  size="large"
                  slot="icon-only"
                  onClick={scrollToTop}
                >
                  <IonIcon icon={caretUp} />
                </IonButton>
              </div>

              {/* 「刪除」按鈕 */}
              {!isNewTicket &&
                ticket?.key &&
                inFunctionList(
                  ETicketFunction.ticket_delete,
                  ticket.function_list
                ) && (
                  <>
                    {
                      <IonToolbar color="light">
                        <IonButton
                          disabled={ticket.error}
                          color="danger"
                          fill="clear"
                          hidden={isProcessing}
                          onClick={(e) => {
                            setCurrentItem(ticket)
                            setShowTicketRemoveAlert(true)
                          }}
                        >
                          <IonIcon slot="start" icon={trash} />
                          <IonLabel>{t('Remove')}</IonLabel>
                        </IonButton>
                      </IonToolbar>
                    }
                  </>
                )}
            </>
          )}
        </div>

        {/* 工單留言（只在小螢幕顯示） */}
        <div hidden={!isShowCommentList}>
          <ListTicketComment
            {...props}
            isAllowNewComment={
              ticket?.ticket_status === ETicketStatus.InProgress
            }
          ></ListTicketComment>
        </div>

        <IonAlert
          isOpen={showChangeStatusAlert}
          onDidDismiss={() => setShowChangeStatusAlert(false)}
          header={t('Change status')}
          inputs={[
            {
              type: 'radio',
              label: t('Ticket Done'),
              value: 'Done',
              checked: ticket.ticket_status === ETicketStatus.Done,
            },
            {
              type: 'radio',
              label: t('Ticket InProgress'),
              value: 'InProgress',
              checked: ticket.ticket_status === ETicketStatus.InProgress,
            },
            {
              type: 'radio',
              label: t('Ticket Observing'),
              value: 'Observing',
              checked: ticket.ticket_status === ETicketStatus.Observing,
            },
            {
              type: 'radio',
              label: t('Ticket Preparing'),
              value: 'Preparing',
              checked: ticket.ticket_status === ETicketStatus.Preparing,
            },
            {
              type: 'radio',
              label: t('Ticket Waiting'),
              value: 'Waiting',
              checked: ticket.ticket_status === ETicketStatus.Waiting,
            },
          ]}
          buttons={[
            {
              text: t('Cancel'),
              role: 'cancel',
              cssClass: 'cancel',
              handler: () => {},
            },
            {
              text: t('OK'),
              cssClass: 'ok',
              handler: (newStatus: ETicketStatus) => {
                changeStatusAction(newStatus)
              },
            },
          ]}
        />

        <IonAlert
          keyboardClose={true}
          isOpen={showTicketRemoveAlert}
          onDidDismiss={() => setShowTicketRemoveAlert(false)}
          header={t('Notice')}
          message={t('Click OK to remove this item.')}
          buttons={[
            {
              text: t('Cancel'),
              role: 'cancel',
              cssClass: 'cancel',
              handler: () => {},
            },
            {
              text: t('OK'),
              cssClass: 'ok',
              handler: (params) => {
                removeTicketAction({ ...currentItem, ...params })
              },
            },
          ]}
        />

        <IonToast
          isOpen={showTicketCreateToast}
          onWillPresent={() => {
            setIsPageLoading(false)
            setIsSubmitting(false)
            setIsReadyToSave(false)
            dispatch(createTicket.cancel())
          }}
          onDidDismiss={() => {
            setShowTicketCreateToast(false)
          }}
          message={t('“{{target}}” has been created successfully.', {
            target: getTicketName(ticket),
          })}
          duration={DELAY_TOAST}
        />

        <IonToast
          isOpen={showTicketUpdateToast}
          onDidDismiss={() => {
            setIsLoaded(true)
            setIsProcessing(false)
            setIsPageLoading(false)
            setIsSubmitting(false)
            setIsReadyToSave(false)
            setShowTicketUpdateToast(false)
            dispatch(updateTicket.cancel())
          }}
          message={t('“{{target}}” has been updated successfully.', {
            target: getTicketName(ticket),
          })}
          duration={DELAY_TOAST}
        />

        <IonToast
          isOpen={showTicketFailedToast}
          onDidDismiss={() => setShowTicketFailedToast(false)}
          message={t(ticket?.error?.response)}
          color="danger"
          duration={DELAY_TOAST}
        />

        <IonToast
          isOpen={showTicketRemoveToast}
          onDidDismiss={() => {
            setShowTicketRemoveToast(false)
            history.push(
              ticket?.listKey ? `/tracking/${ticket.listKey}` : `/home`,
              {
                direction: 'root',
              }
            )
            dispatch(removeTicket.cancel())
          }}
          message={t('“{{target}}” has been removed successfully.', {
            target: getTicketName(ticket),
          })}
          duration={DELAY_TOAST}
        />

        <IonToast
          isOpen={showUserCopyUrlToast}
          onDidDismiss={() => setShowUserCopyUrlToast(false)}
          message={t('The User public link is copied to clipboard.')}
          duration={DELAY_TOAST}
        />

        <IonToast
          isOpen={showSrvPplCopyUrlToast}
          onDidDismiss={() => setShowSrvPplCopyUrlToast(false)}
          message={t('The Service PPL public link is copied to clipboard.')}
          duration={DELAY_TOAST}
        />

        {/* 列印工單 modal */}
        {isShowPrintTicketModal && (
          <IonModal isOpen={isShowPrintTicketModal} backdropDismiss={false}>
            {isShowPrintTicketModal && (
              <PrintTicketModal
                ticket={ticket}
                dismiss={dismissPrintTicketModal}
              />
            )}
          </IonModal>
        )}

        {/* 節點提醒預覽 modal */}
        {isShowReminderPreviewModal && (
          <IonModal isOpen={isShowReminderPreviewModal} backdropDismiss={false}>
            <ReminderPreviewModal
              dismiss={dismissReminderPreviewModal}
              itemKey={ticket?.key}
              notificationBells={ticket?.notification_bells}
            />
          </IonModal>
        )}
      </IonContent>

      {/* 顯示/關閉留言區的按鈕，於小畫面顯示 */}
      <ToggleCommentFAB
        hidden={isSplitComment}
        vertical="bottom"
        horizontal="end"
        slot="fixed"
        color="light"
        isShowCommentList={isShowCommentList}
        toggleCommentList={toggleCommentList}
      />
    </IonPage>
  )
}

export default Ticket
