import { createActions, createReducer } from 'reduxsauce'

export const { Types, Creators } = createActions({
  login: ['token'],
  logout: [],
  checkIn: ['user'],
  openMenu: ['opened'],
  setLoading: ['isLoading'],
  setLocation: ['location'],
})

const INITIAL_STATE: any = {
  token: null,
  isAuthenticated: false,
  isOnline: false,
  currentUser: null,
  opened: false,
  isUserLoaded: false,
  isRunning: false,
  isLogout: false,
}

const login = (state = INITIAL_STATE, { token }: any) => {
  return {
    ...state,
    token,
    isAuthenticated: !!token,
    isUserLoaded: false,
    isRunning: true,
    isLogout: false,
  }
}

const logout = (state = INITIAL_STATE) => {
  return {
    ...state,
    token: null,
    isAuthenticated: false,
    isOnline: false,
    isUserLoaded: false,
    currentUser: null,
    isLogout: true,
  }
}

const checkIn = (state = INITIAL_STATE, { user }: any) => {
  return {
    ...state,
    currentUser: user,
    isOnline: user && state.isAuthenticated,
    isUserLoaded: !!user,
    isRunning: true,
    isLogout: false,
  }
}

const openMenu = (state = INITIAL_STATE, { opened }: any) => {
  return { ...state, opened }
}

const setLoading = (state = INITIAL_STATE, { isLoading }: any) => {
  return { ...state, isLoading }
}

const setLocation = (state = INITIAL_STATE, { location }: any) => {
  return { ...state, location }
}

export default createReducer(INITIAL_STATE, {
  [Types.LOGIN]: login,
  [Types.LOGOUT]: logout,
  [Types.CHECK_IN]: checkIn,
  [Types.OPEN_MENU]: openMenu,
  [Types.SET_LOADING]: setLoading,
  [Types.SET_LOCATION]: setLocation,
})
