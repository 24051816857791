import { isPlatform } from '@ionic/core'
import * as mimeTypes from 'mime-types'
import { url } from '../models/base'
import { IFile, ISuiquiFile, ISuiquiVersionFile } from '../models/suiquiFile'
import { IUser } from '../models/user'
import { RefObject } from 'react'
import html2canvas from 'html2canvas'

export const isExtImage = (file: IFile) => {
  return (
    file?.content_type === 'image/webp' || file?.content_type === 'image/tiff'
  )
}

export const isFilePdf = (file: IFile) => {
  return file?.content_type === 'application/pdf'
}

export const isVersionMedia = (file: IFile) => {
  if (file.blob_info?.content_type.startsWith('video/mp4')) {
    return true
  } else if (file.blob_info?.content_type.startsWith('audio/')) {
    return true
  } else if (
    file.blob_info?.content_type.startsWith('application/pdf') &&
    !isPlatform('mobile')
  ) {
    return true
  }
  return false
}

export const getPreviewImage = (file: File) => {
  // BUGGY AND LAG
  return file.type.startsWith('image/') ? window.URL.createObjectURL(file) : ''
}

export const isRetina = () => {
  const mediaQuery =
    '(-webkit-min-device-pixel-ratio: 1.5), (min--moz-device-pixel-ratio: 1.5), (-o-min-device-pixel-ratio: 3/2), (min-resolution: 1.5dppx)'
  if (window.devicePixelRatio > 1) return true
  if (window.matchMedia && window.matchMedia(mediaQuery).matches) return true
  return false
}

export const getImageUrl = (
  file: IFile,
  size?: number,
  sizeAndCrop?: string
) => {
  return file?.is_image || isExtImage(file)
    ? size !== undefined
      ? `${file.url}=s${isRetina() ? size * 2 : size}${
          sizeAndCrop ? `-${sizeAndCrop}` : ''
        }`
      : file.url
    : ''
}

export const getAvatarUrl = (
  currentUser: IUser,
  size = 36,
  sizeAndCrop = 'c'
) => {
  return currentUser?.profile_pic
    ? `${currentUser?.profile_pic}=s${isRetina() ? size * 2 : size}${
        sizeAndCrop ? `-${sizeAndCrop}` : ''
      }`
    : require('../assets/img/default.png').default
}

export const getFallbackImage = (event: any) => {
  event.target.onerror = null
  event.target.src = require('../assets/img/suiqui-placeholder.png').default
  event.target.srcSet = `${
    require('../assets/img/suiqui-placeholder@2x.png').default
  } 2x`
}

export const getDownloadUrl = (file: IFile) => {
  return file.is_image ? `${file.url}=s0` : file.url
}

export const blobtoDataURL = (blob: Blob, callback: Function) => {
  const fr = new FileReader()
  fr.onload = function (e) {
    callback(e.target?.result)
  }
  fr.readAsDataURL(blob)
}

export const isWebview = () => {
  const useragent = navigator.userAgent
  const rules = [
    'WebView',
    '(iPhone|iPod|iPad)(?!.*Safari/)',
    'Android.*(wv|.0.0.0)',
  ]
  const regex = new RegExp(`(${rules.join('|')})`, 'ig')
  return Boolean(useragent.match(regex))
}

export const openDocument = (
  file: IFile,
  external?: false,
  errMessage?: string
) => {
  if (!file.url) return

  if (
    isWebview() &&
    isPlatform('ios') &&
    isPlatform('mobileweb') &&
    !isFilePdf(file) &&
    !file?.is_image
  ) {
    if (errMessage) {
      alert(errMessage)
    } else {
      alert('iOS in-app')
    }
    return
  }

  if (isPlatform('ios') && isPlatform('pwa') && isFilePdf(file)) {
    window.location.href = file.url
  } else {
    if (file?.is_image || isExtImage(file)) {
      window.open(file.url, '_blank')
    } else {
      window.open(
        external ? `${file.url}` : `${file.url}${file.name}`,
        '_blank'
      )
    }
  }
}

export const dataURLtoBlob = (dataUrl: url) => {
  if (!dataUrl) return ''
  const arr = dataUrl.split(',')
  const mime = arr[0].match(/:(.*?);/)![1]
  const bstr = atob(arr[1])
  let n = bstr.length
  const u8arr = new Uint8Array(n)
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }
  return new Blob([u8arr], { type: mime })
}

export const hashCode = (s: string) =>
  s
    .split('')
    .reduce((a: any, b: any) => ((a << 5) - a + b.charCodeAt(0)) | 0, 0)

export const getFilenameNoExt = (filename: string) => {
  if (!filename) return
  const name = filename.substr(0, filename.lastIndexOf('.')) || filename
  return name
}

export const getFilenameExt = (filename: string) => {
  if (!filename) return { name: filename, ext: null }
  const _filenameArray = filename.split('.')
  const name = filename.substr(0, filename.lastIndexOf('.')) || filename
  const ext =
    _filenameArray.length > 1 ? _filenameArray.pop()?.toLowerCase() : null
  return ext
}

export const getFilenameSplit = (filename: string) => {
  if (!filename) return { name: filename, ext: null }
  const _filenameArray = filename.split('.')
  const name = filename.substr(0, filename.lastIndexOf('.')) || filename
  const ext =
    _filenameArray.length > 1 ? _filenameArray.pop()?.toLowerCase() : null
  return {
    name,
    ext,
  }
}

export const getFileExt = (file: IFile) => {
  if (!file) return
  if (file?.content_type) {
    if (file.content_type === 'application/x-zip-compressed') {
      return 'zip'
    }
    const ext = mimeTypes.extension(file.content_type)
    if (ext === 'webp') {
      return 'png'
    } else if (ext === 'mpga') {
      return getFilenameSplit(`${file.name}`).ext
    } else if (ext === 'bin') {
      const ext2 = mimeTypes.extension(
        mimeTypes.contentType(file.name || '') || ''
      )

      if (!ext2) {
        return (getFilenameExt(file.name || '') as string) || ext2
      }

      if (ext2 === 'markdown') {
        return 'md'
      }
      return ext2
    } else {
      return ext
    }
  }
  if (file?.type) return mimeTypes.extension(file.type)
  return 'file'
}

export const getProgressKey = (file: File, index: number) => {
  return hashCode(`${file.name}-${index}`) || 0
}

export const getIFileProgressKey = (file: IFile, factor?: number) =>
  hashCode(`${file?.key}`) * (factor || 1) || 0

export const getProgressNumber = (pFile: any) => {
  if (pFile?.isFailed) return 100
  return pFile?.isDone ? 100 : pFile?.percentage / 100 || 0
}

export const getProgressBarColor = (pFile: any) => {
  if (pFile?.percentage >= 100) return 'success'
  if (pFile?.isDone) return 'success'
  if (pFile?.isCancel && pFile?.isDone) return 'medium'
  if (pFile?.isCancel) return 'danger'
  return 'warning'
}

const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
)

export const getFileUrl = (file: ISuiquiFile) => {
  return isLocalhost
    ? `${window.location.protocol}//${window.location.host}/file/${
        file.key || ''
      }`
    : `${process.env.REACT_APP_SITE_ENDPOINT}/file/${file.key || ''}`
}

export const getFilenameWithVersion = (item: ISuiquiFile, skipV1: boolean) => {
  if (!item?.version_file?.version) {
    return item.name
  }
  if (skipV1 && item.version_file.version === 1) {
    return item?.version_file?.name
  }
  const filename = getFilenameSplit(item?.version_file?.name)
  return `${filename.name}_v${item?.version_file?.version}.${filename.ext}`
}

export const getVersionFilenameWithVersion = (
  item: ISuiquiVersionFile,
  skipV1: boolean
) => {
  if (!item?.version) {
    return item.name
  }
  if (skipV1 && item.version === 1) {
    return item?.name
  }
  const filename = getFilenameSplit(item?.name)
  return `${filename.name}_v${item?.version}.${filename.ext}`
}

export const getVersionFilename = (file: ISuiquiVersionFile, name?: string) => {
  if (!file.version) {
    return name || file.name
  }
  const filename = getFilenameSplit(name || file.name)
  return `${filename.name}_v${file.version}.${filename.ext}`
}

export const getVersionUrl = (file: ISuiquiVersionFile) => {
  const isLocalhost = Boolean(
    window.location.hostname === 'localhost' ||
      // [::1] is the IPv6 localhost address.
      window.location.hostname === '[::1]' ||
      // 127.0.0.1/8 is considered localhost for IPv4.
      window.location.hostname.match(
        /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
      )
  )

  return isLocalhost
    ? `${window.location.protocol}//${window.location.host}/file/version/${file.key}`
    : `${process.env.REACT_APP_SITE_ENDPOINT}/file/version/${file.key}`
}

export const downloadCSV = (filename: string, csvStr: string) => {
  const hiddenElement = document.createElement('a')
  hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvStr)
  hiddenElement.target = '_blank'
  hiddenElement.download = filename + '.csv'
  hiddenElement.click()
}

// 列印頁面，input 須為 html 字串
export function printHtmlPage(data: string, filename: string) {
  // 將要列印的 html 變成隱形的 iframe element 並新增到 dom 中
  const blob = new Blob([data], { type: 'text/html' })
  const file = window.URL.createObjectURL(blob)
  const hiddenFrame = document.createElement('iframe')
  hiddenFrame.style.visibility = 'hidden'
  hiddenFrame.src = file

  // 列印 iframe element
  hiddenFrame.onload = function () {
    // 更改 title 為要列印的預設檔名
    const originalTitle = document.title
    document.title = filename

    // 在列印結束後，移除剛剛在 dom 中新增的 iframe element，並改回原本的 title
    function onClosePrint() {
      document.body.removeChild(hiddenFrame)
      document.title = originalTitle
    }
    const contentWindow = hiddenFrame.contentWindow
    if (!contentWindow) return
    contentWindow.onbeforeunload = onClosePrint
    contentWindow.onafterprint = onClosePrint
    contentWindow.focus()
    contentWindow.print()
  }
  document.body.appendChild(hiddenFrame)
}

/**
 * 判斷一個 js 原生的檔案型別是否為圖片
 */
export function isRawFileImage(file: File) {
  return file.type.includes('image')
}

// 將 component 轉成圖片並下載
export const exportComponentAsImage = async <T extends HTMLElement>(
  componentRef: RefObject<T>
) => {
  const element = componentRef.current

  if (!element) {
    throw new Error('exportComponentAsImage: element not found')
  }

  const canvas = await html2canvas(element)
  const data = canvas.toDataURL('image/jpeg')
  const link = document.createElement('a')

  link.href = data
  link.download = 'image.jpg'

  // 將 link 加入到 body 中，並觸發點擊事件，下載圖片
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}
