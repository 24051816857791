import { ActionType, createAsyncAction } from 'typesafe-actions'
import { public_token } from '../../models/base'
import { ITemplate } from '../../models/template'
import { ITicket } from '../../models/ticket'
import { ITeamMeta, IPublicTeamMeta } from '../../models/teamMeta'

/**
 *  使用公開 API token 讀取 template 資訊
 */
export const fetchPublicTemplate = createAsyncAction(
  'FETCH_PUBLIC_TEMPLATE_REQUEST',
  'FETCH_PUBLIC_TEMPLATE_SUCCESS',
  'FETCH_PUBLIC_TEMPLATE_FAILURE',
  'FETCH_PUBLIC_TEMPLATE_CANCEL'
)<public_token, ITemplate, Error, undefined>()

/**
 *  使用公開 API token 建立新工單
 */
export const createPublicTicket = createAsyncAction(
  'CREATE_PUBLIC_TICKET_REQUEST',
  'CREATE_PUBLIC_TICKET_SUCCESS',
  'CREATE_PUBLIC_TICKET_FAILURE',
  'CREATE_PUBLIC_TICKET_CANCEL'
)<Partial<ITicket> & { publicToken: public_token }, ITicket, Error, undefined>()

/**
 * 使用公開 API token 取得 teamMeta
 */
export const fetchPublicTeamMeta = createAsyncAction(
  'FETCH_PUBLIC_TEAM_META_REQUEST',
  'FETCH_PUBLIC_TEAM_META_SUCCESS',
  'FETCH_PUBLIC_TEAM_META_FAILURE',
  'FETCH_PUBLIC_TEAM_META_CANCEL'
)<Partial<IPublicTeamMeta>, ITeamMeta, Error, undefined>()

export type PublicTicketAction =
  | ActionType<typeof fetchPublicTemplate>
  | ActionType<typeof createPublicTicket>
  | ActionType<typeof fetchPublicTeamMeta>
