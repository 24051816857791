import FormTemplateCommonProps from '@data-driven-forms/common/form-template'
import { componentTypes, Field, FormSpy } from '@data-driven-forms/react-form-renderer'
import FormRenderer from '@data-driven-forms/react-form-renderer/form-renderer'
import useFormApi from '@data-driven-forms/react-form-renderer/use-form-api'
import Validators from '@data-driven-forms/react-form-renderer/validators'
import { IonList } from '@ionic/react'
import { default as classNames } from 'classnames'
import React, { useCallback, useMemo } from 'react'
import { useBeforeunload } from 'react-beforeunload'
import { useTranslation } from 'react-i18next'
import {
  customComponentMapper,
  loadSubSchemaFromItem,
} from '../../helpers/schema'
import { clone } from '../../helpers/util'
import { EFormBuilderSchemaComponentType } from '../../models/formBuilder'
import { defaultDatePickerProps } from '../../models/schema'
import { ITemplate } from '../../models/template'
import { ITicket } from '../../models/ticket'
import './FilterForm.scss'

type PreviewFormTemplateProp = FormTemplateCommonProps
const FormTemplate = ({
  schema,
  formFields,
  onChange,
}: PreviewFormTemplateProp) => {
  const { handleSubmit, getState } = useFormApi()
  const { dirty } = getState()

  // 關閉視窗時如果有未儲存的輸入會跳出提醒
  useBeforeunload((event: any) => {
    if (dirty) event.preventDefault()
  })

  return (
    <>
      <form onSubmit={handleSubmit}>
        <IonList className="form-container">
          {schema.title}
          {formFields}
          <FormSpy
            subscription={{ values: true, valid: true, dirty: true }}
            onChange={(props) => onChange?.(props)}
          />
        </IonList>
      </form>
    </>
  )
}

const propsAreEqual = (prevProps: any, nextProps: any) => {
  const prevValue = JSON.stringify(prevProps.form?.getState())
  const nextValue = JSON.stringify(nextProps.form?.getState())
  return prevValue === nextValue
}

interface FilterFormProps {
  item: ITicket
  onChange: Function
  initialValues: any
}

const FilterForm = ({ item, onChange, initialValues }: FilterFormProps) => {
  const { t } = useTranslation()
  const currentItem = useMemo(() => clone(item) as ITicket, [item])

  //@ts-ignore
  Validators.messages = {
    //@ts-ignore
    ...Validators.messages,
    required: t('Required'),
  }

  const FilterFormTemplate = (props: PreviewFormTemplateProp) => (
    <div style={{ position: 'relative' }}>
      <div>
        <FormTemplate {...props} onChange={onChange} />
      </div>
    </div>
  )

  // 只能篩選屬於 checkbox 或 select 兩種類型的欄位，並且將 select 類型的欄位轉換成 checkbox
  // 附註：checkbox = 能選擇多個選項 / select = 只能選擇一個選項
  const transformSubSchemaFields = useCallback((fields?: Field[]) => {
    return (fields || [])
      .filter(
        (field) =>
          field.component === EFormBuilderSchemaComponentType.checkbox ||
          field.component === EFormBuilderSchemaComponentType.select
      )
      .map((field) => ({
        ...field,
        isRequired: false,
        initialValue: undefined,
        component: EFormBuilderSchemaComponentType.checkbox,
      }))
  }, [])

  const loadUISchema = useCallback(
    (item: ITemplate | ITicket) => {
      return {
        fields: [
          {
            component: componentTypes.DATE_PICKER,
            name: 'start_date',
            label: '起始日期（開單日）',
            DatePickerProps: defaultDatePickerProps,
          },
          {
            component: componentTypes.DATE_PICKER,
            name: 'end_date',
            label: '結束日期（開單日）',
            DatePickerProps: defaultDatePickerProps,
          },
          {
            component: 'sub-form',
            title: '客戶選單',
            name: 'custom_fields_schema',
            fields: transformSubSchemaFields(
              loadSubSchemaFromItem('custom_fields', item).fields
            ),
          },
          {
            name: 'divider',
            component: 'ITEM_DIVIDER',
          },
          {
            component: 'sub-form',
            title: '內部選單',
            name: 'itn_custom_fields_schema',
            fields: transformSubSchemaFields(
              loadSubSchemaFromItem('itn_custom_fields', item).fields
            ),
          },
          {
            name: 'divider',
            component: 'ITEM_DIVIDER',
          },
        ],
      }
    },
    [transformSubSchemaFields]
  )

  return (
    <div
      className={classNames({
        'page-filter-form': true,
      })}
    >
      {currentItem && (
        <FormRenderer
          initialValues={initialValues}
          schema={loadUISchema(currentItem)}
          componentMapper={customComponentMapper}
          FormTemplate={FilterFormTemplate}
          onSubmit={() => {}}
          className="form-container"
        />
      )}
    </div>
  )
}

export default React.memo(FilterForm, propsAreEqual)
