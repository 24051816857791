import { IonCard, IonThumbnail } from '@ionic/react'
import { getImageUrl, getFallbackImage } from '../../helpers/file'
import { ISuiquiFile } from '../../models/suiquiFile'

interface PhotoCardProps {
  image: ISuiquiFile
  photoSize: number
  onClick: () => void
}

const PhotoCard = ({ image, photoSize, onClick }: PhotoCardProps) => {
  return (
    <IonCard className="fix-card" onClick={onClick}>
      <IonThumbnail
        className="photo"
        style={{
          width: photoSize,
          height: photoSize,
        }}
      >
        <img
          alt=""
          width={photoSize}
          height={photoSize}
          src={`${getImageUrl(image, photoSize, 'c')}`}
          onError={getFallbackImage}
        ></img>
      </IonThumbnail>
    </IonCard>
  )
}

export default PhotoCard
