import {
  IonButton,
  IonContent,
  IonHeader,
  IonItem,
  IonPage,
  IonTitle,
  IonToolbar,
} from '@ionic/react'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { RouteComponentProps, useHistory, useLocation } from 'react-router'
import useLocalForge from '../hooks/use-local-forge'
import { DELAY } from '../models/constants'
import { Creators as AuthActions } from '../store/ducks/auth'
import { fetchAuth } from '../store/epics/auth'
import { fetchUser } from '../store/epics/user'
import { clearSwCacheStore } from '../swCache'
import './Logout.scss'

const Logout: React.FC<RouteComponentProps> = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [token] = useLocalForge('apiToken')
  const authDuck = useSelector((state: any) => state.authDuck)
  const location = useLocation()
  const history = useHistory()

  useEffect(() => {
    const clearCaches = async () => {
      if ('caches' in window.self) {
        await caches.delete('api-user')
      }
      await clearSwCacheStore()
    }

    if (authDuck.opened) {
      dispatch(AuthActions.openMenu(false))
    }

    if (location.pathname !== '/logout') return
    if (!token && authDuck.isUserLoaded) {
      dispatch(fetchAuth.cancel())
      dispatch(fetchUser.cancel())
      dispatch(AuthActions.logout())
      dispatch(AuthActions.openMenu(false))
      clearCaches()
    }
  }, [
    dispatch,
    token,
    authDuck.isUserLoaded,
    authDuck.isLogout,
    location.pathname,
    authDuck.opened,
    location.state,
  ])

  useEffect(() => {
    if (location.pathname !== '/logout') return
    if (!token) {
      setTimeout(() => {
        history.replace('/login', {
          ...(location.state as {}),
          direction: 'root',
        })
      }, DELAY)
    }
  }, [token, history, location.pathname, location.state])

  return (
    <IonPage className="page-logout">
      <IonHeader>
        <IonToolbar color="primary">
          <IonTitle>
            <IonButton class="logo immutable">{t('Suiqui Support')}</IonButton>
            <span className="subtitle">{t('SUIQUI_SUPPORT')}</span>
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonItem lines="none">{t('Redirect...')}</IonItem>
      </IonContent>
    </IonPage>
  )
}

export default Logout
