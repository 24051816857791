import {
  isPlatform,
  useIonViewWillEnter,
  useIonViewWillLeave,
} from '@ionic/react'
import useComponentSize from '@rehooks/component-size'
import classNames from 'classnames'
import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { RouteComponentProps } from 'react-router'
import SplitPane, { Size } from 'react-split-pane'
import { useWindowSize } from 'react-use'
import { COMMENT_LIST_MIN_SIZE } from '../../models/comment'
import { BREAKPOINT_MD } from '../../models/constants'
import { ETicketStatus, ETicketUserType } from '../../models/ticket'
import { ReactRouterAction } from '../../store/epics/types'
import ListTicketComment from '../ListTicketComment'
import PublicTicket from './PublicTicket'
import './PublicTicket.scss'

export type PublicTicketWithSplitPaneProps = {
  userType: ETicketUserType
} & RouteComponentProps &
  ReactRouterAction

const PublicTicketWithSplitPane = (props: PublicTicketWithSplitPaneProps) => {
  const pageRef = useRef(null)
  const [isOpen, setIsOpen] = useState(false)
  const { width, height } = useWindowSize()
  const { width: splitPaneWidth } = useComponentSize(pageRef)
  const [isShowCommentList, setIsShowCommentList] = useState(false)
  const [commentListWidth, setCommentListWidth] = useState<Size>()
  const [commentListHeight, setCommentListHeight] = useState<Size>()
  const [isLoaded, setIsLoaded] = useState(false)
  const ticket = useSelector((state: any) => state.ticket)

  const isLargeScreen =
    (isPlatform('desktop') || isPlatform('ipad')) && width >= BREAKPOINT_MD

  // 隱藏右上角九宮格按鈕
  useIonViewWillEnter(() => {
    const popupmenu = document.querySelector('#global-popup-menu')
    popupmenu?.classList.add('hidden')
  })

  // 離開頁面時取消隱藏右上角九宮格按鈕
  useIonViewWillLeave(() => {
    const popupmenu = document.querySelector('#global-popup-menu')
    popupmenu?.classList.remove('hidden')
  })

  useIonViewWillEnter(() => {
    if (isOpen) return
    setIsOpen(true)

    if (isLargeScreen) {
      setIsShowCommentList(true)
    }

    setTimeout(() => {
      setIsLoaded(true)
    }, 0)
  }, [isLargeScreen, isOpen])

  useIonViewWillLeave(() => {
    if (!isOpen) return
    setIsOpen(false)
  }, [setIsOpen, isOpen])

  const toggleCommentList = (flag: boolean) => {
    if (flag === undefined) {
      flag = !isShowCommentList
    }

    if (flag) {
      setCommentListHeight(110)
    } else {
      setCommentListHeight('100%')
    }

    setIsShowCommentList(flag)
  }

  useEffect(() => {
    setIsShowCommentList(isLargeScreen)
    if (isLargeScreen) {
      setCommentListWidth(COMMENT_LIST_MIN_SIZE)
      setCommentListHeight(undefined)
    } else {
      setCommentListWidth(undefined)
      setCommentListHeight('100%')
    }
  }, [isLargeScreen])

  return (
    <div
      className={classNames({
        'split-pane': true,
        workaround: isLoaded,
      })}
      ref={pageRef}
      style={{ height: '100%' }}
    >
      <SplitPane
        {...props}
        split={width >= BREAKPOINT_MD ? 'vertical' : 'horizontal'}
        primary={isLargeScreen ? 'second' : 'first'}
        minSize={isLargeScreen ? COMMENT_LIST_MIN_SIZE : 110}
        maxSize={isLargeScreen ? splitPaneWidth : height}
        defaultSize={isLargeScreen ? COMMENT_LIST_MIN_SIZE : '100%'}
        size={isLargeScreen ? commentListWidth : commentListHeight}
      >
        <PublicTicket
          {...props}
          pageWidth={width}
          pageHeight={height}
          actions={{
            toggleCommentList,
          }}
          actionProps={{
            isShowCommentList,
          }}
        ></PublicTicket>
        <ListTicketComment
          {...props}
          pageWidth={width}
          pageHeight={height}
          isAllowNewComment={ticket?.ticket_status === ETicketStatus.InProgress}
        ></ListTicketComment>
      </SplitPane>
    </div>
  )
}

export default PublicTicketWithSplitPane
