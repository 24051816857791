import { combineEpics, Epic } from 'redux-observable'
import { of } from 'rxjs'
import { catchError, filter, map, switchMap, takeUntil } from 'rxjs/operators'
import {
  ActionType,
  createAsyncAction,
  createReducer,
  getType,
  isActionOf,
} from 'typesafe-actions'
import { ITeam } from '../../models/team'
import * as Providers from '../../providers'
import { RootState } from '../ducks'
import { RootAction } from './types'

export const fetchTeam = createAsyncAction(
  'FETCH_TEAM_REQUEST',
  'FETCH_TEAM_SUCCESS',
  'FETCH_TEAM_FAILURE',
  'FETCH_TEAM_CANCEL'
)<undefined, ITeam, Error, undefined>()

const initialState = {
  lib_line_notification_mode: undefined,
  isDone: undefined,
  updated: undefined,
}

export const fetchTeamEpic: Epic<
  RootAction,
  RootAction,
  RootState,
  typeof Providers
> = (action$, state$, { teamAPI }) =>
  action$.pipe(
    filter(isActionOf(fetchTeam.request)),
    switchMap((action) =>
      teamAPI.get({ token: state$.value.authDuck.token }).pipe(
        map((data) => fetchTeam.success(data.response)),
        catchError((message: Error) => of(fetchTeam.failure(message))),
        takeUntil(action$.pipe(filter(isActionOf(fetchTeam.cancel))))
      )
    )
  )

export const updateTeam = createAsyncAction(
  'UPDATE_TEAM_REQUEST',
  'UPDATE_TEAM_SUCCESS',
  'UPDATE_TEAM_FAILURE',
  'UPDATE_TEAM_CANCEL'
)<Partial<ITeam>, ITeam, Error, undefined>()

export const updateTeamEpic: Epic<
  RootAction,
  RootAction,
  RootState,
  typeof Providers
> = (action$, state$, { teamAPI }) =>
  action$.pipe(
    filter(isActionOf(updateTeam.request)),
    switchMap((action) =>
      teamAPI
        .update({ token: state$.value.authDuck.token, params: action.payload })
        .pipe(
          map((data) => updateTeam.success(data.response)),
          catchError((message: Error) => of(updateTeam.failure(message))),
          takeUntil(action$.pipe(filter(isActionOf(updateTeam.cancel))))
        )
    )
  )

export const teamReducer = createReducer(initialState)
  .handleAction(getType(fetchTeam.request), (state: any, { payload }: any) => ({
    ...initialState,
  }))
  .handleAction(getType(fetchTeam.success), (state: any, { payload }: any) => ({
    ...initialState,
    ...payload,
    error: null,
    isDone: true,
  }))
  .handleAction(getType(fetchTeam.failure), (state: any, { payload }: any) => ({
    ...state,
    error: payload,
    isDone: true,
  }))
  .handleAction(getType(fetchTeam.cancel), (state: any, { payload }: any) => ({
    ...initialState,
  }))
  .handleAction(
    getType(updateTeam.request),
    (state: any, { payload }: any) => ({
      ...state,
      ...payload,
      updated: undefined,
    })
  )
  .handleAction(
    getType(updateTeam.success),
    (state: any, { payload }: any) => ({
      ...state,
      ...payload,
      error: null,
      updated: true,
    })
  )
  .handleAction(
    getType(updateTeam.failure),
    (state: any, { payload }: any) => ({
      ...state,
      error: payload,
      updated: false,
    })
  )
  .handleAction(getType(updateTeam.cancel), (state: any, { payload }: any) => ({
    ...state,
    error: null,
    updated: undefined,
  }))

export type TeamAction =
  | ActionType<typeof fetchTeam>
  | ActionType<typeof updateTeam>

const teamEpic = combineEpics<any>(fetchTeamEpic, updateTeamEpic)
export default teamEpic
