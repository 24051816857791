import { IonItem, IonLabel, IonNote, IonText } from '@ionic/react'
import { useTranslation } from 'react-i18next'
import { INotificationBellBase } from '../../models/notificationBell'
import './ReminderPreviewComponent.scss'

interface ReminderPreviewComponentProps {
  notificationBells: INotificationBellBase[]
}

// 預覽 節點提醒 的元件，在 Template 中的進階設定 (AdvancedSegment) 中顯示
const ReminderPreviewComponent = ({
  notificationBells,
}: ReminderPreviewComponentProps) => {
  const { t } = useTranslation()

  return (
    <div className="reminder-preview-component">
      {notificationBells.map((bell, idx) => (
        <IonItem
          key={idx}
          lines={idx === notificationBells.length - 1 ? 'none' : 'inset'}
        >
          {/* 提醒名稱 */}
          <IonLabel position="fixed" color="medium">{bell.name}</IonLabel>
          {/* Template 顯示開單日 + 天數 */}
          <IonNote slot="end" className="day-offset">
            <IonText className="ion-margin-end">{t('Created Date')}</IonText>
            <IonText color="success">
              + {bell.day_offset} {t('Days')}
            </IonText>
          </IonNote>
        </IonItem>
      ))}
    </div>
  )
}

export default ReminderPreviewComponent
