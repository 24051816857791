import { print } from 'ionicons/icons'
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonLabel,
  IonPage,
  IonTitle,
  IonToolbar,
  isPlatform,
} from '@ionic/react'
import { Checkbox, FormControlLabel } from '@mui/material'
import { close } from 'ionicons/icons'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useRemoveIonPageInvisible } from '../../hooks/useRemoveIonPageInvisible'
import './PrintTicketModal.scss'
import { useDispatch } from 'react-redux'
import { printTicket } from '../../store/epics/ticket'
import { ITicket } from '../../models/ticket'
import moment from 'moment'

/**
 * 要列印的欄位
 */
type IncludeField =
  | 'system'
  | 'customer'
  | 'custom'
  | 'internal_custom'
  | 'photos'
  | 'signatures'

interface PrintTicketModalProps {
  ticket: ITicket
  dismiss: () => void
}

/**
 * 每個要列印的欄位與其對應顯示在畫面上的 label
 */
const printOptions: { name: IncludeField; label: string }[] = [
  { name: 'system', label: 'System Fields' }, // 系統欄位
  { name: 'customer', label: 'Customer Data' }, // 客戶資訊
  { name: 'custom', label: 'Custom Fields' }, // 客戶選單
  { name: 'internal_custom', label: 'Internal Custom Fields' }, // 內部選單
  { name: 'signatures', label: 'Signature Fields' }, // 簽名欄位
  { name: 'photos', label: 'Photo' }, // 照片
]

const PrintTicketModal = ({ ticket, dismiss }: PrintTicketModalProps) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const pageRef = useRemoveIonPageInvisible()
  const { control, handleSubmit } = useForm<Record<IncludeField, boolean>>({
    defaultValues: {
      system: true,
      customer: true,
      custom: true,
      signatures: true,

      // 預設不列印內部選單與相片
      internal_custom: false,
      photos: false,
    },
  })

  const printTicketAction = () => {
    handleSubmit((input) => {
      // 列印的檔名，e.g. 221230_林XX.pdf
      const filename = `${moment(ticket.create_datetime).format('YYMMDD')}_${
        ticket.customer_name
      }`
      dispatch(
        printTicket.request({
          filename,
          key: ticket.key,
          include_fields: input,
        })
      )
      dismiss()
    })()
  }

  return (
    <IonPage className="page-print-ticket-modal" ref={pageRef}>
      <IonHeader>
        <IonToolbar color="primary">
          <IonTitle>
            <IonButton className="logo immutable">
              {t('Suiqui Support')}
            </IonButton>
            <span className="subtitle" hidden={isPlatform('mobile')}>
              {t('Print Tickets')}
            </span>
          </IonTitle>
          <IonButtons slot="end">
            <IonButton strong fill="clear" slot="icon-only" onClick={dismiss}>
              <IonIcon size="large" icon={close}></IonIcon>
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding" scrollEvents>
        {/* 列印欄位 */}
        <section>
          <h3>{t('Print Include Fields')}</h3>
          <div className="checkboxes">
            {printOptions.map((option) => (
              <FormControlLabel
                label={`${t(option.label)}`}
                key={option.name}
                className="checkbox-label"
                control={
                  <Controller
                    name={option.name}
                    control={control}
                    render={({ field: { value, ...field } }) => (
                      <Checkbox {...field} checked={!!value} />
                    )}
                  />
                }
              />
            ))}
          </div>
        </section>

        {/* 列印按鈕 */}
        <div className="print-btn-container">
          <IonButton color="primary" onClick={printTicketAction}>
            <IonIcon slot="start" icon={print} />
            <IonLabel>{t('Print')}</IonLabel>
          </IonButton>
        </div>
      </IonContent>
    </IonPage>
  )
}

export default PrintTicketModal
