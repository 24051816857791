import {
  IonItemDivider,
  IonButton,
  IonLabel,
  IonButtons,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonToolbar,
  IonContent,
} from '@ionic/react'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { ITeam } from '../models/team'
import { switchAuth } from '../store/epics/auth'
import { clearSwCacheStore } from '../swCache'
import PopupMenu from './PopupMenu'

// 切換團隊的頁面，用於顯示在使用者目前沒有權限查閱，但切換團隊後可以查閱的頁面
// 點擊按鈕後會自動切換團隊，並重新導向回原本的頁面
const TeamSwitchPage = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { t } = useTranslation()
  const auth = useSelector((state: any) => state.auth)

  // 備註：從 TeamSwitchModal 複製而來
  const clearCaches = useCallback(async () => {
    if ('caches' in window.self) {
      await caches.delete('api-user')
    }
    await clearSwCacheStore()
  }, [])

  const switchTeam = useCallback(
    (team: ITeam) => {
      dispatch(
        switchAuth.request({
          team_id: team.team_id,
        })
      )
      clearCaches()

      // 導向至 reload 頁面重新設定 api token 與 redux store，完成後再重新導向回原本的頁面
      history.push(`/reload?redirectUrl=${window.location.pathname}`)
    },
    [dispatch, history, clearCaches]
  )

  if (!auth?.nextTeam) return <></>
  return (
    <IonPage>
      <PopupMenu></PopupMenu>
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="bg">
        {/* 切換團隊按鈕 */}
        <div className="ion-text-center centered">
          <IonButton
            buttonType="button"
            color="primary"
            size="large"
            onClick={() => switchTeam(auth.nextTeam)}
          >
            <IonLabel color="light">
              {t('Switch to team ‘{{name}}’', {
                name: auth?.nextTeam?.name,
              })}
            </IonLabel>
          </IonButton>
        </div>
      </IonContent>
    </IonPage>
  )
}

export default TeamSwitchPage
