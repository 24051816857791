import { combineEpics, Epic } from 'redux-observable'
import { of, Subject } from 'rxjs'
import {
  catchError,
  concatMap,
  filter,
  finalize,
  map,
  merge,
  mergeMap,
  switchMap,
  takeUntil,
} from 'rxjs/operators'
import {
  ActionType,
  createAsyncAction,
  createCustomAction,
  createReducer,
  getType,
  isActionOf,
} from 'typesafe-actions'
import XHRFactory from '../../helpers/XHRFactory'
import { id } from '../../models/base'
import { ITicketComment, ITicketCommentUpload } from '../../models/comment'
import { MAX_PARALLEL_UPLOAD } from '../../models/constants'
import { IEvent, IList } from '../../models/template'
import * as Providers from '../../providers'
import { RootState } from '../ducks'
import { RootAction } from './types'

const initialState = {
  key: undefined,
  comments: [],
  isDone: false,
  pFiles: {},
  doneCount: 0,
  doneCountForCreateTicket: 0,
}

export const fetchCommentList = createAsyncAction(
  'FETCH_COMMENT_LIST_REQUEST',
  'FETCH_COMMENT_LIST_SUCCESS',
  'FETCH_COMMENT_LIST_FAILURE',
  'FETCH_COMMENT_LIST_CANCEL'
)<
  Partial<ITicketComment & IList> & Pick<IList, 'key' | 'cursor'>,
  Partial<ITicketComment & IList>,
  Error,
  undefined
>()
export const fetchCommentListEpic: Epic<
  RootAction,
  RootAction,
  RootState,
  typeof Providers
> = (action$, state$, { commentAPI }) =>
  action$.pipe(
    filter(isActionOf(fetchCommentList.request)),
    switchMap((action) =>
      commentAPI
        .list({
          token: state$.value.authDuck.token,
          params: action.payload,
        })
        .pipe(
          finalize(() => {
            const { event } = action.payload
            if (event) {
              if (event.target) {
                // @ts-ignore
                event.target.complete()
              } else if (event.detail) {
                // @ts-ignore
                event.detail.complete()
              }
            }
          })
        )
        .pipe(
          map((data) => fetchCommentList.success(data.response)),
          catchError((message: Error) => of(fetchCommentList.failure(message))),
          takeUntil(action$.pipe(filter(isActionOf(fetchCommentList.cancel))))
        )
    )
  )

export const fetchPublicCommentList = createAsyncAction(
  'FETCH_PUBLIC_COMMENT_LIST_REQUEST',
  'FETCH_PUBLIC_COMMENT_LIST_SUCCESS',
  'FETCH_PUBLIC_COMMENT_LIST_FAILURE',
  'FETCH_PUBLIC_COMMENT_LIST_CANCEL'
)<
  Partial<ITicketComment & IList> & Pick<IList, 'key' | 'cursor'>,
  Partial<ITicketComment & IList>,
  Error,
  undefined
>()
export const fetchPublicCommentListEpic: Epic<
  RootAction,
  RootAction,
  RootState,
  typeof Providers
> = (action$, state$, { commentAPI }) =>
  action$.pipe(
    filter(isActionOf(fetchPublicCommentList.request)),
    switchMap((action) =>
      commentAPI
        .listPublic({
          params: action.payload,
        })
        .pipe(
          finalize(() => {
            const { event } = action.payload
            if (event) {
              if (event.target) {
                // @ts-ignore
                event.target.complete()
              } else if (event.detail) {
                // @ts-ignore
                event.detail.complete()
              }
            }
          })
        )
        .pipe(
          map((data) => fetchPublicCommentList.success(data.response)),
          catchError((message: Error) =>
            of(fetchPublicCommentList.failure(message))
          ),
          takeUntil(
            action$.pipe(filter(isActionOf(fetchPublicCommentList.cancel)))
          )
        )
    )
  )

export const fetchComment = createAsyncAction(
  'FETCH_COMMENT_REQUEST',
  'FETCH_COMMENT_SUCCESS',
  'FETCH_COMMENT_FAILURE',
  'FETCH_COMMENT_CANCEL'
)<
  Partial<ITicketComment & IEvent> & Pick<ITicketComment, 'key'>,
  ITicketComment,
  Error,
  undefined
>()

export const fetchCommentEpic: Epic<
  RootAction,
  RootAction,
  RootState,
  typeof Providers
> = (action$, state$, { commentAPI }) =>
  action$.pipe(
    filter(isActionOf(fetchComment.request)),
    switchMap((action) =>
      commentAPI
        .get({ token: state$.value.authDuck.token, params: action.payload })
        .pipe(
          finalize(() => {
            const { event } = action.payload
            if (event) {
              if (event.target) {
                // @ts-ignore
                event.target.complete()
              } else if (event.detail) {
                // @ts-ignore
                event.detail.complete()
              }
            }
          })
        )
        .pipe(
          map((data) => fetchComment.success(data.response)),
          catchError((message: Error) => of(fetchComment.failure(message))),
          takeUntil(action$.pipe(filter(isActionOf(fetchComment.cancel))))
        )
    )
  )

export const fetchPublicComment = createAsyncAction(
  'FETCH_PUBLIC_COMMENT_REQUEST',
  'FETCH_PUBLIC_COMMENT_SUCCESS',
  'FETCH_PUBLIC_COMMENT_FAILURE',
  'FETCH_PUBLIC_COMMENT_CANCEL'
)<
  Partial<ITicketComment & IEvent> &
    Pick<ITicketComment, 'commentUserType' | 'key'>,
  ITicketComment,
  Error,
  undefined
>()

export const fetchPublicCommentEpic: Epic<
  RootAction,
  RootAction,
  RootState,
  typeof Providers
> = (action$, state$, { commentAPI }) =>
  action$.pipe(
    filter(isActionOf(fetchPublicComment.request)),
    switchMap((action) =>
      commentAPI
        .getPublic({
          commentUserType: action.payload.commentUserType,
          params: action.payload,
        })
        .pipe(
          finalize(() => {
            const { event } = action.payload
            if (event) {
              if (event.target) {
                // @ts-ignore
                event.target.complete()
              } else if (event.detail) {
                // @ts-ignore
                event.detail.complete()
              }
            }
          })
        )
        .pipe(
          map((data) => fetchPublicComment.success(data.response)),
          catchError((message: Error) =>
            of(fetchPublicComment.failure(message))
          ),
          takeUntil(action$.pipe(filter(isActionOf(fetchPublicComment.cancel))))
        )
    )
  )

export const addComment = createAsyncAction(
  'ADD_COMMENT_REQUEST',
  'ADD_COMMENT_SUCCESS',
  'ADD_COMMENT_FAILURE',
  'ADD_COMMENT_CANCEL'
)<
  Partial<ITicketComment> & Pick<ITicketComment, 'key'>,
  ITicketComment,
  Error,
  undefined
>()

export const addCommentEpic: Epic<
  RootAction,
  RootAction,
  RootState,
  typeof Providers
> = (action$, state$, { commentAPI }) =>
  action$.pipe(
    filter(isActionOf(addComment.request)),
    switchMap((action) =>
      commentAPI
        .add({ token: state$.value.authDuck.token, params: action.payload })
        .pipe(
          map((data) => addComment.success(data.response)),
          catchError((message: Error) => of(addComment.failure(message))),
          takeUntil(action$.pipe(filter(isActionOf(addComment.cancel))))
        )
    )
  )

export const addPublicComment = createAsyncAction(
  'ADD_PUBLIC_COMMENT_REQUEST',
  'ADD_PUBLIC_COMMENT_SUCCESS',
  'ADD_PUBLIC_COMMENT_FAILURE',
  'ADD_PUBLIC_COMMENT_CANCEL'
)<
  Partial<ITicketComment> & Pick<ITicketComment, 'key'>,
  ITicketComment,
  Error,
  undefined
>()

export const addPublicCommentEpic: Epic<
  RootAction,
  RootAction,
  RootState,
  typeof Providers
> = (action$, state$, { commentAPI }) =>
  action$.pipe(
    filter(isActionOf(addPublicComment.request)),
    switchMap((action) =>
      commentAPI
        .addPublic({
          commentUserType: action.payload.commentUserType,
          params: action.payload,
        })
        .pipe(
          map((data) => addPublicComment.success(data.response)),
          catchError((message: Error) => of(addPublicComment.failure(message))),
          takeUntil(action$.pipe(filter(isActionOf(addPublicComment.cancel))))
        )
    )
  )

export const uploadCommentFilesCancelAll = createCustomAction(
  'UPLOAD_COMMENT_FILES_CANCEL_ALL',
  (payload) => {
    return {
      payload,
    }
  }
)

export const uploadCommentFilesFailed = createCustomAction(
  'UPLOAD_COMMENT_FILES_FAILED',
  (payload) => {
    return {
      payload,
    }
  }
)

export const uploadCommentFileProgress = createCustomAction(
  'UPLOAD_COMMENT_FILE_PROGRESS',
  (payload) => {
    return {
      payload: {
        hash: payload.hash,
        percentage: payload.percentage,
      },
    }
  }
)

export const uploadComment = createAsyncAction(
  'UPLOAD_COMMENT_REQUEST',
  'UPLOAD_COMMENT_SUCCESS',
  'UPLOAD_COMMENT_FAILURE',
  'UPLOAD_COMMENT_CANCEL'
)<
  Partial<ITicketCommentUpload> & { key: id },
  ITicketCommentUpload,
  Partial<ITicketCommentUpload> & { message: Error },
  { hash?: number }
>()

export const uploadCommentEpic: Epic<
  RootAction,
  RootAction,
  RootState,
  typeof Providers
> = (action$, state$, { uploadAPI, commentAPI }) => {
  return action$.pipe(
    filter(isActionOf(uploadComment.request)),
    mergeMap((action) => {
      const { hash } = action.payload
      const progressSubscriber$ = new Subject()
      const request$ = commentAPI
        .getUploadUrl({
          token: state$.value.authDuck.token,
          key: action.payload.key,
        })
        .pipe(
          mergeMap((uploadUrl) => {
            const xhr = XHRFactory.getInstance()
            const getRequest$ = () => xhr
            return uploadAPI
              .exec({
                token: state$.value.authDuck.token,
                uploadUrl,
                params: {
                  key: action.payload.key,
                  text: action.payload.text,
                },
                files: action.payload.files,
                filenames: action.payload.filenames,
                createXHR: getRequest$,
                progressSubscriber: progressSubscriber$,
              })
              .pipe(
                concatMap((data) => {
                  return of(
                    uploadComment.success({
                      ...data.response,
                      hash: action.payload.hash,
                      hashFunc: action.payload.hashFunc,
                      files: action.payload.files,
                      doneCount: !!action.payload.files
                        ? action.payload.files?.length
                        : undefined,
                    })
                  )
                }),
                catchError((message: Error) =>
                  of(
                    uploadComment.failure({
                      ...action.payload,
                      message,
                    })
                  )
                ),
                finalize(() => {
                  XHRFactory.release(xhr)
                }),
                takeUntil(
                  !!action.payload.files
                    ? action$.pipe(filter(isActionOf(uploadComment.cancel)))
                    : action$.pipe(
                        filter(isActionOf(uploadComment.cancel)),
                        filter((action) => action.payload.hash === hash)
                      )
                ),
                takeUntil(
                  action$.pipe(filter((action) => action.payload.hash === hash))
                ),
                takeUntil(
                  action$.pipe(filter(isActionOf(uploadCommentFilesCancelAll)))
                ),
                takeUntil(
                  action$.pipe(filter(isActionOf(uploadCommentFilesFailed)))
                )
              )
          }, MAX_PARALLEL_UPLOAD)
        )

      return !!action.payload.files
        ? progressSubscriber$.pipe(
            map((e: any) => ({ percentage: (e.loaded / e.total) * 100 || 0 })),
            concatMap((data) =>
              (action.payload.files || []).map((_file, index) =>
                uploadCommentFileProgress({
                  hash: action.payload.hashFunc
                    ? action.payload.hashFunc(_file, index)
                    : 0,
                  percentage: data.percentage,
                })
              )
            ),
            merge(request$),
            catchError((message: Error) =>
              of(
                uploadComment.failure({
                  ...action.payload,
                  message,
                })
              )
            )
          )
        : progressSubscriber$.pipe(
            map((e: any) => ({ percentage: (e.loaded / e.total) * 100 || 0 })),
            map((data) =>
              uploadCommentFileProgress({
                hash: action.payload.hash,
                percentage: data.percentage,
              })
            ),
            merge(request$),
            catchError((message: Error) =>
              of(
                uploadComment.failure({
                  ...action.payload,
                  message,
                })
              )
            )
          )
    }, MAX_PARALLEL_UPLOAD)
  )
}

export const uploadPublicComment = createAsyncAction(
  'UPLOAD_PUBLIC_COMMENT_REQUEST',
  'UPLOAD_PUBLIC_COMMENT_SUCCESS',
  'UPLOAD_PUBLIC_COMMENT_FAILURE',
  'UPLOAD_PUBLIC_COMMENT_CANCEL'
)<
  Partial<ITicketCommentUpload> & { key: id },
  ITicketCommentUpload,
  Partial<ITicketCommentUpload> & { message: Error },
  { hash?: number }
>()

export const uploadPublicCommentEpic: Epic<
  RootAction,
  RootAction,
  RootState,
  typeof Providers
> = (action$, state$, { uploadAPI, commentAPI }) => {
  return action$.pipe(
    filter(isActionOf(uploadPublicComment.request)),
    mergeMap((action) => {
      const { hash } = action.payload
      const progressSubscriber$ = new Subject()
      const request$ = commentAPI
        .getPublicUploadUrl({
          commentUserType: action.payload.commentUserType,
          key: action.payload.key,
        })
        .pipe(
          mergeMap((uploadUrl) => {
            const xhr = XHRFactory.getInstance()
            const getRequest$ = () => xhr
            return uploadAPI
              .exec({
                token: state$.value.authDuck.token,
                uploadUrl,
                params: {
                  key: action.payload.key,
                  text: action.payload.text,
                },
                files: action.payload.files,
                filenames: action.payload.filenames,
                createXHR: getRequest$,
                progressSubscriber: progressSubscriber$,
              })
              .pipe(
                concatMap((data) => {
                  return of(
                    uploadPublicComment.success({
                      ...data.response,
                      hash: action.payload.hash,
                      hashFunc: action.payload.hashFunc,
                      files: action.payload.files,
                      doneCount: action.payload.files
                        ? action.payload.files?.length
                        : undefined,
                    })
                  )
                }),
                catchError((message: Error) =>
                  of(
                    uploadPublicComment.failure({
                      ...action.payload,
                      message,
                    })
                  )
                ),
                finalize(() => {
                  XHRFactory.release(xhr)
                }),
                takeUntil(
                  action.payload.files
                    ? action$.pipe(
                        filter(isActionOf(uploadPublicComment.cancel))
                      )
                    : action$.pipe(
                        filter(isActionOf(uploadPublicComment.cancel)),
                        filter((action) => action.payload.hash === hash)
                      )
                ),
                takeUntil(
                  action$.pipe(filter((action) => action.payload.hash === hash))
                ),
                takeUntil(
                  action$.pipe(filter(isActionOf(uploadCommentFilesCancelAll)))
                ),
                takeUntil(
                  action$.pipe(filter(isActionOf(uploadCommentFilesFailed)))
                )
              )
          }, MAX_PARALLEL_UPLOAD)
        )

      return !!action.payload.files
        ? progressSubscriber$.pipe(
            map((e: any) => ({ percentage: (e.loaded / e.total) * 100 || 0 })),
            concatMap((data) =>
              (action.payload.files || []).map((_file, index) =>
                uploadCommentFileProgress({
                  hash: action.payload.hashFunc
                    ? action.payload.hashFunc(_file, index)
                    : 0,
                  percentage: data.percentage,
                })
              )
            ),
            merge(request$),
            catchError((message: Error) =>
              of(
                uploadPublicComment.failure({
                  ...action.payload,
                  message,
                })
              )
            )
          )
        : progressSubscriber$.pipe(
            map((e: any) => ({ percentage: (e.loaded / e.total) * 100 || 0 })),
            map((data) =>
              uploadCommentFileProgress({
                hash: action.payload.hash,
                percentage: data.percentage,
              })
            ),
            merge(request$),
            catchError((message: Error) =>
              of(
                uploadPublicComment.failure({
                  ...action.payload,
                  message,
                })
              )
            )
          )
    }, MAX_PARALLEL_UPLOAD)
  )
}

export const removeComment = createAsyncAction(
  'REMOVE_COMMENT_REQUEST',
  'REMOVE_COMMENT_SUCCESS',
  'REMOVE_COMMENT_FAILURE',
  'REMOVE_COMMENT_CANCEL'
)<
  Partial<ITicketComment> & Pick<ITicketComment, 'key'>,
  ITicketComment,
  Error,
  undefined
>()

export const removeCommentEpic: Epic<
  RootAction,
  RootAction,
  RootState,
  typeof Providers
> = (action$, state$, { commentAPI }) =>
  action$.pipe(
    filter(isActionOf(removeComment.request)),
    switchMap((action) =>
      commentAPI
        .remove({ token: state$.value.authDuck.token, params: action.payload })
        .pipe(
          map((data) => removeComment.success(data.response)),
          catchError((message: Error) => of(removeComment.failure(message))),
          takeUntil(action$.pipe(filter(isActionOf(removeComment.cancel))))
        )
    )
  )

export const removePublicComment = createAsyncAction(
  'REMOVE_PUBLIC_COMMENT_REQUEST',
  'REMOVE_PUBLIC_COMMENT_SUCCESS',
  'REMOVE_PUBLIC_COMMENT_FAILURE',
  'REMOVE_PUBLIC_COMMENT_CANCEL'
)<
  Partial<ITicketComment> & Pick<ITicketComment, 'key'>,
  ITicketComment,
  Error,
  undefined
>()

export const removePublicCommentEpic: Epic<
  RootAction,
  RootAction,
  RootState,
  typeof Providers
> = (action$, state$, { commentAPI }) =>
  action$.pipe(
    filter(isActionOf(removePublicComment.request)),
    switchMap((action) =>
      commentAPI
        .removePublic({
          commentUserType: action.payload.commentUserType,
          params: action.payload,
        })
        .pipe(
          map((data) => removePublicComment.success(data.response)),
          catchError((message: Error) =>
            of(removePublicComment.failure(message))
          ),
          takeUntil(
            action$.pipe(filter(isActionOf(removePublicComment.cancel)))
          )
        )
    )
  )

const requestMultiWithPayload = ({ hashFunc, files }: any, pFiles: any) => {
  if (hashFunc && files) {
    files.forEach((file: any, index: number) => {
      pFiles[hashFunc(file, index)] = {}
    })
  } else {
    pFiles = {}
  }

  return pFiles
}

const doneMultiWithPayload = ({ hashFunc, files }: any, pFiles: any) => {
  if (hashFunc && files) {
    files.forEach((file: any, index: number) => {
      pFiles[hashFunc(file, index)] = {
        ...pFiles[hashFunc(file, index)],
        isDone: true,
        percentage: 100,
        isAllowProcessing: false,
      }
    })
  } else {
    pFiles = {}
  }

  return pFiles
}

const doneWithPayload = ({ hash }: any, pFiles: any) => {
  if (hash) {
    pFiles[hash] = {
      ...pFiles[hash],
      isDone: true,
      percentage: 100,
      isAllowProcessing: false,
    }
  } else {
    pFiles = {}
  }

  return pFiles
}

const progressWithPayload = ({ hash, percentage }: any, pFiles: any) => {
  pFiles[hash] = {
    ...pFiles[hash],
    isDone: false,
    isCancel: false,
    percentage: percentage || pFiles[hash].percentage,
    isStartProcessing: false,
    isAllowProcessing: true,
  }

  return pFiles
}

const requestWithPayload = ({ hash }: any, pFiles: any) => {
  pFiles[hash] = {
    isDone: false,
    isCancel: false,
    percentage: NaN,
    isStartProcessing: true,
  }

  return pFiles
}

const resetWithPayload = ({ hash }: any, pFiles: any) => {
  if (pFiles && pFiles[hash]) {
    pFiles[hash] = {
      ...pFiles[hash],
      isDone: false,
      isCancel: true,
      isPending: false,
      percentage: NaN,
      isAllowProcessing: false,
      isFailed: false,
    }
  } else {
    pFiles = {}
  }
  return pFiles
}

const failureMultiWithPayload = ({ hashFunc, files }: any, pFiles: any) => {
  if (hashFunc && files) {
    files.forEach((file: any, index: number) => {
      pFiles[hashFunc(file, index)] = {
        ...pFiles[hashFunc(file, index)],
        isDone: false,
        isCancel: true,
        isPending: false,
        percentage: NaN,
        isAllowProcessing: false,
        isFailed: true,
      }
    })
  } else {
    pFiles = {}
  }

  return pFiles
}

const failureWithPayload = ({ hash }: any, pFiles: any) => {
  if (pFiles[hash]) {
    pFiles[hash] = {
      ...pFiles[hash],
      isDone: false,
      isCancel: true,
      isPending: false,
      percentage: NaN,
      isAllowProcessing: false,
      isFailed: true,
    }
  } else {
    pFiles = {}
  }
  return pFiles
}

export const commentReducer = createReducer(initialState)
  .handleAction(
    getType(fetchCommentList.request),
    (state: any, { payload }: any) => ({
      ...initialState,
      comments: state.comments,
      cursor: payload.cursor,
    })
  )
  .handleAction(
    getType(fetchCommentList.success),
    (state: any, { payload }: any) => ({
      ...initialState,
      ...payload,
      comments: [].concat(state.comments || [], payload.comments || []),
      error: null,
      isDone: true,
    })
  )
  .handleAction(
    getType(fetchCommentList.failure),
    (state: any, { payload }: any) => ({
      ...state,
      error: payload,
      isDone: true,
    })
  )
  .handleAction(
    getType(fetchCommentList.cancel),
    (state: any, { payload }: any) => ({
      ...initialState,
      isDone: false,
    })
  )
  .handleAction(
    getType(fetchPublicCommentList.request),
    (state: any, { payload }: any) => ({
      ...initialState,
      comments: state.comments,
      cursor: payload.cursor,
    })
  )
  .handleAction(
    getType(fetchPublicCommentList.success),
    (state: any, { payload }: any) => ({
      ...initialState,
      ...payload,
      comments: [].concat(state.comments || [], payload.comments || []),
      error: null,
      isDone: true,
    })
  )
  .handleAction(
    getType(fetchPublicCommentList.failure),
    (state: any, { payload }: any) => ({
      ...state,
      error: payload,
      isDone: true,
    })
  )
  .handleAction(
    getType(fetchPublicCommentList.cancel),
    (state: any, { payload }: any) => ({
      ...initialState,
      isDone: false,
    })
  )
  .handleAction(
    getType(fetchComment.request),
    (state: any, { payload }: any) => ({
      ...initialState,
      comments: state.comments,
      cursor: state.cursor,
      isDone: false,
    })
  )
  .handleAction(
    getType(fetchComment.success),
    (state: any, { payload }: any) => ({
      ...initialState,
      comments: state.comments,
      cursor: state.cursor,
      ...payload,
      error: null,
      isDone: true,
    })
  )
  .handleAction(
    getType(fetchComment.failure),
    (state: any, { payload }: any) => ({
      ...state,
      error: payload,
      isDone: true,
    })
  )
  .handleAction(
    getType(fetchComment.cancel),
    (state: any, { payload }: any) => ({
      ...initialState,
      key: state.key,
      comments: state.comments,
      cursor: state.cursor,
      isDone: state.isDone,
      function_list: state.list_function_list || state.function_list,
      error: null,
      shouldReload: state.shouldReload,
    })
  )
  .handleAction(
    getType(fetchPublicComment.request),
    (state: any, { payload }: any) => ({
      ...initialState,
    })
  )
  .handleAction(
    getType(fetchPublicComment.success),
    (state: any, { payload }: any) => ({
      ...payload,
      isDone: true,
      error: null,
    })
  )
  .handleAction(
    getType(fetchPublicComment.failure),
    (state: any, { payload }: any) => ({
      ...state,
      error: payload,
      isDone: true,
    })
  )
  .handleAction(
    getType(fetchPublicComment.cancel),
    (state: any, { payload }: any) => ({
      ...initialState,
    })
  )
  .handleAction(
    getType(addComment.request),
    (state: any, { payload }: any) => ({
      ...state,
      ...payload,
      added: undefined,
    })
  )
  .handleAction(
    getType(addComment.success),
    (state: any, { payload }: any) => ({
      ...state,
      ...payload,
      error: null,
      added: true,
      shouldReload: true,
    })
  )
  .handleAction(
    getType(addComment.failure),
    (state: any, { payload }: any) => ({
      ...state,
      error: payload,
      added: false,
    })
  )
  .handleAction(getType(addComment.cancel), (state: any, { payload }: any) => ({
    ...state,
    error: null,
    added: undefined,
    shouldReload: state.shouldReload,
  }))
  .handleAction(
    getType(addPublicComment.request),
    (state: any, { payload }: any) => ({
      ...state,
      ...payload,
      added: undefined,
    })
  )
  .handleAction(
    getType(addPublicComment.success),
    (state: any, { payload }: any) => ({
      ...state,
      ...payload,
      error: null,
      added: true,
      shouldReload: true,
    })
  )
  .handleAction(
    getType(addPublicComment.failure),
    (state: any, { payload }: any) => ({
      ...state,
      error: payload,
      added: false,
    })
  )
  .handleAction(
    getType(addPublicComment.cancel),
    (state: any, { payload }: any) => ({
      ...state,
      error: null,
      added: undefined,
      shouldReload: state.shouldReload,
    })
  )
  .handleAction(
    getType(uploadCommentFileProgress),
    (state: any, { payload }: any) => ({
      ...state,
      pFiles: progressWithPayload(payload, state.pFiles),
      doneCount: state.doneCount,
    })
  )
  .handleAction(
    getType(uploadCommentFilesCancelAll),
    (state: any, { payload }: any) => ({
      ...state,
      pFiles: resetWithPayload(payload, null),
      ...payload,
    })
  )
  .handleAction(
    getType(uploadComment.request),
    (state: any, { payload }: any) => ({
      ...state,
      doneCount: state.doneCount,
      pFiles: payload.files
        ? requestMultiWithPayload(payload, state.pFiles)
        : requestWithPayload(payload, state.pFiles),
      uploaded: undefined,
    })
  )
  .handleAction(
    getType(uploadComment.success),
    (state: any, { payload }: any) => ({
      ...state,
      pFiles: payload.files
        ? doneMultiWithPayload(payload, state.pFiles)
        : doneWithPayload(payload, state.pFiles),
      doneCount: payload.files ? payload.doneCount : state.doneCount + 1,
      doneCountForCreateTicket: state.doneCountForCreateTicket + 1,
      error: null,
      uploaded: true,
      shouldReload: true,
    })
  )
  .handleAction(
    getType(uploadComment.failure),
    (state: any, { payload }: any) => ({
      ...state,
      pFiles: payload.files
        ? failureMultiWithPayload(payload, state.pFiles)
        : failureWithPayload(payload, state.pFiles),
      doneCount: state.doneCount,
      error: payload,
      uploaded: false,
    })
  )
  .handleAction(
    getType(uploadComment.cancel),
    (state: any, { payload }: any) => ({
      ...state,
      pFiles: resetWithPayload(payload, state.pFiles),
      error: null,
      uploaded: undefined,
      shouldReload: state.shouldReload,
    })
  )
  .handleAction(
    getType(uploadPublicComment.request),
    (state: any, { payload }: any) => ({
      ...state,
      doneCount: state.doneCount,
      pFiles: payload.files
        ? requestMultiWithPayload(payload, state.pFiles)
        : requestWithPayload(payload, state.pFiles),
      uploaded: undefined,
    })
  )
  .handleAction(
    getType(uploadPublicComment.success),
    (state: any, { payload }: any) => ({
      ...state,
      pFiles: payload.files
        ? doneMultiWithPayload(payload, state.pFiles)
        : doneWithPayload(payload, state.pFiles),
      doneCount: payload.files ? payload.doneCount : state.doneCount + 1,
      doneCountForCreateTicket: state.doneCountForCreateTicket + 1,
      error: null,
      uploaded: true,
      shouldReload: true,
    })
  )
  .handleAction(
    getType(uploadPublicComment.failure),
    (state: any, { payload }: any) => ({
      ...state,
      pFiles: payload.files
        ? failureMultiWithPayload(payload, state.pFiles)
        : failureWithPayload(payload, state.pFiles),
      doneCount: state.doneCount,
      error: payload,
      uploaded: false,
    })
  )
  .handleAction(
    getType(uploadPublicComment.cancel),
    (state: any, { payload }: any) => ({
      ...state,
      pFiles: resetWithPayload(payload, state.pFiles),
      error: null,
      uploaded: undefined,
      shouldReload: state.shouldReload,
    })
  )
  .handleAction(
    getType(removeComment.request),
    (state: any, { payload }: any) => ({
      ...state,
      ...payload,
      removed: undefined,
    })
  )
  .handleAction(
    getType(removeComment.success),
    (state: any, { payload }: any) => ({
      ...state,
      ...payload,
      error: null,
      removed: true,
      shouldReload: true,
      key: undefined,
    })
  )
  .handleAction(
    getType(removeComment.failure),
    (state: any, { payload }: any) => ({
      ...state,
      error: payload,
      removed: false,
    })
  )
  .handleAction(
    getType(removeComment.cancel),
    (state: any, { payload }: any) => ({
      ...state,
      error: null,
      removed: undefined,
      shouldReload: state.shouldReload,
    })
  )
  .handleAction(
    getType(removePublicComment.request),
    (state: any, { payload }: any) => ({
      ...state,
      ...payload,
      removed: undefined,
    })
  )
  .handleAction(
    getType(removePublicComment.success),
    (state: any, { payload }: any) => ({
      ...state,
      ...payload,
      error: null,
      removed: true,
      shouldReload: true,
      key: undefined,
    })
  )
  .handleAction(
    getType(removePublicComment.failure),
    (state: any, { payload }: any) => ({
      ...state,
      error: payload,
      removed: false,
    })
  )
  .handleAction(
    getType(removePublicComment.cancel),
    (state: any, { payload }: any) => ({
      ...state,
      error: null,
      removed: undefined,
      shouldReload: state.shouldReload,
    })
  )

export type CommentAction =
  | ActionType<typeof fetchCommentList>
  | ActionType<typeof fetchPublicCommentList>
  | ActionType<typeof fetchComment>
  | ActionType<typeof fetchPublicComment>
  | ActionType<typeof addComment>
  | ActionType<typeof addPublicComment>
  | ActionType<typeof uploadComment>
  | ActionType<typeof uploadCommentFilesCancelAll>
  | ActionType<typeof uploadCommentFilesFailed>
  | ActionType<typeof uploadCommentFileProgress>
  | ActionType<typeof uploadPublicComment>
  | ActionType<typeof removeComment>
  | ActionType<typeof removePublicComment>

const commentEpic = combineEpics<any>(
  fetchCommentListEpic,
  fetchPublicCommentListEpic,
  fetchCommentEpic,
  fetchPublicCommentEpic,
  addCommentEpic,
  addPublicCommentEpic,
  uploadCommentEpic,
  uploadPublicCommentEpic,
  removeCommentEpic,
  removePublicCommentEpic
)
export default commentEpic
