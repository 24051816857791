import { ajax } from 'rxjs/ajax'
import { API, JWT } from '../helpers/base'
import { ITeam } from '../models/team'

export const get = ({ token }: { token: string }) =>
  ajax({
    url: API('team', 'AUTH'),
    method: 'GET',
    headers: JWT(token),
    responseType: 'json',
  })

export const update = ({
  token,
  params,
}: {
  token: string
  params: Partial<ITeam>
}) =>
  ajax({
    url: API('team/update', 'AUTH'),
    method: 'POST',
    body: {
      ...params,
    },
    headers: JWT(token),
    responseType: 'json',
  })

// 取得團隊 meta 資訊
export const getTeamMeta = ({
  token,
}: {
  token: string,
}) => 
  ajax({
    url: API('team/meta', 'SUPPORT'),
    method: 'POST',
    headers: JWT(token),
    responseType: 'json',
  })

/**
 * 更新團隊 meta 資訊, 例如工單必填欄位
 * serv_ppl_{name|phone|email},
 * serv_ppl_2_{name|phone|email},
 * customer_{name|phone|email}.
 */
export const updateTeamMeta = ({
  token,
  params,
}: {
  token: string,
  params: Partial<ITeam>
}) => 
  ajax({
    url: API('team/meta/update', 'SUPPORT'),
    method: 'POST',
    body: {
      ...params,
    },
    headers: JWT(token),
    responseType: 'json',
  })