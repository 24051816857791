import { ReactNode } from 'react'
import ExternalTooltip from 'react-tooltip-lite'
import './Tooltip.scss'

type TooltipPosition = 'top' | 'bottom'

interface TooltipProps {
  // optional，調整 Tooltip component 的樣式
  className?: string

  // 若為 undefined，則不顯示 tooltip
  content?: string
  children: ReactNode
  position?: TooltipPosition
}

// 將外部套件的 tooltip 元件包裝起來，方便之後更換使用的套件，像是 mui
export default function Tooltip({
  className,
  content,
  children,
  position,
}: TooltipProps) {
  if (!content) {
    return <>{children}</>
  }
  return (
    <ExternalTooltip
      className={className}
      content={content}
      direction={position === 'bottom' ? 'down' : 'up'}
    >
      {children}
    </ExternalTooltip>
  )
}
