import { Field as FormRendererField } from '@data-driven-forms/react-form-renderer'
import componentTypes from '@data-driven-forms/react-form-renderer/component-types'
import validatorTypes from '@data-driven-forms/react-form-renderer/validator-types'
import { MAX_SUBJECT_LENGTH } from './constants'
import { ITemplate } from './template'
import { ETicketFunction, ITicket } from './ticket'
import { inFunctionList } from '../helpers/base'
import { IonIcon } from '@ionic/react'
import { add } from 'ionicons/icons'
import { useTranslation } from 'react-i18next'
import { EPreviewFormType } from './form'

/**
 *  Template 與 Ticket 的所有欄位
 */
export interface FormRendererSchema {
  base: SubSchema // 不能自訂的基本資訊
  custom_fields: SubSchema // 自訂的欄位（客戶與承包商皆看得到）
  itn_custom_fields: SubSchema // 自訂的欄位（只有承包商看得到）
}

/**
 *  子類別，i.e 數個欄位的集合
 */
export type SubSchema = {
  fields: FormRendererField[]
}

/**
 *  子類別的種類
 */
export type SubSchemaType = 'custom_fields' | 'itn_custom_fields'

// 增加維修人員按鈕的 icon
// 因無法透過 AddButtonProps 傳入按鈕的文字，所以改用這種方式自訂文字
const ServPpl2AddBtnStartIcon = () => {
  const { t } = useTranslation()
  return (
    <>
      <IonIcon icon={add} />
      {t('Add Service PPL')}
    </>
  )
}

// 維修人員 2 的欄位
const getServicePpl2Fields = (
  item: ITicket | ITemplate | null,
  type: EPreviewFormType.ticket | EPreviewFormType.template
): FormRendererField[] => {
  // 維修人員 2 根據是否有設定資料來決定顯示欄位還是顯示新增 (+號) 按鈕
  const isHaveServPpl2 =
    !!item?.serv_ppl_2_name ||
    !!item?.serv_ppl_2_phone

  // 若有設定資料則顯示欄位
  if (isHaveServPpl2) {
    return [
      {
        component: componentTypes.TEXT_FIELD,
        name: 'serv_ppl_2_name',
        label:
          type === EPreviewFormType.template
            ? '預設維修人員姓名 2'
            : '維修人員姓名 2',
        initialValue: item?.serv_ppl_2_name,
      },
      {
        component: componentTypes.TEXT_FIELD,
        name: 'serv_ppl_2_phone',
        label:
          type === EPreviewFormType.template
            ? '預設維修人員電話 2'
            : '維修人員電話 2',
        initialValue: item?.serv_ppl_2_phone,
      }
    ]
  }

  // 沒有設定資料，顯示新增 (+號) 按鈕
  return [
    {
      component: componentTypes.FIELD_ARRAY,
      name: 'serv_ppl_2',
      maxItems: 1,
      // 增加維修人員的按鈕，無法透過 props 來更改按鈕的 textContent
      // 所以以 workaround 的方式在 startIcon 增加按鈕文字
      // 並透過 CSS 隱藏原本預設的文字
      AddButtonProps: {
        startIcon: <ServPpl2AddBtnStartIcon />,
      },
      UndoButtonProps: { hidden: true }, // 隱藏 Undo 按鈕
      RedoButtonProps: { hidden: true }, // 隱藏 Redo 按鈕
      RemoveButtonProps: { hidden: true }, // 隱藏 Remove 按鈕
      fields: [
        {
          component: componentTypes.TEXT_FIELD,
          name: 'serv_ppl_2_name',
          label:
            type === EPreviewFormType.template
              ? '預設維修人員姓名 2'
              : '維修人員姓名 2',
          initialValue: item?.serv_ppl_2_name,
        },
        {
          component: componentTypes.TEXT_FIELD,
          name: 'serv_ppl_2_phone',
          label:
            type === EPreviewFormType.template
              ? '預設維修人員電話 2'
              : '維修人員電話 2',
          initialValue: item?.serv_ppl_2_phone,
        }
      ],
    },
  ]
}

/**
 *  取得 Template 預設的所有欄位
 */
export const loadDefaultTemplateFullSchema = (
  item?: ITemplate | null
): FormRendererSchema => {
  const baseFields = [
    {
      component: componentTypes.TEXT_FIELD,
      name: 'name',
      label: '專案名稱',
      initialValue: item?.name,
      isRequired: true,
      validate: [
        {
          type: validatorTypes.REQUIRED,
        },
        {
          type: validatorTypes.MAX_LENGTH,
          threshold: MAX_SUBJECT_LENGTH,
        },
      ],
    },
    {
      component: componentTypes.TEXT_FIELD,
      name: 'serv_ppl_name',
      label: '預設維修人員姓名',
      initialValue: item?.serv_ppl_name,
    },
    {
      component: componentTypes.TEXT_FIELD,
      name: 'serv_ppl_phone',
      label: '預設維修人員電話',
      initialValue: item?.serv_ppl_phone,
    }
  ]

  const sendSmsToUserField = {
    component: componentTypes.CHECKBOX,
    name: 'send_sms_to_user',
    label: '自動傳送簡訊給住戶',
    initialValue: item?.send_sms_to_user ?? true,
  }

  return {
    base: {
      fields: [
        ...baseFields,
        ...getServicePpl2Fields(item!, EPreviewFormType.template),
        sendSmsToUserField,
      ],
    },
    custom_fields: {
      fields: [
        {
          component: 'select',
          label: '樓層',
          name: 'custom_fields_5qiT5bGk',
          placeholder: '請選擇樓層',
          helperText: '',
          options: [
            {
              label: '1F',
              value: '1F',
            },
            {
              label: '2F',
              value: '2F',
            },
            {
              label: '3F',
              value: '3F',
            },
            {
              label: '其他',
              value: '其他',
            },
          ],
        },
        {
          component: 'select',
          label: '戶別',
          name: 'custom_fields_5oi25Yil',
          placeholder: '請選擇戶別編號',
          helperText: '',
          isMulti: false,
          options: [
            {
              label: 'A1',
              value: 'A1',
            },
            {
              label: 'A2',
              value: 'A2',
            },
            {
              label: 'A3',
              value: 'A3',
            },
            {
              label: '其他',
              value: '其他',
            },
          ],
        },
        {
          component: 'checkbox',
          label: '維修分類',
          name: 'custom_fields_57at5L+u5bug5ZWG',
          placeholder: '請選擇指定的維修廠商',
          helperText: '',
          options: [
            {
              label: '水電',
              value: '水電',
            },
            {
              label: '磁磚',
              value: '磁磚',
            },
            {
              label: '防水',
              value: '防水',
            },
            {
              label: '其他',
              value: '其他',
            },
          ],
        },
        {
          component: 'textarea',
          label: '補充說明',
          name: 'custom_fields_1VdUE9xM55TvPeVXa',
          placeholder: '請輸入詳細說明',
          helperText: '如有特殊需求請在此填寫，例如：有停車位',
        },
      ],
    },
    itn_custom_fields: {
      fields: [
        {
          component: 'select',
          label: '保固狀態',
          name: 'itn_custom_fields_1U4Oewb4jbQXX1s3H',
          placeholder: '請選擇保固狀態',
          helperText: '',
          isMulti: false,
          initialValue: '保固中',
          options: [
            {
              label: '保固中',
              value: '保固中',
            },
            {
              label: '已過保',
              value: '已過保',
            },
          ],
        },
        {
          component: 'select',
          label: '維修分類',
          name: 'itn_custom_fields_1VGOFvmYZEmzP6wSk',
          placeholder: '請選擇維修分類',
          helperText: '',
          isMulti: false,
          options: [
            {
              label: '機電',
              value: '機電',
            },
            {
              label: '防水',
              value: '防水',
            },
            {
              label: '泥作',
              value: '泥作',
            },
            {
              label: '電梯',
              value: '電梯',
            },
          ],
        },
      ],
    },
  }
}

/**
 *  取得 Ticket 預設的所有欄位
 */
export const loadDefaultTicketFullSchema = (
  item: ITicket,
  isPublic: boolean = false
): FormRendererSchema => {
  const baseFields = [
    {
      component: componentTypes.TEXT_FIELD,
      name: 'ticket_status',
      initialValue: item?.ticket_status,
      hideField: true,
    },
    {
      component: componentTypes.TEXT_FIELD,
      name: 'customer_name',
      label: '客戶姓名',
      initialValue: item?.customer_name,
      isRequired: true,
      validate: [
        {
          type: validatorTypes.REQUIRED,
        },
      ],
    },
    {
      component: componentTypes.TEXT_FIELD,
      name: 'customer_phone',
      label: '客戶電話',
      initialValue: item?.customer_phone,
    },
  ]

  const memoField = {
    component: componentTypes.TEXTAREA,
    name: 'memo',
    label: '說明（例如：水管阻塞）',
    initialValue: item?.memo,
  }

  const expectedEndDateField = {
    component: componentTypes.DATE_PICKER,
    name: 'expected_end_date',
    label: '預計完成日',
    initialValue: item?.expected_end_date,
    DatePickerProps: defaultDatePickerProps,
  }

  const servPplFields = [
    {
      component: componentTypes.TEXT_FIELD,
      name: 'serv_ppl_name',
      label: '維修人員姓名',
      initialValue: item?.serv_ppl_name,
    },
    {
      component: componentTypes.TEXT_FIELD,
      name: 'serv_ppl_phone',
      label: '維修人員電話',
      initialValue: item?.serv_ppl_phone,
    }
  ]

  // 新增工單時，不顯示預計完成日欄位
  if (
    !isPublic &&
    !inFunctionList(ETicketFunction.ticket_update, item.function_list)
  ) {
    return {
      base: {
        fields: [
          ...baseFields,
          ...servPplFields,
          ...getServicePpl2Fields(item!, EPreviewFormType.ticket),
          memoField,
        ],
      },
      custom_fields: { fields: [] },
      itn_custom_fields: { fields: [] },
    }
  }

  // 在非公開模式 (開單後) 可以設定維修人員姓名/電話 & 預計完成日
  if (!isPublic) {
    return {
      base: {
        fields: [
          ...baseFields,
          ...servPplFields,
          ...getServicePpl2Fields(item!, EPreviewFormType.ticket),
          memoField,
          expectedEndDateField,
        ],
      },
      custom_fields: { fields: [] },
      itn_custom_fields: { fields: [] },
    }
  }

  // 公開連結，不顯示維修人員(s) & 預計完成日欄位
  return {
    base: { fields: [...baseFields, memoField] },
    custom_fields: { fields: [] },
    itn_custom_fields: { fields: [] },
  }
}

export const defaultDatePickerProps = {
  inputFormat: 'yyyy-MM-dd',
  clearable: true,
  clearText: '清除',
  cancelText: '取消',
  okText: '確認',
  toolbarTitle: '選擇日期',
  desktopModeMediaQuery: '@media max-width: 10000px', // 一直維持顯示 mobile mode
}
