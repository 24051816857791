import { IonIcon, IonLabel } from '@ionic/react'
import { alarmOutline } from 'ionicons/icons'
import { useTranslation } from 'react-i18next'
import './NoReminder.scss'

// 當沒有提醒時，顯示的畫面 (沒有提醒的 Icon + 文字)
const NoReminder = () => {
  const { t } = useTranslation()

  return (
    <div className="no-reminder">
      <IonIcon icon={alarmOutline}></IonIcon>
      <IonLabel>
        <h2>{t('No Reminder')}</h2>
      </IonLabel>
    </div>
  )
}

export default NoReminder
