import { length } from './base'

export const SUIQUI_DOWNLOAD_CACHE = 'downloads'
export const UPLOAD_DELAY_INTERVAL = 1000
export const MAX_RETRY_TIMES = 5
export const MAX_SUBJECT_LENGTH: length = 300
export const MAX_DISPLAYNAME_LENGTH: length = 50
export const MAX_CUSTOM_FIELDS: length = 5
export const MAX_NOTE_FIELDS: length = 2
export const MAX_NOTE_FIELD_ROWS: length = 5
export const MAX_SIGNATURE_FIELDS: length = 6
export const MAX_FIELD_ROWS: length = 50
export const MAX_COVER_FOOTERS: length = 1
export const MAX_UPLOAD_FILES: length = 30
export const MAX_UPLOAD_FILES_DISPLAY: length = 8
export const MAX_CUSTOM_FIELDS_COMBINE: length = 2
export const MAX_PARALLEL_UPLOAD = 3
export const REQUEST_DELAY = 500
export const REQUEST_DELAY_LONG = 1000
export const REQUEST_DELAY_VERY_LONG = 5000
export const REQUEST_LIMIT = 5
export const AUTO_WRAP_LENGTH = 35
export const THRESHOLD_BATCH_MODE = 5
export const DELAY_SHORT = 350
export const DELAY = 500
export const DELAY_LONG = 1000
export const DELAY_TOAST = 2000
export const DELAY_DEBOUNCE = 600
export const DEFAULT_TEXTAREA_ROWS = 5
export const DEFAULT_MOBILE_TEXTAREA_ROWS = 3

export const BREAKPOINT_XS = 0
export const BREAKPOINT_SM = 576
export const BREAKPOINT_MD = 768
export const BREAKPOINT_LG = 992
export const BREAKPOINT_XL = 1200
