import { IonButton, IonIcon } from '@ionic/react'
import { caretUp } from 'ionicons/icons'
import { DELAY_SHORT } from '../models/constants'
import React, { useCallback } from 'react'

interface ScrollToTopButtonProps {
  contentRef: React.MutableRefObject<HTMLIonContentElement | null>
}

const ScrollToTopButton = ({ contentRef }: ScrollToTopButtonProps) => {
  const scrollToTop = useCallback(() => {
    contentRef.current?.scrollToTop(DELAY_SHORT)
  }, [contentRef])

  return (
    <div className="ion-text-center">
      <IonButton
        color="warning"
        fill="clear"
        size="large"
        slot="icon-only"
        onClick={scrollToTop}
      >
        <IonIcon icon={caretUp} />
      </IonButton>
    </div>
  )
}

export default ScrollToTopButton
