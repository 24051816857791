import FileSaver from 'file-saver'
import { combineEpics, Epic } from 'redux-observable'
import { fromEvent, of } from 'rxjs'
import {
  catchError,
  filter,
  finalize,
  map,
  merge,
  mergeMap,
  takeUntil,
} from 'rxjs/operators'
import {
  ActionType,
  createAsyncAction,
  createCustomAction,
  createReducer,
  getType,
  isActionOf,
} from 'typesafe-actions'
import { SUIQUI_DOWNLOAD_CACHE } from '../../models/constants'
import { IDownloadFile } from '../../models/download'
import { IProgressFile } from '../../models/suiquiFile'
import * as Providers from '../../providers'
import { RootState } from '../ducks'
import { RootAction } from './types'

const initialState: {
  pFiles: Partial<IProgressFile>
  doneCount: number
  blob: Blob
  name: string
} = {
  pFiles: {},
  doneCount: 0,
  blob: new Blob(),
  name: '',
}

export const downloadFile = createAsyncAction(
  'DOWNLOAD_FILE_REQUEST',
  'DOWNLOAD_FILE_SUCCESS',
  'DOWNLOAD_FILE_FAILURE',
  'DOWNLOAD_FILE_CANCEL'
)<
  Partial<IDownloadFile> & Pick<IDownloadFile, 'key' | 'name' | 'url' | 'hash'>,
  Partial<IDownloadFile> & Pick<IDownloadFile, 'name' | 'hash'>,
  Error,
  { hash?: number }
>()

export const downloadFileProgress = createCustomAction(
  'DOWNLOAD_FILE_PROGRESS',
  (payload) => {
    return {
      payload: {
        hash: payload.hash,
        percentage: payload.percentage,
      },
    }
  }
)

export const receiveFileEpic: Epic<
  RootAction,
  RootAction,
  RootState,
  typeof Providers
> = (action$, state$, { downloadAPI }) => {
  return action$.pipe(
    filter(isActionOf(downloadFile.request)),
    mergeMap((action) => {
      const { hash } = action.payload
      const xhr = new XMLHttpRequest()
      const getRequest$ = () => xhr
      const request$ = downloadAPI
        .exec({
          url: action.payload.url,
          createXHR: getRequest$,
        })
        .pipe(
          map((data) => {
            return downloadFile.success({
              blob: data.response,
              ...action.payload,
            })
          }),
          catchError((message: Error) => of(downloadFile.failure(message))),
          finalize(() => {
            xhr.onreadystatechange = null
          }),
          takeUntil(
            action$.pipe(
              filter(isActionOf(downloadFile.cancel)),
              filter((action) => action.payload.hash === hash)
            )
          )
        )

      const size = action.payload?.file?.blob_info?.size || 0
      return fromEvent(xhr, 'progress').pipe(
        map((e: any) => ({
          percentage: (e.loaded / (e.total | size)) * 100 || 0,
        })),
        map((data) =>
          downloadFileProgress({
            hash: action.payload.hash,
            percentage: data.percentage,
          })
        ),
        merge(request$)
      )
    })
  )
}

export type DownloadFileAction =
  | ActionType<typeof downloadFile>
  | ActionType<typeof downloadFileProgress>

const doneWithPayload = ({ hash }: any, pFiles: any) => {
  if (hash) {
    pFiles[hash] = {
      isDone: true,
      isDownloading: false,
      percentage: 100,
    }
  } else {
    pFiles = {}
  }

  return pFiles
}

const progressWithPayload = ({ hash, percentage }: any, pFiles: any) => {
  pFiles[hash] = {
    ...pFiles[hash],
    isDone: false,
    isCancel: false,
    percentage,
    isDownloading: true,
    isPending: Number.isNaN(percentage) || !Number.isFinite(percentage),
  }

  return pFiles
}

const resetWithPayload = ({ hash }: any, pFiles: any) => {
  if (pFiles[hash]) {
    pFiles[hash] = {
      ...pFiles[hash],
      isCancel: true,
      isPending: false,
      percentage: NaN,
    }
  } else {
    pFiles = {}
  }
  return pFiles
}

export const downloadFileReducer = createReducer(initialState)
  .handleAction(
    getType(downloadFile.request),
    (state: any, { payload }: any) => ({
      pFiles: progressWithPayload(payload, state.pFiles),
      doneCount: state.doneCount,
    })
  )
  .handleAction(
    getType(downloadFile.success),
    (state: any, { payload }: any) => {
      if (payload.blob) {
        FileSaver.saveAs(payload.blob, payload.name)
      }

      if ('caches' in window) {
        caches.open(SUIQUI_DOWNLOAD_CACHE).then((cache) => {
          cache.match(payload.url).then((response) => {
            if (response) {
              //cached
            } else {
              cache.put(payload.url, new Response(payload.blob))
            }
          })
        })
      }

      return {
        pFiles: doneWithPayload(payload, state.pFiles),
        doneCount: state.doneCount + 1,
        blob: payload.blob,
        name: payload.name,
      }
    }
  )
  .handleAction(
    getType(downloadFile.cancel),
    (state: any, { payload }: any) => ({
      pFiles: resetWithPayload(payload, state.pFiles),
      doneCount: 0,
    })
  )
  .handleAction(
    getType(downloadFileProgress),
    (state: any, { payload }: any) => ({
      pFiles: progressWithPayload(payload, state.pFiles),
      doneCount: state.doneCount,
    })
  )

const downloadFileEpic = combineEpics<any>(receiveFileEpic)
export default downloadFileEpic
