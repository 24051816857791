import {
  IonAvatar,
  IonButton,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonMenu,
  IonMenuToggle,
  IonModal,
  IonTitle,
  IonToast,
  IonToolbar,
} from '@ionic/react'
import classNames from 'classnames'
import { cube, exit, home, personAdd, server, settings } from 'ionicons/icons'
import { useState } from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useStorageState } from 'react-storage-hooks'
import { AppPage } from '../../declarations'
import { getAvatarUrl } from '../../helpers/file'
import useLocalForge from '../../hooks/use-local-forge'
import TeamSwitchModal from '../../modals/TeamSwitchModal'
import { DELAY_TOAST } from '../../models/constants'
import './Menu.scss'
import MenuItem from './MenuItem'
import { IUser } from '../../models/user'
import { useModalWithData } from '../../hooks/useModal'
import { ITeam } from '../../models/team'
import QuickAddAccountModal from '../../modals/QuickAddAccountModal'

const Menu = () => {
  const { t } = useTranslation()
  const [, setToken] = useLocalForge('apiToken')
  const [, setCurrentUser] = useLocalForge('suiquiUserData')
  const history = useHistory()
  const [, setShowToastSession] = useStorageState(
    sessionStorage,
    'isToastShown',
    false
  )

  const user = useSelector((state: any) => state.user)
  const authDuck = useSelector((state: any) => state.authDuck)
  const [showTeamSwitchModal, setShowTeamSwitchModal] = useState(false)
  const [showCopyUrlToast, setShowCopyUrlToast] = useState(false)

  const [
    isShowQuickAddAccountModal,
    quickAddAccountModalData,
    showQuickAddAccountModal,
    dismissQuickAddAccountModal,
  ] = useModalWithData<ITeam>()

  // 判斷是否為外部使用者
  const isUserTypeExternal = (user: IUser) => user.user_type === 'external'

  const appPages: AppPage[] = [
    {
      title: t('Suiqui Support'),
      url: '/home',
      icon: home,
    },
    {
      title: t('Project Manage'),
      url: '/project',
      icon: server,
    },
    {
      title: t('Settings'),
      url: '/settings',
      icon: settings,
    },
  ]

  const adminPages: AppPage[] = []

  const handleClickSwitchTeam = async (event: any) => {
    event.preventDefault()
    setShowTeamSwitchModal(true)
  }

  const handleClickLogout = async (event: any) => {
    event.preventDefault()
    setToken(null)
    setCurrentUser(null)
    setShowToastSession(false)
    history.push('/logout', { direction: 'root' })
  }

  return (
    <IonMenu className="component-menu" contentId="main">
      <IonHeader className="no-border">
        <IonToolbar color="primary">
          <IonTitle>
            <IonButton class="logo immutable">{t('Suiqui Support')}</IonButton>
            <span className="subtitle">{t('SUIQUI_SUPPORT')}</span>
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {/* 團隊名稱 */}
        <IonHeader>
          <IonToolbar color="light" className="ion-text-center">
            {authDuck?.isUserLoaded && (
              <IonButton
                className="team-name"
                fill="clear"
                size="default"
                strong={true}
                href="#"
                onClick={handleClickSwitchTeam}
              >
                <IonIcon slot="start" icon={cube} />
                <IonLabel>{authDuck.currentUser?.team?.name}</IonLabel>
              </IonButton>
            )}
          </IonToolbar>
        </IonHeader>

        {/* 使用者資訊欄 */}
        {authDuck?.isUserLoaded && (
          <div className="ion-text-center">
            <IonItem lines="none" className="user-box menu-item">
              {/* 使用者 icon */}
              <IonAvatar slot="start">
                <img
                  alt={authDuck.currentUser?.displayname}
                  src={getAvatarUrl(authDuck.currentUser)}
                />
              </IonAvatar>
              <IonLabel color="primary" className="user-name">
                {/* 使用者名稱 */}
                {authDuck?.currentUser?.displayname}

                {/* Line 圖示 */}
                <div className="line-box">
                  <a
                    href={`${process.env.REACT_APP_LINE_APP_URL}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div
                      className={classNames({
                        'line-svg': true,
                        enabled: authDuck?.currentUser.is_line_active,
                      })}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        viewBox="0 0 32 32"
                        className="wh-messenger-svg-line"
                      >
                        <path
                          d=" M27 14.926C27 10.006 22.065 6 16 6S5 10.005 5 14.926c0 4.413 3.913 8.11 9.2 8.808.358.077.845.236.968.542.112.278.073.713.036.995l-.157.942c-.048.28-.22 1.088.953.593 1.174-.494 6.334-3.73 8.642-6.386C26.236 18.67 27 16.896 27 14.925zm-4.247-.41a.577.577 0 0 1 0 1.153h-1.61v1.03h1.61a.578.578 0 0 1 0 1.155h-2.186a.578.578 0 0 1-.577-.577v-4.37c0-.32.26-.578.577-.578h2.186a.578.578 0 0 1 0 1.153h-1.61v1.033h1.61zm-3.537 2.762a.575.575 0 0 1-.578.577.58.58 0 0 1-.46-.23l-2.24-3.05v2.703a.577.577 0 0 1-1.154 0v-4.37a.577.577 0 0 1 1.038-.347l2.24 3.05v-2.703a.578.578 0 0 1 1.154 0v4.37zm-5.26 0a.577.577 0 0 1-1.154 0v-4.37a.577.577 0 0 1 1.153 0v4.37zm-2.262.577H9.508a.577.577 0 0 1-.576-.577v-4.37a.577.577 0 0 1 1.153 0V16.7h1.61a.577.577 0 0 1 0 1.155z"
                          fillRule="evenodd"
                        ></path>
                      </svg>
                    </div>
                  </a>
                  {authDuck?.currentUser?.line_app_verification_code && (
                    <CopyToClipboard
                      text={authDuck?.currentUser?.line_app_verification_code}
                      onCopy={() => {}}
                    >
                      <IonButton
                        fill="clear"
                        onClick={(e) => {
                          e.preventDefault()
                          e.stopPropagation()
                          setShowCopyUrlToast(true)
                        }}
                        className="verification-code"
                      >
                        {authDuck?.currentUser?.line_app_verification_code}
                        <span className="tip">{t('Tap to copy')}</span>
                      </IonButton>
                    </CopyToClipboard>
                  )}
                </div>
              </IonLabel>
            </IonItem>
          </div>
        )}

        {/* 側邊欄選項 */}
        {appPages.map((appPage: AppPage, index: number) => (
          <MenuItem appPage={appPage} index={index} />
        ))}
        {user?.currentUser?.is_admin &&
          adminPages.map((appPage: AppPage, index: number) => (
            <MenuItem appPage={appPage} index={index} />
          ))}

        {/* 新增帳號，非外部使用者才會顯示 */}
        {authDuck?.currentUser && !isUserTypeExternal(authDuck.currentUser) && (
          <IonMenuToggle auto-hide="false">
            <IonItem
              onClick={() => showQuickAddAccountModal(user?.currentUser?.team)}
              button
              lines="none"
              className="menu-item"
            >
              <IonIcon slot="start" icon={personAdd} />
              <IonLabel>{t('Quick Add Account')}</IonLabel>
            </IonItem>
          </IonMenuToggle>
        )}

        {/* 切換團隊 按鈕 */}
        <IonMenuToggle auto-hide="false">
          <IonItem
            onClick={handleClickSwitchTeam}
            button
            lines="none"
            className="menu-item"
          >
            <IonIcon slot="start" icon={cube} />
            <IonLabel>{t('Switch Team')}</IonLabel>
          </IonItem>
        </IonMenuToggle>

        {/* 登出 按鈕 */}
        <IonMenuToggle auto-hide="false">
          <IonItem
            onClick={handleClickLogout}
            button
            lines="none"
            className="menu-item"
          >
            <IonIcon slot="start" icon={exit} />
            <IonLabel>{t('Log Out')}</IonLabel>
          </IonItem>
        </IonMenuToggle>

        <IonModal isOpen={showTeamSwitchModal} backdropDismiss={false}>
          <TeamSwitchModal
            isOpen={showTeamSwitchModal}
            dismiss={() => setShowTeamSwitchModal(false)}
          />
        </IonModal>

        {/* 新增帳號 modal */}
        <IonModal isOpen={isShowQuickAddAccountModal} backdropDismiss={false}>
          <QuickAddAccountModal
            team={quickAddAccountModalData}
            dismiss={dismissQuickAddAccountModal}
          />
        </IonModal>

        <IonToast
          isOpen={showCopyUrlToast}
          onDidDismiss={() => setShowCopyUrlToast(false)}
          message={t('The code is copied to clipboard.')}
          duration={DELAY_TOAST}
        />
      </IonContent>
    </IonMenu>
  )
}

export default Menu
