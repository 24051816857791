import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonPage,
  IonTitle,
  IonToast,
  IonToolbar,
} from '@ionic/react'
import { useTranslation } from 'react-i18next'
import { close, cloudDownload, copy } from 'ionicons/icons'
import QRCode from 'react-qr-code'
import './QRCodeModal.scss'
import { useCallback, useState } from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import { DELAY_TOAST } from '../../models/constants'
const { saveSvgAsPng } = require('save-svg-as-png')

interface QRCodeModalProps {
  isOpen: boolean
  dismiss: Function
  title: string
  url: string
}

const QRCodeModal = ({ isOpen, dismiss, title, url }: QRCodeModalProps) => {
  /* Hooks */
  const { t } = useTranslation()
  const [showCopyUrlToast, setShowCopyUrlToast] = useState(false)

  /* Functions */
  const cancel = useCallback(() => {
    dismiss()
  }, [dismiss])

  const downloadPng = useCallback(() => {
    const node = document.querySelector('.qrcode > svg')
    const filename = 'qrcode.png'
    saveSvgAsPng(node, filename)
  }, [])

  const copyUrl = useCallback(() => {
    setShowCopyUrlToast(true)
  }, [setShowCopyUrlToast])

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="primary">
          {/* 標題 (「快速開單網址」或「維修清單網址」) */}
          <IonTitle>
            <IonButton class="logo immutable">{t('Suiqui Support')}</IonButton>
            <span>{t(title)}</span>
          </IonTitle>

          {/* 關閉按鈕 */}
          <IonButtons slot="end">
            <IonButton strong fill="clear" slot="icon-only" onClick={cancel}>
              <IonIcon size="large" icon={close}></IonIcon>
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="content">
        {/* QR Code */}
        <div className="qrcode">
          <QRCode value={url} />
        </div>

        {/* url */}
        <div className="url">
          <a href={url}>{url}</a>
        </div>

        <IonItem lines="none">
          <IonButtons slot="end">
            {/* 「複製」按鈕 */}
            <CopyToClipboard text={url} onCopy={() => {}}>
              <IonButton fill="solid" color="success" onClick={copyUrl}>
                <IonIcon slot="start" icon={copy}></IonIcon>
                <IonLabel>{t('Copy url')}</IonLabel>
              </IonButton>
            </CopyToClipboard>

            {/* 「下載」按鈕 */}
            <IonButton fill="solid" color="secondary" onClick={downloadPng}>
              <IonIcon slot="start" icon={cloudDownload}></IonIcon>
              <IonLabel>{t('Download')}</IonLabel>
            </IonButton>
          </IonButtons>
        </IonItem>
      </IonContent>

      {/* Toasts */}
      {/* 複製網址成功 toast */}
      <IonToast
        isOpen={showCopyUrlToast}
        onDidDismiss={() => setShowCopyUrlToast(false)}
        message={t('The url is copied to clipboard.')}
        duration={DELAY_TOAST}
      />
    </IonPage>
  )
}

export default QRCodeModal
