import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonLabel,
  IonPage,
  IonTextarea,
  IonTitle,
  IonToolbar,
} from '@ionic/react'
import { checkmark, close, document } from 'ionicons/icons'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useRemoveIonPageInvisible } from '../../hooks/useRemoveIonPageInvisible'
import { RawFileWithMemo } from './CustomerPhotoFields'

interface EditRawFileMemoModalProps {
  file: RawFileWithMemo
  onEdit: (newMemo: string) => void
  dismiss: () => void
}

const EditRawFileMemoModal = ({
  file,
  onEdit,
  dismiss,
}: EditRawFileMemoModalProps) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const pageRef = useRemoveIonPageInvisible()
  const { register, getValues, formState } = useForm<{ memo: string }>({
    defaultValues: { memo: file.memo },
  })

  /**
   * 完成修改，呼叫 callback
   */
  const done = () => {
    const { memo: newMemo } = getValues()
    onEdit(newMemo)
    dismiss()
  }

  return (
    <IonPage className="page-memo-modal" ref={pageRef}>
      <IonHeader>
        <IonToolbar color="primary">
          <IonTitle>
            <IonButton class="logo immutable">{t('Suiqui Library')}</IonButton>
            <span>{t('Memo')}</span>
          </IonTitle>
          <IonButtons slot="end">
            {/* 取消按鈕 */}
            <IonButton strong fill="clear" slot="icon-only" onClick={dismiss}>
              <IonIcon size="large" icon={close}></IonIcon>
            </IonButton>

            {/* 確認按鈕 */}
            <IonButton
              strong
              fill="clear"
              slot="icon-only"
              onClick={done}
              disabled={!formState.isValid}
              data-test="confirm"
            >
              <IonIcon size="large" icon={checkmark}></IonIcon>
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {/* 檔案名稱 */}
        <IonHeader>
          <IonToolbar color="light">
            <IonButtons slot="start">
              <IonButton color="primary" fill="clear" className="immutable">
                <IonIcon slot="icon-only" icon={document} />
              </IonButton>
            </IonButtons>
            <IonLabel>{file.name}</IonLabel>
          </IonToolbar>
        </IonHeader>

        {/* 記事輸入欄 */}
        <IonTextarea
          autofocus
          rows={25}
          placeholder={t('Input memo here.')}
          {...register('memo')}
        ></IonTextarea>
      </IonContent>
    </IonPage>
  )
}

export default EditRawFileMemoModal
