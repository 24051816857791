import Checkbox from '@data-driven-forms/mui-component-mapper/checkbox'
import Select from '@data-driven-forms/mui-component-mapper/select'
import SubForm from '@data-driven-forms/mui-component-mapper/sub-form'
import TextField from '@data-driven-forms/mui-component-mapper/text-field'
import TextArea from '@data-driven-forms/mui-component-mapper/textarea'
import DatePicker from '@data-driven-forms/mui-component-mapper/date-picker'
import FieldArray from '@data-driven-forms/mui-component-mapper/field-array'
import { componentTypes, Field } from '@data-driven-forms/react-form-renderer'
import { IonItemDivider } from '@ionic/react'
import { EPreviewFormType } from '../models/form'
import {
  FormRendererSchema,
  loadDefaultTemplateFullSchema,
  loadDefaultTicketFullSchema,
  SubSchema,
  SubSchemaType,
} from '../models/schema'
import { ITemplate } from '../models/template'
import { ITicket } from '../models/ticket'
import { customAlphabet } from 'nanoid'
import { nolookalikes } from 'nanoid-dictionary'

/**
 *  此檔案的內容目前只有被 PreviewForm 使用
 *  這些 function 的設計在未來重新整理其他表單的部分時會再調整
 */

/**
 *  根據 item 的類別，取得預設的所有欄位
 */
export const loadDefaultFullSchema = (
  type: EPreviewFormType,
  item: ITemplate | ITicket,
  isPublic: boolean = false
): FormRendererSchema => {
  if (
    type === EPreviewFormType.ticket ||
    type === EPreviewFormType.publicTicket
  ) {
    return loadDefaultTicketFullSchema(item as ITicket, isPublic)
  } else if (type === 'template') {
    return loadDefaultTemplateFullSchema(item as ITemplate)
  } else {
    throw new Error('no type')
  }
}

/**
 *  取得 item 子類別的欄位
 *  i.e. custom_fields, itn_custom_fields
 */
export const loadSubSchemaFromItem = (
  name: SubSchemaType,
  item: ITemplate | ITicket
): SubSchema => {
  const schemaName = `${name}_schema`
  if (
    (item as any)[schemaName] &&
    Object.keys((item as any)[schemaName]).length
  ) {
    return JSON.parse((item as any)[schemaName])
  } else {
    return (loadDefaultTemplateFullSchema(item as ITemplate) as any)[name]
  }
}

export const applyIsRequired = (fields: Field[], validateOnMount = true) => {
  return (fields || []).map((field) => {
    if (!!field.isRequired) {
      field.validateOnMount = validateOnMount
      if (field.validate && field.validate.length) {
        const found = field.validate.findIndex(
          (f: any) => f.type === 'required'
        )
        if (found === -1) {
          field.validate.unshift({ type: 'required' })
        }
      } else {
        field.validate = [{ type: 'required' }]
      }
    } else {
      delete field.validateOnMount
      if (field.validate && field.validate.length) {
        const found = field.validate.findIndex(
          (f: any) => f.type === 'required'
        )
        if (found > -1) {
          field.validate.splice(found, 1)
        }
      }
    }
    return field
  })
}

/**
 *  將 item 所有子類別的欄位組裝起來成一個完整的表單
 *  i.e. base, custom_fields, itn_custom_fields
 *  並在各區塊的中間加上分隔線
 */
export const loadUISchema = (
  type: EPreviewFormType,
  item: ITemplate | ITicket
) => {
  return type === EPreviewFormType.publicTicket
    ? {
        fields: [
          ...loadDefaultFullSchema(type, item, true)['base'].fields,
          {
            name: 'divider-001',
            component: 'ITEM_DIVIDER',
          },
          {
            component: 'sub-form',
            title: '客戶選單',
            name: 'custom_fields_schema',
            fields: applyIsRequired(
              loadSubSchemaFromItem('custom_fields', item).fields
            ),
          },
          {
            name: 'divider-002',
            component: 'ITEM_DIVIDER',
          },
        ],
      }
    : {
        fields: [
          ...loadDefaultFullSchema(type, item)['base'].fields,
          {
            name: 'divider-001',
            component: 'ITEM_DIVIDER',
          },
          {
            component: 'sub-form',
            title: '客戶選單',
            name: 'custom_fields_schema',
            fields: applyIsRequired(
              loadSubSchemaFromItem('custom_fields', item).fields
            ),
          },
          {
            name: 'divider-002',
            component: 'ITEM_DIVIDER',
          },
          {
            component: 'sub-form',
            title: '內部選單',
            name: 'itn_custom_fields_schema',
            fields: applyIsRequired(
              loadSubSchemaFromItem('itn_custom_fields', item).fields
            ),
          },
          {
            name: 'divider',
            component: 'ITEM_DIVIDER',
          },
        ],
      }
}

export const generateFieldId = (schemaType: SubSchemaType) => {
  const nanoid = customAlphabet(nolookalikes, 10)
  return `${schemaType}_${nanoid()}`
}

// 從 template 上取出客戶選單或內部選單的資訊
export const getSchemaFieldsFromTemplate = (
  schemaType: SubSchemaType,
  item: ITemplate
) => {
  const schemaName = `${schemaType}_schema`
  const schema = (item as any)[schemaName]
  if (schema && Object.keys(schema).length) {
    return JSON.parse(schema).fields
  } else {
    return (loadDefaultTemplateFullSchema(item) as any)[schemaType].fields
  }
}

export const customComponentMapper = {
  [componentTypes.TEXT_FIELD]: TextField,
  [componentTypes.SELECT]: Select,
  [componentTypes.CHECKBOX]: Checkbox,
  [componentTypes.TEXTAREA]: TextArea,
  [componentTypes.SUB_FORM]: SubForm,
  [componentTypes.DATE_PICKER]: DatePicker,
  [componentTypes.FIELD_ARRAY]: FieldArray,
  ITEM_DIVIDER: () => <IonItemDivider className="field" color="light" />,
}
