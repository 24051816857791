import {
  IonItem,
  IonChip,
  IonAvatar,
  IonLabel,
  IonButton,
  IonIcon,
} from '@ionic/react'
import { removeCircle } from 'ionicons/icons'
import { getAvatarUrl } from '../helpers/file'
import { IUser } from '../models/user'

interface UserItemProps {
  isLast: boolean
  isRemovable?: boolean // 是否提供移除按鈕
  user: IUser
  onRemoveBtnClick?: (user: IUser) => void
}

export function UserItem({
  isLast,
  isRemovable,
  user,
  onRemoveBtnClick,
}: UserItemProps) {
  return (
    <IonItem lines={isLast ? 'none' : 'inset'}>
      <IonChip>
        <IonAvatar>
          <img src={getAvatarUrl(user)} alt={user.displayname} />
        </IonAvatar>
        <IonLabel>{user.displayname}</IonLabel>
      </IonChip>

      <IonButton
        hidden={!isRemovable}
        fill="clear"
        size="small"
        slot="end"
        color="medium"
        onClick={() => onRemoveBtnClick?.(user)}
      >
        <IonIcon slot="icon-only" color="danger" icon={removeCircle} />
      </IonButton>
    </IonItem>
  )
}
