import {
  IonButton,
  IonButtons,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonModal,
  useIonLoading,
} from '@ionic/react'
import {
  close,
  checkmark,
  createOutline,
  imagesOutline,
  refresh,
  copyOutline,
} from 'ionicons/icons'
import { useCallback, useEffect, useState } from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { inFunctionList } from '../../helpers/base'
import useModal from '../../hooks/useModal'
import PermissionModal from '../../modals/permission/PermissionModal'
import QRCodeModal from '../../modals/qrcode/QRCodeModal'
import { EPreviewFormType } from '../../models/form'
import { ETemplateFunction, ITemplate } from '../../models/template'
import { ETicketFunction, ITicket } from '../../models/ticket'
import { IUser } from '../../models/user'
import { RootState } from '../../store/ducks'
import {
  activateServiceApi,
  clearStatus,
  copyTemplate,
  deactivateServiceApi,
  renewServiceApi,
  resetLineVerificationCode,
} from '../../store/templateSlice'
import './PreviewForm.scss'
import ReminderEditModal from '../../modals/reminder/ReminderEditModal'
import ReminderPreviewComponent from './ReminderPreviewComponent'

interface AdvancedSegmentProps {
  item: ITemplate | ITicket
  type: EPreviewFormType
}

function AdvancedSegment({ item, type }: AdvancedSegmentProps) {
  /* States */
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()
  const currentUser: IUser = useSelector(
    (state: RootState) => state.authDuck.currentUser
  )
  const ticket = useSelector((state: any) => state.ticket)
  const template = useSelector((state: RootState) => state.newTemplate.template)
  const state = useSelector((state: RootState) => state.newTemplate)
  item = type === EPreviewFormType.template ? template : ticket
  const [showQRCodeModal, setShowQRCodeModal] = useState({
    title: '',
    isOpen: false,
    url: '',
  })
  const [present, dismiss] = useIonLoading() // 等待的視覺效果

  /* Hooks */
  useEffect(() => {
    if (!ticket.statusUpdated && !ticket.updated) return
    if (!item) return
    item.function_list = ticket.function_list
  }, [ticket.updated, ticket.statusUpdated, item, type, ticket.function_list])

  // 當 status 為 'copied' 時觸發，重新導向至專案設定頁面
  // 備註：'copied' 會在後端 API 完成複製專案時從 redux 設置
  useEffect(() => {
    if (state.status !== 'copied') return
    dismiss() // 移除等待的視覺效果
    dispatch(clearStatus()) // 清除 status
    history.push('/project', {}) // 重新導向到專案設定頁
  }, [state.status, history, dismiss, dispatch])

  /* Functions */
  // Service api = 「快速開單網址」 + 「維修清單網址」
  const onActivateServiceApi = useCallback(() => {
    dispatch(activateServiceApi({ key: item.key }))
  }, [dispatch, item])

  const onDeactivateServiceApi = useCallback(() => {
    dispatch(deactivateServiceApi({ key: item.key }))
  }, [dispatch, item])

  const onRenewServiceApi = useCallback(() => {
    dispatch(renewServiceApi({ key: item.key }))
  }, [dispatch, item])

  // QRCode modal
  const openCreateQRCodeModal = useCallback(() => {
    setShowQRCodeModal({
      title: 'Ticket create url',
      isOpen: true,
      url: item.api['ticket-create-url'],
    })
  }, [item])

  const openListQRCodeModal = useCallback(() => {
    setShowQRCodeModal({
      title: 'Ticket list url',
      isOpen: true,
      url: item.api['ticket-list-url'],
    })
  }, [item])

  const dismissQRCodeModal = useCallback(() => {
    setShowQRCodeModal({ title: '', isOpen: false, url: '' })
  }, [])

  const [isShowPermissionModal, openPermissionModal, dismissPermissionModal] =
    useModal()

  // handle click button of copy project
  const handleClickCopyProject = useCallback(() => {
    present({
      message: t('Copying settings to new project'),
    })
    // send request to copy project
    dispatch(copyTemplate({ key: item.key }))
  }, [item.key, dispatch, t, present])

  const handleResetLineVerificationCode = useCallback(() => {
    dispatch(resetLineVerificationCode({ key: item.key }))
  }, [item.key, dispatch])

  const [isShowReminderModal, openReminderModal, dismissReminderModal] =
    useModal()

  /* Variables */
  const canUpdateTicket =
    type !== EPreviewFormType.template &&
    inFunctionList(ETicketFunction.ticket_update, item.function_list)
  const canUpdateTemplate =
    type === EPreviewFormType.template &&
    inFunctionList(ETemplateFunction.template_update, item.function_list)
  const showUpdateTips = canUpdateTemplate || canUpdateTicket
  const showQrCodeSettings = canUpdateTemplate
  const showLineSettings = type === EPreviewFormType.template
  const showReminderPreview = type === EPreviewFormType.template
  const serviceApi = item?.api

  return (
    <div className="segment-box">
      {/* 提示：設定變更後會立即儲存 */}
      <div className="tips" hidden={!showUpdateTips}>
        {t('The settings will save immediately.')}
      </div>

      {/* 專案權限設定區，限管理者顯示 */}
      {type === EPreviewFormType.template && (
        <IonList color="light" hidden={!currentUser?.is_admin}>
          <IonListHeader className="advanced">
            <IonLabel>
              <h3>{t('Project Permission')}</h3>
            </IonLabel>
            <IonButtons>
              {/* 「編輯」按鈕 */}
              <IonButton fill="clear" onClick={openPermissionModal}>
                <IonIcon slot="icon-only" icon={createOutline}></IonIcon>
              </IonButton>
            </IonButtons>
          </IonListHeader>
          <IonItem lines="full">
            <IonLabel color="medium">
              {t('Description for permission settings of this project')}
            </IonLabel>
          </IonItem>
        </IonList>
      )}

      {/* QR Code 設定區 */}
      {showQrCodeSettings && (
        <IonList color="light">
          <IonListHeader className="advanced">
            <IonLabel>
              <h3>{t('QR Code')}</h3>
            </IonLabel>
            <IonButtons>
              {serviceApi.active_status === 'active' && (
                <>
                  {/* 「重新產生」按鈕 */}
                  <IonButton
                    fill="clear"
                    color="success"
                    onClick={onRenewServiceApi}
                  >
                    <IonIcon slot="start" icon={refresh}></IonIcon>
                    <IonLabel>{t('Renew')}</IonLabel>
                  </IonButton>

                  {/* 「停用」按鈕 */}
                  <IonButton
                    fill="clear"
                    color="danger"
                    onClick={onDeactivateServiceApi}
                  >
                    <IonIcon slot="start" icon={close}></IonIcon>
                    <IonLabel>{t('Close')}</IonLabel>
                  </IonButton>
                </>
              )}
              {serviceApi.active_status === 'inactive' && (
                <>
                  {/* 「啟用」按鈕 */}
                  <IonButton
                    fill="clear"
                    color="success"
                    onClick={onActivateServiceApi}
                  >
                    <IonIcon slot="start" icon={checkmark}></IonIcon>
                    <IonLabel>{t('Generate')}</IonLabel>
                  </IonButton>
                </>
              )}
            </IonButtons>
          </IonListHeader>

          {/* 快速開單網址 */}
          <IonItem
            disabled={serviceApi.active_status !== 'active'}
            lines="full"
          >
            <IonLabel slot="start" color="medium">
              {t('Ticket create url')}
            </IonLabel>
            <IonButtons slot="end">
              {serviceApi.active_status === 'active' && (
                <>
                  {/* 「顯示」按鈕 */}
                  <IonButton fill="clear" onClick={openCreateQRCodeModal}>
                    <IonIcon slot="start" icon={imagesOutline}></IonIcon>
                    <IonLabel>{t('Display')}</IonLabel>
                  </IonButton>
                </>
              )}
            </IonButtons>
          </IonItem>

          {/* 維修清單網址（尚未實作） */}
          {/* <IonItem
            disabled={serviceApi.active_status !== 'active'}
            lines="none"
          >
            <IonLabel slot="start">{t('Ticket list url')}</IonLabel>
            <IonButtons slot="end">
              {serviceApi.active_status === 'active' && (
                <>
                  <IonButton
                    fill="solid"
                    color="secondary"
                    onClick={openListQRCodeModal}
                  >
                    <IonIcon slot="start" icon={imagesOutline}></IonIcon>
                    <IonLabel>{t('Display')}</IonLabel>
                  </IonButton>
                </>
              )}
            </IonButtons>
          </IonItem> */}
        </IonList>
      )}

      {/* 複製專案區 */}
      {type === EPreviewFormType.template && (
        <IonList color="light">
          <IonListHeader className="advanced">
            <IonLabel>
              <h3>{t('Copy to new project')}</h3>
            </IonLabel>

            <IonButtons>
              {/* TODO: add handle onclick event */}
              <IonButton
                fill="clear"
                onClick={handleClickCopyProject}
                data-test="copy-template-btn"
              >
                <IonIcon slot="start" icon={copyOutline}></IonIcon>
                <IonLabel>{t('Copy')}</IonLabel>
              </IonButton>
            </IonButtons>
          </IonListHeader>
          <IonItem lines="full">
            <IonLabel color="medium" className="ion-text-wrap">
              {t('Description for copy settings of this project to new one', {
                projectName: template ? template.name : '',
              })}
            </IonLabel>
          </IonItem>
        </IonList>
      )}

      {/* Line 設定區 */}
      {showLineSettings && template && (
        <IonList color="light">
          <IonListHeader className="advanced">
            <IonLabel>
              <h3>{t('Line notifications')}</h3>
            </IonLabel>

            <IonButtons>
              {/* 「重新產生」按鈕 */}
              {!template.is_line_active ? (
                <IonButton
                  fill="clear"
                  color="success"
                  onClick={handleResetLineVerificationCode}
                >
                  <IonIcon slot="start" icon={refresh}></IonIcon>
                  <IonLabel>
                    {template.line_app_verification_code
                      ? t('Renew')
                      : t('Generate')}
                  </IonLabel>
                </IonButton>
              ) : (
                <IonButton
                  fill="clear"
                  color="danger"
                  onClick={handleResetLineVerificationCode}
                >
                  <IonIcon slot="start" icon={close}></IonIcon>
                  <IonLabel>{t('Remove line notification')}</IonLabel>
                </IonButton>
              )}
            </IonButtons>
          </IonListHeader>
          <IonItem lines="full">
            <IonLabel color="medium">
              {template.is_line_active
                ? t('Line notification is active')
                : t('Line verification code', {
                    verificationCode:
                      template.line_app_verification_code ?? t('None'),
                  })}
            </IonLabel>
          </IonItem>
        </IonList>
      )}

      {/* 節點提醒設定 */}
      {showReminderPreview && (
        <IonList color="light">
          <IonListHeader className="advanced">
            <IonLabel>
              <h3>{t('Reminder')}</h3>
            </IonLabel>
            <IonButtons>
              {/* 「編輯」按鈕 */}
              <IonButton fill="clear" onClick={openReminderModal}>
                <IonIcon slot="icon-only" icon={createOutline}></IonIcon>
              </IonButton>
            </IonButtons>
          </IonListHeader>
          {!item?.notification_bells?.length ? (
            // 當沒有設定提醒時，顯示提示文字
            <IonItem lines="none">
              <IonLabel color="medium">
                {t('Description for the reminder settings of this project')}
              </IonLabel>
            </IonItem>
          ) : (
            // 否則顯示提醒預覽
            <ReminderPreviewComponent
              notificationBells={item.notification_bells}
            />
          )}
        </IonList>
      )}

      {/* Modals */}
      {/* 專案權限設定 modal */}
      <IonModal isOpen={isShowPermissionModal}>
        {isShowPermissionModal && (
          <PermissionModal
            dismiss={dismissPermissionModal}
            template={template!}
          />
        )}
      </IonModal>

      {/* QRCode modal */}
      <IonModal isOpen={showQRCodeModal.isOpen} backdropDismiss={false}>
        {showQRCodeModal.isOpen && (
          <QRCodeModal
            isOpen={showQRCodeModal.isOpen}
            dismiss={dismissQRCodeModal}
            title={showQRCodeModal.title}
            url={showQRCodeModal.url}
          />
        )}
      </IonModal>

      {/* 節點提醒設定 modal */}
      <IonModal isOpen={isShowReminderModal} backdropDismiss={false}>
        {isShowReminderModal && (
          <ReminderEditModal
            dismiss={dismissReminderModal}
            itemKey={item.key!}
            notificationBells={item?.notification_bells ?? []}
            type={type}
          />
        )}
      </IonModal>
    </div>
  )
}

export default AdvancedSegment
