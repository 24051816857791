import moment from 'moment'
export const capitalize = ([firstLetter, ...rest]: any) => {
  return [firstLetter.toLocaleUpperCase(), ...rest].join('')
}

export const humanize = (dateStr: string) => {
  return moment(dateStr).fromNow()
}

export const n2br = (text: string) => {
  if (!text || !text.replace) return text
  return text.replace(new RegExp('\\n', 'g'), '<br />')
}
