import { combineEpics, Epic } from 'redux-observable'
import { of } from 'rxjs'
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  finalize,
  map,
  switchMap,
  takeUntil,
} from 'rxjs/operators'
import {
  ActionType,
  createAsyncAction,
  createReducer,
  getType,
  isActionOf,
} from 'typesafe-actions'
import { downloadCSV } from '../../helpers/file'
import { EAutocomplete, IAutoComplete } from '../../models/autoComplete'
import { DELAY_DEBOUNCE } from '../../models/constants'
import { ITicketSearch } from '../../models/search'
import { IList } from '../../models/template'
import { IUser } from '../../models/user'
import * as Providers from '../../providers'
import { RootState } from '../ducks'
import { RootAction } from './types'

const initialState = {
  key: undefined,
  tickets: [],
  isDone: false,
}

export const fetchTicketSearch = createAsyncAction(
  'FETCH_TICKET_SEARCH_REQUEST',
  'FETCH_TICKET_SEARCH_SUCCESS',
  'FETCH_TICKET_SEARCH_FAILURE',
  'FETCH_TICKET_SEARCH_CANCEL'
)<
  Partial<ITicketSearch & IList> &
    Pick<ITicketSearch, 'key' | 'cursor'> & {
      start_date: string
      end_date: string
    },
  Partial<ITicketSearch & IList>,
  Error,
  undefined
>()
export const fetchTicketSearchEpic: Epic<
  RootAction,
  RootAction,
  RootState,
  typeof Providers
> = (action$, state$, { ticketAPI }) =>
  action$.pipe(
    filter(isActionOf(fetchTicketSearch.request)),
    debounceTime(DELAY_DEBOUNCE),
    distinctUntilChanged(),
    switchMap((action) =>
      ticketAPI
        .search({
          token: state$.value.authDuck.token,
          params: {
            ...action.payload,
            cursor: action.payload.reload ? undefined : action.payload.cursor,
          },
        })
        .pipe(
          finalize(() => {
            const { event } = action.payload
            if (event) {
              if (event.target) {
                // @ts-ignore
                event.target.complete()
              } else if (event.detail) {
                // @ts-ignore
                event.detail.complete()
              }
            }
          })
        )
        .pipe(
          map((data) => fetchTicketSearch.success(data.response)),
          catchError((message: Error) =>
            of(fetchTicketSearch.failure(message))
          ),
          takeUntil(action$.pipe(filter(isActionOf(fetchTicketSearch.cancel))))
        )
    )
  )

export const fetchAutoComplete = createAsyncAction(
  'FETCH_AUTO_COMPLETE_REQUEST',
  'FETCH_AUTO_COMPLETE_SUCCESS',
  'FETCH_AUTO_COMPLETE_FAILURE',
  'FETCH_AUTO_COMPLETE_CANCEL'
)<
  Partial<IAutoComplete & IList & { selected?: IUser[] }> &
    Pick<IAutoComplete, 'cursor'>,
  IAutoComplete,
  Error,
  undefined
>()

export const fetchAutoCompleteEpic: Epic<
  RootAction,
  RootAction,
  RootState,
  typeof Providers
> = (action$, state$, { userAPI }) =>
  action$.pipe(
    filter(isActionOf(fetchAutoComplete.request)),
    debounceTime(600),
    distinctUntilChanged(),
    switchMap((action) =>
      userAPI
        .autoComplete({
          token: state$.value.authDuck.token,
          params: {
            ...action.payload,
            cursor: action.payload.reload ? undefined : action.payload.cursor,
            keyword: action.payload.all ? undefined : action.payload.keyword,
            page_size: action.payload.types?.includes(EAutocomplete.user)
              ? 30 // 當 autoComplete 類型為 user 時，page_size 設定較大以一次取得更多內容
              : undefined, // Notice: 後端在未提供 page_size 時，預設回傳 20 個項目
          },
        })
        .pipe(
          finalize(() => {
            const { event } = action.payload
            if (event) {
              if (event.target) {
                // @ts-ignore
                event.target.complete()
              } else if (event.detail) {
                // @ts-ignore
                event.detail.complete()
              }
            }
          })
        )
        .pipe(
          map((data) =>
            fetchAutoComplete.success({
              ...data.response,
              user: (data.response.user || []).map((user: IUser) => {
                const users = action.payload.selected as IUser[]
                const found = (users || []).find(
                  (_user: IUser) => _user.key === user.key
                )
                if (found) {
                  user.checked = true
                }
                return user
              }),
            })
          ),
          catchError((message: Error) =>
            of(fetchAutoComplete.failure(message))
          ),
          takeUntil(action$.pipe(filter(isActionOf(fetchAutoComplete.cancel))))
        )
    )
  )

export const getTicketSearchCsv = createAsyncAction(
  'GET_TICKET_SEARCH_CSV_REQUEST',
  'GET_TICKET_SEARCH_CSV_SUCCESS',
  'GET_TICKET_SEARCH_CSV_FAILURE',
  'GET_TICKET_SEARCH_CSV_CANCEL'
)<
  Partial<ITicketSearch & IList> &
    Pick<ITicketSearch, 'key' | 'cursor'> & {
      name: string
      start_date: string
      end_date: string
    },
  Partial<ITicketSearch & IList>,
  Error,
  undefined
>()

export const getTicketSearchCsvEpic: Epic<
  RootAction,
  RootAction,
  RootState,
  typeof Providers
> = (action$, state$, { ticketAPI }) =>
  action$.pipe(
    filter(isActionOf(getTicketSearchCsv.request)),
    switchMap((action) =>
      ticketAPI
        .getSearchCsv({
          token: state$.value.authDuck.token,
          params: {
            ...action.payload,
            cursor: action.payload.reload ? undefined : action.payload.cursor,
          },
        })
        .pipe(
          map((data) => {
            downloadCSV(action.payload.name, data.response)
            return getTicketSearchCsv.success({})
          }),
          catchError((message: Error) =>
            of(getTicketSearchCsv.failure(message))
          ),
          takeUntil(action$.pipe(filter(isActionOf(getTicketSearchCsv.cancel))))
        )
    )
  )

export const searchReducer = createReducer(initialState)
  .handleAction(
    getType(fetchTicketSearch.request),
    (state: any, { payload }: any) => {
      if (payload.keyword !== state.keyword) {
        state.tickets = []
      }
      return {
        ...state,
        tickets: payload.reload ? [] : state.tickets,
        cursor: payload.reload ? undefined : state.cursor,
        keyword: payload.all ? undefined : payload.keyword,
        kw_custom_fields: payload.all ? undefined : payload.kw_custom_fields,
        kw_itn_custom_fields: payload.all
          ? undefined
          : payload.kw_itn_custom_fields,
        start_date: payload.start_date,
        end_date: payload.end_date,
        isDone: false,
        isProcessing: true,
        isCancel: false,
      }
    }
  )
  .handleAction(
    getType(fetchTicketSearch.success),
    (state: any, { payload }: any) => ({
      ...state,
      ...payload,
      tickets: [].concat(state.tickets || [], payload.tickets || []),
      cursor: payload.cursor,
      error: null,
      isDone: true,
    })
  )
  .handleAction(
    getType(fetchTicketSearch.failure),
    (state: any, { payload }: any) => ({
      ...state,
      error: payload,
      isDone: true,
    })
  )
  .handleAction(
    getType(fetchTicketSearch.cancel),
    (state: any, { payload }: any) => ({
      ...initialState,
      isDone: false,
    })
  )
  .handleAction(
    getType(fetchAutoComplete.request),
    (state: any, { payload }: any) => {
      if (payload.keyword !== state.keyword) {
        state.user = []
      }
      return {
        ...state,
        user: payload.reload ? [] : state.user,
        cursor: payload.reload ? undefined : state.cursor,
        keyword: payload.all ? undefined : payload.keyword,
        isDone: false,
        isProcessing: true,
        isCancel: false,
      }
    }
  )
  .handleAction(
    getType(fetchAutoComplete.success),
    (state: any, { payload }: any) => {
      return {
        ...state,
        isInit: false,
        isDone: true,
        isProcessing: false,
        isCancel: false,
        cursor: payload.cursor,
        user:
          payload.cursor !== undefined && payload.cursor !== {}
            ? [].concat(state.user || [], payload.user)
            : payload.user,
      }
    }
  )
  .handleAction(
    getType(fetchAutoComplete.failure),
    (state: any, { payload }: any) => ({
      ...state,
      isDone: true,
      isProcessing: false,
      isCancel: false,
      error: payload,
    })
  )
  .handleAction(
    getType(fetchAutoComplete.cancel),
    (state: any, { payload }: any) => ({
      ...initialState,
      isProcessing: false,
      isDone: false,
      isCancel: true,
    })
  )
  .handleAction(
    getType(getTicketSearchCsv.failure),
    (state: any, { payload }: any) => ({
      ...state,
      error: payload,
    })
  )

export type SearchAction =
  | ActionType<typeof fetchTicketSearch>
  | ActionType<typeof fetchAutoComplete>
  | ActionType<typeof getTicketSearchCsv>

const searchEpic = combineEpics<any>(
  fetchTicketSearchEpic,
  fetchAutoCompleteEpic,
  getTicketSearchCsvEpic
)
export default searchEpic
