import { useCallback, useMemo, useState } from 'react'

export default function useModal(): [boolean, () => void, () => void] {
  const [isShow, setIsShow] = useState(false)
  const show = useCallback(() => setIsShow(true), [])
  const dismiss = useCallback(() => setIsShow(false), [])
  return [isShow, show, dismiss]
}

export function useModalWithData<T>(): [
  boolean,
  T | undefined,
  (modalData: T) => void,
  () => void
] {
  const [modalData, setModalData] = useState<T | undefined>(undefined)
  const show = useCallback((data: T) => setModalData(data), [])
  const dismiss = useCallback(() => setModalData(undefined), [])
  const isShow = useMemo(() => modalData !== undefined, [modalData])
  return [isShow, modalData, show, dismiss]
}