import React, { useEffect, useState } from 'react'
import { IonLabel, IonSegment, IonSegmentButton, IonToast } from '@ionic/react'
import { default as classNames } from 'classnames'
import 'react-datepicker/dist/react-datepicker.css'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { inFunctionList } from '../../helpers/base'
import { EPreviewFormType } from '../../models/form'
import { ITemplate } from '../../models/template'
import { ETicketRequiredFields, ITicket } from '../../models/ticket'
import FormSegment from './FormSegment'
import FilesSegment from './FilesSegment'
import './PreviewForm.scss'
import useToast from '../../hooks/useToast'
import { clearToastMessage } from '../../store/epics/ticket'
import { TeamMetaState } from '../../store/ducks'
import { fetchTeamMeta } from '../../store/epics/teamMeta'
import { fetchPublicTeamMeta } from '../../store/public_ticket/actions'

// 子頁面的種類，「表單」、「照片檔案」
type SegmentType = 'form' | 'files' | 'advanced'

interface PreviewFormProps {
  type: EPreviewFormType
  item?: ITemplate | ITicket
  onSubmit?: Function
  onChange?: Function
  isFillMode?: boolean
  isUIAllowSelectOptions?: boolean
  isShowSignatures?: boolean

  /**
   * 是否顯示客戶上傳照片欄位
   * Notes: 此欄位只有在開單時提供客戶上傳照片使用，儲存後會將上傳的內容轉至留言區
   */
  isShowCustomerPhotoFields?: boolean
}

/**
 *  Ticket 的內容，有三個子頁面，分別是「表單」與「照片檔案」（只有 Ticket 有「照片檔案」的子頁面）
 *  理論上此元件已不會用來顯示 template 相關內容，改以 TemplatePage 與 TemplateForm 元件取代
 *
 *  資料流：
 *  所有的資料都是透過 onChange callback 向上傳遞，不會用到 onSubmit
 */
const PreviewForm = ({
  type,
  item,
  onSubmit,
  onChange,
  isFillMode,
  isUIAllowSelectOptions,
  isShowSignatures = true,
  isShowCustomerPhotoFields = false,
}: PreviewFormProps) => {
  /* States */
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [segmentType, setSegmentType] = useState<SegmentType>('form')
  const ticket = useSelector((state: any) => state.ticket)
  item = type !== EPreviewFormType.template ? ticket : item

  const teamMeta = useSelector((state: TeamMetaState) => state.teamMeta)

  /* Hooks */
  const { showToast, toastProps } = useToast()

  // 即時更新 item 的 function_list
  useEffect(() => {
    if (!ticket.updated && !ticket.statusUpdated) return
    if (!item) return
    item.function_list = ticket.function_list
  }, [ticket.statusUpdated, ticket.updated, ticket.function_list, item, type])

  // 當 toastMessage 有值時觸發，顯示相關的文字 toast
  // Notice: toastMessage 的值會在對應後端 API 完成時從 redux 設置
  useEffect(() => {
    if (!ticket || !ticket.toastMessage) return

    // 顯示 toast
    showToast(t(ticket.toastMessage))

    // 清除 toastMessage
    dispatch(clearToastMessage())
  }, [dispatch, showToast, t, ticket])

  /**
   * 如果 teamMeta 的 meta 沒有資訊時，則向後端發送 request ，取得 teamMeta 資料
   */
  useEffect(() => {
    
    if (Array.isArray(teamMeta?.meta?.ticket_required_fields)) return
    if (!item?.key) return
    if (type === EPreviewFormType.template) return

    if (type === EPreviewFormType.ticket) {
      dispatch(
        fetchTeamMeta.request()
      )
      return
    }

    // 預期執行到這裡, type 是 EPreviewFormType.publicTicket
    dispatch(
      fetchPublicTeamMeta.request({
        template_key: item?.key,
      })
    )

  }, [teamMeta?.meta, type, dispatch, item])

  return (
    <div
      className={classNames({
        'page-preview-form': true,
      })}
    >
      {item && (
        <>
          {/* 子頁面選單（「表單」/「照片檔案」/「進階設定」）*/}
          <IonSegment
            hidden={type === EPreviewFormType.publicTicket}
            value={segmentType}
            color="secondary"
            onIonChange={(e) => setSegmentType(e.detail.value as SegmentType)}
          >
            {/* 「表單」 按鈕 */}
            <IonSegmentButton value="form">
              <IonLabel>{t('Form')}</IonLabel>
            </IonSegmentButton>

            {/* 「照片檔案」 按鈕 */}
            {type === EPreviewFormType.ticket &&
              (inFunctionList('ticket_update', item.function_list) ||
                inFunctionList('ticket_read', item.function_list)) && (
                <IonSegmentButton value="files" data-test="file-segment-btn">
                  <IonLabel>{t('Ticket Files')}</IonLabel>
                </IonSegmentButton>
              )}
          </IonSegment>

          {/* 「表單」頁面 */}
          <div className="segment-box" hidden={segmentType !== 'form'}>
            <FormSegment
              type={type}
              item={item}
              // onSubmit 沒有用
              onSubmit={onSubmit}
              // 使用 onChange callback 將表單的資料向上傳遞
              onChange={onChange}
              isFillMode={isFillMode}
              isUIAllowSelectOptions={isUIAllowSelectOptions}
              // 是否顯示其他欄位
              isShowSignatures={isShowSignatures}
              isShowCustomerPhotoFields={isShowCustomerPhotoFields}
              customerPhoneIsRequired={teamMeta?.meta?.ticket_required_fields?.includes(ETicketRequiredFields.customer_phone)}
            />
          </div>

          {/* 「照片檔案」頁面 */}
          {type === EPreviewFormType.ticket && (
            <div className="segment-box" hidden={segmentType !== 'files'}>
              <FilesSegment item={item} />
            </div>
          )}

          {/* Toast */}
          <IonToast {...toastProps} />
        </>
      )}
    </div>
  )
}

/**
 *  意義不明，永遠回傳 true
 */
const propsAreEqual = (prevProps: any, nextProps: any) => {
  const prevValue = JSON.stringify(prevProps.form?.getState())
  const nextValue = JSON.stringify(nextProps.form?.getState())
  return prevValue === nextValue
}

export default React.memo(PreviewForm, propsAreEqual)
