import { key } from '../models/base'

export const API = (url: string, name?: string) => {
  let endpoint
  switch (name) {
    case 'AUTH':
      endpoint = `${process.env.REACT_APP_AUTH_ENDPOINT}`
      break
    case 'SUPPORT':
      endpoint = `${process.env.REACT_APP_API_ENDPOINT}`
      break
    default:
      endpoint = `${process.env.REACT_APP_API_ENDPOINT}`
      break
  }
  return `${endpoint}/${url}/`
}

export const PUBLIC_TOKEN = (token: key) => {
  return {
    Authorization: `SUIQUI ${token}`,
    'Content-Type': 'application/json',
  }
}

export const PUBLIC_JSON = () => {
  return {
    'Content-Type': 'application/json',
  }
}

export const PUBLIC_UPLOAD = () => {
  return {
    'Content-Type': 'application/json',
  }
}

export const PUBLIC_DOWNLOAD = () => {
  return {}
}

export const JWT = (token: key) => {
  return {
    Authorization: `JWT ${token}`,
    'Content-Type': 'application/json',
  }
}

export const JWT_UPLOAD = (token: key) => {
  return {
    Authorization: `JWT ${token}`,
  }
}

export const JWT_DOWNLOAD = (token: key) => {
  return {
    Authorization: `JWT ${token}`,
  }
}

export const inFunctionList = (name: string, functionList: string[] = []) => {
  return functionList.indexOf(name) > -1
}
