import {
  IonAvatar,
  IonButton,
  IonButtons,
  IonCheckbox,
  IonChip,
  IonContent,
  IonHeader,
  IonIcon,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonSpinner,
  IonTitle,
  IonToolbar,
} from '@ionic/react'
import { caretUp, checkmarkDone, close } from 'ionicons/icons'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { getAvatarUrl } from '../helpers/file'
import { makeUnique } from '../helpers/util'
import { useRemoveIonPageInvisible } from '../hooks/useRemoveIonPageInvisible'
import { EAutocomplete } from '../models/autoComplete'
import { IUser } from '../models/user'
import { fetchAutoComplete } from '../store/epics/search'
import './MemberListModal.scss'

interface MemberListModalProps {
  isOpen?: boolean
  isActiveOnly: boolean
  isMultiMode: boolean
  dismiss: any
  selected?: IUser[]
  filterOut?: IUser[] // optional: 不顯示部分成員
}

const MemberListModal = ({
  isActiveOnly,
  isMultiMode,
  dismiss,
  selected,
  filterOut,
}: MemberListModalProps) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const contentRef = useRef<HTMLIonContentElement | null>(null)
  const pageRef = useRemoveIonPageInvisible()
  const search = useSelector((state: any) => state.search)

  const [isReadyToSave, setIsReadyToSave] = useState(false)
  const [isPageLoading, setIsPageLoading] = useState(false)
  const [isProcessing, setIsProcessing] = useState(false)
  const [currentUsers, setCurrentUsers] = useState([] as IUser[])

  useEffect(() => {
    setIsPageLoading(true)
    dispatch(
      fetchAutoComplete.request({
        reload: true,
        all: true,
        types: [EAutocomplete.user],
        is_active: isActiveOnly,
        selected,
      })
    )
  }, [dispatch, setIsPageLoading, isActiveOnly, selected])

  useEffect(() => {
    if (search.isDone) {
      setIsPageLoading(false)
      setIsProcessing(false)
    }
  }, [setIsPageLoading, setIsProcessing, search.isDone])

  const loadData = (event: any, cursor: any) => {
    setIsProcessing(true)
    dispatch(
      fetchAutoComplete.request({
        all: true,
        types: [EAutocomplete.user],
        is_active: isActiveOnly,
        cursor,
        event,
        selected,
      })
    )
  }

  const handleClickUser = (user: IUser) => {
    if (isMultiMode) {
      setIsReadyToSave(true)

      user.checked = !user.checked

      setCurrentUsers(
        makeUnique(
          search.user.filter((u: IUser) => !!u.checked),
          'email'
        )
      )
    } else {
      if (!user) dispatch(fetchAutoComplete.cancel())
      dismiss({ user })
    }
  }

  const scrollToTop = () => {
    contentRef.current?.scrollToTop()
  }

  const cancel = () => {
    dispatch(fetchAutoComplete.cancel())
    dismiss()
  }

  // 不顯示 filterOut array 當中的 users
  const users = useMemo(() => {
    if (!search.user) return []

    return search.user.filter(
      (user: IUser) => !filterOut?.some((f) => f.key === user.key)
    ) as IUser[]
  }, [filterOut, search.user])

  return (
    <IonPage className="page-member-list-modal" ref={pageRef}>
      <IonHeader>
        <IonToolbar color="primary">
          <IonTitle>
            <IonButton class="logo immutable">{t('Suiqui Support')}</IonButton>
            <span className="subtitle">{t('Member Select')}</span>
          </IonTitle>
          <IonButtons slot="end">
            <IonButton
              strong
              fill="clear"
              slot="icon-only"
              onClick={() => cancel()}
            >
              <IonIcon size="large" icon={close}></IonIcon>
            </IonButton>
            <IonButton
              hidden={!isMultiMode}
              strong
              fill="clear"
              slot="icon-only"
              disabled={!isReadyToSave}
              onClick={() =>
                dismiss({
                  users: currentUsers,
                })
              }
            >
              <IonIcon size="large" icon={checkmarkDone}></IonIcon>
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent ref={contentRef} scrollEvents={true}>
        {!isPageLoading && !search.isProcessing && !search?.user?.length && (
          <IonItem className="ion-text-center">
            <IonLabel color="medium">{t('EMPTY')}</IonLabel>
          </IonItem>
        )}
        {isPageLoading ? (
          <div className="ion-text-center centered">
            <IonSpinner color="primary" name="crescent"></IonSpinner>
          </div>
        ) : (
          <IonList>
            {users.map((user, index) => (
              <IonItem
                button={!isMultiMode}
                key={index}
                onClick={() => handleClickUser(user)}
              >
                <IonCheckbox checked={user.checked}></IonCheckbox>
                <IonChip color="primary" outline>
                  <IonAvatar>
                    <img alt={user.displayname} src={getAvatarUrl(user)} />
                  </IonAvatar>
                  <IonLabel>{user.displayname}</IonLabel>
                </IonChip>
              </IonItem>
            ))}
            <div
              className="ion-text-center"
              hidden={isProcessing || !!search?.cursor?.user}
            >
              <IonButton
                color="danger"
                fill="clear"
                size="large"
                onClick={scrollToTop}
              >
                <IonIcon slot="icon-only" icon={caretUp} />
              </IonButton>
            </div>
          </IonList>
        )}
        <IonInfiniteScroll
          threshold="50%"
          onIonInfinite={(e) => loadData(e, search.cursor)}
          disabled={!search?.cursor?.user}
        >
          <IonInfiniteScrollContent></IonInfiniteScrollContent>
        </IonInfiniteScroll>
      </IonContent>
    </IonPage>
  )
}

export default MemberListModal
