import { IonItem, IonLabel, IonChip } from '@ionic/react'
import { useTranslation } from 'react-i18next'
import { inFunctionList } from '../helpers/base'
import { ETemplateFunction, ITemplate } from '../models/template'
import './TemplateListCard.scss'

interface TemplateListCardProps {
  template: ITemplate
}

export function TemplateListCard({ template }: TemplateListCardProps) {
  const { t } = useTranslation()

  const isDisabled = !inFunctionList(
    ETemplateFunction.permission_read,
    template.function_list
  )

  return (
    <IonItem
      className="component-template-list-card"
      button
      detail
      disabled={isDisabled}
      key={template.key}
      routerLink={`/tracking/${template.key}`}
    >
      <IonLabel>
        <h2>{template.name}</h2>
        <p hidden={isDisabled} className="ion-text-wrap dashboard-info">
          <IonChip hidden={!template.stats?.total_tickets} color="medium">
            <IonLabel color="medium">
              {t('Tickets Total')} {template.stats?.total_tickets}
            </IonLabel>
          </IonChip>
          <IonChip
            hidden={!template.stats?.total_tickets_unfinished}
            color="secondary"
          >
            <IonLabel>
              {t('Tickets Unfinished')}{' '}
              {template.stats?.total_tickets_unfinished}
            </IonLabel>
          </IonChip>
          <IonChip hidden={!template.stats?.total_tickets_done} color="success">
            <IonLabel>
              {t('Tickets Done')} {template.stats?.total_tickets_done}
            </IonLabel>
          </IonChip>
          <IonChip
            hidden={!template.stats?.total_tickets_last_month}
            color="tertiary"
          >
            <IonLabel>
              {t('Tickets Last Month')}{' '}
              {template.stats?.total_tickets_last_month}
            </IonLabel>
          </IonChip>
          <IonChip
            hidden={!template.stats?.total_tickets_new_month}
            color="warning"
          >
            <IonLabel>
              {t('Tickets New Month')} {template.stats?.total_tickets_new_month}
            </IonLabel>
          </IonChip>
          <IonChip
            hidden={!template.stats?.total_tickets_overdue}
            color="danger"
          >
            <IonLabel>
              {t('Tickets Overdue')} {template.stats?.total_tickets_overdue}
            </IonLabel>
          </IonChip>
        </p>
      </IonLabel>
    </IonItem>
  )
}
