import {
  IonAlert,
  IonButton,
  IonButtons,
  IonIcon,
  IonLabel,
  IonModal,
} from '@ionic/react'
import {
  add,
  caretDownCircle,
  caretUpCircle,
  reorderFour,
} from 'ionicons/icons'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { getSchemaFieldsFromTemplate } from '../../../helpers/schema'
import useAlert from '../../../hooks/useAlert'
import useModal from '../../../hooks/useModal'
import ReorderModal from '../../../modals/ReorderModal'
import { SubSchemaType } from '../../../models/schema'
import { RootState } from '../../../store/ducks'
import { updateTemplate } from '../../../store/templateSlice'
import EditFieldModal, { Field } from '../EditFieldModal'
import EditModeSchemaField from './EditModeSchemaField'
import './EditModeSchemaField.scss'

interface EditModeSchemaSectionProps {
  schemaType: SubSchemaType
  fields: Field[]
}

// 在編輯模式下，使用客製化元件顯示客戶選單與內部選單
const EditModeSchemaSection = ({
  fields,
  schemaType,
}: EditModeSchemaSectionProps) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const template = useSelector((state: RootState) => state.newTemplate.template)
  const [isHide, setIsHide] = useState(false)
  const [editingField, setEditingField] = useState<Field | undefined>()
  const [isShowAddFieldModal, showAddFieldModal, dismissAddFieldModal] =
    useModal()
  const [isShowReorderModal, showReorderModal, dismissReorderModal] = useModal()
  const { showAlert, alertProps } = useAlert()

  const onDeleteFieldClick = useCallback(
    (deletingField: Field) => {
      if (!template) return

      // 顯示 alert，需確認才能完成刪除
      showAlert({
        message: t('Click OK to remove this item.'),
        onConfirm: () => {
          // 儲存在資料庫中的客戶選單與內部選單為 json 字串，需先轉換為物件
          const fields: Field[] = getSchemaFieldsFromTemplate(
            schemaType,
            template
          )

          // 向後端發送更新 template 的 request
          dispatch(
            updateTemplate({
              key: template.key,
              [`${schemaType}_schema`]: JSON.stringify({
                fields: fields.filter(
                  (field) => field.name !== deletingField.name
                ),
              }),
            })
          )
        },
      })
    },
    [dispatch, schemaType, showAlert, t, template]
  )

  // 交換兩個欄位的順序
  const onReorder = useCallback(
    (fields: Field[]) => {
      if (!template) return
      // 向後端發送更新 template 的 request
      dispatch(
        updateTemplate({
          key: template.key,
          [`${schemaType}_schema`]: JSON.stringify({
            fields,
          }),
        })
      )
    },
    [dispatch, schemaType, template]
  )

  return (
    <section className="schema">
      <div className="schema-title">
        {/* 選單名稱（「內部選單」或「客戶選單」） */}
        <h5>
          {schemaType === 'custom_fields'
            ? t('Custom fields')
            : t('Internal custom fields')}
        </h5>

        <IonButtons>
          {/* 重新排序按鈕 */}
          {!isHide && (
            <IonButton fill="clear" slot="end" onClick={showReorderModal}>
              <IonIcon slot="start" icon={reorderFour} />
              <IonLabel>{t('Reorder')}</IonLabel>
            </IonButton>
          )}

          {/* 隱藏選單按鈕 */}
          <IonButton
            fill="clear"
            slot="end"
            onClick={() => setIsHide((isHide) => !isHide)}
          >
            <IonIcon
              slot="start"
              icon={isHide ? caretUpCircle : caretDownCircle}
            />
            <IonLabel>{isHide ? t('Expand') : t('Hide')}</IonLabel>
          </IonButton>
        </IonButtons>
      </div>

      {!isHide && (
        <>
          {/* 欄位列表 */}
          {fields.map((field) => (
            <EditModeSchemaField
              field={field}
              onEditClick={() => setEditingField(field)}
              onDeleteClick={() => onDeleteFieldClick(field)}
            />
          ))}

          {/* 新增欄位按鈕 */}
          <div className="add-schema-field-btn">
            <IonButton fill="clear" onClick={showAddFieldModal}>
              <IonIcon slot="start" icon={add}></IonIcon>
              <IonLabel>{t('Add field')}</IonLabel>
            </IonButton>
          </div>
        </>
      )}

      {/* 編輯欄位 Modal */}
      <IonModal isOpen={editingField !== undefined} backdropDismiss={false}>
        {editingField !== undefined && (
          <EditFieldModal
            schemaType={schemaType}
            editingField={editingField}
            dismiss={() => setEditingField(undefined)}
          />
        )}
      </IonModal>

      {/* 新增欄位 Modal */}
      <IonModal isOpen={isShowAddFieldModal} backdropDismiss={false}>
        {isShowAddFieldModal && (
          <EditFieldModal
            schemaType={schemaType}
            dismiss={dismissAddFieldModal}
          />
        )}
      </IonModal>

      {/* 新增欄位 Modal */}
      <IonModal isOpen={isShowReorderModal} backdropDismiss={false}>
        {isShowReorderModal && (
          <ReorderModal
            isOpen={isShowReorderModal}
            initialItems={fields}
            getName={(field) => field.label}
            onDone={onReorder}
            dismiss={dismissReorderModal}
          />
        )}
      </IonModal>

      {/* Alert */}
      <IonAlert {...alertProps} />
    </section>
  )
}

export default EditModeSchemaSection
