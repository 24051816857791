import { closeCircleOutline, chatbubbleEllipsesOutline } from 'ionicons/icons'
import { IonFab, IonFabButton, IonIcon, isPlatform } from '@ionic/react'
import { useSelector } from 'react-redux'
import classNames from 'classnames'
import React from 'react'
import './ToggleCommentFAB.scss'

interface ToggleCommentFABProps
  extends Omit<React.ComponentProps<typeof IonFab>, 'className'> {
  isShowCommentList: boolean
  toggleCommentList: () => void
}

// 工單/公開工單用作 toggle 留言畫面的按鈕
const ToggleCommentFAB = ({
  isShowCommentList,
  toggleCommentList,
  color,
  ...ionFabProps
}: ToggleCommentFABProps) => {
  const comment = useSelector((state: any) => state.comment)
  const isAndroid = isPlatform("android")

  return (
    <IonFab {...ionFabProps} className="component-toggle-comment-fab">
      <IonFabButton color={color} onClick={() => toggleCommentList()}>
        <IonIcon
          icon={
            isShowCommentList ? closeCircleOutline : chatbubbleEllipsesOutline
          }
        />

        {/* 工單有留言時在 FAB 按鈕的右上角顯示小紅點 */}
        {!isShowCommentList && !!comment.comments?.length && (
          <span
            className={classNames({
              'has-comment-dot': true,
              // Notice: 目前不明原因，Android 版本的小紅點起始位置與其他裝置不一致，因此特別為這個條件設定了特殊位置
              'is-android': isAndroid,
              green: color === 'danger', // 按鈕被設定為 danger 色系時，小紅點顯示為綠色
            })}
          >
            •
          </span>
        )}
      </IonFabButton>
    </IonFab>
  )
}

export default ToggleCommentFAB
