import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonPage,
  IonTitle,
  IonToolbar,
  isPlatform,
} from '@ionic/react'
import { useTranslation } from 'react-i18next'
import { close } from 'ionicons/icons'
import { TextField } from '@mui/material'
import { useForm } from 'react-hook-form'
import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { resetPassword } from '../../store/epics/auth'
import './ForgetPasswordModal.scss'

interface FormData {
  email: string
}

interface ForgetPasswordModalProps {
  isOpen: boolean
  dismiss: () => void
}

const ForgetPasswordModal = ({ isOpen, dismiss }: ForgetPasswordModalProps) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { handleSubmit, watch, register } = useForm<FormData>({
    defaultValues: { email: '' },
  })
  const isValid = watch('email') && watch('email').length > 0

  // 傳送密碼重設信
  const onConfirm = useCallback(() => {
    handleSubmit(({ email }) => {
      dispatch(resetPassword.request({ username: email }))
    })()
  }, [handleSubmit, dispatch])

  return (
    <IonPage className="page-forget-password-modal">
      <IonHeader>
        <IonToolbar color="primary">
          {/* 標題 */}
          <IonTitle>
            <IonButton className="logo immutable">
              {t('Suiqui Support')}
            </IonButton>
            <span className="subtitle" hidden={isPlatform('mobile')}>
              <span>{t('Forget password')}</span>
            </span>
          </IonTitle>

          {/* 關閉按鈕 */}
          <IonButtons slot="end">
            <IonButton strong fill="clear" slot="icon-only" onClick={dismiss}>
              <IonIcon size="large" icon={close}></IonIcon>
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className="content">
          {/* 電子郵件輸入欄 */}
          <div className="email-field">
            <TextField label={t('Email')} {...register('email')} fullWidth />
          </div>

          {/* 「傳送密碼重設信」按鈕 */}
          <IonButton onClick={onConfirm} color="secondary" disabled={!isValid}>
            {t('Send password reset email')}
          </IonButton>
        </div>
      </IonContent>
    </IonPage>
  )
}

export default ForgetPasswordModal
