import {
  ITemplateDashboard,
  ITemplatePermission,
} from './../../models/template'
import { combineEpics, Epic } from 'redux-observable'
import { of } from 'rxjs'
import {
  catchError,
  filter,
  finalize,
  map,
  switchMap,
  takeUntil,
} from 'rxjs/operators'
import {
  ActionType,
  createAsyncAction,
  createAction,
  createReducer,
  getType,
  isActionOf,
} from 'typesafe-actions'
import { EActiveStatus, IEvent, IList, ITemplate } from '../../models/template'
import * as Providers from '../../providers'
import { RootState } from '../ducks'
import { TemplateState } from '../templateSlice'
import { RootAction } from './types'
import { key } from '../../models/base'

const initialState: TemplateState = {
  key: undefined,
  templates: [],
  isDone: false,
  isReadyToSave: false,
  archived: null,
  unarchived: null,
  updated: null,
  error: false,
  isRenewAPISuccess: null,
  isResetLineVerificationCodeSuccess: null,
  copied: null,
  copyError: null,
  created: null,
  cursor: null,

  name: '',
  serv_ppl_name: null,
  serv_ppl_phone: null,
  serv_ppl_2_name: null,
  serv_ppl_2_phone: null,
  active_status: EActiveStatus.active,
  create_datetime: null,
  modified_datetime: null,
  custom_fields_schema: null,
  custom_fields_data: null,
  itn_custom_fields_schema: null,
  itn_custom_fields_data: null,
  send_sms_to_user: true,
  api: {
    active_status: 'active',
    'ticket-create-url': '',
    'ticket-list-url': '',
    token: '',
  },
  dashboard: null, // 單個專案頁面中統計元件的資料
  permission: null,
  toastMessage: null,
  is_line_active: false,
  line_app_verification_code: null,
  function_list: [],
  signatures: [],
  notification_bells: [],
}

export const fetchTemplateList = createAsyncAction(
  'FETCH_TEMPLATE_LIST_REQUEST',
  'FETCH_TEMPLATE_LIST_SUCCESS',
  'FETCH_TEMPLATE_LIST_FAILURE',
  'FETCH_TEMPLATE_LIST_CANCEL'
)<
  Omit<Partial<ITemplate & IList>, 'active_status'> &
    Pick<IList, 'cursor'> & {
      active_status?: EActiveStatus[] // 'active' or 'archive'
      include_stats?: boolean // 後端回傳的 template 包含統計數據
    },
  Partial<ITemplate & IList>,
  Error,
  undefined
>()

export const fetchTemplateListEpic: Epic<
  RootAction,
  RootAction,
  RootState,
  typeof Providers
> = (action$, state$, { templateAPI }) =>
  action$.pipe(
    filter(isActionOf(fetchTemplateList.request)),
    switchMap((action) =>
      templateAPI
        .list({
          token: state$.value.authDuck.token,
          params: action.payload,
        })
        .pipe(
          finalize(() => {
            const { event } = action.payload
            if (event) {
              if (event.target) {
                // @ts-ignore
                event.target.complete()
              } else if (event.detail) {
                // @ts-ignore
                event.detail.complete()
              }
            }
          })
        )
        .pipe(
          map((data) => fetchTemplateList.success(data.response)),
          catchError((message: Error) =>
            of(fetchTemplateList.failure(message))
          ),
          takeUntil(action$.pipe(filter(isActionOf(fetchTemplateList.cancel))))
        )
    )
  )

export const createTemplate = createAsyncAction(
  'CREATE_TEMPLATE_REQUEST',
  'CREATE_TEMPLATE_SUCCESS',
  'CREATE_TEMPLATE_FAILURE',
  'CREATE_TEMPLATE_CANCEL'
)<Partial<ITemplate>, ITemplate, Error, undefined>()

export const createTemplateEpic: Epic<
  RootAction,
  RootAction,
  RootState,
  typeof Providers
> = (action$, state$, { templateAPI }) =>
  action$.pipe(
    filter(isActionOf(createTemplate.request)),
    switchMap((action) =>
      templateAPI
        .create({ token: state$.value.authDuck.token, params: action.payload })
        .pipe(
          map((data) => createTemplate.success(data.response)),
          catchError((message: Error) => of(createTemplate.failure(message))),
          takeUntil(action$.pipe(filter(isActionOf(createTemplate.cancel))))
        )
    )
  )

export const fetchTemplatePermissionList = createAsyncAction(
  'FETCH_TEMPLATE_PERMISSION_LIST_REQUEST',
  'FETCH_TEMPLATE_PERMISSION_LIST_SUCCESS',
  'FETCH_TEMPLATE_PERMISSION_LIST_FAILURE',
  'FETCH_TEMPLATE_PERMISSION_LIST_CANCEL'
)<
  Pick<ITemplate, 'key'>,
  Pick<ITemplatePermission, 'permission'>,
  Error,
  undefined
>()

export const fetchTemplatePermissionListEpic: Epic<
  RootAction,
  RootAction,
  RootState,
  typeof Providers
> = (action$, state$, { permissionAPI }) =>
  action$.pipe(
    filter(isActionOf(fetchTemplatePermissionList.request)),
    switchMap((action) =>
      permissionAPI
        .list({
          token: state$.value.authDuck.token,
          params: action.payload,
        })
        .pipe(
          map((data) => fetchTemplatePermissionList.success(data.response)),
          catchError((message: Error) =>
            of(fetchTemplatePermissionList.failure(message))
          ),
          takeUntil(
            action$.pipe(filter(isActionOf(fetchTemplatePermissionList.cancel)))
          )
        )
    )
  )

// 清除 store 當中的 toastMessage 狀態
export const clearToastMessage = createAction('CLEAR_TOAST_MESSAGE')()

export const updateTemplatePermission = createAsyncAction(
  'UPDATE_TEMPLATE_PERMISSION_REQUEST',
  'UPDATE_TEMPLATE_PERMISSION_SUCCESS',
  'UPDATE_TEMPLATE_PERMISSION_FAILURE',
  'UPDATE_TEMPLATE_PERMISSION_CANCEL'
)<
  Pick<ITemplate, 'key'> &
    Partial<{
      protected: boolean
      add_readers: key[]
      add_writers: key[]
      remove_readers: key[]
      remove_writers: key[]
      toastMsg: string
    }>,
  Pick<ITemplatePermission, 'permission'> & { toastMsg: string },
  Error,
  undefined
>()

export const updateTemplatePermissionEpic: Epic<
  RootAction,
  RootAction,
  RootState,
  typeof Providers
> = (action$, state$, { permissionAPI }) =>
  action$.pipe(
    filter(isActionOf(updateTemplatePermission.request)),
    switchMap((action) => {
      const { toastMsg, ...params } = action.payload
      // destructure to only pass necessary params to permissionAPI.update
      return permissionAPI
        .update({
          token: state$.value.authDuck.token,
          params,
        })
        .pipe(
          map((data) =>
            updateTemplatePermission.success({ toastMsg, ...data.response })
          ),
          catchError((message: Error) =>
            of(updateTemplatePermission.failure(message))
          ),
          takeUntil(
            action$.pipe(filter(isActionOf(updateTemplatePermission.cancel)))
          )
        )
    })
  )

export const fetchTemplateDashboard = createAsyncAction(
  'FETCH_TEMPLATE_DASHBOARD_REQUEST',
  'FETCH_TEMPLATE_DASHBOARD_SUCCESS',
  'FETCH_TEMPLATE_DASHBOARD_FAILURE',
  'FETCH_TEMPLATE_DASHBOARD_CANCEL'
)<
  Partial<ITemplateDashboard & IEvent> & Pick<ITemplateDashboard, 'key'>,
  ITemplateDashboard,
  Error,
  undefined
>()

export const fetchTemplateDashboardEpic: Epic<
  RootAction,
  RootAction,
  RootState,
  typeof Providers
> = (action$, state$, { templateAPI }) =>
  action$.pipe(
    filter(isActionOf(fetchTemplateDashboard.request)),
    switchMap((action) =>
      templateAPI
        .getDashboard({
          token: state$.value.authDuck.token,
          params: action.payload,
        })
        .pipe(
          finalize(() => {
            const { event } = action.payload
            if (event) {
              if (event.target) {
                // @ts-ignore
                event.target.complete()
              } else if (event.detail) {
                // @ts-ignore
                event.detail.complete()
              }
            }
          })
        )
        .pipe(
          map((data) => fetchTemplateDashboard.success(data.response)),
          catchError((message: Error) =>
            of(fetchTemplateDashboard.failure(message))
          ),
          takeUntil(
            action$.pipe(filter(isActionOf(fetchTemplateDashboard.cancel)))
          )
        )
    )
  )

export const templateReducer = createReducer(initialState)
  .handleAction(
    getType(fetchTemplateList.request),
    (state: any, { payload }: any) => ({
      ...initialState,
      templates: state.templates,
      cursor: payload.cursor,
      name: state.name,
      dashboard: state.dashboard,
    })
  )
  .handleAction(
    getType(fetchTemplateList.success),
    (state: any, { payload }: any) => ({
      ...state,
      ...payload,
      templates: [].concat(state.templates || [], payload.templates || []),
      error: null,
      isDone: true,
      dashboard: state.dashboard,
    })
  )
  .handleAction(
    getType(fetchTemplateList.failure),
    (state: any, { payload }: any) => ({
      ...state,
      error: payload,
      isDone: true,
    })
  )
  .handleAction(
    getType(fetchTemplateList.cancel),
    (state: any, { payload }: any) => ({
      ...state,
      isDone: false,
      key: undefined,
      templates: undefined,
    })
  )
  .handleAction(
    getType(createTemplate.request),
    (state: any, { payload }: any) => ({
      ...state,
      ...payload,
      created: undefined,
    })
  )
  .handleAction(
    getType(createTemplate.success),
    (state: any, { payload }: any) => ({
      ...state,
      ...payload,
      error: null,
      created: true,
    })
  )
  .handleAction(
    getType(createTemplate.failure),
    (state: any, { payload }: any) => ({
      ...state,
      error: payload,
      created: false,
    })
  )
  .handleAction(
    getType(createTemplate.cancel),
    (state: any, { payload }: any) => ({
      ...state,
      error: null,
      created: undefined,
    })
  )
  .handleAction(
    getType(fetchTemplatePermissionList.request),
    (
      state: TemplateState,
      _: ReturnType<typeof fetchTemplatePermissionList.request>
    ) => ({
      ...state,
      permission: null,
      error: null,
      isDone: false,
    })
  )
  .handleAction(
    getType(fetchTemplatePermissionList.success),
    (
      state: TemplateState,
      { payload }: ReturnType<typeof fetchTemplatePermissionList.success>
    ) => ({
      ...state,
      permission: payload.permission,
      isDone: true,
    })
  )
  .handleAction(
    getType(fetchTemplatePermissionList.failure),
    (
      state: TemplateState,
      { payload }: ReturnType<typeof fetchTemplatePermissionList.failure>
    ) => ({
      ...state,
      permission: null,
      error: payload.message,
      isDone: true,
    })
  )
  .handleAction(getType(clearToastMessage), (state: TemplateState) => ({
    ...state,
    toastMessage: null,
  }))
  .handleAction(
    getType(updateTemplatePermission.request),
    (
      state: TemplateState,
      _: ReturnType<typeof updateTemplatePermission.request>
    ) => ({
      ...state,
      error: null,
      toastMessage: null,
      isDone: false,
    })
  )
  .handleAction(
    getType(updateTemplatePermission.success),
    (
      state: TemplateState,
      { payload }: ReturnType<typeof updateTemplatePermission.success>
    ) => ({
      ...state,
      permission: payload.permission,
      toastMessage: payload.toastMsg,
      isDone: true,
    })
  )
  .handleAction(
    getType(updateTemplatePermission.failure),
    (
      state: TemplateState,
      { payload }: ReturnType<typeof updateTemplatePermission.failure>
    ) => ({
      ...state,
      error: payload.message,
      isDone: true,
    })
  )
  .handleAction(
    getType(fetchTemplateDashboard.request),
    (state: any, { payload }: any) => ({
      ...initialState,
      templates: state.templates,
      isDone: state.isDone,
    })
  )
  .handleAction(
    getType(fetchTemplateDashboard.success),
    (state: any, { payload }: any) => ({
      ...initialState,
      templates: state.templates,
      error: null,
      isDone: true,
      dashboard: payload,
    })
  )
  .handleAction(
    getType(fetchTemplateDashboard.failure),
    (state: any, { payload }: any) => ({
      ...state,
      error: payload,
      isDone: true,
    })
  )
  .handleAction(
    getType(fetchTemplateDashboard.cancel),
    (state: any, { payload }: any) => ({
      ...initialState,
      templates: state.templates,
      isDone: state.isDone,
    })
  )

export type TemplateAction =
  | ActionType<typeof fetchTemplateList>
  | ActionType<typeof createTemplate>
  | ActionType<typeof fetchTemplatePermissionList>
  | ActionType<typeof clearToastMessage>
  | ActionType<typeof updateTemplatePermission>
  | ActionType<typeof fetchTemplateDashboard>

const templateEpic = combineEpics<any>(
  fetchTemplateListEpic,
  createTemplateEpic,
  fetchTemplatePermissionListEpic,
  updateTemplatePermissionEpic,
  fetchTemplateDashboardEpic
)

export default templateEpic
