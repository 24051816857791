import { IonSpinner } from '@ionic/react'

const LoadingSpinner = () => {
  return (
    <div className="ion-text-center centered">
      <IonSpinner color="primary" name="crescent" />
    </div>
  )
}

export default LoadingSpinner
