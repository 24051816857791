import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonReorder,
  IonReorderGroup,
  IonSpinner,
  IonTitle,
  IonToolbar,
  isPlatform,
} from '@ionic/react'
import { checkmark, close } from 'ionicons/icons'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ScrollToTopButton from '../components/ScrollToTopButton'
import { DELAY_SHORT } from '../models/constants'
import './ReorderModal.scss'

interface ReorderModalProps<T> {
  isOpen: boolean
  initialItems: T[]
  getName: (item: T) => string
  onDone: (items: T[]) => void
  dismiss: () => void
}

function ReorderModal<T>({
  isOpen,
  initialItems,
  getName,
  onDone,
  dismiss,
}: ReorderModalProps<T>) {
  const { t } = useTranslation()
  const contentRef = useRef<HTMLIonContentElement>(null)
  const pageRef = useRef<HTMLIonContentElement>(null)
  const [items, setItems] = useState(initialItems)
  const [isPageLoading, setIsPageLoading] = useState(true)

  useEffect(() => {
    if (!isOpen) return
    setTimeout(() => {
      // Workaround ...
      pageRef.current?.classList.remove('ion-page-invisible')
    }, DELAY_SHORT)

    setIsPageLoading(false)
  }, [setIsPageLoading, isOpen])

  // 移動欄位的順序
  const onReorder = (event: CustomEvent) => {
    const { from, to } = event.detail
    event.detail.complete()
    setItems((oldItems) => {
      const items = [...oldItems]
      const item = items.splice(from, 1)
      items.splice(to, 0, item[0])
      return items
    })
  }

  return (
    <IonPage className="page-reorder-modal" ref={pageRef}>
      <IonHeader>
        <IonToolbar color="primary">
          <IonTitle>
            <IonButton className="logo immutable">
              {t('Suiqui Support')}
            </IonButton>
            <span className="subtitle" hidden={isPlatform('mobile')}>
              {t('Reorder')}
            </span>
          </IonTitle>
          <IonButtons slot="end">
            <IonButton strong fill="clear" slot="icon-only" onClick={dismiss}>
              <IonIcon size="large" icon={close}></IonIcon>
            </IonButton>
            <IonButton
              strong
              fill="clear"
              slot="icon-only"
              onClick={() => onDone(items)}
            >
              <IonIcon size="large" icon={checkmark}></IonIcon>
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent ref={contentRef} scrollEvents={false}>
        {isPageLoading ? (
          <div className="ion-text-center centered">
            <IonSpinner color="primary" name="crescent"></IonSpinner>
          </div>
        ) : (
          <div>
            <IonList>
              {/* 要重新排序的選項 */}
              <IonReorderGroup disabled={false} onIonItemReorder={onReorder}>
                {initialItems.map((item: any, index: number) => {
                  return (
                    <IonItem key={index}>
                      <IonLabel>{getName(item)}</IonLabel>
                      <IonReorder slot="end" />
                    </IonItem>
                  )
                })}
              </IonReorderGroup>
              {/* 回到最上面按鈕 */}
              <ScrollToTopButton contentRef={contentRef} />
            </IonList>
          </div>
        )}
      </IonContent>
    </IonPage>
  )
}

export default ReorderModal
