import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonItemDivider,
  IonLabel,
  IonPage,
  IonProgressBar,
  IonSpinner,
  IonTitle,
  IonToolbar,
  isPlatform,
} from '@ionic/react'
import useComponentSize from '@rehooks/component-size'
import FileSaver from 'file-saver'
import filesize from 'filesize'
import { close, cloudDownload, expand } from 'ionicons/icons'
import React, { useCallback, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { PDFObject } from 'react-pdfobject'
import { useDispatch, useSelector } from 'react-redux'
import { useFullscreen, useToggle } from 'react-use'
import VideoPlayer from '../components/VideoPlayer'
import {
  dataURLtoBlob,
  getDownloadUrl,
  getIFileProgressKey,
  getProgressBarColor,
  getProgressNumber,
  isFilePdf,
  openDocument,
} from '../helpers/file'
import { SUIQUI_DOWNLOAD_CACHE } from '../models/constants'
import { IFile, ISuiquiFile } from '../models/suiquiFile'
import { downloadFile } from '../store/epics/downloadFile'
import './FileViewerModal.scss'

const FileViewerModal: React.FC<any> = ({
  isOpen,
  dismiss,
  item,
  items,
  index,
  hideHeader,
}: {
  isOpen: boolean
  dismiss: any
  item: ISuiquiFile
  items: ISuiquiFile[]
  index: number
  hideHeader: boolean
}) => {
  const { t } = useTranslation()
  const contentRef = useRef(null)
  const pageRef = useRef(null)
  const dispatch = useDispatch()
  const { width, height } = useComponentSize(pageRef)
  const [obj, setObj] = useState<any>()
  const [isFetched, setIsFetched] = useState(false)
  const [isPageLoading, setIsPageLoading] = useState(true)
  const [isPDF, setIsPDF] = useState(false)
  const [isAudio, setIsAudio] = useState(false)
  const [show, toggle] = useToggle(false)
  const isFullscreen = useFullscreen(pageRef, show, {
    onClose: () => toggle(false),
  })

  const download = useSelector((state: any) => state.download)

  const cancel = () => {
    setObj({
      dataUrl: null,
    })
    setIsFetched(false)
    dismiss()
  }

  const getFile = useCallback(
    async (item) => {
      let resp, cache
      if ('caches' in window) {
        cache = await caches.open(SUIQUI_DOWNLOAD_CACHE)
        resp = await cache.match(item.url)
      }
      if (resp) {
        //cached
        //console.info('get cached file')
        const buf = await resp.blob()
        setObj({
          obj: window.URL.createObjectURL(buf),
        })

        setTimeout(() => {
          setIsPageLoading(false)
        }, 1000)
      } else {
        const resp = await fetch(item.url)
        const buf = await resp.blob()

        setObj({
          obj: window.URL.createObjectURL(buf),
        })

        if ('caches' in window && cache) {
          cache.put(item.url, new Response(buf))
        }

        setTimeout(() => {
          setIsPageLoading(false)
        }, 1000)
      }
    },
    [setIsPageLoading]
  )

  useMemo(() => {
    if (!isOpen) return
    if (!isPageLoading) return

    setTimeout(() => {
      // Workaround ...
      // @ts-ignore
      pageRef.current?.classList.remove('ion-page-invisible')
    }, 300)

    if (item) {
      if (item.content_type === 'application/pdf') {
        setIsPDF(true)
      } else if (item.content_type.startsWith('audio/')) {
        setIsAudio(true)
      }
      if (!isFetched) {
        getFile(item)
        setIsFetched(true)
      }
    }
  }, [isOpen, isPageLoading, item, getFile, isFetched])

  const execDownload = (
    event: React.MouseEvent<any, MouseEvent>,
    file: IFile,
    filename?: string
  ) => {
    event.preventDefault()

    const url = getDownloadUrl(file)

    if (!url) return

    if (url.startsWith('data:')) {
      FileSaver.saveAs(dataURLtoBlob(url), filename)
    } else {
      /* Native Download
      let link = window.document.createElement('a')
      link.href = '#'
      link.download = filename
      link.hidden = true
      window.document.body.appendChild(link)

      link.click() // Save

      window.document.body.removeChild(link)
      */

      const hash = getIFileProgressKey(file)

      dispatch(
        downloadFile.request({
          key: file.key,
          name: filename || '',
          url,
          hash,
          file,
        })
      )
    }
  }

  const getProgressNumberCallback = useCallback((pFile) => {
    return getProgressNumber(pFile)
  }, [])

  const pKey = getIFileProgressKey(item)
  return (
    <IonPage className="page-file-viewer-modal" ref={pageRef}>
      <IonHeader hidden={hideHeader}>
        <IonToolbar color="primary">
          <IonTitle>
            <IonButton class="logo immutable">{t('Suiqui Support')}</IonButton>
            <span className="subtitle">{t('File Viewer')}</span>
          </IonTitle>
          <IonButtons slot="end" hidden>
            <IonButton
              strong
              fill="clear"
              slot="icon-only"
              onClick={() => toggle()}
            >
              <IonIcon size="large" icon={expand}></IonIcon>
            </IonButton>
          </IonButtons>
          <IonButtons slot="end">
            {execDownload && openDocument && (
              <IonButton
                fill="solid"
                color="secondary"
                disabled={
                  !item ||
                  (download.pFiles[pKey] &&
                    download.pFiles[pKey]?.isDownloading &&
                    !download.pFiles[pKey]?.isDone)
                }
                onClick={(e) =>
                  isPlatform('ios') || (isPlatform('mobile') && isFilePdf(item))
                    ? openDocument(item)
                    : execDownload(e, item, item.name)
                }
              >
                <IonIcon slot="start" icon={cloudDownload}></IonIcon>
                <IonLabel>
                  {t('Download')}
                  {item && (
                    <span className="filesize">
                      ({filesize(item?.blob_info?.size || 0, { base: 2 })})
                    </span>
                  )}
                </IonLabel>
              </IonButton>
            )}
          </IonButtons>
          <IonButtons slot="end">
            <IonButton
              strong
              fill="clear"
              slot="icon-only"
              onClick={() => cancel()}
            >
              <IonIcon size="large" icon={close}></IonIcon>
            </IonButton>
          </IonButtons>

          <IonProgressBar
            hidden={!download.pFiles[pKey]}
            type={
              download.pFiles[pKey]?.isPending ? 'determinate' : 'determinate'
            }
            color={
              !download.pFiles[pKey]
                ? 'medium'
                : getProgressBarColor(download.pFiles[pKey])
            }
            value={getProgressNumberCallback(download.pFiles[pKey])}
          ></IonProgressBar>
        </IonToolbar>
        <IonItemDivider color="dark" className="ion-text-nowrap">
          {item?.name}
        </IonItemDivider>
      </IonHeader>
      <IonContent ref={contentRef} scrollEvents={false}>
        {isPageLoading ? (
          <div className="ion-text-center centered">
            <IonSpinner color="light" name="crescent"></IonSpinner>
          </div>
        ) : (
          <>
            {isAudio && (
              <div className="audio-container">
                <audio controls autoPlay>
                  <source
                    src={`${obj?.dataUrl || item.url}`}
                    type={item.content_type}
                  />
                </audio>
              </div>
            )}
            {isPDF && (
              <div className="pdf-container">
                <PDFObject
                  url={`${obj?.dataUrl || item.url}`}
                  fallbackLink={item.url}
                />
              </div>
            )}
            {!isPDF && !isAudio && (
              <div className="video-container">
                <VideoPlayer
                  options={{
                    sources: [
                      {
                        src: `${obj?.dataUrl || item.url}`,
                        type: `${item.content_type}`,
                      },
                    ],
                  }}
                />
              </div>
            )}
          </>
        )}
      </IonContent>
    </IonPage>
  )
}

export default FileViewerModal
