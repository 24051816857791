import { ajax } from 'rxjs/ajax'
import { map } from 'rxjs/operators'
import { API, JWT, PUBLIC_JSON, PUBLIC_UPLOAD } from '../helpers/base'
import { id } from '../models/base'
import { ETicketCommentUserType, ITicketComment } from '../models/comment'
import { IList } from '../models/template'

export const list = ({
  token,
  params,
}: {
  token: string
  params: { key: id } & Partial<IList> & Partial<ITicketComment>
}) => {
  return ajax({
    url: API('ticket/comment/list', 'SUPPORT'),
    method: 'POST',
    body: {
      ...params,
    },
    headers: JWT(token),
    responseType: 'json',
  })
}

export const get = ({
  token,
  params,
}: {
  token: string
  params: { key: id } & Partial<ITicketComment>
}) =>
  ajax({
    url: API('ticket/comment', 'SUPPORT'),
    method: 'POST',
    body: {
      ...params,
    },
    headers: JWT(token),
    responseType: 'json',
  })

export const add = ({
  token,
  params,
}: {
  token: string
  params: { key: id } & Partial<ITicketComment>
}) =>
  ajax({
    url: API('ticket/comment/add', 'SUPPORT'),
    method: 'POST',
    body: {
      ...params,
    },
    headers: JWT(token),
    responseType: 'json',
  })

export const getUploadUrl = ({ token, key }: { token: string; key: id }) => {
  return ajax({
    url: API(`ticket/comment/uploadurl`, 'SUPPORT'),
    method: 'POST',
    body: {
      key,
    },
    headers: JWT(token),
    responseType: 'json',
  })
    .pipe(map((res) => res.response))
    .pipe(map((res: { uploadurl: string }) => res.uploadurl))
}

export const getPublicUploadUrl = ({
  commentUserType,
  key,
}: {
  key: id
  commentUserType?: ETicketCommentUserType
}) => {
  return ajax({
    url: API(`public/ticket/comment/${commentUserType}/uploadurl`, 'SUPPORT'),
    method: 'POST',
    body: {
      key,
    },
    headers: PUBLIC_UPLOAD(),
    responseType: 'json',
  })
    .pipe(map((res) => res.response))
    .pipe(map((res: { uploadurl: string }) => res.uploadurl))
}

export const remove = ({
  token,
  params,
}: {
  token: string
  params: { key: id }
}) =>
  ajax({
    url: API('ticket/comment/delete', 'SUPPORT'),
    method: 'POST',
    body: {
      ...params,
    },
    headers: JWT(token),
    responseType: 'json',
  })

export const listPublic = ({
  params,
  commentUserType,
}: {
  commentUserType?: ETicketCommentUserType
  params: { key: id } & Partial<IList> & Partial<ITicketComment>
}) => {
  return ajax({
    url: API(`public/ticket/comment/list`, 'SUPPORT'),
    method: 'POST',
    body: {
      ...params,
    },
    headers: PUBLIC_JSON(),
    responseType: 'json',
  })
}

export const addPublic = ({
  commentUserType,
  params,
}: {
  commentUserType?: ETicketCommentUserType
  params: Partial<ITicketComment>
}) =>
  ajax({
    url: API(`public/ticket/comment/${commentUserType}/add`, 'SUPPORT'),
    method: 'POST',
    body: {
      ...params,
    },
    headers: PUBLIC_JSON(),
    responseType: 'json',
  })

export const getPublic = ({
  commentUserType,
  params,
}: {
  commentUserType?: ETicketCommentUserType
  params: { key: id } & Partial<ITicketComment>
}) =>
  ajax({
    url: API(`ticket/comment/${commentUserType}`, 'SUPPORT'),
    method: 'POST',
    body: {
      ...params,
    },
    headers: PUBLIC_JSON(),
    responseType: 'json',
  })

export const removePublic = ({
  commentUserType,
  params,
}: {
  commentUserType?: ETicketCommentUserType
  params: { key: id }
}) =>
  ajax({
    url: API(`public/ticket/comment/${commentUserType}/delete`, 'SUPPORT'),
    method: 'POST',
    body: {
      ...params,
    },
    headers: PUBLIC_JSON(),
    responseType: 'json',
  })
