import { IonChip, IonIcon, IonLabel } from '@ionic/react'
import { personOutline } from 'ionicons/icons'
import { useTranslation } from 'react-i18next'
import { inFunctionList } from '../helpers/base'
import { ETemplateFunction } from '../models/template'
import './TemplatePermissionChip.scss'

interface TemplatePermissionChipProps {
  functionList: ETemplateFunction[]
}

export default function TemplatePermissionChip({
  functionList,
}: TemplatePermissionChipProps) {
  const { t } = useTranslation()

  // 用戶權限為編輯者
  if (inFunctionList(ETemplateFunction.template_update, functionList)) {
    return (
      <IonChip className="immutable permission-chip" color="success">
        <IonIcon icon={personOutline} />
        <IonLabel>{t('writer')}</IonLabel>
      </IonChip>
    )
  }

  // 用戶權限為檢視者
  if (inFunctionList(ETemplateFunction.permission_read, functionList)) {
    return (
      <IonChip className="immutable permission-chip" color="secondary">
        <IonIcon icon={personOutline} />
        <IonLabel>{t('reader')}</IonLabel>
      </IonChip>
    )
  }

  // 用戶沒有任何權限
  return null
}
