import { date } from './base'

export const BASE_SERVICE_INTERVAL = 1000
export const BASE_SERVICE_CHECK_DELAY = 5 * 60 * 1000
export type BaseServiceAction = string

export interface IBaseService {
  created_date: date
  actions?: BaseServiceAction[]
}
