import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonPage,
  IonSpinner,
  IonTitle,
  IonToast,
  IonToolbar,
  isPlatform,
} from '@ionic/react'
import { checkmark, close } from 'ionicons/icons'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import * as yup from 'yup'
import { clone } from '../helpers/util'
import { DELAY_SHORT, DELAY_TOAST } from '../models/constants'
import { createTemplate } from '../store/epics/template'
import { TemplateState } from '../store/templateSlice'
import './TemplateCreateModal.scss'

const useYupValidationResolver: any = (validationSchema: any) =>
  React.useCallback(
    async (data) => {
      try {
        const values = await validationSchema.validate(data, {
          abortEarly: false,
        })

        return {
          values,
          errors: {},
        }
      } catch (errors) {
        return {
          values: {},
          errors: errors.inner.reduce(
            (allErrors: any, currentError: any) => ({
              ...allErrors,
              [currentError.path]: {
                type: currentError.type ?? 'validation',
                message: currentError.message,
              },
            }),
            {}
          ),
        }
      }
    },
    [validationSchema]
  )

const TemplateCreateModal: React.FC<any> = ({
  isOpen,
  dismiss,
}: {
  isOpen: boolean
  dismiss: any
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const contentRef = useRef(null)
  const pageRef = useRef(null)
  const template: TemplateState = useSelector((state: any) => state.template)

  const [isPageLoading, setIsPageLoading] = useState(true)
  const [showCreateTemplateToast, setShowCreateTemplateToast] = useState(false)

  const validationSchema = React.useMemo(
    () =>
      yup.object({
        name: yup.string().required(),
      }),
    []
  )

  const resolver = useYupValidationResolver(validationSchema)

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    getValues,
    formState: {errors, isValid},
    reset,
  } = useForm({
    mode: 'onChange',
    resolver,
  })

  useMemo(() => {
    if (!isOpen) return

    setTimeout(() => {
      // Workaround ...
      // @ts-ignore
      pageRef.current?.classList.remove('ion-page-invisible')
    }, DELAY_SHORT)

    setIsPageLoading(false)
  }, [setIsPageLoading, isOpen])

  useEffect(() => {
    register('name')
    register('memo')
  }, [register])

  useMemo(() => {
    if (!template.created) return
    setShowCreateTemplateToast(true)
  }, [template.created, setShowCreateTemplateToast])

  const done = () => {
    const formData = clone(getValues())
    dismiss(formData)
  }

  const handleChange = (e: any) => {
    setValue(e.target.name, e.target.value, { shouldValidate: true })
  }

  return (
    <IonPage className="page-template-create-modal" ref={pageRef}>
      <IonHeader>
        <IonToolbar color="primary">
          <IonTitle>
            <IonButton className="logo immutable">
              {t('Suiqui Support')}
            </IonButton>
            <span className="subtitle" hidden={isPlatform('mobile')}>
              {t('New Project')}
            </span>
          </IonTitle>
          <IonButtons slot="end">
            <IonButton
              strong
              fill="clear"
              slot="icon-only"
              onClick={() => dismiss()}
            >
              <IonIcon size="large" icon={close}></IonIcon>
            </IonButton>
            <IonButton
              strong
              fill="clear"
              slot="icon-only"
              onClick={() => done()}
              disabled={!isValid}
              data-test="confirm-btn"
            >
              <IonIcon size="large" icon={checkmark}></IonIcon>
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent ref={contentRef} scrollEvents={false}>
        {isPageLoading ? (
          <div className="ion-text-center centered">
            <IonSpinner color="primary" name="crescent"></IonSpinner>
          </div>
        ) : (
          <>
            <IonItem>
              <IonLabel position="stacked">{t('Project Name')}</IonLabel>
              <IonInput
                type="text"
                placeholder={t('Input project name')}
                name="name"
                onIonChange={handleChange}
                value={watch().name}
              ></IonInput>
            </IonItem>
          </>
        )}
      </IonContent>

      {template.created ? (
        <IonToast
          isOpen={showCreateTemplateToast}
          onDidDismiss={() => {
            setShowCreateTemplateToast(false)
            dispatch(createTemplate.cancel())
          }}
          message={t('“{{target}}” has been created successfully.', {
            target: template.name,
          })}
          duration={DELAY_TOAST}
        />
      ) : (
        <></>
      )}
    </IonPage>
  )
}

export default TemplateCreateModal
